/**
 * This file is for handling some important user functions
 */
import Parse from "parse/dist/parse.min.js";

export const destroyCreatedSession = async (sessionToken, user) =>{
    Parse.User.become(sessionToken)
    const sessionParams = {userId: user.id}
    await Parse.Cloud.run("destroySessions", sessionParams)
}

export const userAlreadyInAdminRole = (user, admins) => {
    const adminIds = admins.map((admin) => admin.id);
    return adminIds.includes(user.id);
}
