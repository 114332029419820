<template>
  <div class="panel-heading is-flex is-align-items-center is-justify-content-space-between">
    <div class="is-flex">
      <div>{{ panelHeader }}</div>
      <div
        v-if="hexColor"
        class="ml-2"
        style="width:30px; height:30px; border-radius: 5px"
        :style="{'background-color':hexColor, 'border':'1px solid black'}"
      />
    </div>
    <slot />
    <router-link
      v-if="routeName"
      :to="{name: routeName, params: routeParams}"
      class="m-0 p-0 is-pulled-right router-link"
    >
      <i
        class="mdi mdi-plus mdi-24px is-pulled-right is-bordered"
        style="line-height: 1"
      />
    </router-link>
  </div>
</template>

<script>
/**
 *  This file provides a header in a panel
 *  @param: header:String- title of header
 */
export default {
	name: "PanelHeader",
	props: {
		panelHeader: {
			type: String,
			required: true
		},
		hexColor: String,
		routeName: String,
		routeParams: Object,
	}
}
</script>

<style scoped>

.router-link {
	justify-self: end;
	background-color: transparent;
	height: auto;
	color: #363636;
	-webkit-appearance: none;
	align-items: center;
	border: 2px solid white;
	cursor: pointer;
	position: relative;
	vertical-align: top;
	border-radius: 4px;
	box-shadow: none;
	display: flex;
	justify-items: center;
}
</style>
