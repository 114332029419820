/**
 * This file is for converting hex colors
 * @param hexColor
 * @returns {string}
 */

export const getComplementaryHexColor = (hexColor) => {
	const colorMap = {
		"0": "F",
		"1": "E",
		"2": "D",
		"3": "C",
		"4": "B",
		"5": "A",
		"6": "9",
		"7": "8",
		"8": "7",
		"9": "6",
		"A": "5",
		"B": "4",
		"C": "3",
		"D": "2",
		"E": "1",
		"F": "0"
	}
	const hexEntries = hexColor.split("")
	let complHexString = "#"
	hexEntries.shift()
	for (const entry of hexEntries) {
		complHexString += colorMap[entry]
	}
	return complHexString
}

export function hslToHex(h, s, l) {
	l /= 100;
	const a = s * Math.min(l, 1 - l) / 100;
	const f = n => {
		const k = (n + h / 30) % 12;
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
	};
	return `#${f(0)}${f(8)}${f(4)}`;
}
