<template>
	<div>
		<LicenseKindTable />
	</div>
</template>

<script>

import LicenseKindTable from "@/components/licenseKind/LicenseKindTable";

export default {
	name: "AnAbWesenheitsgrund",
	components: { LicenseKindTable },
	data() {
		return {};
	},
	computed: {},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped></style>
