import XLSX from "xlsx/dist/xlsx.full.min";
import {SHEET_NAMES} from "@/lib/constants/Constants";

/**
 * Class for reading sheets from "Stammdaten.xlsx" with sheet.js aka xlsx library
 */
class ExcelWorkBook {
	constructor(workbook) {
		this.workbook = workbook
	}

	getClientsSheet(){
		return XLSX.utils.sheet_to_json(this.workbook.Sheets[SHEET_NAMES.CLIENT_SHEET]);
	}

	getSocialWorkerSheet(){
		return XLSX.utils.sheet_to_json(this.workbook.Sheets[SHEET_NAMES.SOCIAL_WORKER_SHEET]);
	}

	getUsersSheet(){
		return XLSX.utils.sheet_to_json(this.workbook.Sheets[SHEET_NAMES.USER_SHEET]);
	}

	getStatutoryRepresentativesSheet(){
		return XLSX.utils.sheet_to_json(this.workbook.Sheets[SHEET_NAMES.PERSON_SHEET]);
	}

	getGroupsSheet(){
		return XLSX.utils.sheet_to_json(this.workbook.Sheets[SHEET_NAMES.GROUP_SHEET]);
	}

	getDistrictsSheet(){
		return XLSX.utils.sheet_to_json(this.workbook.Sheets[SHEET_NAMES.DISTRICT_SHEET]);
	}

	getSchoolKindergartenSheet(){
		return XLSX.utils.sheet_to_json(this.workbook.Sheets[SHEET_NAMES.SCHOOL_SHEET]);
	}

	getStatutoryBasisSheet(){
		return XLSX.utils.sheet_to_json(this.workbook.Sheets[SHEET_NAMES.STATUTORY_BASIS_SHEET]);
	}
}

export default ExcelWorkBook
