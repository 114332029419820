<template>
	<div>
		<div>
			<SearchableTable :panel-header="'Bedarfsmedikamente'" :panel-route="'AddInstantMedication'"
				:show-search-bar="true" :table-data="instantMedicationData" :table-meta-data="tableMetaData"
				:query-string="'setInstantMedicationSearchQuery'" :event-value="parentEventValue">
				<b-table-column v-slot="props" label="Aktionen" cell-class="has-text-right truncate-column"
					header-class="th-right truncate-column">
					<b-button class="is-small mr-2" @click="triggerView(props.row)">
						<b-icon class="mt-1" icon="eye" style="cursor: pointer" />
					</b-button>
					<b-button class="is-small mr-2" @click="triggerEdit(props.row)">
						<b-icon class="mt-1" icon="pencil" style="cursor: pointer;" />
					</b-button>
					<b-button class="is-small" @click="triggerDelete(props.row)">
						<b-icon class="mt-1" icon="delete" style="cursor: pointer" />
					</b-button>
				</b-table-column>
			</SearchableTable>

			<b-modal v-model="isDeleteCheck">
				<div v-if="isDeleteCheck" class="panel m-5" style="background-color: white">
					<p class="panel-heading">
						Möchtest du "{{ chosenElement.attributes.medication.attributes.name }}" wirklich löschen?
					</p>
					<div class="buttons mt-2" style="display: flex; justify-content: center">
						<b-button class="is-danger" :loading="isLoading" @click="submitDelete()">
							Ja
						</b-button>
						<b-button class="is-primary" @click="closeModal">
							Nein
						</b-button>
					</div>
				</div>
			</b-modal>
		</div>
	</div>
</template>
 
<script>
/**
 * This file shows all currently stored instant medications and allows adding, editing, viewing and soft deleting these.
 */

import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import { mapGetters } from "vuex";
import SearchableTable from "@/components/helperComponents/SearchableTable.vue";
export default {
	name: "InstantMedicationTable",
	components: { SearchableTable },
	data() {
		return {
			parentEventValue: undefined,
			isDeleteCheck: false,
			chosenElement: {},
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "ref", "label": "Medikament", "field": "displayName", "sortField": "attributes.medication.attributes.displayName", "refName": "medication" },
				{ "id": 1, "type": "math", "label": "Bestand"},
				{ "id": 2, "type": "test", "label": "Verantwortlicher", "field": ["first_name", "last_name"], "sortField": "attributes.user.attributes.dependency.attributes.first_name", "outerRef": "user", "innerRef": "dependency" },
				{ "id": 3, "type": "date", "label": "Beginn", "field": "beginDate", "sortField": "attributes.beginDate" },
				{ "id": 4, "type": "date", "label": "Ende", "field": "endDate", "sortField": "attributes.endDate" },
				{ "id": 5, "type": "date", "label": "Ablaufdatum", "field": "expirationDate", "sortField": "attributes.expirationDate" },
				{ "id": 6, "type": "string", "label": "ID", "field": "number", "sortField": "attributes.number" },
			]
		};
	},
	computed: {
		...mapGetters({
			instantMedicationData: "getInstantMedication"
		})
	},
	mounted() { },
	methods: {
		triggerEdit(element) {
			this.$router.push({ name: "AddInstantMedication", params: { data: element } });
		},
		triggerView(element) {
			this.$router.push({ name: "ViewInstantMedication", params: { data: element } });
		},
		triggerDelete(element) {
			this.chosenElement = element;
			this.isDeleteCheck = true;
		},
		closeModal() {
			this.isDeleteCheck = false;
		},
		async submitDelete() {
			this.isLoading = true
			this.chosenElement.set("deleted", true);
			this.chosenElement.save().catch((e) => {
				negativeFeedBack("Bedarfsmedikation für das Medikament " + this.chosenElement.attributes.medication.name + " nicht gelöscht!")
			});
			await positiveFeedBack("Bedarfsmedikation für das Medikament " + this.chosenElement.attributes.medication.attributes.name + " gelöscht!")
			this.isLoading = false
			this.isDeleteCheck = false
		}
	},
};

</script>
  
<style scoped></style>
  