/**
 * This file is used for handling contact educators and contact clients
 */
export const checkContactEducator = async(client, groupPointer, staffMetas) => {
    const contactEducator = staffMetas.filter(usermeta =>{
        const clientIds = usermeta.attributes.clients.map(c=>c.id)
        return clientIds.includes(client.id)
    })
    const wrongGroupEducator = contactEducator.filter(usermeta => {
        const groupIds =usermeta.attributes.groups.map(g => g.id)
        return !groupIds.includes(groupPointer.objectId)
    })
    for(const educator of wrongGroupEducator){
        const clients = educator.attributes.clients.filter(c => c.id !== client.id)
        educator.set("clients", clients)
        await educator.save()
    }
}

export const checkContactClients = async (users) =>{
    for(const usermeta of users){
        const contactClients = usermeta.attributes.clients
        const userMetaGroupIds = usermeta.attributes.groups.map(g => g.id)
        const filteredContactClients = contactClients.filter(client => userMetaGroupIds.includes(client.attributes.group.id))
        usermeta.set("clients", filteredContactClients)
        await usermeta.save()
    }
}

