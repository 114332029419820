<template>
  <div class="borderContainer">
    <b-field label="Mitarbeiter">
      <b-autocomplete
        v-model="user"
        class="m-2"
        :data="userArray"
        placeholder="Mitarbeiter"
        icon="magnify"
        @input="e => userFilter = e"
        @select="option => chosenUsers.push(option)"
      >
        <template #empty>
          Keine Mitarbeiter gefunden
        </template>
        <template slot-scope="props">
          <div class="content">
            {{ props.option.attributes.dependency.attributes.first_name }}
            {{ props.option.attributes.dependency.attributes.last_name }}
          </div>
        </template>
      </b-autocomplete>
    </b-field>
    <div class="overflowY">
      <div
        v-for="(chosenUser) in chosenUsers"
        :key="chosenUser.id"
        class="m-2 pl-4"
        style="display: flex"
      >
        <div
          class="pr-2"
          @click="()=> filter(chosenUser.id)"
        >
          <b-icon
            icon="delete"
            style="cursor: pointer"
            type=""
          />
        </div>
        <p>
          {{ chosenUser.attributes.dependency.attributes.first_name }}
          {{ chosenUser.attributes.dependency.attributes.last_name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";


export default {
	name: "UserSelection",
	props: ["chosenUsers"],
	data() {
		return {
			user: "",
			userFilter: "",
		}
	},
	computed: {
		...mapGetters({
			users: "getStaffUsers"
		}),
		userArray() {
			if(!this.users) return []
			return this.users.filter(user => {
				const name = `${user.attributes.dependency.attributes.first_name} ${user.attributes.dependency.attributes.last_name}`.toLowerCase()
				const isFilterInName = this.userFilter && name.includes(this.userFilter.toLowerCase())
				const userIds = this.chosenUsers.map(u => u.id)
				return isFilterInName && !userIds.includes(user.id)
			})
		}
	},
	methods:{
		filter(id){
			this.$emit('filter', id)
		}
	}
}
</script>

<style scoped>
.borderContainer {
	border-radius: .5rem;
	padding: .5rem;
	margin: .5rem;
}

.overflowY{
	max-height: 10rem;
	overflow-y: auto;
}
</style>
