<template>
  <div>
    <SearchableTable
      :panel-header="'Eintragstypen'"
      :panel-route="'AddEntryType'"
      :show-search-bar="false"
      :table-data="entryTypes"
      :table-meta-data="tableMetaData"
      :query-string="'setPersonSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          v-if="!props.row.attributes.isStandardEntryType"
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
    <b-modal v-model="isDeleteEntryTypeCheck">
      <div
        v-if="isDeleteEntryTypeCheck"
        class="panel m-5"
        style="background-color: white"
      >
        <p class="panel-heading">
          Möchtest du den Eintragstyp
          "{{ chosenEntryType.attributes.name }}" wirklich löschen?
        </p>
        <div
          class="buttons mt-2"
          style="display: flex; justify-content: center"
        >
          <b-button
            class="is-danger"
            :loading="isLoading"
            @click="submitDeleteEntryType()"
          >
            Ja
          </b-button>
          <b-button
            class="is-primary"
            @click="isDeleteEntryTypeCheck = false"
          >
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="entryTypeInUsageWarning">
      <div
        v-if="entryTypeInUsageWarning"
        class="panel m-5"
        style="background-color: white"
      >
        <p class="panel-heading">
          Der Eintragstyp "{{ chosenEntryType.attributes.name }}" ist in
          Verwendung. Möchtest du ihn dennoch löschen?
        </p>
        <div
          class="buttons mt-2"
          style="display: flex; justify-content: center"
        >
          <b-button
            class="is-danger"
            :loading="isLoading"
            @click="submitSoftDeleteEntryType"
          >
            Ja
          </b-button>
          <b-button
            class="is-primary"
            @click="entryTypeInUsageWarning = false"
          >
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { queryEntries } from "@/lib/Query";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import SearchableTable from "../../helperComponents/SearchableTable.vue";

export default {
	name: "EntryTypes",
	components: { SearchableTable },
	data() {
		return {
			isDeleteEntryTypeCheck: false,
			chosenEntryType: undefined,
			entryTypeInUsageWarning: false,
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Eintragstyp", "field": "name", "sortField": "attributes.name" },
				{ "id": 1, "type": "boolean", "label": "Standart", "field": "isStandardEntryType", "true": "Ja", "false": "Nein" },
				{ "id": 2, "type": "color", "label": "Farbe", "field": "color" },
			]
		}
	},
	computed: {
		...mapGetters({
			entryTypes: "getEntryTypes"
		}),
	},
	methods: {
		triggerEdit(entryType) {
			this.$router.push({ name: "EditEntryType", params: { data: entryType } })
		},
		async triggerDelete(entryType) {
			this.chosenEntryType = entryType
			if (await this.entryTypeIsInUsage()) {
				this.entryTypeInUsageWarning = true
			} else {
				this.isDeleteEntryTypeCheck = true
			}
		},
		async entryTypeIsInUsage() {
			const entries = await queryEntries()
			for (const entry of entries) {
				if (entry.attributes.type && entry.attributes.type.id === this.chosenEntryType.id) {
					return true;
				}
			}
			return false
		},
		async submitDeleteEntryType() {
			this.isLoading = true
			try {
				this.chosenEntryType.set("deleted", true)
				await this.chosenEntryType.save()
				positiveFeedBack("Eintragstyp gelöscht!")
			} catch (e) {
				console.error("couldnt delete entry type " + e)
				negativeFeedBack("Eintragstyp nicht gelöscht!")
			}
			this.isDeleteEntryTypeCheck = false
			this.isLoading = false
		},
		async submitSoftDeleteEntryType() {
			this.isLoading = true
			this.chosenEntryType.set("deleted", true)
			try {
				await this.chosenEntryType.save()
				positiveFeedBack("Eintragstyp gelöscht!")
			} catch (e) {
				console.error("couldnt delete entry type " + e)
				negativeFeedBack("Eintragstyp nicht gelöscht!")
			}
			this.isLoading = false
			this.entryTypeInUsageWarning = false
		},
	}
}
</script>

<style scoped></style>