<template>
  <div>
    <SearchableTable
      :panel-header="'Globale Widgets'"
      :panel-route="'NewWidget'"
      :show-search-bar="false"
      :table-data="widgets"
      :table-meta-data="tableMetaData"
      :query-string="'setPersonSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          v-if="!props.row.attributes.isStandardEntryType"
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { queryEntries } from "@/lib/Query";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import SearchableTable from "../helperComponents/SearchableTable.vue";
import Parse from "parse/dist/parse.min.js";

export default {
	name: "WidgetOverview",
	components: { SearchableTable },
	data() {
		return {
			isDeleteEntryTypeCheck: false,
			chosenEntryType: undefined,
			entryTypeInUsageWarning: false,
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Link-Name", "field": "title", "sortField": "attributes.title" },
				{ "id": 1, "type": "string", "label": "Link", "field": "link", "sortField": "attributes.link" },
				{ "id": 2, "type": "icon", "label": "Link-Icon", "field": "icon", "sortField": "attributes.icon" }
			]
		}
	},
	computed: {
		...mapGetters({
			widgets: "getWidgets"
		}),
	},
	mounted() {},	
	methods: {
		triggerEdit(widget) {
			this.$router.push({ name: "EditWidget", params: { data: widget } })
		},
		async triggerDelete(entryType) {
			const widgetParams = {
				widgetID: entryType.id
			};
			await Parse.Cloud.run("deleteWidget", widgetParams);
		},
		async entryTypeIsInUsage() {
			const entries = await queryEntries()
			for (const entry of entries) {
				if (entry.attributes.type && entry.attributes.type.id === this.chosenEntryType.id) {
					return true;
				}
			}
			return false
		},
		async submitDeleteEntryType() {
			this.isLoading = true
			try {
				this.chosenEntryType.set("deleted", true)
				await this.chosenEntryType.save()
				positiveFeedBack("Widget gelöscht!")
			} catch (e) {
				console.error("couldnt delete entry type " + e)
				negativeFeedBack("Widget nicht gelöscht!")
			}
			this.isDeleteEntryTypeCheck = false
			this.isLoading = false
		},
		async submitSoftDeleteEntryType() {
			this.isLoading = true
			this.chosenEntryType.set("deleted", true)
			try {
				await this.chosenEntryType.save()
				positiveFeedBack("Widget gelöscht!")
			} catch (e) {
				console.error("couldnt delete entry type " + e)
				negativeFeedBack("Widget nicht gelöscht!")
			}
			this.isLoading = false
			this.entryTypeInUsageWarning = false
		},
	}
}
</script>

<style scoped></style>