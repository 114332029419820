<template>
  <div class="panel m-5">
    <PanelHeader panel-header="Neuer Eintragstyp" />
    <div class="container pt-3 pb-3">
      <div class="columns">
        <InputWithValidation
          class="column"
          message="Bitte gib einen Eintragstyp als Namen ein!"
          :name="entryTypeName"
          :set-name="(name)=> entryTypeName = name"
          label="Eintragstyp"
        />

        <ColorPicker
          :colors="colors"
          :set-colors="val=> colors=val"
          class="column"
        />

        <SelectionComponent
          class="column"
          :options="['Ja','Nein']"
          label="Standard"
          :select="val=> standardType = val"
          :selected-option="standardType"
        />
      </div>
    </div>

    <div
      class="buttons m-5"
      style="display: flex; justify-content: center"
    >
      <SaveButton
        :disabled="$v.entryTypeName.$invalid"
        :save-function="addAttribute"
        :is-loading="isLoading"
      />
      <CancelButton
        back-route="/diary"
        text="Abbrechen"
      />
    </div>
  </div>
</template>

<script>
/**
 * This file is for creating a new entry type
 */
import Parse from "parse/dist/parse.min.js";
import {queryStandardEntryTypes} from "../../../lib/Query";
import {minLength, required} from "vuelidate/lib/validators";
import {negativeFeedBack, positiveFeedBack} from "@/lib/Toast";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import ColorPicker from "@/components/helperComponents/ColorPicker";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import SelectionComponent from "@/components/helperComponents/SelectionComponent";

export default {
	name: "NewEntryType",
	components: {
		SelectionComponent,
		SaveButton,
		CancelButton,
		ColorPicker,
		PanelHeader,
		InputWithValidation
	},
	data() {
		return {
			entryTypeName: "",
			colors: {
				"hex": "#000000",
				"source": "hex"
			},
			colorPickerOpen: false,
			isLoading: false,
			standardType: "Ja"
		}
	},
	validations: {
		entryTypeName: {
			required,
			minLength: minLength(1)
		},
	},
	methods: {
		async addAttribute() {
			this.isLoading = true
			if(this.$v.entryTypeName.$invalid){
				positiveFeedBack("Bitte gib einen Eintragstypen als Namen ein!")
			}else{
				if (this.standardType === "Ja") {
					const standardEntryTypes = await queryStandardEntryTypes()
					for (const standardEntryType of standardEntryTypes) {
						standardEntryType.set("isStandardEntryType", false)
						try {
							await standardEntryType.save()
						} catch (e) {
							console.error("couldnt change other standard entry types" + e)
						}
					}
				}

				const EntryTypes = Parse.Object.extend("EntryTypes")
				const entryAttribute = new EntryTypes()
				entryAttribute.set("name", this.entryTypeName.trim())
				entryAttribute.set("isStandardEntryType", this.standardType === "Ja")
				entryAttribute.set("color", this.colors.hex)
				try {
					await entryAttribute.save()
					positiveFeedBack("Neuer Eintragstyp angelegt!")
					await this.$router.push({path: "/diary"})
				} catch (e) {
					console.error("entry type couldnt be created" + e)
					negativeFeedBack("Neues Eintragstyp wurde nicht angelegt!")
				}
			}
			this.isLoading = false

		}
	}
}
</script>

<style lang="scss" scoped>

</style>
