<template>
  <div>
    <template v-if="props.row.entity">
      <div class="is-flex is-justify-content-center is-align-items-center">
        <div
          style="cursor: pointer"
          @click="props.row.entity = undefined"
        >
          <b-icon
            icon="delete"
            color="black"
          />
        </div>
        <div v-if="props.row.entity.attributes.group_name">
          {{ props.row.entity.attributes.group_name }}
        </div>
        <div v-else>
          {{ props.row.entity.attributes.first_name }} {{ props.row.entity.attributes.last_name }}
        </div>
      </div>
    </template>
    <b-field
      v-else
      type="is-danger"
      message="Bitte wähle einen Klienten oder eine Gruppe"
    >
      <b-autocomplete
        placeholder="Entität"
        :data="props.row.processedEntities"
        icon="magnify"
        field="name"
        :clearable="true"
        :open-on-focus="true"
        @input="val => props.row.processedEntities = props.row.entities.filter(e => {
          if(!val) return true
          if(e.attributes.group_name) return e.attributes.group_name.toLowerCase().includes(val.toLowerCase())
          return `${e.attributes.first_name} ${e.attributes.last_name}`.toLowerCase().includes(val.toLowerCase())
        })"
        @select="(option) => option? props.row.entity = option : props.row.entity = undefined"
      >
        <template #default="props">
          <div v-if="props.option.attributes.group_name">
            {{ props.option.attributes.group_name }}
          </div>
          <div v-else>
            {{ props.option.attributes.first_name }} {{ props.option.attributes.last_name }}
          </div>
        </template>
      </b-autocomplete>
    </b-field>
  </div>
</template>

<script>
export default {
	name: "FirstColumn",
	props: ["props"]
}
</script>

<style scoped>

</style>