<template>
  <div class="bodyContainer">
    <b-field
      :message="fileValue === undefined ? 'Bitte lade eine Datei hoch!' : ''"
      type="is-danger"
    >
      <b-upload
        v-model="fileValue"
        drag-drop
        accept=".xlsx"
        style="display: flex; flex-direction: column; justify-content: center;gap: .5rem; align-items: center"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon
                icon="upload"
                size="is-large"
              />
            </p>
            <slot />
          </div>
        </section>
      </b-upload>
      <b-tag
        v-if="fileValue"
        style="background-color: rgba(0,0,0,.8); color: white; user-select: none"
      >
        {{ fileValue.name }}
      </b-tag>
    </b-field>
  </div>
</template>

<script>
export default {
	name: "UploadSection",
	props:["file"],
	emits: ["updateFile"],
	computed:{
		fileValue:{
			get() {
				return this.file
			},
			set(val){
				this.$emit("updateFile", val)
			}
		}
	},
};
</script>

<style scoped>

</style>
