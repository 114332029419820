<template>
	<div>
		<div v-if="dataObject" class="panel m-5">
			<PanelHeader panel-header="Arzt" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<div class="column">
						<b-field label="Name">
							<p class="content">
								{{ doctorName }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Spezialisierung">
							<p class="content">
								{{ doctorSpecialization }}
							</p>
						</b-field>
					</div>
				</div>
				<hr>
				<div class="columns">
					<div class="column">
						<b-field label="Straße, Hausnummer">
							<p class="content">
								{{ doctorStreet }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="PLZ">
							<p class="content">
								{{ doctorPlz }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Ort">
							<p class="content">
								{{ doctorLocation }}
							</p>
						</b-field>
					</div>
				</div>
			</div>
		</div>
		<div>
			<section class="m-5">
				<b-button class="is-danger" tag="router-link" to="/doctor">
					Zurück
				</b-button>
			</section>
		</div>
	</div>
</template>
  
<script>
/**
 * This file is for viewing a focused doctor.
 */

import PanelHeader from "@/components/helperComponents/PanelHeader";

export default {
	name: "ViewDoctor",
	components: {
		PanelHeader,
	},
	data() {
		return {
			dataObject: undefined,
			doctorName: "",
			doctorSpecialization: "",
			doctorStreet: "",
			doctorPlz: "",
			doctorLocation: "",
		};
	},
	computed: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.dataObject = this.$route.params.data
			this.doctorName = this.dataObject.attributes.name
			this.doctorSpecialization = this.dataObject.attributes.specialization?.attributes.specialization
			this.doctorStreet = this.dataObject.attributes.street
			this.doctorPlz = this.dataObject.attributes.plz
			this.doctorLocation = this.dataObject.attributes.location
		}
	},
	methods: {
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}
}
</script>
  
<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
  