<template>
  <div class="panel m-5">
    <PanelHeader :panel-header="header" />
    <div class="p-2">
      <div class="columns">
        <InputWithValidation
          class="column"
          label="Bezeichnung"
          :name="documentName"
          :set-name="(val)=>documentName = val"
          message="Btte gib eine Dokumentenbezeichnung ein!"
        />
        <SelectionComponent
          :key="documentInputType"
          class="column"
          label="Posteingang/Postausgang"
          :options="['Eingang', 'Ausgang', 'Intern', 'Extern', 'Posteingang', 'Postausgang']"
          :selected-option="documentInputType"
          :select="data => documentInputType = data"
        />
      </div>
      <div class="columns p-2">
        <DatePicker
          :key="documentDate.toLocaleDateString()"
          class="column"
          label="Datum"
          :selected-date="documentDate"
          :select="date => documentDate = date"
        />
        <InputWithValidation
          class="column"
          label="Ersteller"
          message="Bitte einen Ersteller hinzufügen!"
          :set-name="val => creator = val"
          type="text"
          :name="creator"
        />
        <div class="column">
          <AutoSelection
            label="Kinder"
            :data="filteredClients"
            :select="addClient"
            empty-text="Keine Kinder"
            attribute-name="first_name"
            second-attribute-name="last_name"
            :input="(data)=>filter = data"
          />
          <div
            v-for="(chosenClient) in chosenClients"
            :key="chosenClient.id"
            class="m-2"
            style="display: flex; align-items: center"
          >
            <IconButton
              v-if="chosenClient.id !== client.id"
              icon="delete"
              :trigger-function="()=> deleteClient(chosenClient.id)"
              size="is-small"
              class="mr-1"
            />
            <p>{{ chosenClient.attributes.first_name }} {{ chosenClient.attributes.last_name }}</p>
          </div>
        </div>
      </div>
      <div class="columns">
        <FileDelete
          v-if="pdf !== undefined"
          class="column m-2"
          :text="pdf.name"
          :trigger-delete="()=> pdf = undefined"
        />
        <SingleUpload
          v-else
          :key="pdf? pdf.name : ''"
          class="column m-2"
          :data="pdf"
          :is-loading="isLoading"
          accept=".pdf"
          :set-loading="setLoading"
          :set-pdf="addPdf"
          label="PDF"
          message="Bitte lade eine PDF-Datei hoch!"
        />
      </div>
      <section
        class="m-5 buttons"
        style="display: flex; justify-content: center"
      >
        <b-button
          v-if="isEdit"
          type="is-primary"
          :disabled="$v.documentName.$invalid || $v.creator.$invalid ||
            $v.pdf.$invalid"
          @click="edit"
        >
          Bearbeiten
        </b-button>
        <SaveButton
          v-else
          :is-loading="isLoading"
          :save-function="save"
          :disabled="$v.documentName.$invalid || $v.creator.$invalid ||
            $v.pdf.$invalid"
        />

        <CancelButton
          :key="isEdit"
          :text="isEdit ? 'Bearbeiten beenden' : 'Zurück'"
          :trigger-function="isEdit ? endEditing : back"
          :is-loading="false"
        />
      </section>
      <PdfTable
        v-if="isMounted"
        :pdfs="clientFiles"
        :delete-pdf="deletePdf"
        :edit-pdf="editPdf"
      />
    </div>
    <ModalComponent
      v-if="isDeleteCheck"
      :is-loading="isLoading"
      :cancel="()=> isDeleteCheck = false"
      :item="fileDeleteText"
      :is-delete-check="isDeleteCheck"
      :submit="deleteFile"
    />
  </div>
</template>

<script>
/**
 * This file allows uploading of pdf files for clients
 */
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import PdfTable from "@/components/clientRecords/PdfTable";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import {positiveFeedBack} from "@/lib/Toast";
import {v4 as uuid} from "uuid";
import Parse from 'parse/dist/parse.min.js';
import SelectionComponent from "@/components/helperComponents/SelectionComponent";
import DatePicker from "@/components/helperComponents/DatePicker";
import ModalComponent from "@/components/helperComponents/ModalComponent";
import { queryClientFilesByClientAndCategory, queryClientFilesByUUid,
	queryClients} from "@/lib/Query";
import AutoSelection from "@/components/helperComponents/AutoSelection";
import IconButton from "@/components/helperComponents/IconButton";
import SingleUpload from "@/components/clientRecords/recordHelperComponents/SingleUpload";
import {required} from "vuelidate/lib/validators";
import FileDelete from "@/components/clientRecords/recordHelperComponents/FileDelete";
import {cloneObject} from "@/lib/ParseObjects";

export default {
	name: "FocusedClientCategory",
	components: {
		FileDelete,
		PdfTable,
		SingleUpload,
		IconButton,
		AutoSelection,
		ModalComponent,
		DatePicker, SelectionComponent, InputWithValidation,  SaveButton, CancelButton, PanelHeader},
	data(){
		return{
			clientFiles:[],
			pdf:undefined,
			isLoading: false,
			documentName: undefined,
			documentDate: new Date(),
			documentInputType: "Eingang",
			creators:[],
			creator: "",
			clients:[],
			chosenClients:[],
			filter:"",
			category: undefined,
			newClientFile: undefined,
			isMounted: false,
			isEdit: false,
			isDeleteCheck: false,
			focusedClientFile: undefined,
			clientFilesEdit: [],
			focusedClientFiles: []
		}
	},
	validations: {
		documentName:{
			required
		},
		documentInputType:{
			required
		},
		documentDate:{
			required
		},
		creator:{
			required
		},
		pdf:{
			required
		}
	},
	computed:{
		name(){
			return `${this.client.attributes.first_name} ${this.client.attributes.last_name}`
		},
		client(){
			return this.$store.getters.getFocusedClient
		},
		filteredClients(){
			return this.clients.filter(client => {
				const chosenIds = this.chosenClients.map(c => c.id)
				const name = `${client.attributes.first_name} ${client.attributes.last_name}`.toLowerCase()
				const filter = this.filter ? this.filter.toLowerCase() : ""
				return !chosenIds.includes(client.id) && name.includes(filter)
			})
		},
		header(){
			if(!this.category) return ""
			return `${this.category.attributes.name} - ${this.name}`
		},
		fileDeleteText(){
			let namesString = ""
			let index = 0
			for(const clientFile of this.focusedClientFiles){
				const clientName = `${clientFile.attributes.client.attributes.first_name} ${clientFile.attributes.client.attributes.last_name}`
				namesString += clientName
				if(index < this.focusedClientFiles.length-2){
					namesString += "; "
				}
				if(index === this.focusedClientFiles.length-2){
					namesString += " und "
				}
				index++
			}
			return `${this.focusedClientFile.attributes.title} für ${namesString}`
		}
	},
	async created(){
		if(!this.$route.params.data || !this.client) return await this.$router.push({ path: "/client_records" });
		this.category = this.$route.params.data
		// this.creators = await queryDocumentCreators()
		this.clients = await queryClients()
		this.chosenClients.push(this.client)

		this.reset()

		this.clientFiles = await queryClientFilesByClientAndCategory(this.client, this.category)
		this.isMounted = true
	},
	methods:{
		async edit(){
			this.isLoading = true
			this.clientFiles = this.clientFiles.filter(file => file.id !== this.newClientFile.id)
			for(const clientFile of this.clientFilesEdit){
				clientFile.set("deleted", true)
				await clientFile.save()
			}
			this.isEdit = false
			await this.save()
		},
		async save(){
			this.isLoading = true
			if(!this.pdf.isParseFile && this.newClientFile.attributes.pdf){
				const pdfFile = this.newClientFile.attributes.pdf
				await pdfFile.save()
			}
			// const creator = await queryDocumentCreator(this.creator.objectId)
			this.newClientFile.set("creator", this.creator)
			this.newClientFile.set("title", this.documentName)
			this.newClientFile.set("documentType", this.documentInputType)
			this.newClientFile.set("date", this.documentDate)
			this.newClientFile.set("category", this.category)
			this.newClientFile.set("readers", [])
			this.newClientFile.set("uuid", uuid())
			for(const client of this.chosenClients){
				const clonedClientFile = cloneObject(this.newClientFile)
				clonedClientFile.set("client", client)
				const savedFile = await clonedClientFile.save()

				const params = {
					id: savedFile.id
				}
				await Parse.Cloud.run("readPdf", params)
				if(client.id === this.client.id) this.clientFiles.push(clonedClientFile)
			}

			this.reset()
			this.isLoading = false
			this.isEdit ? positiveFeedBack("Datei angelegt!") :positiveFeedBack("Datei bearbeitet!")
		},
		reset(){
			this.creator = ""// undefined
			this.documentName = undefined
			this.documentInputType = "Eingang"
			this.documentDate = new Date()
			this.chosenClients = [this.client]
			this.pdf = undefined
			const ClientFile = Parse.Object.extend("ClientFile")
			this.newClientFile = new ClientFile()
		},
		async deletePdf(instance){
			this.focusedClientFile = instance
			this.focusedClientFiles = await queryClientFilesByUUid(instance.attributes.uuid)
			this.isDeleteCheck = true
		},
		setLoading(isLoading){
			this.isLoading =isLoading
		},
		addPdf(pdf){
			const pdfFile = new Parse.File(uuid(), pdf);
			this.newClientFile.set("pdfTitle", pdf.name);
			this.newClientFile.set("pdf", pdfFile);
			this.newClientFile.set("pdfLastModifiedDate", pdf.lastModifiedDate);
			this.newClientFile.set("pdfSize", pdf.size);
			this.pdf = pdf
		},
		addClient(client){
			this.chosenClients.push(client)
		},
		deleteClient(id){
			this.chosenClients = this.chosenClients.filter(client =>client.id !== id)
		},
		async editPdf(clientFile){
			this.clientFilesEdit = await queryClientFilesByUUid(clientFile.attributes.uuid)
			this.documentName = clientFile.attributes.title
			this.documentInputType = clientFile.attributes.documentType
			this.documentDate = clientFile.attributes.date
			this.creator = clientFile.attributes.creator //.toPointer()
			this.chosenClients = this.clientFilesEdit.map(file => file.attributes.client)
			this.newClientFile = clientFile
			this.pdf = clientFile.attributes.pdf
			this.pdf.name = clientFile.attributes.pdfTitle
			this.pdf.isParseFile = true
			this.isEdit = true
		},
		endEditing(){
			this.reset()
			this.isEdit = false
		},
		async deleteFile(){ //Delete files for all attached clients (for now)
			for(const clientFile of this.focusedClientFiles){
				clientFile.set("deleted", true)
				await clientFile.save()
			}
			this.clientFiles = this.clientFiles.filter((file) => file.id !== this.focusedClientFile.id)
			positiveFeedBack("Datei gelöscht!")
			this.isDeleteCheck = false
		},
		async back(){
			await this.$router.push({name: "EditClientRecords", params: {data: this.category}});
		}
	}
}
</script>

<style scoped>

</style>
