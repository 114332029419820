<template>
	<div>
		<div class="panel m-5">
			<div class="panel-heading is-flex is-align-items-center is-justify-content-space-between">
				<div class="is-flex">
					<div>Einzahlung</div>
				</div>
			</div>
			<div class="container pt-3 pb-3">
				<div class="bodyContainer">
					<div class="columns">
						<UploadSection class="column" :file="file" @updateFile="val => file = val">
							<p>Budgets</p>
						</UploadSection>
						<div class="column">
							<BalanceGroupSelection @input="setBalance" />
							<AccountSelection :accounts="accounts" :selected-account-value="selectedAccount"
								@input="val => selectedAccount = val" />
						</div>
					</div>
				</div>
			</div>
			<div class="p-2">
				<b-button :disabled="!file" type="is-primary" @click="processFile">
					Vorschau
				</b-button>
			</div>

			<div v-if="columns.length > 0" class="is-flex is-justify-content-center">
				<div style="max-width:70vw; max-height: 40vh; overflow: auto" class="pb-5">
					<b-table narrowed :data="data" striped>
						<b-table-column v-if="selectedBalance" :key="-1" v-slot="props" label="Instanz" centered>
							<FirstColumn :props="props" />
						</b-table-column>
						<b-table-column v-for="(columnObj, idx) in columns" :key="columnObj.id" :field="columnObj.field"
							:label="columnObj.label" centered>
							<template #header="{ column }">
								<HeaderSelection :column="column" :idx="idx" :column-obj="columnObj"
									:selected-balance="selectedBalance" />
							</template>
							<template #default="props">
								{{ getColumnValue(props.row[columnObj.field]) }}
							</template>
						</b-table-column>
					</b-table>
				</div>
			</div>
			<StockUpSave :data="data" :selected-account="selectedAccount" :selected-balance="selectedBalance"
				:columns="columns" @save="save" />
		</div>
		<div class="panel m-5">
			<div class="panel-heading is-flex is-align-items-center is-justify-content-space-between">
				<div class="is-flex">
					<div>Händische Einzahlung</div>
				</div>
			</div>
			<div>
				<div class="container pt-3 pb-3">
					<BalanceGroupSelection ref="manualBalance" @input="setManualBalance" />
					<AccountSelection :accounts="manualAccounts" :selected-account-value="selectedManualAccount"
						@input="val => selectedManualAccount = val" />
				</div>

				<div v-if="selectedManualBalance">
					<div class="container pt-3 pb-3">
						<div class="bodyContainer">
							<div class="columns">
								<div class="column">
									<div class="align-content-center" style="display: inline-flex">
										<p>Geldbetrag (in €)</p>
									</div>
									<b-field :message="manualBudget ? '' : 'Bitte trage einen Geldbetrag ein!'"
										:type="viableManualBudget ? 'is-success' : 'is-danger'">
										<b-input style="margin:auto; max-width:250px;" ref="test" v-model="manualBudget"
											placeholder="Geldbetrag eintragen" pattern="([0-9]+(,[0-9]{0,2})?)"
											validation-message=" Bitte korrekten Betrag eingeben: Zeichenfolge: ([0-9]+(,[0-9]{0,2})?)" />
									</b-field>
								</div>
								<div class="column">
									<div class="align-content-center" style="display: inline-flex">
										<p>Budget</p>
									</div>
									<b-field :message="selectedBudget ? '' : 'Bitte wähle ein Budget!'"
										:type="selectedBudget != undefined ? 'is-success' : 'is-danger'">
										<b-dropdown v-model="selectedBudget" aria-role="list">
											<template #trigger>
												<b-button
													:label="selectedBudget == undefined ? 'Budget' : selectedBudget.attributes.name"
													outlined :icon-right="active ? 'menu-up' : 'menu-down'"
													:type="selectedBudget != undefined ? 'is-success' : 'is-danger'" />
											</template>
											<b-dropdown-item
												v-for="(budgetItem) in selectedManualBalance.attributes.budgets"
												:key="budgetItem.id" :value="budgetItem" aria-role="listitem">
												{{
													budgetItem.attributes.name
												}}
											</b-dropdown-item>
										</b-dropdown>
									</b-field>
								</div>
								<div class="column">
									<div class="align-content-center" style="display: inline-flex">
										<p>Client</p>
									</div>
									<b-field :message="selectedClient ? '' : 'Bitte wähle einen Clienten!'"
										:type="selectedClient != undefined ? 'is-success' : 'is-danger'">
										<b-dropdown v-model="selectedClient" aria-role="list">
											<template #trigger>
												<b-button
													:label="selectedClient == undefined ? 'Client' : selectedClient.attributes.first_name + ' ' + selectedClient.attributes.last_name"
													outlined :icon-right="active ? 'menu-up' : 'menu-down'"
													:type="selectedClient != undefined ? 'is-success' : 'is-danger'" />
											</template>
											<b-dropdown-item
												v-for="(clientItem) in selectedManualBalance.attributes.entities"
												:key="clientItem.id" :value="clientItem" aria-role="listitem" :class="{'disabled_client' : clientItem.attributes.inactive}">
												<template v-if="clientItem.attributes.first_name">
													{{
														clientItem.attributes.first_name + " " +
														clientItem.attributes.last_name
													}}
												</template>
												<template v-else>
													{{
														clientItem.attributes.group_name
													}}
												</template>

											</b-dropdown-item>
										</b-dropdown>
									</b-field>
								</div>
							</div>
						</div>
					</div>
				</div>
				<b-button class="mb-3" ref="safeButton" :disabled="enableManualSave" @click="manualStockUp">
					Einzahlung speichern
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import UploadSection from "@/components/helperComponents/UplaodSection";
import BalanceGroupSelection from "@/components/balance/stockUp/BalanceGroupSelection";
import AccountSelection from "@/components/balance/stockUp/AccountSelection";
import StockUpImport from "@/lib/stopckUp/StockUpImport";
import XLSX from "xlsx/dist/xlsx.full.min";
import Parse from "parse/dist/parse.min"
import StockUpSave from "@/components/balance/stockUp/StockUpSave";
import FirstColumn from "@/components/balance/stockUp/FirstColumn";
import HeaderSelection from "@/components/balance/stockUp/HeaderSelection";
import { mapGetters } from "vuex";

export default {
	name: "StockUp",
	components: { HeaderSelection, FirstColumn, StockUpSave, AccountSelection, BalanceGroupSelection, UploadSection },
	data() {
		return {
			file: undefined,
			selectedBalance: undefined,
			selectedAccount: undefined,
			accounts: [],
			isLoading: false,
			data: [],
			columns: [],
			columns2: [],
			selectedBudget: undefined,
			active: false,
			selectedClient: undefined,
			manualBudget: '',
			selectedManualBalance: undefined,
			selectedManualAccount: undefined,
			manualAccounts: []
		}
	},
	computed: {
		...mapGetters({
			user: "getCurrentUser",
		}),
		enableManualSave() {
			return this.manualBudget == '' || this.selectedBudget == undefined || this.selectedManualAccount == undefined || this.selectedClient == undefined || !(this.$refs.test && this.$refs.test.isValid)
		},
		viableManualBudget() {
			return this.manualBudget != '' && (this.$refs.test && this.$refs.test.isValid)
		}
	},
	watch: {
		selectedBalance(newBalance) {
			if (this.columns.length === 0) return
			this.columns.forEach(c => c.value = undefined)
			this.selectedAccount = undefined
			for (const column of this.columns) {
				column.value = newBalance.attributes.budgets.find(b => b.attributes.name.toLowerCase().includes(column.label.toLowerCase()))
			}
			for (const row of this.data) {
				row.processedEntities = [...newBalance.attributes.entities]
				row.entities = [...newBalance.attributes.entities]
				row.entity = row.entities.find(e => {
					if (e.attributes.group_name) return `${e.attributes.group_name}`.toLowerCase().includes(row.label.toLowerCase())
					return `${e.attributes.first_name} ${e.attributes.last_name}`.toLowerCase().includes(row.label.toLowerCase())
				})
			}
		},
		columns(newColumns) {
			if (!this.selectedBalance) return
			for (const column of newColumns) {
				column.value = this.selectedBalance.attributes.budgets.find(b => b.attributes.name.toLowerCase().includes(column.label.toLowerCase()))
			}
		},
		data(newData) {
			if (!this.selectedBalance) return
			for (const row of newData) {
				row.processedEntities = [...this.selectedBalance.attributes.entities]
				row.entities = [...this.selectedBalance.attributes.entities]
				row.entity = row.entities.find(e => {
					if (e.attributes.group_name) return `${e.attributes.group_name}`.toLowerCase().includes(row.label.toLowerCase())
					return `${e.attributes.first_name} ${e.attributes.last_name}`.toLowerCase().includes(row.label.toLowerCase())
				})
			}
		}
	},
	methods: {
		async save() {
			const typQuery = new Parse.Query('BalanceTypes');
			const typResult = await typQuery.find();

			let typEinzahlung = "";

			for (var i = 0; i < typResult.length; i++) {
				if (typResult[i].attributes.title == "Einzahlung") {
					typEinzahlung = typResult[i];
				}
			}

			const Balance = Parse.Object.extend("Balance")
			for (const row of this.data) {
				for (const column of this.columns) {
					const reference_counter = await this.calculate_next_balance_number("Einzahlung", this.selectedBalance, new Date());
					const reference = new Date().getFullYear().toString().slice(-2) + "_" + this.selectedBalance.attributes.balanceNumber + "_" + reference_counter[1] + reference_counter[0].toString();
					const entry = row[column.field]
					if (isNaN(entry)) continue;
					if (entry <= 0) continue
					const balance = new Balance()
					balance.set("description", "Einzahlung auf " + this.selectedAccount.name)
					balance.set("creator", this.user)
					balance.set("user", Parse.User.current())
					balance.set("value", Number(entry.toFixed(2)))
					balance.set("group", this.selectedBalance)

					balance.set("balanceType", typEinzahlung);

					balance.set("account", this.selectedAccount)
					balance.set("storno", false)
					balance.set("transfer", false)
					balance.set("reference", reference)
					balance.set("balanceNumber", parseInt(reference_counter[0]))
					balance.set("client", row.entity.attributes.group_name ? undefined : row.entity.toPointer())
					balance.set("budget", column.value)
					balance.set("images", [])
					let savedBalance = await balance.save()
					if (!savedBalance.attributes.receiptdate) {
						balance.set("receiptdate", savedBalance.attributes.createdAt)
						await balance.save()
					}
				}
			}
			this.data = []
			this.columns = []
			this.selectedBalance = undefined
			this.selectedAccount = undefined
			this.file = undefined
		},
		setBalance(val) {
			this.selectedBalance = val
			this.updateAccounts()
		},
		async updateAccounts() {
			this.accounts = this.selectedBalance.attributes.accounts
		},
		setManualBalance(val) {
			this.selectedManualBalance = val
			this.updateManualAccounts()
		},
		async updateManualAccounts() {
			this.manualAccounts = this.selectedManualBalance.attributes.accounts
		},
		processFile() {
			this.isLoading = true
			const reader = new FileReader();

			reader.onload = (function (updateSums) {
				return function (e) {
					const data = e.target.result;
					const workbook = XLSX.read(data, {
						type: "binary",
						cellDates: true,
					});
					const stockUpImport = new StockUpImport(workbook)
					updateSums(stockUpImport)

					this.isLoading = false
				};
			})(this.updateSums);
			reader.readAsBinaryString(this.file);
		},
		async updateSums(stockUpImport) {
			this.data = await stockUpImport.getData()
			this.columns = stockUpImport.getColumns()
		},
		currencyFormatDE(num) {
			return (
				num
					.toFixed(2) // always two decimal digits
					.replace('.', ',') // replace decimal point character with ,
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
			) // use . as a separator
		},
		getColumnValue(val) {
			if (isNaN(val)) return val
			return this.currencyFormatDE(val)
		},
		async manualStockUp() {
			const typQuery = new Parse.Query('BalanceTypes');
			const typResult = await typQuery.find();

			let typEinzahlung = "";

			for (var i = 0; i < typResult.length; i++) {
				if (typResult[i].attributes.title == "Einzahlung") {
					typEinzahlung = typResult[i];
				}
			}

			const reference_counter = await this.calculate_next_balance_number("Einzahlung", this.selectedManualBalance, new Date());
			const reference = new Date().getFullYear().toString().slice(-2) + "_" + this.selectedManualBalance.attributes.balanceNumber + "_" + reference_counter[1] + reference_counter[0].toString();

			const Balance = Parse.Object.extend("Balance")

			const balance = new Balance()
			balance.set("description", "Einzahlung auf " + this.selectedManualAccount.name)
			balance.set("user", Parse.User.current())
			balance.set("creator", this.user)
			balance.set("value", Number(this.manualBudget.replace(',', '.')))
			balance.set("group", this.selectedManualBalance)

			balance.set("balanceType", typEinzahlung);

			balance.set("account", this.selectedManualAccount)
			balance.set("storno", false)
			balance.set("transfer", false)
			balance.set("reference", reference)
			balance.set("balanceNumber", parseInt(reference_counter[0]))
			balance.set("client", this.selectedClient)
			balance.set("budget", this.selectedBudget)
			balance.set("images", [])
			let savedBalance = await balance.save()
			if (!savedBalance.attributes.receiptdate) {
				balance.set("receiptdate", savedBalance.attributes.createdAt)
				await balance.save()
			}

			this.selectedManualBalance = undefined
			this.selectedManualAccount = undefined
			this.selectedBudget = undefined
			this.selectedClient = undefined
			this.manualBudget = undefined
			this.$refs.manualBalance.reset();
		},

		async calculate_next_balance_number(bType, tGroupResult, datum) {
			if (!datum) {
				datum = new Date();
			}

			let startDatum = new Date(datum.getFullYear(), 0, 1);
			let endDatum = new Date(datum.getFullYear(), 11, 31);

			const balanceTypes = new Parse.Query('BalanceTypes');
			balanceTypes.equalTo("title", bType);
			const bTypeObject = await balanceTypes.find();
			const bTypeTocken = bTypeObject[0].attributes.token;

			const balanceTypesCommon = new Parse.Query('BalanceTypes');
			balanceTypesCommon.equalTo("token", bTypeTocken);

			const balanceQuery_temp = new Parse.Query('Balance');
			balanceQuery_temp.notEqualTo("deleted", true);
			balanceQuery_temp.equalTo("group", tGroupResult);
			balanceQuery_temp.notEqualTo("storno", true);

			balanceQuery_temp.matchesQuery("balanceType", balanceTypesCommon);
			balanceQuery_temp.greaterThanOrEqualTo("receiptdate", startDatum);
			balanceQuery_temp.lessThanOrEqualTo("receiptdate", endDatum);

			balanceQuery_temp.descending("balanceNumber");
			const results = await balanceQuery_temp.find();

			let reference_counter = 1;

			if (results.length > 0) {
				reference_counter = parseInt(results[0].attributes.balanceNumber) + 1
			}

			return [reference_counter, bTypeTocken];
		}
	}
}
</script>

<style scoped>

.disabled_client
{
	display:none;
}

</style>