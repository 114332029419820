<template>
  <div>
    <SearchableTable
      :panel-header="'Gruppen'"
      :panel-route="'AddGroup'"
      :show-search-bar="true"
      :table-data="groupArrayFiltered"
      :table-meta-data="tableMetaData"
      :query-string="'setGroupSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerView(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="eye"
            style="cursor: pointer"
          />
        </b-button>
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
    <b-modal v-model="isDeleteCheck">
      <div
        v-if="isDeleteCheck"
        class="panel m-5"
        style="background-color: white"
      >
        <p class="panel-heading">
          Möchtest du die Gruppe "{{
            chosenGroup.attributes.group_name
          }}" wirklich löschen?
        </p>
        <div
          class="buttons mt-2"
          style="display: flex; justify-content: center"
        >
          <b-button
            class="is-danger"
            :loading="isLoading"
            @click="submitDelete()"
          >
            Ja
          </b-button>
          <b-button
            class="is-primary"
            @click="closeModal()"
          >
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="groupInUsageWarning">
      <div
        v-if="groupInUsageWarning"
        class="panel m-5"
        style="background-color: white"
      >
        <p class="panel-heading">
          Du kannst die Gruppe "{{ chosenGroup.attributes.group_name }}" erst löschen nachdem
          alle Klienten und Mitarbeiter aus der Gruppe entfernt wurden!
        </p>
        <div
          class="buttons mt-2"
          style="display: flex; justify-content: center"
        >
          <b-button
            class="is-primary"
            @click="groupInUsageWarning = false"
          >
            Okay
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/**
 * This file shows all currently stored groups and allows adding, editing, viewing and soft deleting groups
 */
import { queryUserMetaClasses } from "../../lib/Query";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import { mapGetters } from "vuex";
import SearchableTable from "../helperComponents/SearchableTable.vue";

export default {
	name: "GroupsComponent",
	components: { SearchableTable },
	data() {
		return {
			group: "",
			isDeleteCheck: false,
			chosenGroup: undefined,
			groupInUsageWarning: false,
			groupFilter: "",
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Email", "field": "email", "sortField": "attributes.email" },
				{ "id": 1, "type": "string", "label": "Gruppenname", "field": "group_name", "sortField": "attributes.group_name" }
			]
		};
	},
	computed: {
		...mapGetters({
			groupArray: "getGroups",
			groupArrayFiltered: "getGroupsFiltered",
			clientData: "getClients",
			users: "getUserMetas"
		})
	},
	mounted() {
		this.$store.dispatch("setGroupSearchQuery", "")
	},
	methods: {
		triggerEdit(group) {
			this.$store.dispatch("setGroupId", group.id)
			this.$router.push({ name: "AddGroup", params: { data: group } });
		},
		triggerView(group) {
			this.$router.push({ name: "ViewGroup", params: { data: group } });
		},
		async triggerDelete(group) {
			this.chosenGroup = group;
			if (await this.groupInUsage()) {
				this.groupInUsageWarning = true
			} else {
				this.isDeleteCheck = true;
			}
		},
		async refresh() {
			this.clientData = this.$store.state.clients.data //essential for pointers in "ViewGroup"
			this.users = await queryUserMetaClasses()
			this.groupArray = this.$store.state.groups.data
			this.groupArrayFiltered = this.groupArray
		},
		async groupInUsage() {
			for (const client of this.clientData) {
				if (client.attributes.group.id === this.chosenGroup.id) {
					return true;
				}
			}
			for (const user of this.users) {
				const groupIds = user.attributes.groups ? user.attributes.groups.map(group => {
					return group.id
				}) : []
				if (groupIds.includes(this.chosenGroup.id)) {
					return true;
				}
			}
			return false;
		},
		closeModal() {
			this.isDeleteCheck = false;
		},
		async submitDelete() {
			this.isLoading = true
			this.chosenGroup.destroy().then(async () => {
				positiveFeedBack("Gruppe gelöscht!")
				await this.refresh()
				await this.filterGroups()
				this.isLoading = false
				this.isDeleteCheck = false
			}).catch(e => {
				negativeFeedBack("Gruppe nicht gelöscht!")
				console.error(e)
			})

		},
		filterGroups() {
			this.$store.dispatch("setGroupSearchQuery", this.groupFilter.toLowerCase())
			this.groupArrayFiltered = this.groupArray.filter(group => group.attributes.group_name.toLowerCase().includes(this.groupFilter.toLowerCase()))
		}
	},
};
</script>

<style lang="scss" scoped></style>
