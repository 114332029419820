<template>
  <b-collapse
    class="m-2 card"
    animation="slide"
    aria-id="filter-collapse"
  >
    <template #trigger="props">
      <div
        class="card-header"
        role="button"
        aria-controls="filter-collapse"
        :aria-expanded="props.open"
      >
        <p class="card-header-title">
          <b-icon :icon="'filter-variant'" />Filter
        </p>
        <a class="card-header-icon">
          <b-icon
            :icon="props.open ? 'menu-down' : 'menu-up'"
          />
        </a>
      </div>
    </template>

    <div class="card-content">
      <b-field label="Kategorie">
        <b-autocomplete
          placeholder="Kategorie "
          :data="clientRecordCategoriesProcessed"
          icon="magnify"
          field="attributes.name"
          :clearable="true"
          :open-on-focus="true"
          @input="val => categoryFilter = val"
          @select="(option) => (selectedCategory = option)"
        />
      </b-field>
      <div class="columns">
        <b-field
          label="Klient"
          class="column"
        >
          <b-autocomplete
            placeholder="Klient"
            :data="clientsProcessed"
            icon="magnify"
            field="name"
            :clearable="true"
            :open-on-focus="true"
            @input="val => clientFilter = val"
            @select="(option) => option? selectedClient = option.object : selectedClient = undefined"
          />
        </b-field>
        <b-field
          label="Ersteller"
          class="column"
        >
          <b-input
            v-model="creator"
            placeholder="Ersteller"
            type="search"
            icon="magnify"
            autocomplete="off"
            expanded
          />
        </b-field>
      </div>
      <!--			<b-field label="Volltextsuche">-->
      <!--				<b-input-->
      <!--					placeholder="Volltextsuche"-->
      <!--					type="search"-->
      <!--					icon="magnify"-->
      <!--					v-model="searchTerm"-->
      <!--					autocomplete="off"-->
      <!--					expanded-->
      <!--				></b-input>-->
      <!--			</b-field>-->
      <b-field label="Datum">
        <div class="is-flex is-justify-content-center">
          <b-datepicker
            v-model="startDate"
            placeholder="von"
            icon="calendar-today"
            trap-focus
            class="mr-1"
          />
          <b-datepicker
            v-model="endDate"
            placeholder="bis"
            icon="calendar-today"
            trap-focus
          />
        </div>
      </b-field>
    </div>
  </b-collapse>
</template>

<script>
import {queryActiveAndInactiveClients, queryClientRecordCategories} from "@/lib/Query";

export default {
	name:"PdfFilter",
	components: {},
	props: {
		startDateProp: Date,
		endDateProp: Date,
		searchTermProp: String,
		selectedCategoryProp: Object,
		selectedClientProp: Object,
		creatorProp: String
	},
	data(){
		return{
			clientRecordCategories: [],
			clients: [],
			clientFilter:  "",
			categoryFilter:  ""
		}
	},
	computed:{
		startDate: {
			get(){
				return this.startDateProp
			},
			set(value){
				this.$emit("setStartDate", value)
			}
		},
		endDate: {
			get(){
				return this.endDateProp
			},
			set(value){this.$emit("setEndDate", value)
			}
		},
		searchTerm: {
			get(){
				return this.searchTermProp
			},
			set(value){
				this.$emit("setSearchTerm", value)
			}
		},creator: {
			get(){
				return this.creatorProp
			},
			set(value){
				this.$emit("setCreator", value)
			}
		},
		selectedCategory: {
			get(){
				return this.selectedCategoryProp
			},
			set(value){this.$emit("setSelectedCategory", value)
			}
		},selectedClient: {
			get(){
				return this.selectedClientProp
			},
			set(value){this.$emit("setSelectedClient", value)
			}
		},
		clientsProcessed(){
			const filteredClients = this.clients.filter(client => {
				const name = `${client.attributes.first_name} ${client.attributes.last_name}`
				if(!this.clientFilter) return true
				return name.toLowerCase().includes(this.clientFilter.toLowerCase())
			})
			return filteredClients.map(client =>({
				name: `${client.attributes.first_name} ${client.attributes.last_name} (${client.attributes.group.attributes.group_name})`,
				object: client
			}))
		},
		clientRecordCategoriesProcessed(){
			const filteredClientCategories = this.clientRecordCategories.filter(category => {
				const name = category.attributes.name.toLowerCase()
				if(!this.categoryFilter || this.categoryFilter.length === 0) return true
				return name.includes(this.categoryFilter.toLowerCase())
			})
			return filteredClientCategories
		}
	},
	async created() {
		this.clientRecordCategories = await queryClientRecordCategories()
		this.clients = await queryActiveAndInactiveClients()
	},
}
</script>

<style scoped>

</style>
