/**
 * This file is used to import relevant data from one database to another (not updated)
 */
import Parse from "parse/dist/parse.min.js";
import {v4 as uuid} from "uuid";
import {queryAdminRole, queryExternalRole, queryStaffRole} from "./Query";

const SERVER_URL_TEST = "https://parse.metop.de/parse";
const SERVER_URL_QA = "https://parse.qa.metop.de/parse";

const APP_ID_TEST = "KJHZ";
const APP_ID_QA = "KJHZQA";

const APP_KEY_TEST = "ThWmZq4t7w!z%C*F-JaNdRgUjXn2r5u8x/A?D(G+KbPeShVmYp3s6v9y$B&E)H@M";
const APP_KEY_QA = "ThWmZq4t7w!z%C*F-JaNdRgUjXn2r5u8x/A?D(G+KbPeShVmYp3s6v9y$B&E)H@M";

const fetchRoles = async(roleConfigMap) => {
	const queryUsers = new Parse.Query("User")
	const users = await queryUsers.find()
	const queryRoles = new Parse.Query(Parse.Role)
	const roles = await queryRoles.find()
	for(const role of roles){
		const roleUsers = await role.getUsers().query().find()
		const roleUserIds = roleUsers.map(roleUser => roleUser.id)
		const roleUsersFiltered = users.filter(user => roleUserIds.includes(user.id))
		const userIds = []
		for(const roleUser of roleUsersFiltered){
			userIds.push(roleUser.attributes.dependency.id)
		}
		roleConfigMap[role.attributes.name] = userIds
	}
	return roles
}

const fetchAttributeConfig = async() =>{
	const queryAttributeConfig = new Parse.Query("AttributeConfig")
	queryAttributeConfig.limit(100000000)
	const attributeConfigs = await queryAttributeConfig.find()
	return attributeConfigs
}

const fetchEntryAttributes = async() =>{
	const queryEntryAttributes = new Parse.Query("EntryAttributes")
	queryEntryAttributes.limit(100000000)
	const entryAttributes = await queryEntryAttributes.find()
	return entryAttributes
}

const fetchEntryTypes = async() =>{
	const queryEntryTypes = new Parse.Query("EntryTypes")
	queryEntryTypes.limit(100000000)
	const entryTypes = await queryEntryTypes.find()
	return entryTypes
}


const fetchUserMetaClasses = async() => {
	const queryMetaClasses = new Parse.Query("UserMetaClass")
	queryMetaClasses.limit(100000000)
	const userMetaClasses = await queryMetaClasses.find()
	return userMetaClasses
}

export const fetchTestDashboard = async() =>{
	Parse.initialize(APP_ID_TEST, APP_KEY_TEST);
	Parse.serverURL = SERVER_URL_TEST
	const roleConfigMap = {}
	const data = {}
	data.roles = await fetchRoles(roleConfigMap)
	data.userMetaClasses = await fetchUserMetaClasses()
	data.entryTypes = await fetchEntryTypes()
	data.entryAttributes = await fetchEntryAttributes()
	data.attributesConfigs = await fetchAttributeConfig()
	data.roleConfigMap = roleConfigMap
	return data


}

const setAttribute = (objectCopy, object, attributeName) =>{
	objectCopy.set(attributeName, object.attributes[attributeName])
}
/* UNUSED
const setRoles = async (roles, ) =>{
	for(const role of roles){
		const roleACL = new Parse.ACL();
		roleACL.setPublicReadAccess(true);
		roleACL.setPublicWriteAccess(true)
		const newRole = new Parse.Role(role.attributes.name, roleACL);
		await newRole.save();

	}
} */

const addUser = async(userMetaClass, sessionToken, roleConfigMap, userMetaClassTest) =>{
	try {
		await userMetaClass.save();
	} catch (e) {
		console.error("Metaclass for User couldntbe created" + e);
	}
	const user = new Parse.User()
	user.set("username", userMetaClass.attributes.email)
	user.set("password", "secret")
	user.set("dependency", userMetaClass.toPointer())
	const userClass = await user.signUp()
	Parse.User.become(sessionToken)
	const adminRole = await queryAdminRole();
	if(userMetaClass.attributes.is_admin) {
		//set as admin
		try {
			await adminRole.getUsers().add(userClass);
			await adminRole.save();
		} catch (e) {
			console.error(
				"couldnt save the user as admin " + e
			);
		}
	}
	const externalRole = await queryExternalRole()
	if(roleConfigMap.External.includes(userMetaClassTest.id)){
			await externalRole.getUsers().add(userClass);
			await externalRole.save();
	}
	const staffRole = await queryStaffRole()
	if(roleConfigMap.Staff.includes(userMetaClassTest.id)){
		await staffRole.getUsers().add(userClass);
		await staffRole.save();
	}


}

const setUserMeaClasses = async(userMetaClasses, sessionToken, roleConfigMap) => {
	for(const userMetaClassTest of userMetaClasses){
		const UserMetaClass = Parse.Object.extend("UserMetaClass")
		const userMetaClass = new UserMetaClass()
		setAttribute(userMetaClass, userMetaClassTest, "gender")
		setAttribute(userMetaClass, userMetaClassTest, "street")
		setAttribute(userMetaClass, userMetaClassTest, "phone1")
		setAttribute(userMetaClass, userMetaClassTest, "phone2")
		setAttribute(userMetaClass, userMetaClassTest, "birth_date")
		setAttribute(userMetaClass, userMetaClassTest, "last_name")
		setAttribute(userMetaClass, userMetaClassTest, "location")
		setAttribute(userMetaClass, userMetaClassTest, "function")
		setAttribute(userMetaClass, userMetaClassTest, "deleted")
		setAttribute(userMetaClass, userMetaClassTest, "first_name")
		setAttribute(userMetaClass, userMetaClassTest, "is_admin")
		setAttribute(userMetaClass, userMetaClassTest, "email")
		setAttribute(userMetaClass, userMetaClassTest, "plz")

		if(userMetaClassTest.attributes.profile_picture){
			const response = await fetch(userMetaClassTest.attributes.profile_picture._url);
			const blob = await response.blob();
			const file = new File([blob], uuid(), {type: blob.type});
			const imageFile = new Parse.File(
				uuid(),
				file
			);
			userMetaClass.set("profile_picture", imageFile)
			await imageFile.save();
		}
		await addUser(userMetaClass, sessionToken, roleConfigMap, userMetaClassTest)
	}
}

/* UNUSED
const setEntryTypes = async(entryTypes) =>{
	for(const entryType of entryTypes){
		const EntryType = Parse.Object.extend("EntryTypes")
		const entryTypeCopy = new EntryType()
		setAttribute(entryTypeCopy, entryType, "name")
		setAttribute(entryTypeCopy, entryType, "color")
		setAttribute(entryTypeCopy, entryType, "isStandardEntryType")
		setAttribute(entryTypeCopy, entryType, "deleted")
		await entryTypeCopy.save()
	}
} */

/* UNUSED
const addEntryAttributeToAttributeConfig = async(entryAttribute, attributeConfig) =>{
	if(attributeConfig){
		const previousEntryAttributes = attributeConfig.attributes.entryAttributes
		previousEntryAttributes.push(entryAttribute)
		attributeConfig.set("entryAttributes", previousEntryAttributes)
		await attributeConfig.save()
	}else{
		console.warn("the following attribute wasnt included (corrupt)")
		console.warn(entryAttribute)

	}

} */
/* UNUSED
const setEntryAttributes = async(entryAttributes, configMap) =>{
	for(const entryAttribute of entryAttributes){
			const EntryAttribute = Parse.Object.extend("EntryAttributes")
			const entryAttributeCopy = new EntryAttribute()
			setAttribute(entryAttributeCopy, entryAttribute, "name")
			setAttribute(entryAttributeCopy, entryAttribute, "deleted")
			setAttribute(entryAttributeCopy, entryAttribute, "type")
			entryAttributeCopy.set("attribute_config", entryAttribute.attributes.attribute_config ? configMap[entryAttribute.attributes.attribute_config.id] : undefined)
			await entryAttributeCopy.save()
			if(entryAttribute.attributes.attribute_config) await addEntryAttributeToAttributeConfig(entryAttributeCopy, configMap[entryAttribute.attributes.attribute_config.id])
		}
} */

/* UNUSED
const setAttributeConfig = async(attributeConfigs, configMap) =>{
	for(const attributeConfig of attributeConfigs){
		const AttributeConfig = Parse.Object.extend("AttributeConfig")
		const attributeConfigCopy = new AttributeConfig()
		setAttribute(attributeConfigCopy, attributeConfig, "color")
		setAttribute(attributeConfigCopy, attributeConfig, "name")
		setAttribute(attributeConfigCopy, attributeConfig, "deleted")
		setAttribute(attributeConfigCopy, attributeConfig, "isSingleSelection")
		attributeConfigCopy.set("entryAttributes", [])
		await attributeConfigCopy.save()
		configMap[attributeConfig.id] = attributeConfigCopy
	}
} */

export const setQADashBoard = async(data) =>{

	await Parse.initialize(APP_ID_QA, APP_KEY_QA);
	Parse.serverURL = SERVER_URL_QA

	const UserMetaClass = Parse.Object.extend("UserMetaClass")
	const userMetaClass = new UserMetaClass()
	userMetaClass.set("first_name", "test")
	userMetaClass.set("last_name", "test")
	const userMetaClassInst = await userMetaClass.save()
	const user = new Parse.User()
	user.set("username", "test.com")
	user.set("password", "secret")
	user.set("dependency", userMetaClassInst)
	await user.signUp()

	const sessionToken = Parse.User.current().getSessionToken()
	// await setRoles(data.roles)
	await setUserMeaClasses(data.userMetaClasses, sessionToken, data.roleConfigMap)

	// const configMap = {}
	// await setEntryTypes(data.entryTypes)
	// await setAttributeConfig(data.attributesConfigs, configMap)
	// await setEntryAttributes(data.entryAttributes, configMap)




}






