<template>
  <div>
    <div class="panel m-5">
      <PanelHeader :panel-header="isOptional ? 'Neues optionales Attribut' : 'Neues Attribut' " />
      <div class="container pt-3 pb-3">
        <div class="columns">
          <InputWithValidation
            class="column"
            message="Bitte gib einen Attributnamen ein!"
            :name="attributeName"
            :set-name="(name)=> attributeName = name"
            label="Attributname"
          />

          <ColorPicker
            class="column"
            :set-colors="val => colors = val"
            :colors="colors"
          />

          <SelectionComponent
            class="column"
            label="Einfach- oder Mehrfachauswahl"
            :options="['Einfachauswahl','Mehrfachauswahl']"
            :selected-option="selectionType"
            :select="val => selectionType = val"
          />
        </div>
        <div>
          <b-table
            narrowed
            :data="attributeCharacteristics"
            striped
          >
            <b-table-column
              v-slot="props"
              :label="attributeName.length>0 ? attributeName : 'Attributname'"
              cell-class="has-text-left"
            >
              {{ props.row }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Löschen"
              cell-class="has-text-right"
              header-class="th-right"
            >
              <IconButton
                style="display: flex; justify-content: end"
                icon="delete"
                size="is-small"
                :trigger-function="()=>triggerDeleteAttributeCharacteristic(props.row)"
              />
            </b-table-column>
          </b-table>
          <div style="display: flex">
            <b-input
              v-model="attributeCharacteristic"
              class="ml-2 mr-2"
              placeholder="Ausprägung, z.B. Schule"
              type="text"
            />
            <b-button @click="addAttributeCharacteristic">
              <b-icon
                icon="plus"
                style="line-height:1; color: #0d0d0d !important;"
              />
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="buttons m-5"
      style="display: flex; justify-content: center"
    >
      <SaveButton
        :save-function="addAttribute"
        :is-loading="isLoading"
        :disabled="$v.attributeName.$invalid"
      />
      <CancelButton
        :is-loading="false"
        back-route="/diary"
        text="Abbrechen"
      />
    </div>
  </div>
</template>

<script>
/**
 * This file is for creating a new attribute and additional sub attributes (used in diary)
 */
import Parse from "parse/dist/parse.min.js";
import {negativeFeedBack, positiveFeedBack} from "@/lib/Toast";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import {minLength, required} from "vuelidate/lib/validators";
import ColorPicker from "@/components/helperComponents/ColorPicker";
import SelectionComponent from "@/components/helperComponents/SelectionComponent";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import IconButton from "@/components/helperComponents/IconButton";

export default {
	name: "NewAttribute",
	components: {IconButton, SaveButton, CancelButton, SelectionComponent, ColorPicker, PanelHeader, InputWithValidation},
	data() {
		return {
			attributeName: "",
			colors: {
				"hex": "#000000",
				"source": "hex"
			},
			attributeCharacteristics: [],
			attributeCharacteristic: "",
			isLoading: false,
			selectionType: "Einfachauswahl"
		}
	},
	computed:{
		isOptional(){
			return !!this.$route.params.isOptional;
		}
	},
	validations: {
		attributeName: {
			required,
			minLength: minLength(1),
		},

	},
	methods: {
		async addAttribute() {
			this.isLoading = true
			const AttributeClass = Parse.Object.extend("AttributeConfig")
			const newAttributeClass = new AttributeClass()
			try{
				await newAttributeClass.save()
			}catch (e){
				console.error("couldnt save the attribute object " + e)
			}

			const entryAttributes = []
			for(const attribute of this.attributeCharacteristics){
				const EntryAttribute = Parse.Object.extend("EntryAttributes")
				const entryAttribute = new EntryAttribute()
				entryAttribute.set("attribute_config", newAttributeClass.toPointer())
				entryAttribute.set("type", this.attributeName.trim())
				entryAttribute.set("name", attribute.trim())
				try{
					await entryAttribute.save()
					entryAttributes.push(entryAttribute.toPointer())
				}catch(e){
					console.error("couldnt save the attributes of attribute " + e)
				}
			}
			newAttributeClass.set("entryAttributes", entryAttributes)
			//further attribute arguments
			newAttributeClass.set("name", this.attributeName.trim())
			newAttributeClass.set("is_optional", this.isOptional)
			newAttributeClass.set("isSingleSelection", this.selectionType === 'Einfachauswahl')
			newAttributeClass.set("color", this.colors.hex)
			try {
				await newAttributeClass.save()
				positiveFeedBack("Neues Attribut angelegt!",)
				await this.$router.push({path: "/diary"})
			} catch (e) {
				console.error("attribute couldnt be created" + e)
				negativeFeedBack("Neues Attribut wurde nicht angelegt!")
			}
			this.isLoading = false
		},
		addAttributeCharacteristic(){
			this.attributeCharacteristics.push(this.attributeCharacteristic)
			this.attributeCharacteristic = ""
		},
		triggerDeleteAttributeCharacteristic(item){
			this.attributeCharacteristics = this.attributeCharacteristics.filter(characteristic => characteristic !== item)
		},


	}
}
</script>

<style lang="scss" scoped>

</style>
