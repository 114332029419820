import store from '../store/store';
import {queryLocationByType, queryPersonByType, queryPersonsWithOutSocialWorkers, queryStatutoryBasis} from "@/lib/Query";

function getContactEducator(client) {
	var emails = []
	const filteredUserMetaClasses = store.getters.getUserMetas.filter(userMetaClass => {
		if (!userMetaClass.attributes.clients) return false
		const contactChildrenIds = userMetaClass.attributes.clients.map(contactChild => contactChild.id)
		return contactChildrenIds.includes(client.id)
	})
	filteredUserMetaClasses.forEach((userMetaClass) => {
		emails.push(userMetaClass.attributes.email)
	})
	return emails[0];
}

function getGoalDay(dayNumber) {
	switch (dayNumber) {
		case 1: return 'Montag';
		case 2: return 'Dienstag';
		case 3: return 'Mittwoch';
		case 4: return 'Donnerstag';
		case 5: return 'Freitag';
		case 6: return 'Samstag';
		case 0: return 'Sonntag';
		default: return 'Sonntag';
	}
}

const getPerson = (persons, number) =>{
	if(persons.length <= number) return undefined
	return `${persons[number].attributes.last_name}, ${persons[number].attributes.first_name}, ${persons[number].attributes.type}`
}

const getCustody = (custodyArray, number) =>{
	if(custodyArray.length <= number) return undefined
	if(custodyArray[number]) return "Ja"
	return "Nein"
}

function getSocialWorkerName(socialWorker) {
	if(socialWorker === undefined) return undefined
	return `${socialWorker.attributes.last_name}, ${socialWorker.attributes.first_name}`
}

function getSchoolName(school) {
	if(school === undefined) return undefined
	return school.attributes.name
}

export const getClientsData = async() => {

	var clientArray = []
	var clients = store.getters.getClients

	for (const client of clients) {
		clientArray.push({
			Name: client.attributes.last_name,
			Vorname: client.attributes.first_name,
			Geschlecht: client.attributes.gender,
			Geburtstag: client.attributes.birth_date,
			Geburtsort: client.attributes.birth_location,
			Landkreis: client.attributes.administrative_district ? client.attributes.administrative_district.attributes.name : undefined,
			Gruppe: client.attributes.group.attributes.group_name,
			'gesetzliche Grundlage': client.attributes.statutory_basis ? client.attributes.statutory_basis.attributes.name : undefined,
			Zugang: client.attributes.entry,
			'Bewilligung bis': client.attributes.license,
			Abgang: client.attributes.exit,
			'Telefon 1': client.attributes.phone1,
			'Info 1': client.attributes.info1,
			'Telefon 2': client.attributes.phone2,
			'Info 2': client.attributes.info2,
			Straße: client.attributes.street,
			PLZ: client.attributes.plz,
			Ort: client.attributes.location,
			"Bezugsperson 1": getPerson(client.attributes.persons, 0),
			"Sorgerecht 1": getCustody(client.attributes.custody, 0),
			"Bezugsperson 2": getPerson(client.attributes.persons, 1),
			"Sorgerecht 2": getCustody(client.attributes.custody, 1),
			"Bezugsperson 3": getPerson(client.attributes.persons, 2),
			"Sorgerecht 3": getCustody(client.attributes.custody, 2),
			"Sozialarbeiter": getSocialWorkerName(client.attributes.socialWorker),
			"Schule": getSchoolName(client.attributes.school),
			Bewertungsstichtag: getGoalDay(client.attributes.goal_day),
			Kontakterzieher: getContactEducator(client),
		})
	}
	return clientArray;
}
export const getGroupsData = async () => {
	var groupsArray = []
	var groups = store.getters.getGroups

	for (const group of groups) {
		groupsArray.push({
			Name: group.attributes.group_name,
			"Straße": group.attributes.street,
			"Plz": group.attributes.plz,
			"Ort": group.attributes.location,
			"E-Mail": group.attributes.email,
			"Telefon 1": group.attributes.phone1,
			"Info 1": group.attributes.info1,
			"Telefon 2": group.attributes.phone2,
			"Info 2": group.attributes.info2,
		})
	}
	return groupsArray;
}

function getGroup(groups) {
	if(groups === undefined || groups === null || groups.length === 0) return undefined
	return groups[0].attributes.group_name
}

export const getUsersData = async () => {
	var userArray = []

	for (const user of store.getters.getUserMetas) {
		userArray.push({
			Name: user.attributes.last_name,
			Vorname: user.attributes.first_name,
			Geschlecht: user.attributes.gender,
			Geburtstag: user.attributes.birth_date,
			'E-Mail': user.attributes.email,
			Gruppe: getGroup(user.attributes.groups),
			'Telefon 1': user.attributes.phone1,
			'Info 1': user.attributes.info1,
			'Telefon 2': user.attributes.phone2,
			'Info 2': user.attributes.info2,
			Straße: user.attributes.street,
			PLZ: user.attributes.plz,
			Ort: user.attributes.location,
			Admin: user.attributes.is_admin ? 'Ja' : 'Nein' ,
		})
	}
	return userArray;
}

export const getDistrictData = async () =>{
	const districtData = []
	const districts =  await queryLocationByType("district")

	for (const district of districts) {
		districtData.push({
			Name: district.attributes.name,
			Straße: district.attributes.street,
			Plz: district.attributes.plz,
			Ort: district.attributes.location,
			'E-Mail': district.attributes.email,
			'Telefon 1': district.attributes.phone1,
			'Info 1': district.attributes.info1,
			'Telefon 2': district.attributes.phone2,
			'Info 2': district.attributes.info2,
		})
	}
	return districtData;
}

export const getSocialWorkerData = async () =>{
	const personData = []
	const persons =  await queryPersonByType("socialWorker")

	for (const person of persons) {
		personData.push({
			Name: person.attributes.last_name,
			Vorname: person.attributes.first_name,
			"Name, Vorname": `${person.attributes.last_name}, ${person.attributes.first_name}`,
			Straße: person.attributes.street,
			Plz: person.attributes.plz,
			Ort: person.attributes.location,
			'E-Mail': person.attributes.email,
			'Telefon 1': person.attributes.phone1,
			'Info 1': person.attributes.info1,
			'Telefon 2': person.attributes.phone2,
			'Info 2': person.attributes.info2,

		})
	}
	return personData;
}

export const getSchoolData = async () =>{
	const schoolData = []
	const schools =  await queryLocationByType("school")

	for (const school of schools) {
		schoolData.push({
			Name: school.attributes.name,
			Straße: school.attributes.street,
			Plz: school.attributes.plz,
			Ort: school.attributes.location,
			'E-Mail': school.attributes.email,
			'Telefon 1': school.attributes.phone1,
			'Info 1': school.attributes.info1,
			'Telefon 2': school.attributes.phone2,
			'Info 2': school.attributes.info2,
		})
	}
	return schoolData;
}

export const getPersonData = async () =>{
	const personData = []
	const persons =  await queryPersonsWithOutSocialWorkers()

	for (const person of persons) {
		personData.push({
			Name: person.attributes.last_name,
			Vorname: person.attributes.first_name,
			"Name, Vorname, Typ": `${person.attributes.last_name}, ${person.attributes.first_name}, ${person.attributes.type}`,
			Straße: person.attributes.street,
			Plz: person.attributes.plz,
			Ort: person.attributes.location,
			Typ: person.attributes.type,
			'Telefon 1': person.attributes.phone1,
			'Info 1': person.attributes.info1,
			'Telefon 2': person.attributes.phone2,
			'Info 2': person.attributes.info2,
			'E-Mail': person.attributes.email,
		})
	}
	return personData;
}

export const getStatutoryBasisData = async () =>{
	const statutoryBasisData = []
	const statutoryBasisArray =  await queryStatutoryBasis()

	for (const statutoryBasis of statutoryBasisArray) {
		statutoryBasisData.push({
			"Gesetz": statutoryBasis.attributes.name,
		})
	}
	return statutoryBasisData;
}