<template>
	<div>
		<SearchableTable :panel-header="'Nutzer'" :panel-route="'AddUser'" :show-search-bar="true" :table-data="staffUsers"
			:table-meta-data="tableMetaData" :query-string="'setUserSearchQuery'">
			<b-table-column v-slot="props" label="Aktionen" cell-class="has-text-right truncate-column"
				header-class="th-right truncate-column">
				<b-button class="is-small mr-2" @click="triggerView(props.row)">
					<b-icon class="mt-1" icon="eye" style="cursor: pointer" />
				</b-button>
				<b-button class="is-small mr-2" @click="triggerEdit(props.row)">
					<b-icon class="mt-1" icon="pencil" style="cursor: pointer;" />
				</b-button>
				<b-button class="is-small" @click="triggerDelete(props.row)">
					<b-icon class="mt-1" icon="delete" style="cursor: pointer" />
				</b-button>
			</b-table-column>
		</SearchableTable>
		<b-modal v-model="isDeleteCheck">
			<div v-if="isDeleteCheck" class="panel m-5" style="background-color: white">
				<p class="panel-heading">
					Möchtest du
					"{{ chosenUser.attributes.first_name }}
					{{ chosenUser.attributes.last_name }}" wirklich
					löschen?
				</p>
				<div class="buttons mt-2" style="display: flex; justify-content: center">
					<b-button class="is-danger" :loading="isLoading" @click="submitDelete()">
						Ja
					</b-button>
					<b-button class="is-primary" @click="closeModal">
						Nein
					</b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>

/**
 * This file shows all currently stored users and allows adding, editing, viewing and soft deleting users
 */

import Parse from "parse/dist/parse.min.js";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import { mapGetters } from "vuex";
import SearchableTable from "../helperComponents/SearchableTable.vue";

export default {
	name: "UsersComponent",
	components: { SearchableTable },
	data() {
		return {
			staffUsersData: undefined,
			chosenUser: undefined,
			isDeleteCheck: false,
			staffUsersDataFilter: undefined,
			filterString: "",
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Email", "field": "email", "sortField": "attributes.email" },
				{ "id": 1, "type": "string", "label": "Vorname", "field": "first_name", "sortField": "attributes.first_name" },
				{ "id": 2, "type": "string", "label": "Nachname", "field": "last_name", "sortField": "attributes.last_name" },
				{ "id": 3, "type": "boolean", "label": "Admin", "field": "is_admin", "true": "Ja", "false": "Nein" },
			]
		};
	},
	computed: {
		...mapGetters({
			staffUsers: "getStaffUserMetasFiltered",
			currentUserMeta: "getCurrentUserMeta"
		})
	},
	mounted() {
		this.$store.dispatch("setUserSearchQuery", "")
	}, methods: {
		getData(dataField, fieldName) {
			return dataField.row.attributes[fieldName]
		},
		triggerEdit(user) {
			this.$router.push({ name: "AddUser", params: { data: user } });
		},
		triggerView(user) {
			this.$router.push({ name: "ViewUser", params: { data: user } });
		},
		triggerDelete(user) {
			this.chosenUser = user;
			this.isDeleteCheck = true;
		},
		closeModal() {
			this.isDeleteCheck = false;
		},
		async submitDelete() {
			this.isLoading = true
			this.chosenUser.set("deleted", true);
			this.chosenUser
				.save()
				.then(async () => {
					positiveFeedBack("Mitarbeiter gelöscht!")
					if (this.chosenUser.id === this.currentUserMeta.id) {
						await Parse.User.logOut()
						await this.$router.push("/login")
					}
					await this.filterElements()
					this.isDeleteCheck = false;
				})
				.catch((e) => {
					negativeFeedBack("Mitarbeiter nicht gelöscht!")
					console.error(e);
				});
			this.isLoading = false
		},
		filterElements() {
			this.$store.dispatch("setUserSearchQuery", this.filterString)
		}
	},
};
</script>

<style lang="scss"></style>
