<template>
	<div>
		<div v-if="dataObject" class="panel m-5">
			<PanelHeader panel-header="Darreichungsform (Art)" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<div class="column">
						<b-field label="Bezeichner">
							<p class="content">
								{{ medicalUnitName }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Kürzel">
							<p class="content">
								{{ medicalUnitSymbol }}
							</p>
						</b-field>
					</div>
				</div>
			</div>
		</div>
		<div>
			<section class="m-5">
				<b-button class="is-danger" tag="router-link" to="/medication_unit">
					Zurück
				</b-button>
			</section>
		</div>
	</div>
</template>
  
<script>
/**
 * This file is for viewing a focused medication unit.
 */

import PanelHeader from "@/components/helperComponents/PanelHeader";

export default {
	name: "ViewMedicationUnit",
	components: {
		PanelHeader,
	},
	data() {
		return {
			dataObject: undefined,
			medicalUnitName: "",
			medicalUnitSymbol: "",
		};
	},
	computed: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.dataObject = this.$route.params.data
			this.medicalUnitName = this.dataObject.attributes.name
			this.medicalUnitSymbol = this.dataObject.attributes.symbol
		}
	},
	methods: {
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		},
	},
}
</script>
  
<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
  