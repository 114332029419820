<template>
  <div>
    <div
      v-if="userMetaClass"
      class="panel m-5"
    >
      <p class="panel-heading">
        Stammdaten
      </p>
      <div class="container pt-3 pb-3">
        <div class="columns is-centered">
          <div class="column image_column">
            <div>
              <b-field label="Profilbild">
                <b-image
                  :src="dropFileURL"
                  ratio="64by64"
                  alt="Profilbild"
                  rounded
                />
              </b-field>
            </div>
          </div>
        </div>
        <hr>
        <div class="columns">
          <div class="column">
            <b-field label="Vorname">
              <p class="content">
                {{ firstName }}
              </p>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Nachname">
              <p class="content">
                {{ familyName }}
              </p>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Geschlecht">
              <p class="content">
                {{ gender }}
              </p>
            </b-field>
          </div>
          <div class="column">
            <b-field
              v-if="birthday"
              label="Geburtsdatum"
            >
              <p class="content">
                {{ birthday.toLocaleDateString("de-DE", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }) }}
              </p>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <div class="panel m-5">
      <p class="panel-heading">
        Personaldaten
      </p>
      <div class="container pt-3 pb-3">
        <div class="columns">
          <div class="column">
            <b-field label="Gruppen">
              <p
                v-for="(group, index) in userGroups"
                :key="index"
                class=""
              >
                {{ group.attributes.group_name }}
              </p>
            </b-field>
          </div>
          <div
            class="column"
            style="display: flex; justify-content: center"
          >
            <b-field label="Kontaktkinder">
              <ul
                class=""
                style="list-style-type: none; "
              >
                <li
                  v-for="(client, index) in chosenClients"
                  :key="index"
                >
                  {{ client.attributes.first_name }} {{ client.attributes.last_name }}
                </li>
              </ul>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Funktion">
              <p class="content">
                {{ func }}
              </p>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <div class="panel m-5">
      <p class="panel-heading">
        Kontakt- und Adressdaten
      </p>
      <div class="container pt-3 pb-3">
        <div class="columns">
          <b-field
            label="Telefon 1"
            class="column"
          >
            <p class="content">
              {{ phone1 }}
            </p>
          </b-field>
          <b-field
            label="Info 1"
            class="column"
          >
            <p class="content">
              {{ info1 }}
            </p>
          </b-field>
        </div>
        <div class="columns">
          <b-field
            label="Telefon 2"
            class="column"
          >
            <p class="content">
              {{ phone2 }}
            </p>
          </b-field>
          <b-field
            label="Info 2"
            class="column"
          >
            <p class="content">
              {{ info2 }}
            </p>
          </b-field>
        </div>
        <div class="columns">
			<div class="column">
            <b-field label="Straße">
              <p class="content">
                {{ street }}
              </p>
            </b-field>
          </div>
          <div class="column">
            <b-field label="PLZ">
              <p class="content">
                {{ plz }}
              </p>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Ort">
              <p class="content">
                {{ location }}
              </p>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <section class="m-2">
      <div style="display: flex; justify-content: center">
        <b-button
          class="is-danger"
          tag="router-link"
          to="/users"
        >
          Zurück
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
/**
 * This file is for viewing a focused user
 */
import {getUserPicture} from "../../lib/Picture";
import {queryGroups, queryUserMetaClasses} from "@/lib/Query";

export default {
	name: "ViewUser",
	data() {
		return {
			dropFile: undefined,
			firstName: "",
			familyName: "",
			birthday: new Date(),
			func: "",
			groupId: "",
			gender: "",
			phone1: "",
			phone2: "",
			location: "",
			street: "",
			plz: "",
			groupName: "",
			userMetaClass: undefined,
			userGroups: [],
			chosenClients: [],
			info1: "",
			info2: ""
		};
	},
	computed: {
		dropFileURL() {
			const pictureObject = {
				newProfilePicture: typeof this.dropFile === "string" ? undefined : this.dropFile,
				userPicture: typeof this.dropFile === "string" ? this.dropFile : undefined,
				userGender: this.userMetaClass.attributes.gender
			}
			return getUserPicture(pictureObject)
		},
	},
	async created() {
		await queryUserMetaClasses()
		const userMetaClass = this.$route.params.data
		this.userMetaClass = userMetaClass
		if(!this.userMetaClass){
			await this.$router.push("/users")
		}
		if (userMetaClass) {
			this.firstName = userMetaClass.attributes.first_name ? userMetaClass.attributes.first_name : ""
			this.familyName = userMetaClass.attributes.last_name ? userMetaClass.attributes.last_name : ""
			this.street = userMetaClass.attributes.street ? userMetaClass.attributes.street : ""
			this.birthday = userMetaClass.attributes.birth_date ? userMetaClass.attributes.birth_date : undefined
			this.plz = userMetaClass.attributes.plz ? userMetaClass.attributes.plz : ""
			this.location = userMetaClass.attributes.location ? userMetaClass.attributes.location : ""
			this.gender = userMetaClass.attributes.gender ? userMetaClass.attributes.gender : ""
			this.phone1 = userMetaClass.attributes.phone1 ? userMetaClass.attributes.phone1 : ""
			this.phone2 = userMetaClass.attributes.phone2 ? userMetaClass.attributes.phone2 : ""
			this.info1 = userMetaClass.attributes.info1 ? userMetaClass.attributes.info1 : ""
			this.info2 = userMetaClass.attributes.info2 ? userMetaClass.attributes.info2 : ""
			this.dropFile = userMetaClass.attributes.profile_picture ? userMetaClass.attributes.profile_picture._url : undefined
			const groupsUser = userMetaClass.attributes.groups ? userMetaClass.attributes.groups : []
			this.func = userMetaClass.attributes.function ? userMetaClass.attributes.function : ""
			this.chosenClients = userMetaClass.attributes.clients ? userMetaClass.attributes.clients : ""
			const groups = await queryGroups()
			for (const group of groupsUser) {

				this.userGroups.push(groups.find(groupOfGroups => groupOfGroups.id === group.id))
			}

		}
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
