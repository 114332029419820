<template>
  <b-field>
    <template #label>
      <div
        class="align-content-center"
        style="display: inline-flex"
      >
        <p>{{ label }}</p>
      </div>
    </template>
    <b-select
      v-model="selectedItem"
      class="m-2"
      :placeholder="label"
      expanded
      @input="set()"
    >
      <option
        v-for="(option) in options"
        :key="option"
        :value="option"
      >
        {{ option }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
/**
 * This file provides a selection field for different items with description
 * label: String for description, options: Array of Strings as options, selectedOption: String for selected option, select: Function for selecting the option
 */
export default {
	name: "SelectionComponent",
	props:["label", "options", "selectedOption", "select"],
	data(){
		return{
			selectedItem: this.selectedOption
		}
	},
	methods:{
		set(){
			this.select(this.selectedItem)
		}
	},
}
</script>

<style scoped>

</style>
