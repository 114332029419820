/** var SERVER_URL = undefined;
var APP_ID = undefined;
var APP_KEY = undefined;

if (process.env.NODE_ENV === 'production') {
	SERVER_URL = process.env.VUE_APP_SERVER_URL_PROD;
	APP_ID = process.env.VUE_APP_APP_ID_PROD;
	APP_KEY = process.env.VUE_APP_APP_KEY_PROD;
}
else if (process.env.NODE_ENV === 'qa') {
	SERVER_URL = process.env.VUE_APP_SERVER_URL_QA;
	APP_ID = process.env.VUE_APP_APP_ID_QA;
	APP_KEY = process.env.VUE_APP_APP_KEY_QA;
} else if (process.env.NODE_ENV === 'development'){
	SERVER_URL = process.env.VUE_APP_SERVER_URL_TEST;
	APP_ID = process.env.VUE_APP_APP_ID_TEST;
	APP_KEY = process.env.VUE_APP_APP_KEY_TEST;
}

export { SERVER_URL, APP_ID, APP_KEY } **/

var SERVER_URL = process.env.VUE_APP_SERVER_URL;
var APP_ID = process.env.VUE_APP_APP_ID;
var APP_KEY = process.env.VUE_APP_APP_KEY;
var APP_LOGO = process.env.VUE_APP_LOGO;
var APP_LOGO_COPYRIGHT = process.env.VUE_APP_LOGO_COPYRIGHT;
var APP_IMPRINT = process.env.VUE_APP_IMPRINT;
var APP_DATA_PROTECTION = process.env.VUE_APP_DATA_PROTECTION;

export { SERVER_URL, APP_ID, APP_KEY, APP_LOGO, APP_LOGO_COPYRIGHT, APP_IMPRINT, APP_DATA_PROTECTION }
