<template>
  <div>
    <div> Sie nutzen die Version {{ applicationVersion }} des Dashboards.</div>
    <!--	<b-button v-if="isLocalHost()" @click="triggerCloudFunction()" :loading="isLoading">100 neue Einträge</b-button>-->
  </div>
</template>

<script>

import {positiveFeedBack} from "@/lib/Toast";
import { version } from "../../../package.json"

function randomIntFromInterval(min, max) { // min and max included
	return Math.floor(Math.random() * (max - min + 1) + min)
}
/**
 * This file is just an empty page (used internally for some testing of cloud functions)
 */

import Parse from "parse/dist/parse.min.js";
import {
	queryAttributeConfigs,
	queryClients, queryEntries,
	queryEntryAttributes,
	queryEntryTypes,
	queryUsers
} from "@/lib/Query";

async function createEntry({clients, entryAttributes, entryAttributeConfigs, types}, iterator){
	const client = clients[randomIntFromInterval(0, clients.length-1)]
	const chosenAttributes = []
	for(const config of entryAttributeConfigs){
		const attr = entryAttributes.filter(ea => ea.attributes.attribute_config && ea.attributes.attribute_config.id === config.id)
		chosenAttributes.push(attr[randomIntFromInterval(0, attr.length-1)])
	}

	const users = await queryUsers()
	const author = users[randomIntFromInterval(0, users.length-1)]

	const attachment = false
	const pictures = []

	const title = `Bavaria ipsum ${iterator.toString()}`
	const type = types[randomIntFromInterval(0, types.length-1)]
	const text = "Bavaria ipsum dolor sit amet oa jo mei is des schee, nia des basd scho des is a gmahde Wiesn middn zua a so a Schmarn. Hod Lewakaas wiavui gwihss. Kimmt Engelgwand Engelgwand, a ganze Charivari amoi? Sowos iabaroi Greichats so kummd gwiss Buam imma measi a ganze a bravs. Hod schnacksln dringma aweng jo mei, obacht blärrd vui. Kaiwe mechad scheans singan Schdarmbeaga See umananda! Des is a gmahde Wiesn Schmankal oans Ledahosn, imma! Oans, zwoa, gsuffa Mongdratzal Hemad, glacht! So schee Greichats moand sei. A fescha Bua von heitzdog unbandig des Hemad etza Biakriagal Deandlgwand? Von measi naa koa mehra woaß de und Gstanzl lem und lem lossn. Zidern jo mei singan, spernzaln Spotzerl!"
	const rating = randomIntFromInterval(1, 6)

	const date = new Date()
	const creationDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()-randomIntFromInterval(0, 365))
	const Entry = Parse.Object.extend("Entry")
	const entry = new Entry()
	entry.set("attachment", attachment)
	entry.set("author", author)
	entry.set("text", text)
	entry.set("readers", [])
	entry.set("pictures", pictures)
	entry.set("attributes", chosenAttributes)
	entry.set("title", title)
	entry.set("type", type)
	entry.set("created", creationDate)
	const savedEntry = await entry.save()

	const EntryLink = Parse.Object.extend("EntryLink")
	const entryLink = new EntryLink()
	entryLink.set("client", client)
	entryLink.set("readers", [])
	entryLink.set("entry", savedEntry)
	entryLink.set("reader_timestamps", [])
	entryLink.set("rating", rating)
	entryLink.set("created", creationDate)
	await entryLink.save()
}

export default {
	name: "InfoComponent",
	data() {
		return {
			isLoading: false,
			applicationVersion : version,
		};
	},
	methods: {
		isLocalHost(){
			return window.location.href.includes("localhost")
		},
		async triggerCloudFunction(){
			// const params ={
			// 	// clientId: "io4yTZlyzc"
			// 	// dependencyId: "gDRv3oTcn0"
			// 	// ids: ["Zf5ZooMwUk"]
			// }
			
			// const params={
			// 	clientFileId: "6EzrYkCqle"
			// }
			//
			
			// const clientQuery = new Parse.Query("Client")
			// const clients = await clientQuery.find()
			this.isLoading = true
			await positiveFeedBack("started")
			const clients = await queryClients()
			const entryAttributes = await queryEntryAttributes()
			const entryAttributeConfigs = await queryAttributeConfigs()
			const types = await queryEntryTypes()
			for(let iterator = 0; iterator <100; iterator++){
				await createEntry({clients, entryAttributes, entryAttributeConfigs, types}, iterator)
				
			}
			await positiveFeedBack("created")
			this.isLoading = false
		},
		async setEntriesVis(){
			const entries = await queryEntries()
			for(const entry of entries){
				entry.set("visibility", "Alle")
				await entry.save()
				
			}
			await positiveFeedBack("Done")
		}
	},
};
</script>

<style lang="scss" scoped>
</style>
