<template>
  <div>
    <div class="panel m-5">
      <PanelHeader panel-header="Stammdaten" />
      <div class="container pt-3 pb-3">
        <div class="columns is-centered">
          <div class="column image_column">
            <b-field label="Profilbild">
              <ImageWithDelete
                v-if="dropFile"
                :delete-drop-file="deleteDropFile"
                :drop-file-u-r-l="dropFileURL"
              />
              <ImageUpload
                v-else
                :drop-file="dropFile"
                :set-drop-file="(file)=>dropFile = file"
              />
            </b-field>
          </div>
        </div>
        <hr>
        <div class="columns">
          <InputWithValidation
            class="column"
            message="Bitte gib einen Vornamen ein!"
            :name="firstName"
            :set-name="(name)=> firstName = name"
            label="Vorname"
          />
          <InputWithValidation
            class="column"
            message="Bitte gib einen Nachnamen ein!"
            :name="familyName"
            :set-name="(name)=> familyName = name"
            label="Nachname"
          />
          <SelectionComponent
            class="column"
            label="Geschlecht"
            :options="genderArray"
            :select="data=> gender = data"
            :selected-option="gender"
          />
        </div>

        <div class="columns">
          <SelectionComponent
            class="column"
            label="Funktion"
            :options="functions"
            :select="data=> externalFunction = data"
            :selected-option="externalFunction"
          />
          <div class="column">
            <AutoSelection
              label="Kontaktkinder"
              attribute-name="first_name"
              second-attribute-name="last_name"
              empty-text="Keine Klienten gefunden"
              :input="val => filter= val"
              :select="option => addClient(option)"
              :data="clientsArray"
            />
            <div
              v-for="(client, index) in chosenClients"
              :key="index"
              class="m-2"
              style="display: flex"
            >
              <IconButton
                icon="delete"
                :trigger-function="()=> deleteClient(index)"
                size="is-small"
                class="mr-1"
              />
              <p>{{ client.attributes.first_name }} {{ client.attributes.last_name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel m-5">
      <PanelHeader panel-header="Kontakt- und Adressdaten" />
      <div class="container pt-3 pb-3">
        <div class="columns">
          <InputComponent
            class="column"
            :name="phone1"
            :set-name="(name)=> phone1 = name"
            label="Telefon 1"
          />
          <InputComponent
            class="column"
            :name="phone2"
            :set-name="(name)=> phone2 = name"
            label="Telefon 2"
          />
        </div>
        <div class="columns">
		<InputComponent
            class="column"
            :name="street"
            :set-name="(name)=> street = name"
            label="Straße"
          />
          <InputComponent
            class="column"
            :name="plz"
            :set-name="(name)=> plz = name"
            label="PLZ"
          />
          <InputComponent
            class="column"
            :name="location"
            :set-name="(name)=> location = name"
            label="Ort"
          />
        </div>
      </div>
    </div>
    <div class="panel m-5">
      <Header header="Login-Daten" />
      <div class="container pt-3 pb-3">
        <div class="columns">
          <InputWithValidation
            type="email"
            class="column"
            message="Bitte gib eine valide Email ein!"
            :name="email"
            :set-name="(name)=> email = name"
            label="Email"
          />
          <InputWithValidation
            type="password"
            class="column"
            message="Bitte gib ein Passwort ein! (mind. 6 Zeichen)"
            :is-new-password="true"
            :name="password"
            :set-name="(name)=> password = name"
            label="Passwort"
          />
        </div>
      </div>
    </div>

    <div
      class="buttons m-5"
      style="display: flex; justify-content: center"
    >
      <SaveButton
        :is-loading="isLoading"
        :disabled="$v.firstName.$invalid ||$v.familyName.$invalid ||
          $v.email.$invalid || $v.password.$invalid"
        :save-function="handleAddExternal"
      />
      <CancelButton
        :is-loading="false"
        text="Abbrechen"
        back-route="/externals"
      />
    </div>

    <b-modal v-model="isDublicateExternalUser">
      <div v-if="isDublicateExternalUser" class="panel m-5" style="background-color: white">
        <p class="panel-heading">
          Achtung!
        </p>
        <p class="mb-2 mt-2">
          Ein externer Nutzer mit der Email Adresse <b>{{ checkDublicateExternalUserEmail }}</b> existiert bereits.
        </p>
        <div class="buttons mt-2" style="display: flex; justify-content: center">
          <b-button class="is-primary" @click="closeModalDublicateExternalUser">
            OK
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="isReactivateExternalUser">
      <div v-if="isReactivateExternalUser" class="panel m-5" style="background-color: white">
        <p class="panel-heading">
          Achtung!
        </p>
        <p class="mb-2 mt-2">
          Es existiert bereits ein Nutzer <b>{{ reactivatableExternalUserClass.attributes.first_name }} {{ reactivatableExternalUserClass.attributes.last_name }}</b> mit den Zugangsdaten <b>{{ reactivatableExternalUserClass.attributes.email }}</b>.
        </p>
        <p class="mb-2 bold">
          <strong>Wollen Sie diesen externen Nutzer reaktivieren?</strong>
        </p>
        <p>
          <b>Hinweis:</b> Dadurch gehen alle derzeit eingegebenen Informationen verloren und es werden die bereits im System hinterlegten Informationen wiederhergestellt.
        </p>
        <div class="buttons mt-2" style="display: flex; justify-content: center">
          <b-button class="is-primary" :loading="isLoading" @click="submitReactivateExternalUser()">
            Ja
          </b-button>
          <b-button class="is-danger" @click="closeModalReactivateExternalUser">
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/**
 * This file is creating a new external person (teacher, parents, etc.)
 */
import Parse from "parse/dist/parse.min.js"
import {v4 as uuid} from "uuid";
import {queryClients, queryExternalRole, queryUserMetaClassByEmail} from "@/lib/Query";
import {minLength, required} from "vuelidate/lib/validators";
import email from "vuelidate/lib/validators/email";
import {GENDERS} from "@/lib/constants/Constants";
import {negativeFeedBack, positiveFeedBack} from "@/lib/Toast";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import ImageUpload from "@/components/helperComponents/ImageUpload";
import ImageWithDelete from "@/components/helperComponents/ImageWithDelete";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import InputComponent from "@/components/helperComponents/InputComponent";
import SelectionComponent from "@/components/helperComponents/SelectionComponent";
import AutoSelection from "@/components/helperComponents/AutoSelection";
import IconButton from "@/components/helperComponents/IconButton";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";

export default {
	name: "NewExternal",
	components: {
		SaveButton,
		CancelButton,
		IconButton, AutoSelection, SelectionComponent, ImageWithDelete, ImageUpload, PanelHeader, InputWithValidation, InputComponent},
	data() {
		return {
			dropFile: undefined,
			firstName: "",
			familyName: "",
			email: "",
			phone1: "",
			phone2: "",
			street: "",
			location: "",
			plz: "",
			password: "",
			externalFunction: "",
			functions: ["Lehrer", "Therapeuten", "Elternteil"],
			clientsArrayDefault: [],
			chosenClients: [],
			gender: "",
			genderArray: GENDERS,
			isLoading: false,
			filter: "",
			//dublicationCheck and Reactivation
			isDublicateExternalUser: false,
			checkDublicateExternalUserEmail: "",
			isReactivateExternalUser: false,
			reactivatableExternalUserClass: undefined
		}
	},
	validations: {
		email: {
			required,
			email
		},
		password: {
			required,
			minLength: minLength(6)
		},
		firstName: {
			required,
			minLength: minLength(1)
		},
		familyName: {
			required,
			minLength: minLength(1)
		}
	},
	computed: {
		dropFileURL() {
			if (this.dropFile) {
				return URL.createObjectURL(this.dropFile);
			}
			return undefined;
		},
		clientsArray(){
			return this.clientsArrayDefault.filter(client => {
				const clientIds = this.chosenClients.map(c => c.id)
				const name = `${client.attributes.first_name} ${client.attributes.last_name}`.toLowerCase()
				const isFilterInName = this.filter && name.includes(this.filter.toLowerCase())
				return !clientIds.includes(client.id) && isFilterInName
			})
		}
	},
	async mounted() {
		this.clientsArrayDefault =  await queryClients()
	},
	methods: {
		triggerDublicateExternalUser(userMetaClass) {
			this.checkNewUserMetaClass = userMetaClass;
			this.isDublicateExternalUser = true;
		},
		closeModalDublicateExternalUser() {
			this.isDublicateExternalUser = false;
		},
		triggerReactivateExternalUser(userMetaClass) {
			this.reactivatableExternalUserClass = userMetaClass;
			this.isReactivateExternalUser = true;
		},
		closeModalReactivateExternalUser() {
			this.isReactivateExternalUser = false;
		},
		async handleAddExternal() {
			this.isLoading = true

			// checken, ob es den Nutzer schon gibt
			const email = this.email.trim();
			const existingExternalUserMetaClass = await queryUserMetaClassByEmail(email);
			if(existingExternalUserMetaClass != undefined) {
				// Wenn ja und inaktiv, dann Reaktivierung ermöglichen
				if(existingExternalUserMetaClass.attributes.deleted && existingExternalUserMetaClass.attributes.type == "external"){
						this.triggerReactivateExternalUser(existingExternalUserMetaClass);
						this.isLoading = false
						return
					}
				// Sonst doppelte vermeiden
				else if (existingExternalUserMetaClass.attributes.type == "external"){
					this.checkDublicateExternalUserEmail = existingExternalUserMetaClass.attributes.email;
					this.triggerDublicateExternalUser(existingExternalUserMetaClass);
					this.isLoading = false
					return
				}
				else
				{
					negativeFeedBack("Externe Person konnte nicht angelegt werden! Nutzer bereits bekannt.")
					this.isLoading = false
					return
				}
			}

			// Ansonsten ganz normal verarbeiten
			const UserMetaClass = Parse.Object.extend("UserMetaClass")
			const externalMetaClass = new UserMetaClass()
			externalMetaClass.set("first_name", this.firstName.trim())
			externalMetaClass.set("last_name", this.familyName.trim())
			externalMetaClass.set("email", this.email.trim())
			externalMetaClass.set("phone1", this.phone1.trim())
			externalMetaClass.set("phone2", this.phone2.trim())
			externalMetaClass.set("plz", this.plz.trim())
			externalMetaClass.set("location", this.location.trim())
			externalMetaClass.set("street", this.street.trim())
			externalMetaClass.set("function", this.externalFunction)
			externalMetaClass.set("is_admin", false)
			externalMetaClass.set("gender", this.gender)
			externalMetaClass.set("type", "external");
			const clientPointers = this.chosenClients.map(client => client.toPointer())
			externalMetaClass.set("clients", clientPointers)
			try {
				if (this.dropFile !== undefined) {
					const imageFile = new Parse.File(uuid(), this.dropFile);
					externalMetaClass.set("profile_picture", imageFile);
					await imageFile.save()
				}
				await externalMetaClass.save()
				const sessionToken = Parse.User.current().getSessionToken()
				const external = new Parse.User()
				external.set("username", this.email.trim())
				external.set("password", this.password)
				external.set("dependency", externalMetaClass.toPointer())
				const externalClass = await external.signUp()
				Parse.User.become(sessionToken)
				const params = {userId: externalClass.id}
				await Parse.Cloud.run("destroySessions", params)
				// const params = {
				// 	dependencyId: externalMetaClass.id,
				// 	email: this.email,
				// 	password: this.password
				// }
				// const external = await Parse.Cloud.run("signUpUser", params)
				positiveFeedBack("Externe Person angelegt!")
				const externalRole = await queryExternalRole()
				if (externalRole) {
					externalRole.getUsers().add(external)
					await externalRole.save()
				}
				this.firstName = ""
				this.familyName = ""
				this.email = ""
				this.phone1 = ""
				this.phone2 = ""
				this.street = ""
				this.location = ""
				this.plz = ""
				this.password = ""
				this.externalFunction = ""
				this.chosenClients = []
				await this.$router.push({path: "/externals"})

			} catch (error) {
				// TODO Hier muss noch mehr passieren, wenn es zu einem Fehler beim Anlegen des Nutzers kommt!
				// Er wird nämlich trotzdem angelegt schlimmstenfalls
				negativeFeedBack("Externe Person konnte nicht angelegt werden nicht angelegt!")
				console.error(error)
			}

			this.isLoading = false
		},
		deleteDropFile() {
			this.dropFile = undefined;
		},
		addClient(option){
			this.chosenClients.push(option)
		},
		deleteClient(index){
			this.chosenClients.splice(index, 1)
		},
		async submitReactivateExternalUser() {
			this.isLoading = true
			this.reactivatableExternalUserClass.set("deleted", false);
			this.reactivatableExternalUserClass.save()
				.then(async () => {
					positiveFeedBack("Externe Person wiederhergestellt!")
					await this.$router.push({path: "/externals"})
				}).catch((e) => {
					// TODO: Hier muss mehr passieren??
					negativeFeedBack("Externe Person konnte nicht wiederhergestellt werden!")
					console.error(e);
				});
			this.isLoading = false
		},
	}
};
</script>

<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
