<template>
  <div>
    <div>
      <SearchableTable
        :panel-header="'Klienten'"
        :panel-route="'AddClient'"
        :show-search-bar="true"
        :table-data="clients"
        :table-meta-data="tableMetaData"
        :query-string="'setClientSearchQuery'"
        :event-value="parentEventValue"
      >
        <b-table-column
          v-slot="props"
          label="Aktionen"
          cell-class="has-text-right truncate-column"
          header-class="th-right truncate-column"
        >
          <b-button
            class="is-small mr-2"
            @click="triggerView(props.row)"
          >
            <b-icon
              class="mt-1"
              icon="eye"
              style="cursor: pointer"
            />
          </b-button>
          <b-button
            class="is-small mr-2"
            @click="triggerEdit(props.row)"
          >
            <b-icon
              class="mt-1"
              icon="pencil"
              style="cursor: pointer;"
            />
          </b-button>
          <b-button
            class="is-small"
            @click="triggerDelete(props.row)"
          >
            <b-icon
              class="mt-1"
              icon="delete"
              style="cursor: pointer"
            />
          </b-button>
        </b-table-column>
      </SearchableTable>

      <b-modal v-model="isDeleteCheck">
        <div
          v-if="isDeleteCheck"
          class="panel m-5"
          style="background-color: white"
        >
          <p class="panel-heading">
            Möchten Sie den Klienten "{{ chosenClient.attributes.first_name }} {{ chosenClient.attributes.last_name }}" wirklich löschen?
          </p>
          <div
            class="buttons mt-2"
            style="display: flex; justify-content: center"
          >
            <b-button
              class="is-danger"
              :loading="isLoading"
              @click="submitDelete()"
            >
              Ja
            </b-button>
            <b-button
              class="is-primary"
              @click="closeModal"
            >
              Nein
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
/**
 * This file shows all currently stored clients and allows adding, editing, viewing and soft deleting clients
 */
import Parse from "parse/dist/parse.min.js";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import { mapGetters } from "vuex";
import SearchableTable from "../helperComponents/SearchableTable.vue";

export default {
	name: "ClientTable",
	components: { SearchableTable },
	data() {
		return {
			parentEventValue: undefined,
			clientData: [],
			isDeleteCheck: false,
			groupNames: [],
			chosenClient: {},
			clientFilter: "",
			clientDataFiltered: [],
			isLoading: false,
			userMetaClasses: [],
			tableMetaData: [
				{ "id": 1, "type": "string", "label": "Vorname", "field": "first_name", "sortField": "attributes.first_name" },
				{ "id": 2, "type": "string", "label": "Nachname", "field": "last_name", "sortField": "attributes.last_name" },
				{ "id": 3, "type": "ref", "label": "Gruppe", "field": "group_name", "sortField": "attributes.group.attributes.group_name", "refName": "group" },
				{ "id": 4, "type": "shallowData", "label": "Kontakterzieher", "field": "educator", "sortField": "educator" },
				{ "id": 5, "type": "boolean", "label": "Aktivität", "field": "all_inactive", "true": "Inaktiv", "false": "Aktiv", "sortField": "attributes.all_inactive"}
			]
		};
	},
	computed: {
		...mapGetters({
			clients: "getClientsFilteredWithContactEducators"
		})
	},

	methods: {
		// UNUSED
		// getPicture(client) {
		// 	if (!client) return ""
		// 	const pictureObject = {
		// 		newProfilePicture: undefined,
		// 		clientPicture: client.attributes.profile_picture ? client.attributes.profile_picture._url : undefined,
		// 		clientGender: client.attributes.gender
		// 	}
		// 	return getClientPicture(pictureObject)
		// },
		triggerEdit(client) {
			this.$router.push({ name: "AddClient", params: { data: client } });
		},
		triggerView(client) {
			this.$router.push({ name: "ViewClient", params: { data: client } });
		},
		triggerDelete(client) {
			this.chosenClient = client;
			this.isDeleteCheck = true;
		},
		closeModal() {
			this.isDeleteCheck = false;
		},
		async submitDelete() {
			this.isLoading = true

			// Alle zusammengehörigen Klienten raussuchen
			let correspondingClientsQuery = new Parse.Query('Client');
			correspondingClientsQuery.equalTo("uuid", this.chosenClient.attributes.uuid);
			correspondingClientsQuery.find().then((correspondingClients) => {

				for (let clientDelete of correspondingClients){
					clientDelete.set("deleted", true);
					clientDelete.save()
					.then(async (clientObject) => {
						const params = { clientId: this.chosenClient.id }
						await Parse.Cloud.run("deleteClientConnections", params)
						positiveFeedBack("Klient gelöscht!")
						const queryNotifications = new Parse.Query("Notification")
						queryNotifications.equalTo("client", clientObject)
						const notifications = await queryNotifications.find()
						for (const notification of notifications) {
							await notification.destroy()
						}
						// 0155 - NumberOfAssociatedClients neu berechnen lassen
						await Parse.Cloud.run("recalculateNumberOfAssociatedClientsByDeletedClientId", params)
					})
					.catch((e) => {
						negativeFeedBack("Klient nicht gelöscht!")
						console.error(e);
					});
				}

				this.isLoading = false
				this.isDeleteCheck = false;
			});
		},
	},
};

</script>

<style scoped></style>
