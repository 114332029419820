<template>
  <b-field
    :message="selectedBalance ? '' : 'Bitte wähle eine Kasse!' "
    :type="selectedBalance ? 'is-success' : 'is-danger'"
  >
    <template #label>
      <div
        class="align-content-center"
        style="display: inline-flex"
      >
        <p>Kassen</p>
      </div>
    </template>
    <b-select
      v-model="selectedBalance"
      class="m-2"
      placeholder="Kassen"
      expanded
      @input="set"
    >
      <option
        v-for="(balance) in balances"
        :key="balance.id"
        :value="balance"
      >
        {{ balance.attributes.name }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
import Parse from "parse/dist/parse.min"
export default {
	name:"BalanceGroupSelection",
	data(){
		return{
			balances: [],
			selectedBalance: undefined
		}
	},
	async created() {
		const query = new Parse.Query("BalanceGroup").notEqualTo("deleted", true).include("budgets").include("entities")
		this.balances = await query.find()
	},
	methods:{
		set(){
			this.$emit("input", this.selectedBalance)
		},
		reset(){
			this.selectedBalance = undefined
		}
	}
}
</script>

<style scoped>

</style>
