<template>
  <div class="hero is-fullheight is-light">
    <div class="hero-body container">
      <div class="box">
        <h4><b>Möchtest du dich wirklich abmelden?</b></h4>
        <b-button
          type="is-danger"
          :loading="isLoading"
          class="m-2"
          @click="logoutUser()"
        >
          Ja
        </b-button>
        <b-button
          type="is-primary"
          tag="router-link"
          to="/"
          class="m-2"
        >
          Nein
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * This file provides logout
 */
import Parse from "parse/dist/parse.min.js";
import {negativeFeedBack, positiveFeedBack} from "@/lib/Toast";
export default {
	name: "LogoutComponent",
	data(){
	return{
		isLoading: false
	}
	},
	methods:{
		async logoutUser(){
			this.isLoading = true
			try{
				await Parse.User.logOut()
				await this.$router.push("/login")
				positiveFeedBack("Nutzer abgemeldet!")
				location.reload()
			}catch(e){
				console.error("couldnt logout the user " + e)
				negativeFeedBack("Abmeldung fehlgeschlagen!")
			}
			this.isLoading = false
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
