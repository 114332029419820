const state = {
	data: [],
};

const getters = {
	getData(state){
		if(state.data){
			return state.data
		} else {
			return undefined
		}
	},
	getInstantMedicationLogs(state, getters, rootState, rootGetters){
		let returnArray = []

		state.data.forEach(element => {
			if(element.attributes.instantMedication){
				returnArray.push(element)
			}
		});

		return returnArray
	}
};

const mutations = {
	SET(state, objects) {
		state.data = objects
	},
	INSERT(state, object) {
		object.set("date", new Date(object.attributes.date))
		state.data.push(object)
	},
	DELETE(state, object) {
		state.data = state.data.filter((elem) => {
			return elem.id != object.id
		})
	},
	REPLACE(state, object) {
		object.set("date", new Date(object.attributes.date))
		const oldObject = state.data.filter(item => item.id == object.id)
		const index = state.data.indexOf(oldObject[0]);
		if (index > -1) {
			state.data.splice(index, 1, object);
		}
	},
	LOAD(state, load) {
		state.loaded = load
	},
	SET_FOCUSED_MEDICATION_LOG_ENTRY_ID(state, value) {
		state.focusedMedicationLogEntryId = value
	},
	SET_FOCUSED_MEDICATION_LOG_ENTRY(state, value) {
		state.focusedMedicationLogEntry = value;
	}
};

const actions = {
	set({ commit }, objects) {
		commit('SET', objects)
	},
	insert({ commit }, object) {
		commit('INSERT', object)
	},
	delete({ commit }, object) {
		commit('DELETE', object)
	},
	replace({ commit }, object) {
		commit('REPLACE', object)
	},
	load({ commit }, object) {
		commit('LOAD', object)
	},
	setFocusedMedicationEntryId({ commit }, query) {
		commit("SET_FOCUSED_MEDICATION_LOG_ENTRY_ID", query)
	},
	setFocusedMedicationEntry({ commit }, query) {
		commit("SET_FOCUSED_MEDICATION_LOG_ENTRY", query)
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};