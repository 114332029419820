export let icon_list = 
{
	0: "earth",
	// 1: "account",
	// 2: "book-open-variant",
	// 3: "bell",
	// 4: "chart-areaspline",
	// 5: "star",
	// 6: "plus",
	// 7: "cake",
	// 8: "book",
	// 9: "wallet",
	// 10: "calendar-week",
	11: "email",
	12: "phone",
	13: "download-circle",
	14: "file-document",
	15: "attachment",
	17: "application",
	18: "account-group",
	19: "chat",
	20: "video",
	21: "music-circle",
	22: "youtube",
	23: "linkedin",
	24: "facebook",
	// 25: "xing",
	26: "instagram",
	27: "spotify",
	28: "folder",
	29: "bank",
	30: "cloud",
	31: "microsoft-teams",
	32: "microsoft-word",
	33: "microsoft-excel",
	34: "skype",
	35: "slack",
	// 36: "discord",
	37: "twitter",
	38: "web",
	39: "cellphone",
	40: "podium-gold"
}