<template>
	<div>
		<DoctorTable />
	</div>
</template>
  
<script>
/**
 * This file shows all currently stored doctors and allows adding, editing, viewing and soft deleting these.
 */
import DoctorTable from "@/components/medication/doctor/DoctorTable";
export default {
	name: "Doctor",
	components: { DoctorTable },
	data() {
		return {};
	},
	computed: {},
	mounted() { },
	methods: {},
};
</script>
  
<style lang="scss" scoped></style>
  