<template>
  <div class="panel m-5">
    <PanelHeader panel-header="Neue Kinderakte-Kategorie" />

    <div class="p-2">
      <div class="columns">
        <div class="column">
          <InputWithValidation
            message="Bitte gib einen Kategorienamen ein!"
            :name="categoryName"
            :set-name="setCategoryName"
            label="Kategoriename"
          />
        </div>
        <div class="column">
          <ColorPicker
            :colors="colors"
            :set-colors="setColors"
          />
        </div>
      </div>


      <div
        class="m-5 buttons"
        style="display: flex; justify-content: center"
      >
        <SaveButton
          :disabled="$v.categoryName.$invalid"
          :is-loading="isLoading"
          :save-function="addCategory"
        />
        <CancelButton
          back-route="/client_records"
          text="Abbrechen"
          :is-loading="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse/dist/parse.min.js";
import {required} from "vuelidate/lib/validators";
import InputWithValidation from "@/components/helperComponents/InputWithValidation"
import ColorPicker from "@/components/helperComponents/ColorPicker";
import SaveButton from "@/components/helperComponents/SaveButton";
import CancelButton from "@/components/helperComponents/CancelButton";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import {positiveFeedBack} from "@/lib/Toast";

/**
 * This file handles setting up a new category for client records and adding pdf files to this category
 */
export default {
	name: "NewClientRecordCategory",
	components: {
		PanelHeader,
		CancelButton,
		SaveButton,
		ColorPicker,
		InputWithValidation
	},
	data(){
		return{
			categoryName: "",
			colorPickerOpen: false,
			isLoading: false,
			colors: {
				"hex": "#000000",
				"source": "hex"
			},

		}
	},
	validations: {
		categoryName: {
			required,
		}
	},

	methods:{
		async addCategory(){
			this.isLoading = true
			const ClientRecordCategory = Parse.Object.extend("ClientRecordCategory")
			const clientRecordCategory = new ClientRecordCategory()
			clientRecordCategory.set("name", this.categoryName)
			clientRecordCategory.set("color", this.colors.hex)
			try{
				await clientRecordCategory.save()
			}catch (e){
				console.error("couldnt save the client record category " + e)
			}
			this.isLoading = false
			positiveFeedBack("Kategorie angelegt!")
			return await this.$router.push({ path: "/client_records" })
		},
		setCategoryName(name){
			this.categoryName = name
		},
		setColors(colors){
			this.colors = colors
		},
		setLoading(isLoading){
			this.isLoading =isLoading
		},

	}
}
</script>

<style scoped>

</style>
