<template>
  <div>
    <ClientTable />
    <!--		<ClientTable header-title="Inaktive Klienten" :clients="inactiveCLients" :filter="filterInActiveClients"/>-->
  </div>
</template>

<script>
/**
 * This file shows all currently stored clients and allows adding, editing, viewing and soft deleting clients
 */
import {mapGetters} from "vuex";
import ClientTable from "@/components/clients/ClientTable";
export default {
	name: "ClientModalBody",
	components: {ClientTable},
	data() {
		return {
			clientData: [],
			isDeleteCheck: false,
			groupNames: [],
			chosenClient: {},
			clientFilter: "",
			clientDataFiltered: [],
			isLoading: false,
			userMetaClasses: []
		};
	},
	computed: {
		...mapGetters({
			inactiveCLients: "getInactiveClientsFiltered"
		})
	},
	mounted() {
		this.$store.dispatch("setClientSearchQuery", "")
	},
	methods: {
		filterActiveClients() {
			this.$store.dispatch("setClientSearchQuery", this.clientFilter)
			// this.clientDataFiltered = this.clientData.filter(client => {
			// 	const name = `${client.attributes.first_name.toLowerCase()} ${client.attributes.last_name.toLowerCase()}`
			// 	return name.includes(this.clientFilter.toLowerCase())
			// })
		},
		filterInActiveClients() {
			this.$store.dispatch("setClientInactiveSearchQuery", this.clientFilter)
		},
	},
};
</script>

<style lang="scss" scoped>
</style>
