import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../lib/Errors';

let localSubscription = undefined;

// Role subscription
function createSubscription(store) {
    let roleQuery = new Parse.Query(Parse.Role);
    roleQuery.notEqualTo("deleted", true)
        .find()
        .then((results) => {
            store.dispatch('roles/set', results)
            const adminRole = results.find(res => res.attributes.name === "Admin")
            const staffRole = results.find(res => res.attributes.name === "Staff")
            const externalRole = results.find(res => res.attributes.name === "External")
            adminRole.getUsers().query().find().then(admins => {
                store.dispatch('admins/set', admins)
            })
            staffRole.getUsers().query().find().then(staffs => {
                store.dispatch('staffs/set', staffs)
            })
            externalRole.getUsers().query().find().then(externals => {
                store.dispatch('externals/set', externals)
            })

        })
        .catch((error) => {
            handleParseError(error);
           
        });
    roleQuery.subscribe().then((subscription) => {
        localSubscription = subscription;

        subscription.on('create', (object) => {
            store.dispatch('roles/insert', object)
        });
        subscription.on('update', (object) => {
            store.dispatch('roles/replace', object)
        });
        subscription.on('enter', (object) => {
            store.dispatch('roles/insert', object)
        });
        subscription.on('leave', (object) => {
            store.dispatch('roles/delete', object)
        });
        subscription.on('delete', (object) => {
            store.dispatch('roles/delete', object)
        });
        subscription.on('close', () => {
        });
		subscription.on('error', error => {
            console.log('Role subscription error', error);
        })
    })
}

export default function createRoleSubscription() {
    return store => {
        if (store.state.currentUser) {
            createSubscription(store)
        }
        store.subscribe(mutation => {
            if (mutation.type === 'SET_CURRENT_USER') {
                if (localSubscription) {
                    localSubscription.unsubscribe()
                }
                if (store.state.currentUser) {
                    createSubscription(store)
                }
            }
        })
    }
}
