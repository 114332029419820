<template>
  <div>
    <SearchableTable
      :panel-header="'Schulen/Kindergarten'"
      :panel-route="'AddSchool'"
      :show-search-bar="false"
      :table-data="schools"
      :table-meta-data="tableMetaData"
      :query-string="'setPersonSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerView(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="eye"
            style="cursor: pointer"
          />
        </b-button>
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
    <ModalComponent
      v-if="isDeleteCheck"
      :item="school.attributes.name"
      :is-delete-check="isDeleteCheck"
      :is-loading="isLoading"
      :cancel="() => isDeleteCheck = false"
      :submit="submitDelete"
    />
  </div>
</template>

<script>
import { queryLocationByType } from "@/lib/Query";
import ModalComponent from "../helperComponents/ModalComponent"
import { positiveFeedBack } from "@/lib/Toast";
import SearchableTable from "../helperComponents/SearchableTable.vue";

export default {
	name: "SchoolsComponent",
	components: { SearchableTable, ModalComponent },
	data() {
		return {
			schools: [],
			school: undefined,
			isDeleteCheck: false,
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Email", "field": "email", "sortField": "attributes.email" },
				{ "id": 1, "type": "string", "label": "Name", "field": "name", "sortField": "attributes.name" },
				{ "id": 2, "type": "string", "label": "Ort", "field": "location", "sortField": "attributes.location" }
			]
		}
	},
	async created() {
		this.schools = await queryLocationByType("school")
	},
	methods: {
		triggerEdit(school) {
			this.$router.push({ name: "AddSchool", params: { data: school } });
		},
		triggerView(school) {
			this.$router.push({ name: "ViewSchool", params: { data: school } });
		},
		triggerDelete(school) {
			this.school = school
			this.isDeleteCheck = true
		},
		async submitDelete() {
			const name = this.school.attributes.name
			this.school.set("deleted", true)
			await this.school.save()
			this.isDeleteCheck = false
			this.schools = await queryLocationByType("school")
			await positiveFeedBack(name + " gelöscht!")
		}
	}
}
</script>

<style scoped></style>
