<template>
	<div>
		<div class="panel m-5">
			<PanelHeader :panel-header="panelHeader" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<ValidatedSelection class="column" label="Medikament" message="Bitte wählen Sie ein Medikament aus!"
						attributeName="displayName" :has-validation="true" :options="medicationArray"
						:selected-option="medicationMedication" :select="(item) => (medicationMedication = item)" />
					<InputWithValidation type="number" class="column" message="Bitte geben Sie die Mindestmenge ein!"
						:name="medicationMin" :set-name="(val) => (medicationMin = val)" label="Mindestmenge" />
					<ValidatedSelection class="column" label="Verantwortlicher"
						message="Bitte wählen Sie einen Verantwortlichen aus!" attributeName="dependency"
						secondAttributeName="first_name" thirdAttributeName="last_name" :has-validation="true"
						:options="filteredmedicationUserArray" :selected-option="medicationUser" :nestedAttribute="true"
						:select="(item) => (medicationUser = item)" />
				</div>
				<hr>
				<div class="columns">
					<DatePicker :has-validation="true" message="Bitte wählen Sie das Startdatum aus!" class="column"
						label="Beginn" :selected-date="medicationBeginDate" :select="date => medicationBeginDate = date" />
					<DatePicker :has-validation="true" message="Bitte wählen Sie das Enddatum aus!" class="column"
						label="Ende" :selected-date="medicationEndDate" :select="date => medicationEndDate = date" />
					<DatePicker :has-validation="true" message="Bitte wählen Sie das Ablaufdatum aus!" class="column"
						label="Ablaufdatum" :selected-date="medicationExpirationDate"
						:select="date => medicationExpirationDate = date" />
				</div>
				<hr>
				<div class="columns">
					<InputComponent type="textarea" class="column" :name="medicationHint"
						:set-name="(val) => (medicationHint = val)" label="Hinweise"
						:placeholder="'Beispiel: Zur Anwendung bei Kindern ab 20kg, Jugendlichen und Erwachsenen'" />
				</div>
			</div>
		</div>
		<div class="m-5 buttons" style="display: flex; justify-content: center">
			<SaveButton :is-loading="isLoading" :save-function="saveInstantMedication"
				:disabled="$v.medicationMedication.$invalid || $v.medicationMin.$invalid || $v.medicationUser.$invalid" />
			<CancelButton text="Abbrechen" back-route="/instant_medication" :is-loading="false" />
		</div>
	</div>
</template>  

<script>
/**
 * This file is for creating and editing instant medications.
 */

import Parse from "parse/dist/parse.min.js";
import { minLength, required } from "vuelidate/lib/validators";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import InputComponent from "@/components/helperComponents/InputComponent"
import { mapGetters } from "vuex";
import ValidatedSelection from "@/components/helperComponents/ValidatedSelection";
import DatePicker from "@/components/helperComponents/DatePicker";

export default {
	name: "NewInstantMedication",
	components: {
		SaveButton,
		CancelButton,
		InputWithValidation,
		PanelHeader,
		InputComponent,
		ValidatedSelection,
		DatePicker,
	},
	data() {
		return {
			dataObject: undefined,
			isLoading: false,
			medicationMedication: undefined,
			medicationNumber: -1,
			medicationMin: 0,
			medicationHint: "",
			medicationBeginDate: undefined,
			medicationEndDate: undefined,
			medicationExpirationDate: undefined,
			medicationUser: undefined,
			panelHeader: "Neues Bedarfsmedikament anlegen"
		};
	},
	computed: {
		...mapGetters({
			medicationArray: "getMedication",
			medicationUserArray: "getUsers",
			medicationFormArray: "getMedicalForm",
			medicationUnitArray: "getMedicalUnit",
		}),
		filteredmedicationUserArray: function () {
			let filteredUserArray = this.medicationUserArray.filter((element) => element.attributes.dependency.attributes.first_name ||
				element.attributes.dependency.attributes.last_name)
			return filteredUserArray
		},
	},
	watch: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.dataObject = this.$route.params.data
			this.medicationMedication = this.dataObject.attributes.medication.attributes.deleted ? undefined : (this.dataObject.attributes.medication).toPointer()
			this.medicationMin = this.dataObject.attributes.min
			this.medicationNumber = this.dataObject.attributes.number
			this.medicationHint = this.dataObject.attributes.hint
			this.medicationBeginDate = this.dataObject.attributes.beginDate
			this.medicationEndDate = this.dataObject.attributes.endDate
			this.medicationExpirationDate = this.dataObject.attributes.expirationDate
			this.medicationUser = (this.dataObject.attributes.user).toPointer()
			this.panelHeader = "Bedarfsmedikament bearbeiten"
		}
	},
	validations: {
		medicationMedication: {
			required,
		},
		medicationMin: {
			required,
		},
		medicationUser: {
			required,
		},
	},
	methods: {
		async saveInstantMedication() {
			this.isLoading = true;

			if(this.medicationNumber == -1)
			{
				this.medicationNumber = await this.calculate_next_medication_number();
			}
			
			const parseObject = Parse.Object.extend("MedicationInstantMedication")
			const dataObject = this.dataObject ? this.dataObject : new parseObject()

			for(var i=0; i< this.medicationArray.length; i++)
			{
				if(this.medicationArray[i].id == this.medicationMedication.objectId)
				{
					dataObject.set("medication", this.medicationArray[i])
				}
			}

			for(var j=0; j< this.medicationUserArray.length; j++)
			{
				if(this.medicationUserArray[j].id == this.medicationUser.objectId)
				{
					dataObject.set("user", this.medicationUserArray[j])
				}
			}

			//dataObject.set("medication", this.get_medication_data(this.medicationMedication.objectId))
			//dataObject.set("medication", this.medicationMedication)
			
			dataObject.set("number", Number(this.medicationNumber))
			dataObject.set("min", Number(this.medicationMin))
			dataObject.set("hint", this.medicationHint)
			dataObject.set("beginDate", this.medicationBeginDate)
			dataObject.set("endDate", this.medicationEndDate)
			dataObject.set("expirationDate", this.medicationExpirationDate)
			
			dataObject.set("deleted", false)
			
			await dataObject.save().catch((e) => {
				negativeFeedBack("Bedarfsmedikament nicht gespeichert!")
			});
			this.isLoading = false;
			await this.$router.push({ path: "/instant_medication" })
			await positiveFeedBack("Bedarfsmedikament gespeichert!")
		},
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		},
		async calculate_next_medication_number()
		{
			const medicationQuery_temp = new Parse.Query('MedicationInstantMedication');
			medicationQuery_temp.notEqualTo("deleted", true);
			medicationQuery_temp.descending("number");
			const results = await medicationQuery_temp.find();

			let number_counter = 1;

			if (results.length > 0) {
				number_counter = parseInt(results[0].attributes.number) + 1
			}

			return number_counter;
		},
		// async get_medication_data(o_id)
		// {
		// 	const medicationQuery_temp = new Parse.Query('MedicationMedication');
		// 	const results = await medicationQuery_temp.find();

		// 	let number_counter = 1;

		// 	if (results.length > 0) {
		// 		number_counter = parseInt(results[0].attributes.number) + 1
		// 	}

		// 	return number_counter;
		// }
	},
};
</script>
  
<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
  