import { queryAdminRole, queryClients, queryGroupByName, queryObjectByName } from "@/lib/Query";
import { equalsDate } from "@/lib/Date";
import Parse from "parse/dist/parse.min.js";

export const clientExists = async (client) => {
	if (client) {
		const clients = await queryClients()
		const filteredClients = clients.filter(c => {
			const { first_name, last_name, birth_date } = c.attributes
			return !!(equalsDate(birth_date, client.birth_date) && first_name === client.first_name && last_name === client.last_name);
		}) 
		return filteredClients.length > 0
	}
	return false
}

export const clientWithGroupExists = async (client, groupId) => {
	const clients = await queryClients()
	const filteredClients = clients.filter(c => {
		const { first_name, last_name, birth_date, group } = c.attributes
		return !!(equalsDate(birth_date, client.birth_date) && first_name === client.first_name
			&& last_name === client.last_name
			&& groupId === group.id);
	})
	return filteredClients.length > 0
}

export const destroyCreatedSession = async (sessionToken, user) => {
	Parse.User.become(sessionToken)
	const sessionParams = { userId: user.id }
	await Parse.Cloud.run("destroySessions", sessionParams)
}

export const addUserToAdmin = async (user) => {
	const adminRole = await queryAdminRole()
	const admins = adminRole.getUsers();
	admins.add(user)
	await adminRole.save()
}

export const removeUserFromAdmin = async (user) => {
	const adminRole = await queryAdminRole()
	const admins = adminRole.getUsers();
	admins.remove(user)
	await adminRole.save()
}

export const editUser = async (params, userMetaClass, user) => {
	if (!userMetaClass.attributes.is_admin && params.isAdmin) await addUserToAdmin(user)
	if (userMetaClass.attributes.is_admin && !params.isAdmin) await removeUserFromAdmin(user)
	params["userMetaClassId"] = userMetaClass.id
	await Parse.Cloud.run("editUser", params)
}

export const getUser = async (email) => {
	const query = new Parse.Query("_User")
	const users = await query.find()
	return users.find(user => user.attributes.username.toLowerCase() === email.toLowerCase())
}

const getGroupFromDatabase = async (object) => {
	const group = await queryGroupByName(object.group_name)
	return group
}

const getLocationFromDatabase = async (object) => {
	const obj = await queryObjectByName("Location", object.name)
	return obj
}

const getStatutoryFromDatabase = async (object) => {
	const obj = await queryObjectByName("StatutoryBasis", object.name)
	return obj
}

const getPersonFromDatabase = async (object) => {
	const query = new Parse.Query("Person")
	query.equalTo("first_name", object.first_name.trim())
	query.equalTo("last_name", object.last_name.trim())
	query.equalTo("type", object.type)
	const obj = await query.first()
	return obj
}

export const getObjectFromDatabase = async (objectName, object) => {
	if (objectName === "Group") return await getGroupFromDatabase(object)
	if (objectName === "Location") return await getLocationFromDatabase(object)
	if (objectName === "Person") return await getPersonFromDatabase(object)
	if (objectName === "StatutoryBasis") return await getStatutoryFromDatabase(object)
	return undefined
}

export const getFirstNameLastNameType = (input) => {
	if (input === undefined || !input.includes(", ")) return { firstName: undefined, lastName: undefined, type: undefined }
	const splitData = input.split(", ")
	if (splitData.length === 1) {
		const lastName = splitData[0]
		return { firstName: undefined, lastName: lastName, type: undefined }
	}
	if (splitData.length === 2) {
		const firstName = splitData[1]
		const lastName = splitData[0]
		return { firstName: firstName, lastName: lastName, type: undefined }
	}
	const firstName = splitData[1]
	const lastName = splitData[0]
	const type = splitData[2]
	return { firstName, lastName, type }
}
