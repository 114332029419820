<template>
  <div>
    <div
      v-if="isMounted"
      class="panel m-5"
    >
      <PanelHeader panel-header="Stammdaten" />
      <div class="container pt-3 pb-3">
        <div class="columns is-centered">
          <b-field
            v-if="!onBoarding"
            class="column image_column"
            label="Profilbild"
          >
            <ImageWithDelete
              v-if="dropFile"
              :delete-drop-file="deleteDropFile"
              :drop-file-u-r-l="dropFileURL"
            />
            <ImageUpload
              v-else
              :drop-file="dropFile"
              :set-drop-file="(file)=>dropFile = file"
            />
          </b-field>
        </div>
        <hr>
        <div class="columns">
          <InputWithValidation
            class="column"
            message="Bitte gib einen Vornamen ein!"
            :name="firstName"
            :set-name="(name)=> firstName = name"
            label="Vorname"
          />
          <InputWithValidation
            class="column"
            message="Bitte gib einen Nachnamen ein!"
            :name="familyName"
            :set-name="(name)=> familyName = name"
            label="Nachname"
          />
        </div>
        <div class="columns">
          <SelectionComponent
            class="column"
            label="Geschlecht"
            :options="genderArray"
            :selected-option="gender"
            :select="(item)=> gender = item"
          />
          <DatePicker
            :key="isMounted? 'dateU': 'date'"
            class="column"
            label="Geburtstag"
            :selected-date="birthday"
            :select="date => birthday=date"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!onBoarding"
      class="panel m-5"
    >
      <PanelHeader panel-header="Personaldaten" />
      <div class="container pt-3 pb-3">
        <div class="columns">
          <div class="column">
            <b-field label="Gruppen">
              <b-select
                class="m-2"
                placeholder="Gruppe"
                expanded
                @input="groupObj => addGroup(groupObj)"
              >
                <option
                  v-for="groupObj in groupListLocal"
                  :key="groupObj.id"
                  class="content"
                  :value="groupObj"
                >
                  {{ groupObj.attributes.group_name }}
                </option>
              </b-select>
            </b-field>
            <div
              v-for="(userGroup, index) in userGroups"
              :key="index"
              class="m-2"
              style="display: flex"
            >
              <b-button
                class="is-small mr-2"
                @click="spliceGroup(userGroup)"
              >
                <b-icon
                  icon="delete"
                  style="cursor: pointer"
                />
              </b-button>
              <p v-if="userGroup">
                {{ userGroup.group.attributes.group_name }}
              </p>
            </div>
          </div>
          <div class="column">
            <b-field
              label="Kontaktkinder"
              :message="getFilteredClients().length === 0? 'Wähle eine Gruppe mit Kindern' : ''"
              type="is-primary"
            >
              <b-select
                v-if="clientList"
                class="m-2"
                placeholder="Klient"
                expanded
                @input="clientObj => addClient(clientObj)"
              >
                <option
                  v-for="clientOption in getFilteredClients()"
                  :key="clientOption.id"
                  class="content"
                  :value="clientOption"
                >
                  {{ clientOption.attributes.first_name }} {{ clientOption.attributes.last_name }}
                </option>
              </b-select>
            </b-field>
            <div
              v-for="(client, index) in chosenClients"
              :key="index"
              class="m-2"
              style="display: flex"
            >
              <b-button
                class="is-small mr-2"
                @click="deleteClient(index)"
              >
                <b-icon
                  icon="delete"
                  style="cursor: pointer"
                />
              </b-button>
              <p
                v-if="client"
                style="text-align: start"
              >
                {{ client.attributes.first_name }} {{ client.attributes.last_name }}
              </p>
            </div>
          </div>
          <InputComponent
            :key="isMounted? 'funcU': 'func'"
            class="column"
            :name="func"
            :set-name="(name)=> func = name"
            label="Funktion"
          />
        </div>
      </div>
    </div>

    <div
      v-if="!onBoarding"
      class="panel m-5"
    >
      <PanelHeader panel-header="Kontakt- und Adressdaten" />
      <div class="container pt-3 pb-3">
        <div class="columns">
          <InputComponent
            :key="isMounted ? 'phone1u' : 'phone1'"
            class="column"
            :name="phone1"
            :set-name="(val) => (phone1 = val)"
            label="Telefon 1"
          />
          <InputComponent
            :key="isMounted ? 'info1u' : 'info1'"
            class="column"
            :name="info1"
            :set-name="(val) => (info1 = val)"
            label="Info 1"
          />
        </div>
        <div class="columns">
          <InputComponent
            :key="isMounted ? 'phone2u' : 'phone2'"
            class="column"
            :name="phone2"
            :set-name="(val) => (phone2 = val)"
            label="Telefon 2"
          />
          <InputComponent
            :key="isMounted ? 'info2u' : 'info2'"
            class="column"
            :name="info2"
            :set-name="(val) => (info2 = val)"
            label="Info 2"
          />
        </div>
        <div class="columns">
		<InputComponent
            :key="isMounted? 'streetU': 'street'"
            class="column"
            :name="street"
            :set-name="(name)=> street = name"
            label="Straße"
          />
          <InputComponent
            :key="isMounted? 'plzU': 'plz'"
            class="column"
            :name="plz"
            :set-name="(name)=> plz = name"
            label="PLZ"
          />
          <InputComponent
            :key="isMounted? 'locationU': 'location'"
            class="column"
            :name="location"
            :set-name="(name)=> location = name"
            label="Ort"
          />
        </div>
      </div>
    </div>
    <div class="panel m-5">
      <PanelHeader panel-header="Login-Daten" />
      <div class="container pt-3 pb-3">
        <div class="columns">
          <b-field
            v-if="enableAdminRole || !onBoarding"
            label="Admin"
            class="column"
          >
            <b-switch
              v-model="enableAdminRole"
            >
              <div>{{ enableAdminRole? 'Admin' : 'Nutzer' }}</div>
            </b-switch>
          </b-field>
          <template v-if="userMetaClass !== undefined">
            <InputWithValidation
              message="Passwort ist nicht lang genug! (mind. 6 Zeichen)"
              type="password"
              class="column"
              :name="newPassword"
              :set-name="(name)=> newPassword = name"
              label="Neues Passwort"
            />
            <InputWithValidation
              message="Passwort stimmt nicht überein!"
              type="password"
              :password-validation="newPassword"
              class="column"
              :name="repeatNewPassword"
              :set-name="(name)=> repeatNewPassword = name"
              label="Neues Passwort wiederholen"
            />
          </template>
          <template v-else>
            <b-field
              class="column"
              :message="
                !$v.loginRequest.email.$invalid
                  ? ''
                  : 'Bitte gib eine valide Email ein!'
              "
              :type="
                !$v.loginRequest.email.$invalid
                  ? 'is-success'
                  : 'is-danger'
              "
            >
              <template #label>
                <div
                  class="align-content-center"
                  style="display: inline-flex"
                >
                  <p>Email</p>
                </div>
              </template>
              <b-input
                v-model="loginRequest.email"
                class="m-2 has-text-left"
                placeholder="Email"
                type="email"
                autocomplete="one-time-code"
              />
            </b-field>
            <InputWithValidation
              type="password"
              class="column"
              :message="onBoarding ? 'Gib den Masterkey ein!' : 'Bitte gib ein Passwort ein! (mind. 6 Zeichen)'"
              :is-new-password="true"
              :name="loginRequest.password"
              :set-name="(name)=> loginRequest.password = name"
              :label="onBoarding? 'Masterkey' : 'Passwort'"
            />
          </template>
        </div>
      </div>
    </div>
    <div
      class="buttons m-5"
      style="display: flex; justify-content: center"
    >
      <SaveButton
        :is-loading="isLoading"
        :disabled="disabledSave"
        :save-function="editUser"
      />
      <CancelButton
        v-if="!onBoarding"
        text="Abbrechen"
        back-route="/users"
        :is-loading="false"
      />
    </div>
    <b-modal v-model="isDublicateUser">
      <div v-if="isDublicateUser" class="panel m-5" style="background-color: white">
        <p class="panel-heading">
          Achtung!
        </p>
        <p class="mb-2 mt-2">
          Ein Nutzer mit der Email Adresse <b>{{ checkDublicateUserEmail }}</b>> existiert bereits.
        </p>
        <div class="buttons mt-2" style="display: flex; justify-content: center">
          <b-button class="is-primary" @click="closeModalDublicateUser">
            OK
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="isReactivateUser">
      <div v-if="isReactivateUser" class="panel m-5" style="background-color: white">
        <p class="panel-heading">
          Achtung!
        </p>
        <p class="mb-2 mt-2">
          Es existiert bereits ein Nutzer <b>{{ reactivatableUserClass.attributes.first_name }} {{ reactivatableUserClass.attributes.last_name }}</b> mit den Zugangsdaten <b>{{ reactivatableUserClass.attributes.email }}</b>.
        </p>
        <p class="mb-2 bold">
          <strong>Wollen Sie diesen Nutzer reaktivieren?</strong>
        </p>
        <p>
          <b>Hinweis:</b> Dadurch gehen alle derzeit eingegebenen Informationen verloren und es werden die bereits im System hinterlegten Informationen wiederhergestellt.
        </p>
        <div class="buttons mt-2" style="display: flex; justify-content: center">
          <b-button class="is-primary" :loading="isLoading" @click="submitReactivateUser()">
            Ja
          </b-button>
          <b-button class="is-danger" @click="closeModalReactivateUser">
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/**
 * This file allows the creation and editing of new users (also used for onboarding)
 */
import Parse from "parse/dist/parse.min.js";
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import email from "vuelidate/lib/validators/email";
import {v4 as uuid} from "uuid";
import {GENDERS} from "../../lib/constants/Constants";
import {getUserPicture, resizeImage} from "@/lib/Picture";
import {negativeFeedBack, positiveFeedBack} from "@/lib/Toast";
import {
	queryAdminRole, queryClients, queryGroups,
	queryStaffRole, queryStaffs, queryUserByUserMetaClass, queryUserMetaClassByEmail
} from "@/lib/Query";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import ImageUpload from "@/components/helperComponents/ImageUpload";
import ImageWithDelete from "@/components/helperComponents/ImageWithDelete";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import InputComponent from "@/components/helperComponents/InputComponent";
import SelectionComponent from "@/components/helperComponents/SelectionComponent";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import DatePicker from "@/components/helperComponents/DatePicker";
import {
	destroyCreatedSession,
	userAlreadyInAdminRole
} from "@/lib/User";

async function getModifiedImage(value) {
	return await resizeImage(value);
}

const savePictures = async (userMetaClass, dropFile , dropFileType)=>{
	if (dropFile !== undefined && dropFileType !== "string") {
		const imageFile = new Parse.File(uuid(),{ base64: dropFile },"image/jpeg");
		try {
			await imageFile.save(null, {useMasterKey: true});
		} catch (e) {
			console.error("couldnt save the profile picture " + e);
		}
		userMetaClass.set("profile_picture", imageFile);
	}
	if(dropFile === undefined && dropFileType === "undefined"){
		userMetaClass.unset("profile_picture")
	}
	await userMetaClass.save()
}
export default {
	name: "VUser",
	components: {
		SaveButton, CancelButton, SelectionComponent, ImageWithDelete, ImageUpload, PanelHeader, InputWithValidation, InputComponent,DatePicker},
	props:["onBoarding"],
	data() {
		return {
			loginRequest: {
				email: "",
				password: "",
			},
			dropFile: undefined,
			dropFileModified: undefined,
			firstName: "",
			familyName: "",
			isLoading: false,
			birthday: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
			func: "",
			groupId: "",
			genderArray: GENDERS,
			gender: "",
			phone1: "",
			phone2: "",
			location: "",
			street: "",
			plz: "",
			info1: undefined,
			info2: undefined,
			queriedUser: [],
			userGroups: [],
			enableAdminRole: false,
			chosenClients: [],
			groupListLocal: [],
			groupList :[],
			staffUsers: [],
			staffRole: undefined,
			adminRole: undefined,
			clientList: [],
			//for edit
			previousChosenClients: [],
			userMetaClass: undefined,
			isMounted: false,
			newPassword: undefined,
			repeatNewPassword: undefined,
			//dublicationCheck and Reactivation
			isDublicateUser: false,
			checkDublicateUserEmail: "",
			isReactivateUser: false,
			reactivatableUserClass: undefined
		};
	},
	validations: {
		loginRequest: {
			email: {
				required,
				email,
			},
			password: {
				required,
				minLength: minLength(6)
			},
		},
		firstName: {
			required,
			minLength: minLength(1),
		},
		familyName: {
			required,
			minLength: minLength(1),
		},
		newPassword: { minLength: minLength(6) },
		repeatNewPassword: {
			sameAsPassword: sameAs("newPassword"), minLength: minLength(6)
		},
	},
	computed: {
		disabledSave(){
			if(this.isNewUser() || this.onBoarding){

				return (this.$v.firstName.$invalid ||this.$v.familyName.$invalid ||
						this.$v.loginRequest.email.$invalid ||this.$v.loginRequest.password.$invalid)
			}

			return (this.$v.firstName.$invalid ||this.$v.familyName.$invalid || this.$v.newPassword.$invalid
					|| this.$v.repeatNewPassword.$invalid)

		},
		dropFileURL() {
			if(!this.userMetaClass && this.dropFile) return URL.createObjectURL(this.dropFile);
			if(!this.userMetaClass)return undefined;
			const pictureObject = {
				newProfilePicture: typeof this.dropFile === "string" ? undefined : this.dropFile,
				userPicture: typeof this.dropFile === "string" ? this.dropFile : undefined,
				userGender: this.userMetaClass.attributes.gender
			}
			return getUserPicture(pictureObject)

		},
	},
	watch: {
		dropFile: {
			handler: function (fileArray) {
				if (fileArray && fileArray.type) {
					this.dropFileModified = undefined;
					if (fileArray.type.match(/image.*/)) {
						getModifiedImage(fileArray)
							.then((response) => {
								this.dropFileModified = response;
							})
							.catch(function (error) {
								
							});
					}
				}
			},
			deep: true,
		},
	},
	async created() {
		if(this.onBoarding){
			return this.isMounted = true;
		}
		this.groupList = await queryGroups()
		this.groupListLocal = this.groupList
		this.staffUsers = await queryStaffs()
		this.staffRole = await queryStaffRole()
		this.adminRole = await queryAdminRole()
		this.clientList = await queryClients()
		await this.setExistentUser()
		this.isMounted = true
	},
	methods: {
		triggerDublicateUser(userMetaClass) {
			this.checkNewUserMetaClass = userMetaClass;
			this.isDublicateUser = true;
		},
		closeModalDublicateUser() {
			this.isDublicateUser = false;
		},
		triggerReactivateUser(userMetaClass) {
			this.reactivatableUserClass = userMetaClass;
			this.isReactivateUser = true;
		},
		closeModalReactivateUser() {
			this.isReactivateUser = false;
		},
		isNewUser(){
			return !this.userMetaClass
		},
		async editUser() {
			this.isLoading = true

			if(this.isNewUser()){
				const newUserParams = {
					email: this.loginRequest.email,first_name: this.firstName.trim(),
					family_name: this.familyName.trim(),birth_date: this.birthday,
					gender: this.gender,phone1: this.phone1.trim(),
					phone2: this.phone2.trim(),location: this.location.trim(),
					street: this.street.trim(),plz: this.plz,
					groupIds:this.userGroups.map((group) => {if(group.group) return group.group.id}),
					clientIds: this.chosenClients.map(client => client.id),password: this.loginRequest.password,
					type: "staff",
					isAdmin: this.enableAdminRole,func: this.func.trim(),
					info1: this.info1, info2: this.info2
				}
				if(this.onBoarding) {
					await this.createFirstAdmin(newUserParams)
					return location.reload()
				}

				let userObject = undefined;
				// Check if User already exists
				let other_userMetaClass = await queryUserMetaClassByEmail(newUserParams["email"])

				// User exisits in some way, not decided whether this user deleted or not
				if(other_userMetaClass != undefined) {

					if (other_userMetaClass.attributes.type == "staff")
					{
						// User exists and has been deleted
						if(other_userMetaClass.attributes.deleted){
							this.triggerReactivateUser(other_userMetaClass);
							this.isLoading = false
							return
						}
						// User exists, but has not been deleted
						else{
							this.checkDublicateUserEmail = other_userMetaClass.attributes.email;
							this.triggerDublicateUser(other_userMetaClass);
							this.isLoading = false
							return
						}
					}
					else {
						negativeFeedBack("Nutzer konnte nicht angelegt werden! Nutzer bereits bekannt.")
						this.isLoading = false
						return
					}
				}
				// this User is new completely
				else{
					userObject = await Parse.Cloud.run("createUser", newUserParams)
				}

				const sessionToken = Parse.User.current().getSessionToken()
				this.userMetaClass = userObject.userMeta
				await destroyCreatedSession(sessionToken, userObject.user)
			}
			if(!this.isNewUser()){
				const editUserParams = {
					userMetaClassId: this.userMetaClass.id, first_name: this.firstName.trim(),
					family_name: this.familyName.trim(),birth_date: this.birthday,gender: this.gender,
					phone1: this.phone1.trim(),phone2: this.phone2.trim(),location: this.location.trim(),
					street: this.street.trim(),plz: this.plz,
					groupIds:this.userGroups.map((group) => {if(group.group) return group.group.id}),
					clientIds: this.chosenClients.map(client => client.id),
					type: "staff", isAdmin: this.enableAdminRole, func: this.func.trim(),
					info1: this.info1, info2: this.info2
				}
				this.userMetaClass = await Parse.Cloud.run("editUser", editUserParams)
				await this.changePassword()
			}
			await savePictures(this.userMetaClass, this.dropFileModified, typeof this.dropFile)
			const allUsers = await queryStaffs()
			const user = allUsers.find((user) => user.attributes.dependency.id === this.userMetaClass.id);
			const admins = this.adminRole.getUsers();
			const queriedAdmins = await admins.query().find();

			await this.addAdminRoleToUser(admins, user, queriedAdmins)
			await this.removeAdminRoleToUser(admins, user, queriedAdmins)

			positiveFeedBack("Mitarbeiter gespeichert!")
			await this.$router.push({ path: "/users" });
		},
		async addAdminRoleToUser(admins, user, queriedAdmins){
			if (this.enableAdminRole && !userAlreadyInAdminRole(user, queriedAdmins)) {//set as admin if enabled and isnt already in this role
				admins.add(user);
				await this.adminRole.save();
			}
		},
		async removeAdminRoleToUser(admins, user, queriedAdmins){
			if (!this.enableAdminRole && userAlreadyInAdminRole(user, queriedAdmins)) {// if admin disabled, but user in admin role -> delete from admin role
				admins.remove(user);
				await this.adminRole.save();
			}
		},
		async changePassword(){
			if(this.newPassword && this.repeatNewPassword &&
				this.newPassword.length>=6 && this.repeatNewPassword.length>=6 &&
				this.newPassword === this.repeatNewPassword){

				const currentUser = Parse.User.current();
				const editedUser = await queryUserByUserMetaClass(this.userMetaClass);

				// Change password for myself
				if(currentUser.id === editedUser.id) {
					await currentUser.setPassword(this.newPassword);
					await currentUser.save();
				}
				// Change password for other user
				else {
					await Parse.Cloud.run("changeUserPassword", {userId: editedUser.id, password: this.newPassword});
				}
			}
		},
		async createFirstAdmin(params){
			const userClass = await Parse.Cloud.run("createFirstAdmin", params)
			if(!userClass) {
				negativeFeedBack("Falscher Masterkey!")
				this.isLoading = false
				return;
			}
			await Parse.User.logIn(
				this.loginRequest.email,
				this.loginRequest.password
			)

			await this.$store.dispatch("setSavedUser", userClass)
			await this.$router.push({ path: "/" })
			this.isLoading = false
			return undefined;
		},
		deleteDropFile() {
			this.dropFile = undefined;
			this.dropFileModified = undefined;
		},
		addGroup(group){
			if(!group) return
			this.userGroups.push({
				group: group,
				index: this.userGroups.length,
			});
			this.groupListLocal = this.groupListLocal.filter(g => g.id !== group.id)
		},
		spliceGroup(userGroup) {
			this.userGroups = this.userGroups.filter(group => group.group.id !== userGroup.group.id)
			const userGroupIds = this.userGroups.map(userGroup => userGroup.group.id)
			this.chosenClients = this.chosenClients.filter(client => userGroupIds.includes(client.attributes.group.id ))
			this.groupListLocal = this.groupList.filter(group => !userGroupIds.includes(group.id))
		},
		addClient(option){
			if(option) this.chosenClients.push(option)
		},
		deleteClient(index){
			this.chosenClients.splice(index, 1)
		},
		getFilteredClients(){
			const userGroups = this.userGroups.map(group => group.group)
			const userGroupNames = userGroups.map(group => group? group.attributes.group_name : "")
			return this.clientList.filter(client => {
				const isClientGroupInUserGroup = userGroupNames.includes(client.attributes.group.attributes.group_name )
				const isClientSelected = this.chosenClients.includes(client)
				return isClientGroupInUserGroup && !isClientSelected
			})
		},
		async setExistentUser(){
			if(!this.$route.params.data) return;
			this.groupListLocal = this.groupList
			this.userMetaClass = this.$route.params.data;
			this.firstName = this.userMetaClass.attributes.first_name
				? this.userMetaClass.attributes.first_name
				: "";
			this.familyName = this.userMetaClass.attributes.last_name
				? this.userMetaClass.attributes.last_name
				: "";
			this.street = this.userMetaClass.attributes.street
				? this.userMetaClass.attributes.street
				: "";
			this.birthday = this.userMetaClass.attributes.birth_date
				? this.userMetaClass.attributes.birth_date
				: undefined;
			this.plz = this.userMetaClass.attributes.plz
				? this.userMetaClass.attributes.plz
				: "";
			this.location = this.userMetaClass.attributes.location
				? this.userMetaClass.attributes.location
				: "";
			this.gender = this.userMetaClass.attributes.gender
				? this.userMetaClass.attributes.gender
				: "";
			this.phone1 = this.userMetaClass.attributes.phone1
				? this.userMetaClass.attributes.phone1
				: "";
			this.phone2 = this.userMetaClass.attributes.phone2
				? this.userMetaClass.attributes.phone2
				: "";
			this.info1 = this.userMetaClass.attributes.info1
				? this.userMetaClass.attributes.info1
				: "";
			this.info2 = this.userMetaClass.attributes.info2
				? this.userMetaClass.attributes.info2
				: "";
			this.dropFile = this.userMetaClass.attributes.profile_picture
				? this.userMetaClass.attributes.profile_picture._url
				: undefined;
			const groups = this.userMetaClass.attributes.groups
				? this.userMetaClass.attributes.groups
				: [];
			this.func = this.userMetaClass.attributes.function
				? this.userMetaClass.attributes.function
				: "";
			this.enableAdminRole = this.userMetaClass.attributes.is_admin
				? this.userMetaClass.attributes.is_admin
				: false;

			for (const group of groups) {
				const selectedGroup = this.groupList.find(groupObject => groupObject.id === group.id)
				this.userGroups.push({
					index: this.userGroups.length,
					group: selectedGroup
				});
			}
			const userGroupIds = this.userGroups.map(userGroup => userGroup.group.id)
			this.groupListLocal = this.groupList.filter(group => !userGroupIds.includes(group.id))
			this.chosenClients = this.userMetaClass.attributes.clients ? this.userMetaClass.attributes.clients: []
			for(const client of this.chosenClients){
				this.previousChosenClients.push(client)
			}

			if(userGroupIds.length != 0)
			{
				this.chosenClients = this.chosenClients.filter(client => userGroupIds.includes(client.attributes.group.id ))
			}else
			{
				this.chosenClients = [];
			}
			this.isMounted = true
		},
		async submitReactivateUser() {
			this.isLoading = true

			this.isMounted = false

			this.reactivatableUserClass.set("deleted", false);
			this.reactivatableUserClass
					.save()
					.then(async () => {
						this.userMetaClass = this.reactivatableUserClass;

						// Nutzerdaten laden??
						this.isReactivateUser = false
						this.$route.params.data = this.reactivatableUserClass
						this.groupList = await queryGroups()
						this.groupListLocal = this.groupList
						this.staffUsers = await queryStaffs()
						this.staffRole = await queryStaffRole()
						this.adminRole = await queryAdminRole()
						this.clientList = await queryClients()
						await this.setExistentUser()
						this.isMounted = true;
						positiveFeedBack("Mitarbeiter wiederhergestellt!")
					}).catch((e) => {
						// TODO: Hier muss mehr passieren!
						this.reactivatableUserClass.set("deleted", true);
						this.reactivatableUserClass.save()
						negativeFeedBack("Mitarbeiter konnte nicht wiederhergestellt werden!")
						console.error(e);
					});
			this.isLoading = false
		},
	}
};
</script>

<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
