<template>
	<div>
		<AnwesenheitsTable />
		<AbwesenheitsTable />
	</div>
</template>

<script>

import AbwesenheitsTable from "@/components/anwesenheit/abwesenheitsgrund/AbwesenheitsTable";
import AnwesenheitsTable from "@/components/anwesenheit/anwesenheitsgrund/AnwesenheitsTable";

export default {
	name: "AnAbWesenheitsgrund",
	components: { AnwesenheitsTable, AbwesenheitsTable },
	data() {
		return {};
	},
	computed: {},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped></style>
