<template>
  <div>
    <ClientRecordsTable />
    <ClientRecordsUpload />
    <PdfPaginator />
    <!--		<DocumentCreator/>-->
  </div>
</template>

<script>
/**
 * This file shows the used categories in client records, which store all pdfs for clients
 */
import ClientRecordsUpload from "@/components/clientRecords/upload/ClientRecordsUpload";
import PdfPaginator from "@/components/clientRecords/edit/PdfPaginator";
import ClientRecordsTable from "@/components/clientRecords/home/ClientRecorsTable";

export default {
	name: "ClientRecords",
	components:{
		ClientRecordsTable,
		PdfPaginator,
		ClientRecordsUpload,
		// DocumentCreator,
	},
}
</script>

<style scoped>

</style>
