import XLSX from "xlsx/dist/xlsx.full.min";
import {v4 as uuid} from "uuid"

function convertNumber(n) {
	if(!isNaN(n)) return n
	n = n.replace(/\./g, '').replace(',', '.');
	if(isNaN(+n)) throw new Error("invalid number")
	return +n
}

export default class StockUpImport{
	constructor(workbook) {
		this.workbook = workbook
	}

	getBudgetSheet(){
		return XLSX.utils.sheet_to_json(this.workbook.Sheets["Budgets"]);
	}

	getColumns(){
		let columns = []
		for (const row of this.getBudgetSheet()) {
			columns = Object.keys(row)
			break
		}
		return columns.map(c =>({
			id: uuid(),
			field: c,
			label: c,
			value: undefined
		}))
	}


	async getData() {
		const sheet = this.getBudgetSheet()
		const tableData = []
		for (const row of sheet) {
			const rowData ={}
			let clientName = ""
			let skippedFirstColumn = false
			for(const column in row){
				if(!skippedFirstColumn){
					clientName = row[column]
					rowData[column] = clientName
					skippedFirstColumn = true
					continue
				}
				let budget = convertNumber(row[column])
				rowData[column] = budget
			}
			rowData.processedEntities = []
			rowData.entities = []
			rowData.entity =undefined
			rowData.label = clientName
			tableData.push(rowData)
		}
		return tableData
	}

}
