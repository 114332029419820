<template>
  <div class="panel m-5">
    <PanelHeader :panel-header="district ? district.attributes.name : 'Landkreis'" />
    <div class="container pt-3 pb-3">
      <div class="columns">
        <b-field
          v-if="district"
          class="column"
          label="Name"
        >
          <p>{{ district.attributes.name }}</p>
        </b-field>
        <b-field
          v-if="district"
          class="column"
          label="Email"
        >
          <p>{{ district.attributes.email }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
        <b-field
          v-if="district"
          class="column"
          label="Telefon 1"
        >
          <p>{{ district.attributes.phone1 }}</p>
        </b-field>
        <b-field
          v-if="district"
          class="column"
          label="Info 1"
        >
          <p>{{ district.attributes.info1 }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
        <b-field
          v-if="district"
          class="column"
          label="Telefon 2"
        >
          <p>{{ district.attributes.phone2 }}</p>
        </b-field>
        <b-field
          v-if="district"
          class="column"
          label="Info 2"
        >
          <p>{{ district.attributes.info2 }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
		<b-field
          v-if="district"
          class="column"
          label="Straße"
        >
          <p>{{ district.attributes.street }}</p>
        </b-field>
        <b-field
          v-if="district"
          class="column"
          label="PLZ"
        >
          <p>{{ district.attributes.plz }}</p>
        </b-field>
        <b-field
          v-if="district"
          class="column"
          label="Ort"
        >
          <p>{{ district.attributes.location }}</p>
        </b-field>
      </div>
      <hr>
      <CancelButton
        text="Zurück"
        back-route="/districts"
      />
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/helperComponents/PanelHeader";
import CancelButton from "@/components/helperComponents/CancelButton";
export default {
	name: "ViewDistrict",
	components: {CancelButton, PanelHeader},
	data(){
		return{
			district: undefined
		}
	},
	mounted() {
		if (!this.$route.params.data) return (this.isMounted = true);
		this.district = this.$route.params.data;
	}
}
</script>

<style scoped>
p{
	text-align: center;
}
</style>