<template>
	<div>
		<div class="panel m-5">
			<PanelHeader :panel-header="panelHeader" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<InputWithValidation class="column" message="Bitte geben Sie einen Bezeichner ein!" :name="icd10CodeName"
						:set-name="(name) => (icd10CodeName = name)" label="Bezeichner" />
					<InputWithValidation class="column" message="Bitte geben Sie den ICD-10-Code ein!" :name="icd10Code"
						:set-name="(name) => (icd10Code = name)" label="ICD-10-Code" />
				</div>
			</div>
		</div>
		<div class="m-5 buttons" style="display: flex; justify-content: center">
			<SaveButton :is-loading="isLoading" :save-function="saveDataObject"
				:disabled="$v.icd10CodeName.$invalid || $v.icd10Code.$invalid" />
			<CancelButton text="Abbrechen" back-route="/icd_10" :is-loading="false" />
		</div>
	</div>
</template>
  
<script>
/**
 * This file is for creating and editing medication forms.
 */
import Parse from "parse/dist/parse.min.js";
import { minLength, required } from "vuelidate/lib/validators";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";

export default {
	name: "NewICD10",
	components: {
		SaveButton,
		CancelButton,
		InputWithValidation,
		PanelHeader,
	},
	data() {
		return {
			dataObject: undefined,
			isLoading: false,
			icd10CodeName: "",
			icd10Code: "",
			panelHeader: "Neuen ICD-10-Code anlegen"
		};
	},
	computed: {},
	watch: {},
	mounted() {
		if (this.$route.params.data) {
			this.dataObject = this.$route.params.data
			this.icd10CodeName = this.dataObject.attributes.name
			this.icd10Code = this.dataObject.attributes.icd_10_code
			this.panelHeader = "ICD-10-Code bearbeiten"
		}
	},
	validations: {
		icd10CodeName: {
			required,
			minLength: minLength(1),
		},
		icd10Code: {
			required,
			minLength: minLength(1),
		},
	},
	methods: {
		async saveDataObject() {
			this.isLoading = true;
			const parseObject = Parse.Object.extend("MedicationICD10Code")
			const dataObject = this.dataObject ? this.dataObject : new parseObject()
			dataObject.set("name", this.icd10CodeName)
			dataObject.set("icd_10_code", this.icd10Code)
			dataObject.set("deleted", false)
			await dataObject.save().catch((e) => {
				negativeFeedBack("ICD-10-Code nicht gespeichert!")
			});
			this.isLoading = false;
			await this.$router.push({ path: "/icd_10" })
			await positiveFeedBack("ICD-10-Code gespeichert!")
		},
	},
};
</script>
  
<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
  