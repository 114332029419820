<template>
  <div class="panel m-5">
    <PanelHeader :panel-header="socialWorker ? getName(socialWorker) : 'Sozialarbeiter'" />
    <div class="container pt-3 pb-3">
      <div class="columns">
        <b-field
          v-if="socialWorker"
          class="column"
          label="Vorname"
        >
          <p>{{ socialWorker.attributes.first_name }}</p>
        </b-field>
        <b-field
          v-if="socialWorker"
          class="column"
          label="Nachname"
        >
          <p>{{ socialWorker.attributes.last_name }}</p>
        </b-field>
        <b-field
          v-if="socialWorker"
          class="column"
          label="Email"
        >
          <p>{{ socialWorker.attributes.email }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
        <b-field
          v-if="socialWorker"
          class="column"
          label="Telefon 1"
        >
          <p>{{ socialWorker.attributes.phone1 }}</p>
        </b-field>
        <b-field
          v-if="socialWorker"
          class="column"
          label="Info 1"
        >
          <p>{{ socialWorker.attributes.info1 }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
        <b-field
          v-if="socialWorker"
          class="column"
          label="Telefon 2"
        >
          <p>{{ socialWorker.attributes.phone2 }}</p>
        </b-field>
        <b-field
          v-if="socialWorker"
          class="column"
          label="Info 2"
        >
          <p>{{ socialWorker.attributes.info2 }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
		<b-field
          v-if="socialWorker"
          class="column"
          label="Straße"
        >
          <p>{{ socialWorker.attributes.street }}</p>
        </b-field>
        <b-field
          v-if="socialWorker"
          class="column"
          label="PLZ"
        >
          <p>{{ socialWorker.attributes.plz }}</p>
        </b-field>
        <b-field
          v-if="socialWorker"
          class="column"
          label="Ort"
        >
          <p>{{ socialWorker.attributes.location }}</p>
        </b-field>
      </div>
      <hr>
      <CancelButton
        text="Zurück"
        back-route="/socialWorker"
      />
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/helperComponents/PanelHeader";
import CancelButton from "@/components/helperComponents/CancelButton";
export default {
	name: "ViewSocialWorker",
	components: {CancelButton, PanelHeader},
	data(){
		return{
			socialWorker: undefined
		}
	},
	async mounted() {
		if (!this.$route.params.data) return await this.$router.push({path: "/socialWorker"})
		this.socialWorker = this.$route.params.data;
	},
	methods:{
		getName(socialWorker){
			return `${socialWorker.attributes.first_name} ${socialWorker.attributes.last_name}`
		}
	}
}
</script>

<style scoped>
p{
	text-align: center;
}
</style>
