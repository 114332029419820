<template>
  <b-field :label="label">
    <b-switch
      :key="isActivated? 'active' : 'notA'"
      v-model="isActive"
      @input="set()"
    >
      {{ text }}
    </b-switch>
  </b-field>
</template>

<script>
/**
 * This file provides a Switcher for turning something on or off with description
 * @param: label:String with description, isActivated: boolean indicating whether switch is activated,
 * switchActivation: function to switch the boolean, text:String next to the switcher
 */
export default {
	name: "SwitcherComponent",
	props:["label", "isActivated", "switchActivation", "text"],
	data(){
		return {
			isActive: this.isActivated
		}
	},
	methods:{
		set(){
			this.switchActivation()
		}
	}
}
</script>

<style scoped>

</style>
