<template>
	<div>
		<InstantMedicationTable />
	</div>
</template>
  
<script>
/**
 * This file shows all currently stored instant medications and allows adding, editing, viewing and soft deleting these.
 */
import InstantMedicationTable from "@/components/medication/instant_medication/InstantMedicationTable";
export default {
	name: "InstantMedication",
	components: { InstantMedicationTable },
	data() {
		return {};
	},
	computed: {},
	mounted() { },
	methods: {},
};
</script>
  
<style lang="scss" scoped></style>
  