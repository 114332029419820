<template>
  <div class="borderContainer">
    <b-field label="Gruppe">
      <b-autocomplete
        class="m-2"
        :data="groupsArray"
        placeholder="Gruppe"
        icon="magnify"
        @input="e => groupFilter = e"
        @select="option => selectGroup(option)"
      >
        <template #empty>
          Keine Gruppen gefunden
        </template>
        <template slot-scope="props">
          <div class="content">
            {{ props.option.attributes.group_name }}
          </div>
        </template>
      </b-autocomplete>
    </b-field>
    <div
      v-if="chosenGroup"
      class="m-2 pl-4"
      style="display: flex"
    >
      <div
        class="pr-2"
        @click="() => selectGroup(undefined)"
      >
        <b-icon
          icon="delete"
          style="cursor: pointer"
          type=""
        />
      </div>
      <p>{{ chosenGroup.attributes.group_name }}</p>
    </div>
  </div>
</template>

<script>
import { queryGroups, queryClients } from "@/lib/Query";
import { mapGetters } from "vuex";


export default {
	name: "GroupSelection",
	props: ["chosenGroup", "chosenClients", "chosenUsers"],
	data() {
		return {
			groupsArrayDefault: [],
			groupFilter: "",
			clients: [],
		}
	},
	computed: {
		...mapGetters({
			users: "getStaffUsers"
		}),
		groupsArray() {
			return this.groupsArrayDefault.filter(group => {
				const name = group.attributes.group_name.toLowerCase()
				const isFilterInName = this.groupFilter && name.includes(this.groupFilter.toLowerCase())
				if (!this.chosenGroup) return isFilterInName
				return isFilterInName && this.chosenGroup.id !== group.id
			})
		}
	},
	async mounted() {
		this.groupsArrayDefault = await queryGroups()
		this.clients = await queryClients()
	},
	methods: {
		selectGroup(option) {
			if (option != undefined)
			{
				this.$emit("setGroup", option)
				const clientIds = this.chosenClients.map(c => c.id)
				const userIds = this.chosenUsers.map(u => u.id)
				const clientsInGroup = this.clients.filter(c => c.attributes.group.id === option.id && !clientIds.includes(c.id))
				const usersInGroup = this.users.filter(u => {
					if (!u.attributes.dependency.attributes.groups) return false
					const userGroupIds = u.attributes.dependency.attributes.groups.map(g => g.id)
					return userGroupIds.includes(option.id) && !userIds.includes(u.id)
				})
				this.$emit("setClients", clientsInGroup)
				this.$emit("setUsers", usersInGroup)
			}
			else {
				this.$emit("setGroup", option)
				this.$emit("setClients", [])
				this.$emit("setUsers", [])
			}
		}
	}
}
</script>

<style scoped>
.borderContainer {
	border-radius: .5rem;
	padding: .5rem;
	margin: .5rem;
}
</style>
