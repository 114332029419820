<template>
	<div>
		<div>
			<SearchableTable :panel-header="'ICD-10-Codes'" :panel-route="''" :show-search-bar="true"
				:table-data="icd_10_codes" :table-meta-data="tableMetaData" :query-string="'setICD10CodeUnitSearchQuery'"
				:event-value="parentEventValue">
			</SearchableTable>
		</div>
	</div>
</template>
  
<script>
/**
 * This file shows all currently stored icd 10 codes and allows adding, editing, viewing and soft deleting these.
 */
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import { mapGetters } from "vuex";
import SearchableTable from "@/components/helperComponents/SearchableTable.vue";

export default {
	name: "ICD10Table",
	components: { SearchableTable },
	data() {
		return {
			parentEventValue: undefined,
			isDeleteCheck: false,
			chosenElement: {},
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "ICD-10-Code", "field": "icd_10_code", "sortField": "attributes.icd_10_code" },
				{ "id": 1, "type": "string", "label": "Bezeichner", "field": "name", "sortField": "attributes.name" },
			]
		};
	},
	computed: {
		...mapGetters({
			icd_10_codes: "get_icd_10_code"
		})
	},
	mounted() { },
	methods: {
		triggerView(element) {
			//this.$router.push({ name: "ViewICD10", params: { data: element } });
		}, /*
		triggerEdit(element) {
			this.$router.push({ name: "AddICD10", params: { data: element } });
		}, 
		triggerDelete(element) {
			this.chosenElement = element;
			this.isDeleteCheck = true;
		},
		closeModal() {
			this.isDeleteCheck = false;
		}, 
		async submitDelete() {
			this.isLoading = true
			this.chosenElement.set("deleted", true);
			this.chosenElement.save().catch((e) => {
				negativeFeedBack(this.chosenElement.attributes.name + " nicht gelöscht!")
			});
			await positiveFeedBack(this.chosenElement.attributes.name + " gelöscht!")
			this.isLoading = false
			this.isDeleteCheck = false
		} */
	},
};

</script>
  
<style scoped></style>
  