<template>
	<b-field :label="label" :message="(hasValidation && !$v.file.$model ? message : '' || $v.file.$model ? file.name : '')"
		:type="hasValidation && !$v.file.$model ? 'is-success' : 'is-danger'">
		<b-upload v-model="file" style="display: flex; justify-content: center; cursor:pointer" :accept="accept"
			@input="e => inputPdf(e)">
			<div class="content" @click="setLoadingTrue">
				<p>
					<b-icon class="uploadIcon" icon="upload" size="is-large" />
				</p>
			</div>
		</b-upload>
	</b-field>
</template>

<script>
/**
 * This file is for uploading a file with validation
 * @param: data: File object uploaded, isLoading: boolean indicating loading process,
 * accept: String which files should be accepted, setLoading: function for setting loading true or false,
 * setPdf: function for setting the file, label: String with description, message: String for indicating an inappropriate or missing input
 */
import { required } from "vuelidate/lib/validators";
export default {
	name: "SingleUpload",
	props: [
		"data",
		"isLoading",
		"accept",
		"setLoading",
		"setPdf",
		"label",
		"message",
		"object",
		"hasValidation"
	],
	data() {
		return {
			file: this.data
		}
	},
	mounted() {},
	methods: {
		setLoadingTrue() {
			this.setLoading(true)
		},
		inputPdf(event) {
			if (this.object) {
				this.setPdf(this.file, this.object)
			} else {
				this.setPdf(this.file)
			}
			this.setLoading(false)
		},
	},
	validations: {
		file: {
			required
		}
	}
}
</script>

<style scoped></style>
