<template>
  <div class="panel m-5">
    <PanelHeader :panel-header="isEdit ? documentName : 'Upload'" />
    <div class="p-2">
      <div class="columns">
        <InputWithValidation
          class="column"
          label="Bezeichnung"
          :name="documentName"
          :set-name="(val) => documentName = val"
          message="Btte gib eine Dokumentenbezeichnung ein!"
        />
        <ValidatedSelection
          :key="isEdit ? 'CatEdit' : 'CatNew'"
          class="column"
          label="Kategorie"
          :options="categories"
          :selected-option="category"
          :select="data => category = data"
          message="Bitte füge eine Kategorie hinzu!"
          :has-validation="true"
          attribute-name="name"
        />
        <SelectionComponent
          :key="isEdit ? 'TypeEdit' : 'TypeNew'"
          class="column"
          label="Posteingang/Postausgang"
          :options="['Eingang', 'Ausgang', 'Intern', 'Extern', 'Posteingang', 'Postausgang']"
          :selected-option="documentInputType"
          :select="data => documentInputType = data"
        />
      </div>
      <div class="columns p-2">
        <DatePicker
          :key="documentDate.toLocaleDateString()"
          class="column"
          label="Datum"
          :selected-date="documentDate"
          :select="date => documentDate = date"
        />
        <InputWithValidation
          class="column"
          label="Ersteller"
          message="Bitte einen Ersteller hinzufügen!"
          :set-name="val => creator = val"
          type="text"
          :name="creator"
        />
        <div
          v-if="isEdit"
          class="column"
        >
          <b-field
            label="Ausgewähltes Kind"
            class=""
          >
            <div class="p-2">
              {{ chosenClient.attributes.first_name }}
              {{ chosenClient.attributes.last_name }}
            </div>
          </b-field>
        </div>
        <div
          v-if="!isEdit"
          class="column"
        >
          <AutoSelection
            label="Kinder"
            :data="filteredClients"
            :select="addClient"
            empty-text="Keine Kinder"
            attribute-name="first_name"
            second-attribute-name="last_name"
            :input="(data) => filter = data"
            :message="chosenClients.length === 0 ? 'Füge einen Klienten hinzu' : undefined"
            :show-group-name="true"
          />
          <div
            v-for="(innerChosenClient) in chosenClients"
            :key="innerChosenClient.id"
            class="m-2"
            style="display: flex; align-items: center"
          >
            <IconButton
              icon="delete"
              :trigger-function="() => deleteClient(innerChosenClient.id)"
              size="is-small"
              class="mr-1"
            />
            <p>{{ innerChosenClient.attributes.first_name }} {{ innerChosenClient.attributes.last_name }}</p>
          </div>
        </div>
      </div>
      <div class="columns">
        <FileDelete
          v-if="pdf !== undefined"
          class="column m-2"
          :text="pdf.name"
          :trigger-delete="() => pdf = undefined"
        />
        <SingleUpload
          v-else
          :key="pdf ? pdf.name : ''"
          class="column m-2"
          :data="pdf"
          :is-loading="isLoading"
          accept=".pdf"
          :set-loading="(value) => isLoading = value"
          :set-pdf="addPdf"
          label="PDF"
          message="Bitte lade eine PDF-Datei hoch!"
        />
      </div>
      <section
        class="m-5 buttons"
        style="display: flex; justify-content: center"
      >
        <SaveButton
          :is-loading="isLoading"
          :save-function="save"
          :disabled="$v.documentName.$invalid || $v.creator.$invalid ||
            $v.pdf.$invalid || $v.category.$invalid || clientValide"
        />
        <CancelButton
          v-if="isEdit"
          :back-route="'/client_records'"
          text="Zurück"
        />
      </section>
    </div>
  </div>
</template>

<script>
import PanelHeader from "../../helperComponents/PanelHeader.vue";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import AutoSelection from "@/components/helperComponents/AutoSelection";
import IconButton from "@/components/helperComponents/IconButton";
import FileDelete from "@/components/clientRecords/recordHelperComponents/FileDelete";
import SingleUpload from "@/components/clientRecords/recordHelperComponents/SingleUpload";
import SaveButton from "@/components/helperComponents/SaveButton";
import SelectionComponent from "@/components/helperComponents/SelectionComponent";
import { required } from "vuelidate/lib/validators";
import { queryClientFilesByIds, queryClientRecordCategories, queryClients } from "@/lib/Query";
import { v4 as uuid } from "uuid";
import { cloneObject } from "@/lib/ParseObjects";
import { positiveFeedBack } from "@/lib/Toast";
import Parse from "parse/dist/parse.min"
import ValidatedSelection from "@/components/helperComponents/ValidatedSelection";
import DatePicker from "@/components/helperComponents/DatePicker";
import CancelButton from "@/components/helperComponents/CancelButton";
export default {
	name: "ClientRecordsUpload",
	components: {
		CancelButton,
		DatePicker,
		ValidatedSelection,
		SelectionComponent,
		SaveButton,
		SingleUpload,
		FileDelete,
		IconButton,
		AutoSelection,
		InputWithValidation,
		PanelHeader
	},
	data() {
		return {
			pdf: undefined,
			isLoading: false,
			documentName: undefined,
			documentDate: new Date(),
			documentInputType: "Eingang",
			creator: "",
			clients: [],
			chosenClients: [],
			filter: "",
			category: undefined,
			newClientFile: undefined,
			focusedClientFile: undefined,
			focusedClientFiles: [],
			categories: [],
			isEdit: false,
			chosenClient: undefined,
			clientValide: false
		}
	},
	validations: {
		documentName: {
			required
		},
		documentInputType: {
			required
		},
		documentDate: {
			required
		},
		creator: {
			required
		},
		pdf: {
			required
		},
		category: {
			required
		},
		chosenClients: {
			required
		},
		chosenClient: {
			required
		}
	},
	computed: {
		filteredClients() {
			if (this.clients.length === 0) return []
			return this.clients.filter(client => {
				const chosenIds = this.chosenClients.map(c => c.id)
				const name = `${client.attributes.first_name} ${client.attributes.last_name}`.toLowerCase()
				const filter = this.filter ? this.filter.toLowerCase() : ""
				return !chosenIds.includes(client.id) && name.includes(filter) && (client.attributes.inactive === undefined || !client.attributes.inactive)
			})
		}
	},
	watch: {
		chosenClient() {
			this.compute_client_validate();
		},
		chosenClients() {
			this.compute_client_validate();
		}
	},
	async created() {
		
		this.clients = await queryClients()
		this.categories = await queryClientRecordCategories()
		this.reset()

		if (this.$route.params) {
			const pdfs = await queryClientFilesByIds([this.$route.params.id])
			if (pdfs.length !== 1) return
			const pdf = pdfs[0]
			this.newClientFile = pdf
			this.creator = pdf.attributes.creator
			this.documentName = pdf.attributes.title
			this.documentInputType = pdf.attributes.documentType
			this.documentDate = pdf.attributes.date
			this.category = pdf.attributes.category.toPointer()
			this.chosenClient = pdf.attributes.client
			this.isEdit = true
			this.pdf = pdf.attributes.pdf
			this.pdf.name = pdf.attributes.pdfTitle
			this.pdf.isParseFile = true
			
		}
	},
	methods: {
		compute_client_validate(){
			if (Object.keys(this.$route.params).length != 0 && !this.$v.chosenClient.$invalid) {

				this.clientValide = false;
			}else if (Object.keys(this.$route.params).length == 0 && !this.$v.chosenClients.$invalid) {
				this.clientValide = false;
			}else
			{
				this.clientValide = true;
			}
			
		},
		async save() {
			this.isLoading = true
			if (!this.pdf.isParseFile && this.newClientFile.attributes.pdf) {
				const pdfFile = this.newClientFile.attributes.pdf
				await pdfFile.save()
			}
			this.newClientFile.set("creator", this.creator)
			this.newClientFile.set("title", this.documentName)
			this.newClientFile.set("documentType", this.documentInputType)
			this.newClientFile.set("date", this.documentDate)
			this.newClientFile.set("category", this.category)
			this.newClientFile.set("readers", [])
			this.newClientFile.set("uuid", uuid())
			if (this.isEdit) {
				const savedFile = await this.newClientFile.save()

				const params = {
					id: savedFile.id
				}
				await Parse.Cloud.run("readPdf", params)
				await this.$router.push({ name: "ClientRecords" })
				positiveFeedBack("Datei gespeichert!")
				return
			}
			for (const client of this.chosenClients) {
				const clonedClientFile = cloneObject(this.newClientFile)
				clonedClientFile.set("client", client)
				const savedFile = await clonedClientFile.save()

				const params = {
					id: savedFile.id
				}
				await Parse.Cloud.run("readPdf", params)
				const notificationParams = {
					target: "contactEducator",
					isAuthorIncluded: false,
					parseObjectIds: {
						"ClientFile": [savedFile.id, "clientFile"],
						"Client": [client.id, "client"],
						"_User": [Parse.User.current().id, "author"]
					},
					notificationAttributes: {
						"message": "Neues Dokument!",
						type: "newDocument"
					},
					authorId: Parse.User.current().id,
					clientId: client.id
				}
				await Parse.Cloud.run("createNotifications", notificationParams)
			}

			this.reset()
			this.isLoading = false
			this.$root.$emit('updateTable')
			positiveFeedBack("Datei angelegt!")
		},
		reset() {
			this.creator = ""// undefined
			this.documentName = undefined
			// this.documentInputType = "Eingang"
			this.documentDate = new Date()
			this.chosenClients = []
			// this.category = undefined
			this.pdf = undefined
			const ClientFile = Parse.Object.extend("ClientFile")
			this.newClientFile = new ClientFile()
		},
		addPdf(pdf) {
			const pdfFile = new Parse.File(uuid(), pdf);
			this.newClientFile.set("pdfTitle", pdf.name);
			this.newClientFile.set("pdf", pdfFile);
			this.newClientFile.set("pdfLastModifiedDate", pdf.lastModifiedDate);
			this.newClientFile.set("pdfSize", pdf.size);
			this.pdf = pdf
		},
		addClient(client) {
			this.chosenClients.push(client)
		},
		deleteClient(id) {
			this.chosenClients = this.chosenClients.filter(client => client.id !== id)
		},
	}
}
</script>

<style scoped></style>
