import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../lib/Errors';
import { router } from '../../lib/router';

let localSubscription = undefined;

// AttributeConfig subscription
function createSubscription(store) {

    let attributeQuery = new Parse.Query('AttributeConfig');
    attributeQuery
        .notEqualTo("deleted", true)
        .equalTo("is_optional", true)
        .find()
        .then((results) => {
            store.dispatch('optional_attributeconfigs/set', results)
        })
        .catch((error) => {
            handleParseError(error);
            
        });
    attributeQuery.subscribe().then((subscription) => {
        localSubscription = subscription;

        subscription.on('create', object => {
            store.dispatch('optional_attributeconfigs/insert', object)
        });
        subscription.on('update', object => {
            store.dispatch('optional_attributeconfigs/replace', object)
        });
        subscription.on('enter', object => {
            store.dispatch('optional_attributeconfigs/insert', object)
        });
        subscription.on('leave', object => {
            store.dispatch('optional_attributeconfigs/delete', object)
        });
        subscription.on('delete', object => {
            store.dispatch('optional_attributeconfigs/delete', object)
        });
        subscription.on('close', () => {
        });
        subscription.on('error', error => {
            
        })
    })
}

export default function createOptionalAttributeConfigSubscription() {
	return store => {
		store.subscribe(mutation => {
			//1: Check - Wird die richtige Mutation ausgelöst? Diese wird im Router bei jedem Routing-Vorgang ausgelöst.
			if (mutation.type === 'ADD_LAST_ROUTE') {
				//2: Check - Bin ich aktuell bei den Widgets?
				if (router.currentRoute.name == "diary") {
					//3: Check - Prüfe, ob bereits eine lokale Subscription vorliegt.
					if (!localSubscription) {
						createSubscription(store)
					}
				}
			}
		})
	}
}
