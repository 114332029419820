<template>
  <div class="panel m-5">
    <PanelHeader :panel-header="'PDFs'" />
    <PdfFilter
      :end-date-prop="endDate"
      :search-term-prop="searchTerm"
      :start-date-prop="startDate"
      :selected-category-prop="category"
      :selected-client-prop="client"
      :creator="creator"
      @setSelectedClient="val => client = val"
      @setCreator="val => creator = val"
      @setStartDate="val => startDate = val"
      @setEndDate="val => endDate = val"
      @setSearchTerm="val => searchTerm = val"
      @setSelectedCategory="val => category = val"
    />
    <PdfTable
      v-if="renderComponent"
      :key="page"
      :edit-pdf="(pdf)=>editPdf(pdf)"
      :delete-pdf="(pdf)=>deletePdf(pdf)"
      :pdfs="pdfsOnPage"
    />
    <section class="is-flex is-justify-content-space-between p-3">
      <div class="has-text-weight-semibold">
        Seite: {{ page }}
      </div>
      <div class="is-flex is-justify-content-center ">
        <IconButton
          v-if="page !== 1"
          icon="arrow-left"
          :size="20"
          :trigger-function="goBack"
        />
        <IconButton
          v-if="pdfsOnPage.length === 10"
          icon="arrow-right"
          :size="20"
          :trigger-function="goAhead"
        />
      </div>
    </section>
    <ModalComponent
      v-if="isDeleteCheck"
      :is-loading="isLoading"
      :cancel="()=> isDeleteCheck = false"
      :item="fileDeleteText"
      :is-delete-check="isDeleteCheck"
      :submit="deleteFile"
    />
  </div>
</template>

<script>
import PdfTable from "@/components/clientRecords/PdfTable";
import {queryNextPdfs} from "@/lib/Query";
import IconButton from "@/components/helperComponents/IconButton";
import ModalComponent from "@/components/helperComponents/ModalComponent";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import PdfFilter from "@/components/clientRecords/edit/PdfFilter";

export default {
	components: {PdfFilter, PanelHeader, IconButton, PdfTable, ModalComponent},
	data(){
		return{
			pdfs: [],
			page: 1,
			pdfMap: new Map,
			pdfsOnPage: [],

			category: undefined,
			searchTerm: "",
			creator: "",
			endDate: new Date(),
			startDate: undefined,
			client: undefined,

			isDeleteCheck: false,
			isLoading: false,
			chosenPdf: undefined,
			fileDeleteText: "",
			renderComponent: true

		}
	},
	computed:{
		pdfIds(){
			return this.pdfs.map(pdf => pdf.id)
		},
		queryParams(){
			return {
				category: this.category,
				searchTerm: this.searchTerm,
				endDate: this.endDate,
				startDate: this.startDate,
				client: this.client,
				creator: this.creator
			}
		}
	},
	watch:{
		page: async function(newPage){
			if(this.pdfMap.has(newPage)){
				return this.pdfsOnPage = this.pdfMap.get(newPage)
			}
			const queriedPdfs = await queryNextPdfs(this.pdfIds,this.queryParams)
			this.pdfMap.set(newPage, queriedPdfs)
			this.pdfs = [...this.pdfs, ...queriedPdfs]
			this.pdfsOnPage = queriedPdfs
		},
		category: async function(){
			await this.update()
		},
		searchTerm: async function(){
			await this.update()
		},
		startDate: async function(){
			await this.update()
		},
		endDate: async function(){
			await this.update()
		},
		client: async function(){
			await this.update()
		},creator: async function(){
			await this.update()
		},
	},
	async created(){
		const queriedPdfs = await queryNextPdfs([], this.queryParams)
		this.pdfMap.set(this.page, queriedPdfs)
		this.pdfs = [...this.pdfs, ...queriedPdfs]
		this.pdfsOnPage = queriedPdfs
	},
	async mounted() {
		this.$root.$on('updateTable', () => {
			this.updateTable()
		})
    },
	beforeDestroy(){
		this.$root.$off('updateTable')
	},
	methods:{
		goBack(){
			this.page = this.page - 1
		},
		goAhead(){
			this.page = this.page + 1
		},
		async update(){
			this.page = 1
			this.pdfMap = new Map()
			this.pdfs = []
			const queriedPdfs = await queryNextPdfs([],this.queryParams)
			this.pdfMap.set(this.page, queriedPdfs)
			this.pdfs = [...this.pdfs, ...queriedPdfs]
			this.pdfsOnPage = queriedPdfs
		},
		editPdf(pdf){
			this.$router.push({name:"EditPdf", params: {id: pdf.id}})
		},
		deletePdf(pdf){
			this.isDeleteCheck = true
			this.chosenPdf = pdf
			this.fileDeleteText = `die PDF ${pdf.attributes.title}`
		},
		async updateTable(){
			this.renderComponent = false;
			await this.update()
			this.$nextTick(() => {
				this.renderComponent = true;
			});
		},
		async deleteFile(){
			this.renderComponent = false;
			this.chosenPdf.set("deleted", true)
			await this.chosenPdf.save()
			this.isDeleteCheck = false
			this.$nextTick(() => {
				this.renderComponent = true;
			});
		}
	}

}
</script>

<style scoped>

</style>
