<template>
  <div>
    <div
      v-if="attributeCharacteristics"
      class="panel m-5"
    >
      <div
        class="panel-heading"
        style="display: flex"
      >
        <p class="mr-2">
          {{ attributeName }}
        </p>
        <div
          class=""
          style="width:30px; height:30px; border-radius: 5px"
          :style="{'background-color':colors.hex, 'border':'1px solid black'}"
        />
      </div>
      <div class="container pt-3 pb-3">
        <b-table
          narrowed
          :data="attributeCharacteristics"
          striped
        >
          <b-table-column
            v-slot="props"
            :label="attribute.attributes.name"
            cell-class="has-text-left"
          >
            {{ props.row ? props.row.attributes.name : "" }}
          </b-table-column>
        </b-table>

        <section>
          <div
            class="buttons"
            style="display: flex; justify-content: center"
          >
            <b-button
              class="is-danger"
              @click="cancel()"
            >
              Zurück
            </b-button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * This file is for viewing a focused attribute with sub attributes
 */
import {
	queryAttributeCharacteristicsByConfigPointer,
} from "../../../lib/Query";

export default {
	name: "ViewAttribute",
	data() {
		return {
			attributeName: "",
			colors: {
				"hex": "#000000",
				"source": "hex"
			},
			isSingleSelection: true,
			attribute: undefined,
			attributeCharacteristics: undefined,
		}
	},
	async created() {
		const attribute = this.$route.params.data
		if(!attribute) return await this.$router.push("/diary")
		this.attributeName = attribute.attributes.name
		this.colors["hex"] = attribute.attributes.color
		this.isSingleSelection = attribute.attributes.isSingleSelection
		this.attribute = attribute
		this.attributeCharacteristics = await queryAttributeCharacteristicsByConfigPointer(this.attribute.toPointer())
	},
	methods: {
		async cancel(){
			await this.$router.push({path: "/diary"})
		},
	}
}
</script>

<style lang="scss" scoped>

</style>
