<template>
	<b-modal v-model="isActive" has-modal-card :can-cancel="false" @close="closeModal()">
		<div class="modal-card modalCardContainer" style="
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
					">
			<header class="modal-card-head" style="background-color: rgba(40, 40, 40, 1); color: white">
				Wichtiger Hinweis
			</header>
			<section class="modal-card-body" style="padding: 0.5em; color: black">
				<P>
					Es existiert mindestens ein Klient, der die von Ihnen angegebenen Daten (Vorname, Nachname,
					Geburtstag) bereits besitzt.
				</P>
				<P>
					Wenn es sich um ein ungewolltes Duplikat des Klienten handelt, können Sie den Vorgang mit
					"Abbrechen" beenden.
				</P>
				<P>
					Sollte es dich jedoch um ein gewolltes Duplikat handeln, können Sie den Vorgang mit "Speichern" fortsetzen.
				</P>
			</section>
			<footer class="modal-card-foot footerContainer" style="background-color: rgba(40, 40, 40, 1); color: white">
				<b-button type="is-primary" @click="abort">
					Abbrechen
				</b-button>
				<b-button type="is-primary" @click="save">
					Speichern
				</b-button>
			</footer>
		</div>
	</b-modal>
</template>

<script>

export default {
	name: "DuplicateModal",
	props: ["value"],
	data() {
		return {};
	},
	computed: {
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
	mounted() { },
	methods: {
		async abort() {
			this.isActive = false;
			//await this.$router.push("/clients")
		},
		async save() {
			this.$emit('save-duplicated-client')
			this.isActive = false;
		}
	}
}

</script>

<style scoped></style>
