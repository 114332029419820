<template>
  <div
    v-if="isMounted"
    class="panel m-5"
  >
    <PanelHeader :panel-header="group? group.attributes.group_name : 'Neue Gruppe'" />
    <div class="container pt-3 pb-3">
      <div class="bodyContainer">
        <div class="columns">
          <InputWithValidation
            class="column"
            message="Bitte gib einen Gruppenname ein!"
            :name="groupName"
            :set-name="(name)=> groupName = name"
            label="Gruppenname"
          />
          <InputComponent
            :key="isMounted ? 'emailU' : 'email'"
            class="column"
            :name="email"
            :set-name="(val) => (email = val)"
            label="Email"
          />
        </div>
        <div class="columns">
          <InputComponent
            :key="isMounted ? 'phone1u' : 'phone1'"
            class="column"
            :name="phone1"
            :set-name="(val) => (phone1 = val)"
            label="Telefon 1"
          />
          <InputComponent
            :key="isMounted ? 'info1u' : 'info1'"
            class="column"
            :name="info1"
            :set-name="(val) => (info1 = val)"
            label="Info 1"
          />
        </div>
        <div class="columns">
          <InputComponent
            :key="isMounted ? 'phone2u' : 'phone2'"
            class="column"
            :name="phone2"
            :set-name="(val) => (phone2 = val)"
            label="Telefon 2"
          />
          <InputComponent
            :key="isMounted ? 'info2u' : 'info2'"
            class="column"
            :name="info2"
            :set-name="(val) => (info2 = val)"
            label="Info 2"
          />
        </div>
        <div class="columns">
		<InputComponent
            :key="isMounted ? 'streetu' : 'street'"
            class="column"
            :name="street"
            :set-name="(val) => (street = val)"
            label="Straße"
          />
          <InputComponent
            :key="isMounted ? 'plzu' : 'plz'"
            class="column"
            :name="plz"
            :set-name="(val) => (plz = val)"
            label="PLZ"
          />
          <InputComponent
            :key="isMounted ? 'locationu' : 'location'"
            class="column"
            :name="location"
            :set-name="(val) => (location = val)"
            label="Ort"
          />
        </div>
        <div>
          <b-field label="Wer soll benachrichtigt werden?">
            <b-switch v-model="isGroup">
              {{ isGroup? 'Gruppe' : 'Kontakterzieher' }}
            </b-switch>
          </b-field>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Suche Klienten">
              <b-autocomplete
                v-model="client"
                class="m-2"
                :data="clientsArray"
                placeholder="Klient"
                icon="magnify"
                @input="e => clientFilter = e"
                @select="option => chosenClients.push(option)"
              >
                <template #empty>
                  Keine Klienten gefunden
                </template>
                <template slot-scope="props">
                  <div class="content">
                    {{ props.option.attributes.first_name }} {{ props.option.attributes.last_name }} ({{ props.option.attributes.group.attributes.group_name }})
                  </div>
                </template>
              </b-autocomplete>
            </b-field>
            <div
              v-for="(innerClient, index) in chosenClients"
              :key="index"
              class="m-2 pl-4"
              style="display: flex"
            >
              <p>{{ innerClient.attributes.first_name }} {{ innerClient.attributes.last_name }} ({{ innerClient.attributes.group.attributes.group_name }})</p>
            </div>
          </div>

          <div class="column">
            <AutoSelection
              label="Suche Mitarbeiter"
              attribute-name="first_name"
              second-attribute-name="last_name"
              empty-text="Keine Mitarbeiter gefunden"
              :data="usersArray"
              :select="val => chosenUsers.push(val)"
              :input="val =>filter= val"
            />
            <div
              v-for="(innerUser, index) in chosenUsers"
              :key="index"
              class="m-2"
              style="display: flex"
            >
              <b-button
                class="is-small mr-2"
                @click="deleteUser(index)"
              >
                <b-icon
                  icon="delete"
                  style="cursor: pointer"
                />
              </b-button>
              <p>{{ innerUser.attributes.first_name }} {{ innerUser.attributes.last_name }}</p>
            </div>
          </div>
        </div>

        <div
          class="buttons m-5"
          style="display: flex; justify-content: center"
        >
          <SaveButton
            :disabled="$v.groupName.$invalid"
            :save-function="handleEditGroup"
            :is-loading="isLoading"
          />
          <CancelButton
            text="Abbrechen"
            back-route="/groups"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * This file is for creating and editing a group
 */
import Parse from "parse/dist/parse.min.js"
import {queryClients, queryUserDetails, queryUserMetaClasses} from "../../lib/Query";
import {minLength, required} from "vuelidate/lib/validators";
import {negativeFeedBack, positiveFeedBack} from "@/lib/Toast";
import {checkContactClients, checkContactEducator} from "@/lib/ContactClient";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import InputComponent from "@/components/helperComponents/InputComponent";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import AutoSelection from "@/components/helperComponents/AutoSelection";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
export default {
	name: "NewGroup",
	components:{SaveButton, CancelButton, AutoSelection,  PanelHeader, InputWithValidation, InputComponent},
	data() {
		return {
			groupName: "",
			client: "",
			user: "",
			clientsArrayDefault: [],
			chosenClients: [],
			chosenUsers: [],
			isLoading: false,
			staffUsers: [],
			staffMetas: [],
			filter: "",
			clientFilter: "",
			//for edit
			group: undefined,
			oldUsers:[],
			isMounted: false,
			isGroup: true,
			email: undefined,
			plz: undefined,
			street: undefined,
			phone1: undefined,
			info1: undefined,
			phone2: undefined,
			info2: undefined,
			location: undefined,
		}
	},
	validations: {
		groupName: {
			required,
			minLength: minLength(1)
		},

	},
	computed:{
		usersArray(){
			return this.staffMetas.filter(user => {
				const name = `${user.attributes.first_name} ${user.attributes.last_name}`.toLowerCase()
				const isFilterInName = this.filter && name.includes(this.filter.toLowerCase())
				const userIds = this.chosenUsers.map(u => u.id)
				return isFilterInName && !userIds.includes(user.id)
			})
		},
		clientsArray(){
			return this.clientsArrayDefault.filter(client => {
				const name = `${client.attributes.first_name} ${client.attributes.last_name}`.toLowerCase()
				const isFilterInName = this.clientFilter && name.includes(this.clientFilter.toLowerCase())
				const clientIds = this.chosenClients.map(c => c.id)
				return isFilterInName && !clientIds.includes(client.id)
			})
		}
	},
	async created() {
		this.clientsArrayDefault = await queryClients()
		this.staffMetas = await queryUserMetaClasses()
		await this.setExistingData()
	},
	methods: {
		isNewGroup(){
			return this.group === undefined
		},
		async handleEditGroup() {
			this.isLoading = true
			const Group = Parse.Object.extend("Group")
			const groupObject = this.isNewGroup()? new Group() : this.group
			groupObject.set("group_name", this.groupName)
			groupObject.set("target", this.isGroup ? "group" : "contactEducator")

			if(!this.isNewGroup()){
				await this.deleteGroupPointersFromClients()
				await this.deleteGroupPointersFromUsers()
			}
			groupObject.set("group_name", this.groupName)
			groupObject.set("email", this.email)
			groupObject.set("street", this.street)
			groupObject.set("phone1", this.phone1)
			groupObject.set("info1", this.info1)
			groupObject.set("phone2", this.phone2)
			groupObject.set("plz", this.plz)
			groupObject.set("info2", this.info2)
			groupObject.set("location", this.location)
			try {
				await groupObject.save()
			} catch (e) {
				console.error("groupcouldnt be created " + e)
				negativeFeedBack("Gruppe nicht bearbeitet!")
			}

			if(!this.isNewGroup()){
				const chosenMetaIds = this.chosenUsers.map(usermeta => usermeta.id)
				const deletedUsers = this.oldUsers.filter(usermeta => !chosenMetaIds.includes(usermeta.id))
				await checkContactClients(deletedUsers)
			}

			for (const user of this.chosenUsers) {
				const userObject = await queryUserDetails(user.id)
				const groups = userObject.attributes.groups? userObject.attributes.groups : []
				groups.push(groupObject)
				userObject.set("groups", groups)
				try {
					await userObject.save()
				} catch (e) {
					console.error("couldnt add group pointer to user " + e)
				}
			}

			for (const client of this.chosenClients) {
				if(client.attributes.group.id !== groupObject.id){
					await checkContactEducator(client, groupObject.toPointer(), this.staffMetas)
				}
				client.set("group", groupObject)
				try {
					await client.save()
				} catch (e) {
					console.error("couldnt add group pointer to client " + e)
				}
			}
			positiveFeedBack("Gruppe gespeichert!")
			await this.$router.push({path: "/groups"})
			this.isLoading = false
		},
		deleteUser(index) {
			this.chosenUsers.splice(index, 1)
		},
		async deleteGroupPointersFromUsers(){
			const users = await queryUserMetaClasses()
			for(const user of users){
				const userGroups = user.attributes.groups? user.attributes.groups.filter(group => group.id !== this.group.id): []
				user.set("groups", userGroups)
				try{
					await user.save()
				}catch(e){console.error(e)}
			}
		},
		async deleteGroupPointersFromClients(){
			const clients = await queryClients()
			for(const client of clients){
				if(client.attributes.group.id === this.group.id){
					client.attributes.group.set("group", undefined)
					try{
						await client.save()
					}catch(e){
						console.error(e)
					}
				}
			}
		},
		async setExistingData(){
			if(!this.$route.params.data) return this.isMounted = true;
			this.group = this.$route.params.data
			this.groupName = this.group.attributes.group_name
			this.email = this.group.attributes.email
			this.location = this.group.attributes.location
			this.plz = this.group.attributes.plz
			this.street = this.group.attributes.street
			this.phone1 = this.group.attributes.phone1
			this.info1 = this.group.attributes.info1
			this.phone2 = this.group.attributes.phone2
			this.info2 = this.group.attributes.info2
			this.isGroup = this.group.attributes.target ==="group"
			this.clientsArrayDefault.forEach(client => {
				if(client.attributes.group.id === this.group.id){
					this.chosenClients.push(client)
				}
			})
			this.staffMetas.forEach(user => {
				const groupIds = user.attributes.groups ? user.attributes.groups.map(userGroup => userGroup.id): []
				if(groupIds.includes(this.group.id)){
					this.chosenUsers.push(user)
					this.oldUsers.push(user)
				}
			})
			this.isMounted = true
		}
	},
}
</script>

<style lang="scss" scoped>

</style>
