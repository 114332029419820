<template>
	<div>
		<div class="panel m-5">
			<PanelHeader :panel-header="panelHeader" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<InputWithValidation class="column" message="Bitte geben Sie einen Bezeichner ein!"
						:name="licenseKindName" :set-name="(val) => (licenseKindName = val)" label="Bezeichner" />
					<b-field class="column"
						:message="!$v.licenseKindRate.$invalid ? '' : 'Bitte tragen Sie einen validen Geldbetrag ein! Dieser hat mindestens eine Stelle vor dem Komma und genau 2 Nachkommastellen.'"
						:type="!$v.licenseKindRate.$invalid ? 'is-success' : 'is-danger'">
						<template #label>
							<div class="align-content-center" style="display: inline-flex">
								<p> Tagessatz (in €)</p>
							</div>
						</template>
						<b-input v-model="licenseKindRate" placeholder="Geldbetrag eintragen" />
					</b-field>
					<InputComponent class="column" :name="licenseKindHint" :key="isMounted ? 'hinte' : 'hint'"
						:set-name="(val) => (licenseKindHint = val)" label="Anmerkung" />
				</div>
			</div>
		</div>

		<div class="m-5 buttons" style="display: flex; justify-content: center">
			<SaveButton :is-loading="isLoading" :disabled="$v.licenseKindName.$invalid || $v.licenseKindRate.$invalid"
				:save-function="saveData" />
			<CancelButton text="Abbrechen" back-route="/license_kind" :is-loading="false" />
		</div>
	</div>
</template>

<script>

import Parse from "parse/dist/parse.min.js";
import { minLength, required } from "vuelidate/lib/validators";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import InputComponent from "@/components/helperComponents/InputComponent";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import { positiveFeedBack, negativeFeedBack } from "@/lib/Toast";

export default {
	name: "NewAnwesenheit",
	components: {
		PanelHeader,
		InputWithValidation,
		InputComponent,
		SaveButton,
		CancelButton,
	},
	data() {
		return {
			isLoading: false,
			isMounted: false,
			licenseKind: undefined,
			licenseKindName: "",
			licenseKindHint: "",
			licenseKindRate: "0",
			panelHeader: "Neuen Bewilligungstyp anlegen"
		};
	},
	computed: {},
	watch: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.licenseKind = this.$route.params.data
			this.licenseKindName = this.licenseKind.attributes.name
			this.licenseKindRate = String(this.licenseKind.attributes.rate.toFixed(2)).replace('.', ',')
			this.licenseKindHint = this.licenseKind.attributes.hint
			this.panelHeader = "Bewilligungstyp bearbeiten"
			this.isMounted = true
		}
	},
	validations: {
		licenseKindName: {
			required,
			minLength: minLength(1),
		},
		licenseKindRate: {
			required,
			validator: function (value) {
				let regexReturn = value.match(/((^(0,\d{2})$)|(^([1-9][0-9]*(,\d{2})?)$))/g) ? true : false
				return (value == "0") || regexReturn
			}
		},
	},
	methods: {
		async saveData() {
			this.isLoading = true;
			const LicenseKind = Parse.Object.extend("LicenseKind")
			const licenseKind = this.licenseKind ? this.licenseKind : new LicenseKind()
			licenseKind.set("name", this.licenseKindName)
			licenseKind.set("rate", Number(this.licenseKindRate.replace(',', '.')))
			licenseKind.set("hint", this.licenseKindHint)
			licenseKind.set("deleted", false)
			await licenseKind.save().catch((e) => {
				negativeFeedBack("Bewilligungstyp nicht gespeichert!")
			});
			this.isLoading = false;
			await this.$router.push({ path: "/license_kind" })
			await positiveFeedBack("Bewilligungstyp gespeichert!")
		},
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		},
	}
};


</script>

<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}

.anwesenheitstage {
	flex-direction: column;
}
</style>
