<template>
  <div class="hero">
    <div
      class="
                hero-head
                is-flex is-justify-content-center is-align-content-center
            "
    >
      <b-button
        class="m-1"
        icon-left="chevron-left"
        @click="routeBack()"
      >
        Zurück
      </b-button>
    </div>
    <div
      class="hero-body container scrollable content"
      v-html="data_protection[app_data_protection]"
    />
  </div>
</template>

<script>

import { APP_DATA_PROTECTION } from "@/lib/config/AppConfigurations";
import DATA_PROTECTION from '../../assets/texts/data_protection.json'

export default {
    name: "DataProtection",
    data() {
        return {
			data_protection : DATA_PROTECTION,
			app_data_protection : APP_DATA_PROTECTION
		};
    },
    computed: {},
    methods: {
        async routeBack() {
            return await this.$router.back();
        },
    },
};
</script>

<style scoped>
.scrollable {
    overflow: auto;
    height: 93%;
}
</style>