<template>
  <div>
    <SearchableTable
      :panel-header="'Berichtsvorlagen'"
      :panel-route="'AddReportTemplate'"
      :show-search-bar="false"
      :table-data="reportTemplates"
      :table-meta-data="tableMetaData"
      :query-string="'setPersonSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
    <b-modal v-model="isDeleteCheck">
      <div
        v-if="isDeleteCheck"
        class="panel m-5"
        style="background-color: white"
      >
        <p class="panel-heading">
          Möchtest du die Vorlage "{{
            chosenTemplate.attributes.title
          }}" wirklich löschen?
        </p>
        <div
          class="buttons mt-2"
          style="display: flex; justify-content: center"
        >
          <b-button
            class="is-danger"
            :loading="isLoading"
            @click="submitDelete()"
          >
            Ja
          </b-button>
          <b-button
            class="is-primary"
            @click="closeModal()"
          >
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/**
 * This file shows all currently stored templates for reports and allows adding, editing, viewing and soft deleting them
 */
import Parse from "parse/dist/parse.min"
import SearchableTable from "../helperComponents/SearchableTable.vue";
export default {
	name: "ReportTemplates",
	components: { SearchableTable },
	data() {
		return {
			reportTemplates: [],
			isDeleteCheck: false,
			chosenTemplate: undefined,
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Titel", "field": "title", "sortField": "attributes.title" },
				{ "id": 1, "type": "string", "label": "Bearbeitung", "field": "processingDays", "sortField": "attributes.processingDays" },
				{ "id": 2, "type": "string", "label": "Korrektur", "field": "correctionDays", "sortField": "attributes.correctionDays" },
				{ "id": 3, "type": "string", "label": "Genehmigung", "field": "approvalDays", "sortField": "attributes.approvalDays" },
				{ "id": 4, "type": "string", "label": "Versand", "field": "shippingDays", "sortField": "attributes.shippingDays" },
			]
		};
	},
	async mounted() {
		await this.updateTable()
	},
	methods: {
		async updateTable() {
			const query = new Parse.Query("ReportTemplate")
			query.notEqualTo("deleted", true)
			this.reportTemplates = await query.find()
		},
		triggerEdit(template) {
			this.$router.push({ name: "AddReportTemplate", params: { data: template } });
		},
		triggerDelete(template) {
			this.chosenTemplate = template
			this.isDeleteCheck = true
		},
		closeModal() {
			this.isDeleteCheck = false
		},
		async submitDelete() {
			this.isLoading = true
			this.chosenTemplate.set("deleted", true)
			await this.chosenTemplate.save()
			this.isLoading = false
			this.isDeleteCheck = false
			await this.updateTable()
		}
	},
};
</script>

<style lang="scss" scoped></style>
