/**
 * This file is used for storing constants
 * @type {string[]}
 */
export const DISTRICTS = [
	"Salzlandkreis",
	"Anhalt-Bitterfeld",
	"Stadt Halle",
	"Burgenlandkreis",
	"Landkreis Harz",
	"Landkreis Eichsfeld",
	"Landkreis Nordsachsen",
	"Landkreis Börde",
	"Landeshauptstadt Magdeburg",
	"Gera",
	"Stadt Leipzig",
	"Erfurt",
	"Merseburg",
	"Mansfeld Südharz"
]

export const STATUTORY_BASIS_ARRAY = [
	"§20 Betreuung und Versorgung des Kindes in Notsituation",
	"§27 Hilfe zur Erziehung",
	"§28 Erziehungsberatung",
	"§30 Erziehungsbeistand, Betreuungshelfer",
	"§32 Erziehung in einer Tagesgruppe",
	"§34 Heimerziehung, sonstige betreute Wohnform",
	"§35a Eingliederungshilfe für seelisch behinderte Kinder und Jugendliche",
	"§41 Hilfe für Volljährige, Nachbetreuung",
	"§42 Inobhutnahme von Kindern und Jugendlichen",
]

export const GENDERS = [
	"weiblich",
	"männlich",
	"divers"
]

export const GOAL_DAY_DICT = {
	"Sonntag": 0,
	"Montag": 1,
	"Dienstag": 2,
	"Mittwoch": 3,
	"Donnerstag": 4,
	"Freitag": 5,
	"Samstag": 6,
}

export const PERSON_TYPES =[
	"Amtsvormund",
	"Mutter",
	"Vater",
	"Oma",
	"Opa",
	"Stiefvater",
	"Stiefmutter",
	"Tante",
	"Onkel",
	"Bruder",
	"Schwester",
	"Schwägerin",
	"Schwager",
	"Pflegeeltern",
	"Soziale Eltern",
	"Wirtschaftliche Jugendhilfe",
]

export const SHEET_NAMES = {
	CLIENT_SHEET: "Klienten",
	USER_SHEET: "Mitarbeiter",
	SOCIAL_WORKER_SHEET: "Sozialarbeiter",
	PERSON_SHEET: "Bezugspersonen",
	SCHOOL_SHEET: "Schule|Kita",
	GROUP_SHEET: "Gruppen",
	DISTRICT_SHEET: "Landkreis",
	STATUTORY_BASIS_SHEET: "Gesetzliche Grundlagen",
	BALANCE_SHEET: "Buchungen"
}
