<template>
  <div>
    <SearchableTable
      :panel-header="'Kategorien'"
      :panel-route="'AddClientRecords'"
      :show-search-bar="false"
      :table-data="categoryPage"
      :table-meta-data="tableMetaData"
      :query-string="'setPersonSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerView(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="eye"
            style="cursor: pointer"
          />
        </b-button>
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
    <ModalComponent
      v-if="isDeleteCheck"
      :item="focusedCategory.attributes.name"
      :is-delete-check="isDeleteCheck"
      :is-loading="isLoading"
      :cancel="cancel"
      :submit="submitDelete"
    />
    <b-pagination
      v-if="categories.length > 10"
      v-model="currentPage"
      class="p-4"
      tag="button"
      range-before="1"
      range-after="1"
      order="is-centered"
      :total="categories.length"
      :per-page="itemsPerPage"
      :current.sync="currentPage"
      aria-next-label="Nächste Seite"
      aria-previous-label="Vorherige Seite"
      aria-page-label="Seite"
      aria-current-label="derzeitige Seite"
      @change="refreshCategoryPage"
    />
  </div>
</template>

<script>
/**
 * This file shows the used categories in client records, which store all pdfs for clients
 */
import { queryClientRecordCategories } from "@/lib/Query";
import ModalComponent from "../../helperComponents/ModalComponent"
import { positiveFeedBack } from "@/lib/Toast";
import SearchableTable from "../../helperComponents/SearchableTable.vue";

export default {
	name: "ClientRecordsTable",
	components: {
		SearchableTable,
		ModalComponent
	},
	data() {
		return {
			categories: [],
			categoryPage: [],
			focusedCategory: undefined,
			isDeleteCheck: false,
			isLoading: false,
			itemsPerPage: 10,
			currentPage: 1,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Kategorie", "field": "name", "sortField": "attributes.name" },
			]
		}
	},
	async created() {
		this.categories = await queryClientRecordCategories()
		this.refreshCategoryPage()
	},
	methods: {
		triggerEdit(category) {
			this.$router.push({ name: "EditClientRecords", params: { data: category } });
		},
		triggerView(category) {
			this.$router.push({ name: "ViewClientRecords", params: { data: category } });
		},
		triggerDelete(category) {
			this.focusedCategory = category
			this.isDeleteCheck = true
		},
		cancel() {
			this.isLoading = false
			this.isDeleteCheck = false
		},
		async submitDelete() {
			this.focusedCategory.set("deleted", true)
			await this.focusedCategory.save()
			this.isDeleteCheck = false
			this.categories = await queryClientRecordCategories()
			positiveFeedBack("Kategorie gelöscht!")
		},
		refreshCategoryPage() {
			this.categoryPage = this.categories.filter((c, i) => {
				return i >= (this.currentPage - 1) * this.itemsPerPage
					&& i < (this.currentPage) * this.itemsPerPage
			})
		}
	}
}
</script>

<style scoped></style>
