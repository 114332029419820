<template>
  <div>
    <div
      v-if="externalMetaClass"
      class="panel m-5"
    >
      <p class="panel-heading">
        Stammdaten
      </p>
      <div class="container pt-3 pb-3">
        <div class="columns is-centered">
          <div class="column image_column">
            <b-field label="Profilbild">
              <b-image
                :src="dropFileURL"
                responsive
                ratio="64by64"
                alt="Profilbild"
                rounded
              />
            </b-field>
          </div>
        </div>
        <hr>
        <div class="columns">
          <div class="column">
            <b-field label="Vorname">
              <p class="content">
                {{ firstName }}
              </p>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Nachname">
              <p class="content">
                {{ familyName }}
              </p>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Geschlecht">
              <p class="content">
                {{ gender }}
              </p>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Funktion">
              <p class="content">
                {{ externalFunction }}
              </p>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Klienten">
              <ul>
                <li
                  v-for="(client, index) in clients"
                  :key="index"
                >
                  {{ client.attributes.first_name }} {{ client.attributes.last_name }}
                </li>
              </ul>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <div class="panel m-5">
      <p class="panel-heading">
        Kontakt- und Adressdaten
      </p>
      <div class="container pt-3 pb-3">
        <div class="columns">
          <div class="column">
            <b-field label="Telefon 1">
              <p class="content">
                {{ phone1 }}
              </p>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Telefon 2">
              <p class="content">
                {{ phone2 }}
              </p>
            </b-field>
          </div>
        </div>
        <div class="columns">
			<div class="column">
            <b-field label="Straße">
              <p class="content">
                {{ street }}
              </p>
            </b-field>
          </div>
          <div class="column">
            <b-field label="PLZ">
              <p class="content">
                {{ plz }}
              </p>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Ort">
              <p class="content">
                {{ location }}
              </p>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <section class="m-2">
      <div style="display: flex; justify-content: center">
        <b-button
          class="is-danger"
          tag="router-link"
          to="/externals"
        >
          Zurück
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
/**
 * This file is for viewing a focused external person
 */
import {getUserPicture} from "@/lib/Picture";

export default {
	name: "ViewExternal",
	data() {
		return {
			dropFile: undefined,
			firstName: "",
			familyName: "",
			phone1: "",
			phone2: "",
			location: "",
			street: "",
			plz: "",
			externalFunction: "",
			clients:[],
			externalMetaClass: undefined,
			gender: ""
		};
	},
	computed: {
		dropFileURL() {
			const pictureObject = {
				newProfilePicture: typeof this.dropFile === "string" ? undefined : this.dropFile,
				userPicture: typeof this.dropFile === "string" ? this.dropFile : undefined,
				userGender: this.externalMetaClass.attributes.gender
			}
			return getUserPicture(pictureObject)
		},
	},
	async created() {
		const externalMetaClass = this.$route.params.data
		this.externalMetaClass= externalMetaClass
		if (externalMetaClass) {
			this.firstName = externalMetaClass.attributes.first_name ? externalMetaClass.attributes.first_name : ""
			this.familyName = externalMetaClass.attributes.last_name ? externalMetaClass.attributes.last_name : ""
			this.street = externalMetaClass.attributes.street ? externalMetaClass.attributes.street : ""
			this.plz = externalMetaClass.attributes.plz ? externalMetaClass.attributes.plz : ""
			this.location = externalMetaClass.attributes.location ? externalMetaClass.attributes.location : ""
			this.phone1 = externalMetaClass.attributes.phone1 ? externalMetaClass.attributes.phone1 : ""
			this.phone2 = externalMetaClass.attributes.phone2 ? externalMetaClass.attributes.phone2 : ""
			this.dropFile = externalMetaClass.attributes.profile_picture ? externalMetaClass.attributes.profile_picture._url : undefined
			this.externalFunction = externalMetaClass.attributes.function?  externalMetaClass.attributes.function : ""
			this.clients = externalMetaClass.attributes.clients? externalMetaClass.attributes.clients : []
			this.gender = externalMetaClass.attributes.gender? externalMetaClass.attributes.gender : ""
		}
	},
	async mounted(){
		if(!this.externalMetaClass){
			await this.$router.push("/externals")
		}
	},
	methods: {}
}

</script>

<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
