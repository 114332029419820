<template>
	<b-field class="borderContainer">
		<div class="is-flex is-flex-direction-column">
			<div v-for="inputObject in inputList" :key="inputObject.id"
				class="is-flex pb-2 is-justify-content-center is-flex-direction-column">
				<div class="is-flex is-align-items-center is-justify-content-center is-flex-direction-row">
					<div class="is-flex is-align-items-center is-justify-content-center">
						<div style="background-color: rgba(0,0,0,0); padding: .25rem; cursor: pointer"
							class="is-flex is-align-items-center is-align-self-stretch mt-3"
							@click="() => filterInput(inputObject.id)">
							<b-icon icon="delete" type="" />
						</div>

						<InputWithValidation message="Bitte geben Sie einen Budgetnamen ein!" :name="inputObject.name"
							:set-name="(val) => (inputObject.name = val)" label="Budget" class="is-align-self-stretch"
							placeholder="Budget" />

						<InputWithValidation style="margin-bottom: 0.75rem;"
							message="Bitte geben Sie eine Budgetnummer ein!" class="is-align-self-stretch"
							:name="inputObject.budgetNumber" :set-name="(val) => (inputObject.budgetNumber = val)"
							label="Budgetnummer" placeholder="Budgetnummer" />

						<!--div class="columns">
							<b-input v-model="inputObject.name" class="has-text-left column" :placeholder="label" />
							<b-input v-model="inputObject.budgetNumber" class="column" type="number"
								placeholder="Budgetnummer" />
						</div-->
						<b-switch class="is-align-self-stretch mt-3 ml-2" v-model="inputObject.childSpecific"
							passive-type="is-dark" type="is-success">
							<div class="">
								{{ inputObject.childSpecific ? 'Kind-spezifisch' : 'Kind-unspezifisch' }}
							</div>
						</b-switch>
					</div>
					<!--div class="is-flex is-align-items-center is-justify-content-flex-start pl-3" style="width: 20%;">
						
					</div-->
				</div>
				<b-input v-model="inputObject.description" placeholder="Hinweis" />
			</div>

			<div style="background-color: rgba(0,0,0,0); padding: .25rem; cursor: pointer" @click="(e) => addInput(e)">
				<b-icon icon="plus" />
			</div>
		</div>
	</b-field>
</template>

<script>
import { v4 as uuid } from "uuid"
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
export default {
	name: "BudgetList",
	components: {
		InputWithValidation
	},
	props: ["label", "inputList"],
	methods: {
		addInput(e) {
			e.preventDefault();
			this.inputList.push({
				id: uuid(),
				name: "",
				childSpecific: false,
				entities: {},
				defaultValue: 0,
				budgetNumber: undefined,
				description: ""
			});
		},
		filterInput(id) {
			this.$emit("filter", id);
		},
	}
}
</script>

<style scoped>
.borderContainer {
	border-radius: .5rem;
	padding: .5rem;
	margin: .5rem;
}

::v-deep .label {
	font-size: large;
}

::v-deep .switch:hover input[type=checkbox]:checked+.check.is-success,
::v-deep .switch input[type="checkbox"]:checked + .check.is-success {
	background: rgba(54, 54, 54, 0.9);
}

::v-deep .switch input[type="checkbox"]:focus:checked + .check.is-success, 
::v-deep .switch input[type="checkbox"]:active:checked + .check.is-success
{
	box-shadow:0 0 0.5em rgba(54, 54, 54, 0.8);
}
</style>
