/**
 * This file is for handling some important calendar functions
 */

export const getDate = (year, month, day, hour, minute, seconds) => {
    const monthFormatted = month.toString().length === 1 ? `0${month}` : `${month}`
    const dayFormatted = day.toString().length === 1 ? `0${day}` : `${day}`
    const hourFormatted = hour.toString().length === 1 ? `0${hour}` : `${hour}`
    const minuteFormatted = minute.toString().length === 1 ? `0${minute}` : `${minute}`
    const secondFormatted = seconds.toString().length === 1 ? `0${seconds}` : `${seconds}`
    return `${year}-${monthFormatted}-${dayFormatted}T${hourFormatted}:${minuteFormatted}:${secondFormatted}`
}

export const equalsDate = (date1, date2) => {
	if (date1 && date2) {
		const isYearEqual = date1.getFullYear() === date2.getFullYear()
		const isMonthEqual = date1.getMonth() === date2.getMonth()
		const isDateEqual = date1.getDate() === date2.getDate()
		return isYearEqual && isMonthEqual && isDateEqual
	}
	return false
}

export const isDayFullTimeEvent = (start, end) => {
    const previousEndDay = new Date(end.getTime() - 60 * 1000 * 60 * 24)
    const isStartDayPreviousDayOfEnd = equalsDate(start, previousEndDay)
    const areHoursZero = start.getHours() === 0 && end.getHours() === 0
    const areMinutesZero = start.getMinutes() === 0 && end.getMinutes() === 0
    const areSecondsZero = start.getSeconds() === 0 && end.getSeconds() === 0
    return isStartDayPreviousDayOfEnd && areHoursZero && areMinutesZero && areSecondsZero
}

export const milliSecondsToMinutes = (milliSeconds) => milliSeconds/(1000*60)

export const milliSecondsToHours = (milliSeconds) => milliSecondsToMinutes(milliSeconds)/(60)

export const milliSecondsToDays = (milliSeconds) => milliSecondsToHours(milliSeconds)/(24)

export const milliSecondsToWeeks = (milliSeconds) => milliSecondsToDays(milliSeconds)/(7)

export const getDateOfMonthAfterBirthday = (months, client) =>{
    if(!client.attributes.birth_date) return undefined
    const birthday = client.attributes.birth_date
    return new Date(birthday.getFullYear(), birthday.getMonth()+months, birthday.getDate(), birthday.getHours(), birthday.getMinutes(), birthday.getSeconds())
}

export const clientAge = (client) =>{
    if(!client.attributes.birth_date) return undefined
    return new Date() - client.attributes.birth_date
}

export const isEventSingleEvent = event => event.attributes.repeatTimePeriod === "Nie"

export const isEventDailyEvent = event => event.attributes.repeatTimePeriod === "Täglich"

export const isEventWeeklyEvent = event => event.attributes.repeatTimePeriod === "Wöchentlich"

export const isEventMonthlyEvent = event => event.attributes.repeatTimePeriod === "Monatlich"

export const isEventYearlyEvent = event => event.attributes.repeatTimePeriod === "Jährlich"

export const isEventMultipleEvent = event => isEventDailyEvent(event) || isEventWeeklyEvent(event) || isEventMonthlyEvent(event) || isEventYearlyEvent(event)

const isReminderTimePeriodMinutes = (reminder) => reminder.timePeriod === "Minuten"

const isReminderTimePeriodHours = (reminder) => reminder.timePeriod === "Stunden"

const isReminderTimePeriodDays = (reminder) => reminder.timePeriod === "Tage"

const isReminderTimePeriodWeeks = (reminder) => reminder.timePeriod === "Wochen"

const isReminderTimePeriodMonth = (reminder) => reminder.timePeriod === "Monate"

const getReminderDateMinutes = (reminder, eventStart) => new Date(eventStart.getTime() - 60 * 1000 * reminder.number)

const getReminderDateHours = (reminder, eventStart) => new Date(eventStart.getTime() - 60 * 1000 * 60 * reminder.number)

const getReminderDateDays = (reminder, eventStart) => new Date(eventStart.getTime() - 60 * 1000 * 60 * 24 * reminder.number)

const getReminderDateWeeks = (reminder, eventStart) => new Date(eventStart.getTime() - 60 * 1000 * 60 * 24 * 7 * reminder.number)

const getReminderDateMonth = (reminder, eventStart) => {
    const year = Math.floor(reminder.number/12)  + Math.floor((12 - eventStart.getMonth() -1 + reminder.number)/12)
    const month = eventStart.getMonth() - reminder.number + 1 <= 0 ? 12+(eventStart.getMonth() - reminder.number + 1)%12 : eventStart.getMonth() - reminder.number + 1
    return new Date(getDate(eventStart.getFullYear()-year, month, eventStart.getDate(), eventStart.getHours(), eventStart.getMinutes(), 0))
}

export const getReminderDate = (reminder, eventStart) => {
    if (isReminderTimePeriodMinutes(reminder)) {
        return getReminderDateMinutes(reminder, eventStart)
    }
    if (isReminderTimePeriodHours(reminder)) {
        return getReminderDateHours(reminder, eventStart)
    }
    if (isReminderTimePeriodDays(reminder)) {
        return getReminderDateDays(reminder, eventStart)
    }
    if (isReminderTimePeriodWeeks(reminder)) {
        return getReminderDateWeeks(reminder, eventStart)
    }
    if (isReminderTimePeriodMonth(reminder)) {
        return getReminderDateMonth(reminder, eventStart)
    }
    return undefined
}

export const dateFormatter = (dt) => {
    return dt.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
}
