import ExcelWorkBook from "@/lib/import/ExcelWorkbook";
import {negativeFeedBack} from "@/lib/Toast";

const handleClientExceptions= (row) =>{
	if(row["Name"] === undefined){
		const mess = "Name eines Klienten fehlt!"
		negativeFeedBack(mess)
		throw new Error(mess)
	}
	if(row["Vorname"] === undefined){
		const mess = "Vorname eines Klienten fehlt!"
		negativeFeedBack(mess)
		throw new Error(mess)
	}
	if(row["Gruppe"] === undefined){
		const mess = "Gruppe eines Klienten fehlt!"
		negativeFeedBack(mess)
		throw new Error(mess)
	}
}

const handleMissingLawException= (row) =>{
	if(row["Gesetz"] === undefined){
		const mess = "Gesetz in einer gesetzlichen Grundlage fehlt!"
		negativeFeedBack(mess)
		throw new Error(mess)
	}
}

const handleUserExceptions= (row) =>{
	if(row["Name"] === undefined){
		const mess = "Name eines Mitarbeiters fehlt!"
		negativeFeedBack(mess)
		throw new Error(mess)
	}
	if(row["Vorname"] === undefined){
		const mess = "Vorname eines Mitarbeiters fehlt!"
		negativeFeedBack(mess)
		throw new Error(mess)
	}
	if(row["E-Mail"] === undefined){
		const mess = "Email eines Mitarbeiters fehlt!"
		negativeFeedBack(mess)
		throw new Error(mess)
	}
}

const handleMissingNameException = (row, message)=>{
	if(row["Name"] === undefined){
		negativeFeedBack(message)
		throw new Error(message)
	}
}
const handlePersonException = (row, messageFirstName, messageSecondName)=>{
	if(row["Vorname"] === undefined){
		negativeFeedBack(messageFirstName)
		throw new Error(messageFirstName)
	}
	if(row["Name"] === undefined){
		negativeFeedBack(messageSecondName)
		throw new Error(messageSecondName)
	}
}

/**
 * class for reading data from sheets in a workbook
 */
class ExcelSheet extends ExcelWorkBook {
	constructor(workbook) {
		super(workbook);
	}

	getSocialWorkerData() {
		const sheet = super.getSocialWorkerSheet()
		const socialWorkerData = []
		for (const row of sheet) {
			handlePersonException(row, "Vorname eines Sozialarbeiters fehlt!",
				"Name eines Sozialarbeiters fehlt!")
			socialWorkerData.push({
				last_name: row["Name"] ? row["Name"].trim(): row["Name"],
				first_name: row["Vorname"] ? row["Vorname"].trim(): row["Vorname"],
				street: row["Straße"] ? row["Straße"].trim() : row["Straße"],
				plz: row["Plz"] ? row["Plz"].toString() : row["Plz"],
				location: row["Ort"] ? row["Ort"].trim() :row["Ort"],
				phone1: row["Telefon 1"] ? row["Telefon 1"].toString() : row["Telefon 1"],
				info1: row["Info 1"] ? row["Info 1"].toString() :row["Info 1"],
				phone2: row["Telefon 2"] ?  row["Telefon 2"].toString() : row["Telefon 2"],
				info2: row["Info 2"] ? row["Info 2"].toString() :row["Info 2"],
				email: row["E-Mail"] ? row["E-Mail"].trim(): row["E-Mail"],
				type: "socialWorker"
			})
		}
		return socialWorkerData
	}

	getStatutoryBasisData() {
		const sheet = super.getStatutoryBasisSheet()
		const statutoryBasisData = []
		for (const row of sheet) {
			handleMissingLawException(row)
			statutoryBasisData.push({
				name: row["Gesetz"] ? row["Gesetz"].trim():row["Gesetz"]
			})
		}
		return statutoryBasisData
	}

	getSchoolKindergartenData() {
		const sheet = super.getSchoolKindergartenSheet()
		const schoolKindergartenData = []
		for (const row of sheet) {
			handleMissingNameException(row, "Name in einer Schule/Kindergarten fehlt!")
			schoolKindergartenData.push({
				name:  row["Name"]? row["Name"].trim() : row["Name"],
				street: row["Straße"] ? row["Straße"].trim() :row["Straße"],
				plz: row["Plz"] ? row["Plz"].toString() : row["Plz"],
				location: row["Ort"] ? row["Ort"].trim() : row["Ort"],
				email: row["E-Mail"] ? row["E-Mail"].trim(): row["E-Mail"],
				phone1: row["Telefon 1"] ? row["Telefon 1"].toString() : row["Telefon 1"],
				info1: row["Info 1"] ? row["Info 1"].toString() :row["Info 1"],
				phone2: row["Telefon 2"] ?  row["Telefon 2"].toString() : row["Telefon 2"],
				info2: row["Info 2"] ? row["Info 2"].toString() :row["Info 2"],
				type: "school"
			})
		}
		return schoolKindergartenData
	}

	getDistrictData() {
		const sheet = super.getDistrictsSheet()
		const districtsData = []
		for (const row of sheet) {
			handleMissingNameException(row, "Name in einem Landkreis fehlt!")
			districtsData.push({
				name: row["Name"] ? row["Name"].trim(): row["Name"],
				street: row["Straße"] ? row["Straße"].trim() : row["Straße"],
				plz: row["Plz"] ? row["Plz"].toString() : row["Plz"],
				location: row["Ort"] ? row["Ort"].trim(): row["Ort"],
				email: row["E-Mail"] ? row["E-Mail"].trim(): row["E-Mail"],
				phone1: row["Telefon 1"] ? row["Telefon 1"].toString() : row["Telefon 1"],
				info1: row["Info 1"] ? row["Info 1"].toString() :row["Info 1"],
				phone2: row["Telefon 2"] ?  row["Telefon 2"].toString() : row["Telefon 2"],
				info2: row["Info 2"] ? row["Info 2"].toString() :row["Info 2"],
				type: "district"
			})
		}
		return districtsData
	}

	getGroupsData(){
		const sheet = super.getGroupsSheet()
		const groupsData = []
		for (const row of sheet) {
			handleMissingNameException(row, "Name in einer Gruppe fehlt!")
			groupsData.push({
				group_name: row["Name"] ? row["Name"].trim():row["Name"],
				target: "group",
				street: row["Straße"] ? row["Straße"].trim(): row["Straße"],
				plz: row["Plz"] ? row["Plz"].toString() : row["Plz"],
				location: row["Ort"] ? row["Ort"].trim():row["Ort"],
				email: row["E-Mail"] ? row["E-Mail"].trim() :row["E-Mail"],
				phone1: row["Telefon 1"] ? row["Telefon 1"].toString() : row["Telefon 1"],
				info1: row["Info 1"] ? row["Info 1"].toString() :row["Info 1"],
				phone2: row["Telefon 2"] ?  row["Telefon 2"].toString() : row["Telefon 2"],
				info2: row["Info 2"] ? row["Info 2"].toString() :row["Info 2"],
			})
		}
		return groupsData
	}

	getStatutoryRepresentativesData() {
		const sheet = super.getStatutoryRepresentativesSheet()
		const statutoryRepresentativeData = []
		for (const row of sheet) {
			handlePersonException(row, "Vorname eines gesetzlichen Vertreters fehlt!",
				"Name eines gesetzlichen Vertreters fehlt!")
			statutoryRepresentativeData.push({
				last_name: row["Name"] ? row["Name"].trim() : row["Name"],
				first_name: row["Vorname"] ? row["Vorname"].trim() : row["Vorname"],
				type: row["Typ"] ? row["Typ"].trim() : row["Typ"],
				street: row["Straße"] ? row["Straße"].trim():row["Straße"],
				plz: row["Plz"] ? row["Plz"].toString() : row["Plz"],
				location: row["Ort"] ? row["Ort"].trim() : row["Ort"],
				phone1: row["Telefon 1"] ? row["Telefon 1"].toString() : row["Telefon 1"],
				info1: row["Info 1"] ? row["Info 1"].toString() :row["Info 1"],
				phone2: row["Telefon 2"] ?  row["Telefon 2"].toString() : row["Telefon 2"],
				info2: row["Info 2"] ? row["Info 2"].toString() :row["Info 2"],
				email: row["E-Mail"] ? row["E-Mail"].trim() : row["E-Mail"],
			})
		}
		return statutoryRepresentativeData
	}

	getUsersData(){
		const sheet = super.getUsersSheet()
		const usersData = []
		for (const row of sheet) {
			handleUserExceptions(row)
			{
				usersData.push({
					last_name: row["Name"] ? row["Name"].trim() : row["Name"],
					first_name: row["Vorname"] ? row["Vorname"].trim():row["Vorname"],
					birth_date: row["Geburtstag"],
					gender: row["Geschlecht"] ? row["Geschlecht"].trim() : row["Geschlecht"],
					email: row["E-Mail"] ? row["E-Mail"].trim() : row["E-Mail"],
					group: row["Gruppe"] ? row["Gruppe"].trim(): row["Gruppe"],
					street: row["Straße"] ? row["Straße"].trim() : row["Straße"],
					plz: row["PLZ"] ? row["PLZ"].toString() : row["PLZ"],
					location: row["Ort"],
					phone1: row["Telefon 1"] ? row["Telefon 1"].toString() : row["Telefon 1"],
					info1: row["Info 1"] ? row["Info 1"].toString() :row["Info 1"],
					phone2: row["Telefon 2"] ?  row["Telefon 2"].toString() : row["Telefon 2"],
					info2: row["Info 2"] ? row["Info 2"].toString() :row["Info 2"],
					isAdmin: row["Admin"] ? row["Admin"].trim() : row["Admin"]
				})
			}

		}
		return usersData
	}

	getClientsData() {
		const sheet = super.getClientsSheet()
		const clientsData = []
		for (const row of sheet) {
			handleClientExceptions(row)
			{
				clientsData.push({
					last_name: row["Name"] ? row["Name"].trim() : row["Name"],
					first_name: row["Vorname"] ? row["Vorname"].trim(): row["Vorname"],
					gender: row["Geschlecht"] ? row["Geschlecht"].trim() : row["Geschlecht"],
					birth_date: row["Geburtstag"],
					birth_location: row["Geburtsort"] ? row["Geburtsort"].trim():row["Geburtsort"],
					administrative_district: row["Landkreis"] ? row["Landkreis"].trim() : row["Landkreis"],
					group: row["Gruppe"] ? row["Gruppe"].trim() : row["Gruppe"],
					statutory_basis: row["gesetzliche Grundlage"] ? row["gesetzliche Grundlage"].trim():row["gesetzliche Grundlage"],
					entry: row["Zugang"],
					license: row["Bewilligung bis"],
					exit: row["Abgang"],
					street: row["Straße"] ? row["Straße"].trim(): row["Straße"],
					plz: row["PLZ"] ? row["PLZ"].toString() : row["PLZ"],
					location: row["Ort"] ? row["Ort"].trim() : row["Ort"],
					phone1: row["Telefon 1"] ? row["Telefon 1"].toString() : row["Telefon 1"],
					info1: row["Info 1"] ? row["Info 1"].toString() :row["Info 1"],
					phone2: row["Telefon 2"] ?  row["Telefon 2"].toString() : row["Telefon 2"],
					info2: row["Info 2"] ? row["Info 2"].toString() :row["Info 2"],
					statutoryRepresentative1: row["Bezugsperson 1"],
					custody1: row["Sorgerecht 1"],
					statutoryRepresentative2: row["Bezugsperson 2"],
					custody2: row["Sorgerecht 2"],
					statutoryRepresentative3: row["Bezugsperson 3"],
					custody3: row["Sorgerecht 3"],
					socialWorker: row["Sozialarbeiter"] ? row["Sozialarbeiter"].trim() : row["Sozialarbeiter"],
					school: row["Schule"] ? row["Schule"].trim(): row["Schule"],
					goalDay: row["Bewertungsstichtag"] ? row["Bewertungsstichtag"].trim() : row["Bewertungsstichtag"],
					contactEducatorEmail: row["Kontakterzieher"] ? row["Kontakterzieher"].trim(): row["Kontakterzieher"],
				})
			}

		}
		return clientsData
	}
}

export default ExcelSheet
