<template>
  <div>
    <template v-if="!hasUsers">
      <OnboardingComponent />
    </template>
    <div
      v-if="hasUsers"
      class="hero is-fullheight is-light"
    >
      <div
        class="
                hero-head
                is-flex is-justify-content-center is-align-content-center
            "
      >
        <figure class="image mt-3">
          <img
            class="logo-color"
            :src="require('@/assets/logo/' + app_logo)"
          >
        </figure>
      </div>
      <div class="hero-body container">
        <div class="box">
          <p class="title is-4">
            Anmeldung
          </p>
          <b-field label="E-Mail">
            <b-input
              id="email"
              v-model="loginRequest.email"
              type="email"
              @input="$v.loginRequest.email.$touch"
              @keydown.native.enter="login"
            />
          </b-field>
          <b-field label="Passwort">
            <b-input
              id="password"
              v-model="loginRequest.password"
              type="password"
              password-reveal
              @input="loginRequest.password.$touch"
              @keydown.native.enter="login"
            />
          </b-field>
          <b-button
            id="login"
            type="is-primary"
            :loading="isLoading"
            :disabled="loginRequest.$invalid"
            expanded
            @click="login"
          >
            Anmelden
          </b-button>
          <b-button
            v-if="false"
            class="mt-2 is-small"
            style="
                            background-color: rgba(0, 0, 0, 0.4);
                            color: white;
                            border-radius: 0.25rem;
                        "
            :loading="isLoading"
            expanded
            @click="passwordResetModal"
          >
            Passwort vergessen?
          </b-button>
        </div>
      </div>
      <about-bar class="hero-foot" />
    </div>
    <PasswordResetModal />
  </div>
</template>

<script>
/**
 * This file allows login of admins (only admins can login in dashboard)
 */
import Parse from "parse/dist/parse.min.js";
import { required } from "vuelidate/lib/validators";
import email from "vuelidate/lib/validators/email";
import { fetchTestDashboard, setQADashBoard } from "../../lib/DashboardImport";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import OnboardingComponent from "@/components/onboarding/OnboardingComponent";
import PasswordResetModal from "@/components/passwordReset/PasswordResetModal";
import AboutBar from "@/components/about/AboutBar";
import { APP_LOGO } from "@/lib/config/AppConfigurations";

export default {
    name: "LoginComponent",
    components: { PasswordResetModal, OnboardingComponent, AboutBar },
    data() {
        return {
            loginRequest: {
                email: "",
                password: "",
            },
            isLoading: false,
            admin: undefined,
            hasUsers: true,
			app_logo: APP_LOGO
        };
    },
    validations: {
        loginRequest: {
            email: { required, email },
            password: { required },
        },
    },
    async mounted() {
		await this.checkUsers();
	},
	methods: {
		async checkUsers() {
			try {
				this.hasUsers = await Parse.Cloud.run("hasUsers");
				return;
			} catch (e) {
				console.error(e);
			}
			this.hasUsers = true;
		},
		async importData() {
			await Parse.User.logIn("daniel.walke@metop.de", "secret");
			const data = await fetchTestDashboard();
			await Parse.User.logOut();
            await setQADashBoard(data);
        },
        async login() {
            this.isLoading = true;
            if (this.$v.loginRequest.email.$invalid)
                negativeFeedBack("Keine valide Email-Adresse");
            if (this.$v.loginRequest.password.$invalid)
                negativeFeedBack("Kein Passwort");
            if (
                !this.$v.loginRequest.email.$invalid &&
                !this.$v.loginRequest.password.$invalid
            ) {
                // password and email valid
                const params = { userName: this.loginRequest.email };
                const isUserAdmin = await Parse.Cloud.run("isAdmin", params);
                if (isUserAdmin) {
                    //authorization checked
                    Parse.User.logIn(
                        this.loginRequest.email,
                        this.loginRequest.password
                    )
                        .then(() => {
                            positiveFeedBack("Nutzer angemeldet!");
                            window.location = "/";
                        })
                        .catch((err) => {
                            negativeFeedBack(
                                "Falsche Email-Adresse oder falsches Passwort!"
                            );
                            console.error(err);
                        });
                } else {
                    negativeFeedBack("Du hast keine Zugriffsberechtigung!");
                }
            }
            this.isLoading = false;
        },
        passwordResetModal() {
            this.$store.dispatch("setIsPasswordResetModalOpen", true);
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
