<template>
  <div>
    <SearchableTable
      :panel-header="'Gesetzliche Grundlagen'"
      :panel-route="'AddStatutoryBasis'"
      :show-search-bar="false"
      :table-data="statutoryBasis"
      :table-meta-data="tableMetaData"
      :query-string="'setPersonSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
    <ModalComponent
      v-if="isDeleteCheck"
      :item="selectedStatutoryBasis.attributes.name"
      :is-delete-check="isDeleteCheck"
      :is-loading="isLoading"
      :cancel="() => isDeleteCheck = false"
      :submit="submitDelete"
    />
  </div>
</template>

<script>
import ModalComponent from "@/components/helperComponents/ModalComponent";
import { queryStatutoryBasis } from "@/lib/Query";
import { positiveFeedBack } from "@/lib/Toast";
import SearchableTable from "../helperComponents/SearchableTable.vue";

export default {
	name: "StatutoryBasis",
	components: { SearchableTable, ModalComponent },
	data() {
		return {
			statutoryBasis: [],
			selectedStatutoryBasis: undefined,
			isDeleteCheck: false,
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Name", "field": "name", "sortField": "attributes.name" },
			]
		}
	},
	async created() {
		this.statutoryBasis = await queryStatutoryBasis()
	},
	methods: {
		triggerEdit(school) {
			this.$router.push({ name: "AddStatutoryBasis", params: { data: school } });
		},
		triggerDelete(selectedStatutoryBasis) {
			this.selectedStatutoryBasis = selectedStatutoryBasis
			this.isDeleteCheck = true
		},
		async submitDelete() {
			const name = this.selectedStatutoryBasis.attributes.name
			this.selectedStatutoryBasis.set("deleted", true)
			await this.selectedStatutoryBasis.save()
			this.isDeleteCheck = false
			this.statutoryBasis = await queryStatutoryBasis()
			await positiveFeedBack(name + " gelöscht!")
		}
	}
}
</script>

<style scoped></style>
