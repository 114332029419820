import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../lib/Errors';

let localSubscription = undefined;

// User subscription
function createSubscription(store) {
	let userQuery = new Parse.Query('User');
	userQuery.limit(10000)
	userQuery.notEqualTo("deleted", true)
		.find()
		.then((results) => {
			store.dispatch('users/set', results)
		})
		.catch((error) => {
			handleParseError(error);
			
		});
	userQuery.subscribe().then((subscription) => {
		localSubscription = subscription;
		
		subscription.on('create', (object) => {
			store.dispatch('users/insert', object)
		});
		subscription.on('update', (object) => {
			store.dispatch('users/replace', object)
		});
		subscription.on('enter', (object) => {
			store.dispatch('users/insert', object)
		});
		subscription.on('leave', (object) => {
			store.dispatch('users/delete', object)
		});
		subscription.on('delete', (object) => {
			store.dispatch('users/delete', object)
		});
		subscription.on('close', () => {
		});
		subscription.on('error', error => {
			console.log('User subscription error', error);
		})
	})
}

export default function createUserSubscription() {
	return store => {
		if(store.state.currentUser) {
			createSubscription(store)
		}
		store.subscribe(mutation => {
			if (mutation.type === 'SET_CURRENT_USER'|| mutation.type==="SET_SAVED_USER") {
				if (localSubscription) {
					localSubscription.unsubscribe()
				}
				if (store.state.currentUser) {
					createSubscription(store)
				}
			}
		})
	}
}
