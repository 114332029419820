<template>
  <b-button
    type="is-primary"
    :loading="isLoading"
    :disabled="disabled"
    @click="save()"
  >
    Speichern
  </b-button>
</template>

<script>
/**
 *  This file is a save button
 *  @param:  saveFunction: function for submitting, disabled: boolean for disabling the button, isLoading: boolean indicating loading after submitting
 */
export default {
	name: "SaveButton",
	props:["isLoading", "disabled", "saveFunction"],
	methods:{
		save(){
			this.saveFunction()
		}
	}
}
</script>

<style scoped>

</style>
