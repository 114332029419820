<template>
  <div>
    <div class="panel m-5">
      <div
        class="panel-heading has-text-left"
        style="display: flex; justify-content: space-between"
      >
        <p>Datenimport</p>
      </div>
      <div class="container pt-3 pb-3">
        <UploadSection
          :file="file"
          @updateFile="val => file = val"
        >
          <p>Stammdaten</p>
        </UploadSection>
        <b-button
          v-if="file"
          class="m-2"
          type="is-success"
          @click="fileChanged()"
        >
          Importieren
        </b-button>
        <b-button
          v-if="file"
          class="m-2"
          type="is-danger"
          @click="file=undefined"
        >
          Abbrechen
        </b-button>
      </div>
      <div v-if="isLoading">
        Lädt...
      </div>

      <b-table
        :data="data"
        :columns="columns"
      />
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx/dist/xlsx.full.min";
import ParseWriter from "@/lib/import/ParseWriter";
import {mapGetters} from "vuex";
import UploadSection from "@/components/helperComponents/UplaodSection";

export default {
    name: "DataImport",
	components: {UploadSection},
	data() {
        return {
            file: undefined,
			isLoading: false,
			columns: [
				{
					field: 'task',
					label: 'Fehlermeldung',
				},
				{
					field: 'status',
					label: 'Status',
				},
			]
        };
    },
	computed:{
		...mapGetters({
			data: "getFailureData"
		})
	},
	mounted() {
this.$store.dispatch("setFailureData", [])
	},
	methods: {
        fileChanged() {
			this.$store.dispatch("setFailureData", [])
				const reader = new FileReader();

				reader.onload = async function (e) {
					const data = e.target.result;

					const workbook = XLSX.read(data, {
						type: "binary",
						cellDates: true,
					});
					const parseWriter = new ParseWriter(workbook)
					await parseWriter.writeSocialWorkers()
					await parseWriter.writeStatutoryBasis()
					await parseWriter.writeStatutoryRepresentatives()
					await parseWriter.writeDistricts()
					await parseWriter.writeGroups()
					await parseWriter.writeSchoolKindergartens()
					await parseWriter.writeClients()
					await parseWriter.writeUsers()
					this.isLoading = false
				};
				reader.readAsBinaryString(this.file);
				this.file = undefined
        },
    },
};
</script>

<style scoped>

::v-deep .media-content{
	background-color: black;
}
</style>
