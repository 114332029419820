<template>
  <div style="width: 100%">
    <BalanceComponent />
    <StockUp />
  </div>
</template>

<script>
import BalanceComponent from "@/components/balance/balanceGroup/BalanceComponent";
import StockUp from "@/components/balance/stockUp/StockUp";
export default {
	name: "BalanceOverview",
	components: {StockUp, BalanceComponent}
}
</script>

<style scoped>

</style>
