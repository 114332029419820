<template>
  <div>
    <div class="panel m-5 pb-3">
      <div
        class="panel-heading has-text-left"
        style="display: flex; justify-content: space-between"
      >
        <p>Datenexport</p>
      </div>
      <div class="panel m-5">
        <div
          class="panel-heading has-text-left"
          style="display: flex; justify-content: space-between"
        >
          <p>Stammdaten</p>
        </div>
        <div class="container pt-3 pb-3">
          <div
            class="bodyContainer"
            style="
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 0.5rem;
                        align-items: center;
                    "
          >
            <b-button
              size="is-large"
              icon-left="download"
              :loading="isLoading"
              @click="exportClientData()"
            >
              Stammdaten exportieren
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx/dist/xlsx.full.min";
import {
	getClientsData,
	getDistrictData,
	getGroupsData, getPersonData,
	getSchoolData, getSocialWorkerData,
	getStatutoryBasisData,
	getUsersData
} from "@/lib/DataExport";
import { positiveFeedBack } from "@/lib/Toast";
import { SHEET_NAMES } from "@/lib/constants/Constants";

export default {
	name: "DataExport",
	data() {
		return {
			isLoading: false, startDate: undefined, endDate: undefined, selectedGroup: undefined, groupFilter: undefined, balanceGroups: [],
		};
	},
	computed: {
		groupsArray() {
			return this.balanceGroups.filter(group => {
				const name = group.attributes.name.toLowerCase()
				const isFilterInName = this.groupFilter && name.includes(this.groupFilter.toLowerCase())
				if (!this.selectedGroup) return isFilterInName
				return (isFilterInName && this.selectedGroup.id !== group.id)
			})
		}
	},
	methods: {
		async exportClientData() {
			this.isLoading = true;
			const wb = XLSX.utils.book_new();

			wb.Props = {
				Title: "Stammdaten",
				CreatedDate: new Date(),
			};

			const { CLIENT_SHEET, USER_SHEET, GROUP_SHEET, SOCIAL_WORKER_SHEET, PERSON_SHEET,
				STATUTORY_BASIS_SHEET, DISTRICT_SHEET, SCHOOL_SHEET } = SHEET_NAMES

			wb.SheetNames.push(CLIENT_SHEET);
			wb.SheetNames.push(USER_SHEET);
			wb.SheetNames.push(SOCIAL_WORKER_SHEET);
			wb.SheetNames.push(PERSON_SHEET);
			wb.SheetNames.push(SCHOOL_SHEET);
			wb.SheetNames.push(GROUP_SHEET);
			wb.SheetNames.push(DISTRICT_SHEET);
			wb.SheetNames.push(STATUTORY_BASIS_SHEET);

			const statutoryBasisData = await getStatutoryBasisData()
			const statutoryBasisSheet = XLSX.utils.json_to_sheet(statutoryBasisData)
			wb.Sheets[STATUTORY_BASIS_SHEET] = statutoryBasisSheet

			const districtData = await getDistrictData()
			const districtSheet = XLSX.utils.json_to_sheet(districtData)
			wb.Sheets[DISTRICT_SHEET] = districtSheet

			const schoolData = await getSchoolData()
			const schoolSheet = XLSX.utils.json_to_sheet(schoolData)
			wb.Sheets[SCHOOL_SHEET] = schoolSheet

			const personData = await getPersonData()
			const personSheet = XLSX.utils.json_to_sheet(personData)
			wb.Sheets[PERSON_SHEET] = personSheet

			const socialWorkerData = await getSocialWorkerData()
			const socialSheet = XLSX.utils.json_to_sheet(socialWorkerData)
			wb.Sheets[SOCIAL_WORKER_SHEET] = socialSheet

			var groupsData = await getGroupsData();
			var groupsSheet = XLSX.utils.json_to_sheet(groupsData);
			wb.Sheets[GROUP_SHEET] = groupsSheet;

			var usersData = await getUsersData();
			var usersSheet = XLSX.utils.json_to_sheet(usersData);
			wb.Sheets[USER_SHEET] = usersSheet;

			var clientsData = await getClientsData();
			var clientsSheet = XLSX.utils.json_to_sheet(clientsData);
			wb.Sheets[CLIENT_SHEET] = clientsSheet;


			XLSX.writeFileXLSX(wb, "Stammdaten.xlsx", {
				type: "binary",
				cellDates: true,
			});

			positiveFeedBack("Daten exportiert!");

			this.isLoading = false;
		}
	},
};
</script>

<style scoped>
.borderContainer {
	border-radius: .5rem;
	padding: .5rem;
	margin: .5rem;
}
</style>
