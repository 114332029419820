<template>
  <b-modal
    v-model="isModalOpen"
    has-modal-card
    :can-cancel="false"
    @close="closeModal()"
  >
    <div
      class="modal-card modalCardContainer"
      style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
			background-color: rgba(30,30,30,1)"
    >
      <p
        class="panel-heading"
        style="text-align: center; background-color: rgba(30,30,30,1)!important; color: white; width: 100%"
      >
        Passwort zurücksetzen
      </p>
      <div
        class="is-centered"
        style="background-color: white"
      >
        <b-field
          label="E-Mail Adresse"
          class="p-3"
          :message="
            !$v.email.$invalid
              ? ''
              : 'Valide E-Mail Adresse eingeben!'
          "
          :type="
            !$v.email.$invalid
              ? 'is-success'
              : 'is-danger'
          "
        >
          <b-input v-model="email" />
        </b-field>
      </div>
      <div
        class="modal-card-foot buttons"
        style="display: flex; justify-content: center; background-color: rgba(30,30,30,1)"
      >
        <b-button
          type="is-danger"
          @click="closeModal()"
        >
          Passwort zurücksetzen
        </b-button>
        <b-button
          type="is-primary"
          @click="closeModal()"
        >
          Zurück
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Parse from "parse/dist/parse.min"
import {required} from "vuelidate/lib/validators";
import email from "vuelidate/lib/validators/email";
import {mapGetters} from "vuex";
export default {
	name: "PasswordResetModal",
	data(){
		return{
			email: undefined
		}
	},
	computed:{
		...mapGetters({
			isModalOpen: "getIsResetPasswordModalOpen"
		})
	},
	validations: {
		email: {
			required,
			email,
		},
	},
	methods:{
		resetPassword(){
			Parse.User.requestPasswordReset(this.email)
				.then(() => {
					// Password reset request was sent successfully
					this.closeModal()
				}).catch((error) => {
				// Show the error message somewhere
				alert("Error: " + error.code + " " + error.message);
			});

		},
		closeModal(){
			this.$store.dispatch("setIsPasswordResetModalOpen", false)
		}
	}
}
</script>

<style scoped>

</style>
