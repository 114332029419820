<template>
  <div id="app" ref="scrollToMe">
    <TopBar v-if="isUserLoggedIn" />
    <div class="columns">
      <SideBar
        v-if="isUserLoggedIn"
        class="column is-one-quarter has-text-left mt-5"
      />
      <MainBar class="column" />
    </div>
  </div>
</template>

<script>
/**
 * This file is the main app file injected in the html file containing a side panel with different tabs and a top bar
 * with logo and logout.
 */
import TopBar from "@/components/bars/Topbar";
import SideBar from "@/components/bars/Sidebar";
import MainBar from "@/components/bars/Mainbar";
import Parse from "parse/dist/parse.min";

export default {
	name: "App",
	components: { MainBar, TopBar, SideBar },
	mounted() {
		// log <-- error <-- warn <-- info <-- debug
		// wenig inklusive - sehr inklusiv
		//this.$log.apply({ level: 'error' })
	},
	computed: {
		isUserLoggedIn() {
			if (Parse.User.current()) return true;
			return false;
		},
	},
};
</script>

<style scoped>
</style>


<style lang="scss">
#app {
	text-align: center;
	color: #2c3e50;
	margin-top: 0;
}

.panel-heading {
	background-color: rgba(0, 0, 0, 0.8) !important;
	color: white !important;
}

.is-pulled-right {
	color: white;
}

div {
	user-select: none;
}

p {
	user-select: text;
}
</style>
