import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../lib/Errors';

let localSubscription = undefined;

// Client subscription
function createSubscription(store) {
	let clientQueryActive = new Parse.Query('Client');
	clientQueryActive.equalTo("inactive", false)
	clientQueryActive.notEqualTo("all_inactive", true)
	clientQueryActive.notEqualTo("deleted", true)
	clientQueryActive.limit(10000)

	let clientQueryAllInactive = new Parse.Query('Client');
	clientQueryAllInactive.notEqualTo("deleted", true)
	clientQueryAllInactive.equalTo("all_inactive", true)

	var mainClientQuery = Parse.Query.or(clientQueryActive, clientQueryAllInactive);

	mainClientQuery.find()
	.then((results) => {
		store.dispatch('clients/set', results)
	})
	.catch((error) => {
		handleParseError(error);
	});

	mainClientQuery.subscribe().then((subscription) => {
		localSubscription = subscription;

		subscription.on('create', (object) => {
			store.dispatch('clients/insert', object)
		});
		subscription.on('update', (object) => {
			store.dispatch('clients/replace', object)
		});
		subscription.on('enter', (object) => {
			store.dispatch('clients/insert', object)
		});
		subscription.on('leave', (object) => {
			store.dispatch('clients/delete', object)
		});
		subscription.on('delete', (object) => {
			store.dispatch('clients/delete', object)
		});
		subscription.on('close', () => {
		});
		subscription.on('error', error => {
            console.log('Clients subscription error', error);
        })
	})
}

export default function createClientSubscription() {
	return store => {
		if (store.state.currentUser) {
			createSubscription(store)
		}
		store.subscribe(mutation => {
			if (mutation.type === 'SET_CURRENT_USER') {
				if (localSubscription) {
					localSubscription.unsubscribe()
				}
				if (store.state.currentUser) {
					createSubscription(store)
				}
			}
		})
	}
}
