<template>
	<div>
		<div>
			<SearchableTable :panel-header="'Bewilligungstypen'" :panel-route="'AddLicenseKind'" :show-search-bar="false"
				:table-data="licenseKind" :table-meta-data="tableMetaData" :query-string="'setClientSearchQuery'"
				:event-value="parentEventValue">
				<b-table-column v-slot="props" label="Aktionen" cell-class="has-text-right truncate-column"
					header-class="th-right truncate-column">
					<b-button class="is-small mr-2" @click="triggerView(props.row)">
						<b-icon class="mt-1" icon="eye" style="cursor: pointer" />
					</b-button>
					<b-button class="is-small mr-2" @click="triggerEdit(props.row)">
						<b-icon class="mt-1" icon="pencil" style="cursor: pointer;" />
					</b-button>
					<b-button class="is-small" @click="triggerDelete(props.row)">
						<b-icon class="mt-1" icon="delete" style="cursor: pointer" />
					</b-button>
				</b-table-column>
			</SearchableTable>

			<b-modal v-model="isDeleteCheck">
				<div v-if="isDeleteCheck" class="panel m-5" style="background-color: white">
					<p class="panel-heading">
						Möchtest du
						"{{ chosenElement.attributes.name }}" wirklich
						löschen?
					</p>
					<div class="buttons mt-2" style="display: flex; justify-content: center">
						<b-button class="is-danger" :loading="isLoading" @click="submitDelete()">
							Ja
						</b-button>
						<b-button class="is-primary" @click="closeModal">
							Nein
						</b-button>
					</div>
				</div>
			</b-modal>
		</div>
	</div>
</template>

<script>
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import { mapGetters } from "vuex";
import SearchableTable from "../helperComponents/SearchableTable.vue";

export default {
	name: "LicenseKindTable",
	components: { SearchableTable },
	data() {
		return {
			parentEventValue: undefined,
			isDeleteCheck: false,
			chosenElement: {},
			isLoading: false,
			tableMetaData: [
				{ "id": 1, "type": "string", "label": "Bezeichner", "field": "name", "sortField": "attributes.name" },
				{ "id": 2, "type": "currency", "label": "Tagessatz", "symbol": "€", "field": "rate", "sortField": "attributes.rate" },
				{ "id": 3, "type": "string", "label": "Kürzel", "field": "hint", "sortField": "attributes.hint" },
			]
		};
	},
	computed: {
		...mapGetters({
			licenseKind: "licenseKind/getData"
		})
	},
	mounted() { },
	methods: {
		triggerEdit(data) {
			this.$router.push({ name: "AddLicenseKind", params: { data: data } });
		},
		triggerView(data) {
			this.$router.push({ name: "ViewLicenseKind", params: { data: data } });
		},
		triggerDelete(data) {
			this.chosenElement = data;
			this.isDeleteCheck = true;
		},
		closeModal() {
			this.isDeleteCheck = false;
		},
		async submitDelete() {
			this.isLoading = true
			this.chosenElement.set("deleted", true);
			this.chosenElement.save().catch((e) => {
				negativeFeedBack(this.chosenElement.attributes.name + " nicht gelöscht!")
			});
			await positiveFeedBack(this.chosenElement.attributes.name + " gelöscht!")
			this.isLoading = false
			this.isDeleteCheck = false
		},
	},
};

</script>

<style scoped></style>
