import {ToastProgrammatic as Toast} from "buefy";

/**
 * This file is for sending messages as toast
 * @param message
 */
export const positiveFeedBack = message =>{
    Toast.open({
        message: message,
        type: "is-success",
        position: "is-bottom",
    });
}

export const negativeFeedBack = (message, duration) =>{
    Toast.open({
        message: message,
        type: "is-danger",
        position: "is-bottom",
        duration: duration? duration: 2500,
        pauseOnHover: true
    });
}
