import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../lib/Errors';

let localSubscription = undefined;

// User subscription
function createSubscription(store) {
		let userQuery = new Parse.Query('UserMetaClass');
		userQuery.limit(10000)
		userQuery.notEqualTo("deleted", true)
			.find()
			.then((results) => {
				store.dispatch('usermetas/set', results)
			})
			.catch((error) => {
				handleParseError(error);
				
			});
		userQuery.subscribe().then((subscription) => {
			localSubscription = subscription;
			
			subscription.on('create', (object) => {
				store.dispatch('usermetas/insert', object)
			});
			subscription.on('update', (object) => {
				store.dispatch('usermetas/replace', object)
			});
			subscription.on('enter', (object) => {
				store.dispatch('usermetas/insert', object)
			});
			subscription.on('leave', (object) => {
				store.dispatch('usermetas/delete', object)
			});
			subscription.on('delete', (object) => {
				store.dispatch('usermetas/delete', object)
			});
			subscription.on('close', () => {
			});
			subscription.on('error', error => {
				console.log('UserMetaClass subscription error', error);
			})
		})
}

export default function createUserMetaSubscription() {
	return store => {
		if( store.state.currentUser) {
			createSubscription(store)
		}
		store.subscribe(mutation => {
			if (mutation.type === 'SET_CURRENT_USER' || mutation.type==="SET_SAVED_USER") {
				if (localSubscription) {
					localSubscription.unsubscribe()
				}
				if (store.state.currentUser) {
					createSubscription(store)
				}
			}
		})
	}
}
