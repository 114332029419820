<template>
  <div class="panel m-5">
    <PanelHeader
      :panel-header="categoryName"
      :hex-color="colors.hex"
    />

    <div class="p-2">
      <PdfTable :pdfs="clientFiles" />

      <section
        class="m-5 buttons"
        style="display: flex; justify-content: center"
      >
        <CancelButton
          back-route="/client_records"
          text="Zurück"
          :is-loading="isCancelLoading"
        />
      </section>
    </div>
  </div>
</template>

<script>
/**
 * This file is for viewing all pdf files in a category
 */
import PdfTable from "@/components/clientRecords/PdfTable";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import CancelButton from "@/components/helperComponents/CancelButton";
import {queryClientFilesByCategory} from "@/lib/Query";

export default {
	name: "ViewClientRecordCategory",
	components: {
		PdfTable,
		PanelHeader,
		CancelButton,
	},
	data(){
		return{
			categoryName: "",
			colorPickerOpen: false,
			isLoading: false,
			isCancelLoading: false,
			colors: {
				"hex": "#000000",
				"source": "hex"
			},
			pdfs: [],
			category: undefined,
			clientFile: undefined,
			clientFiles: [],
		}
	},
	async created(){
		if(!this.$route.params.data) return await this.$router.push({ path: "/client_records" });
		this.isCancelLoading = true
		this.category = this.$route.params.data
		this.categoryName = this.category.attributes.name
		this.colors = {
			"hex": this.category.attributes.color,
			"source": "hex"
		}
		this.clientFiles = await queryClientFilesByCategory(this.category)
		this.isCancelLoading = false
	}
}
</script>

<style scoped>

</style>
