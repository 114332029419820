import ExcelSheet from "@/lib/import/ExcelSheet";
import Parse from "parse/dist/parse.min"
import {negativeFeedBack, positiveFeedBack} from "@/lib/Toast";
import {GOAL_DAY_DICT} from "@/lib/constants/Constants";
import {
	queryClientsByNameAndBirthday,
	queryGroupByName,
	queryUserMetaClassIncludingDeletedByUser
} from "@/lib/Query";
import {v4 as uuid} from "uuid";
import store from "@/store/store";
import {
	addUserToAdmin,
	clientExists,
	clientWithGroupExists,
	editUser, getFirstNameLastNameType, getObjectFromDatabase,
	getUser
} from "@/lib/import/HelperFunctions";
import {destroyCreatedSession} from "@/lib/User";

const writeParseObjects = async (objects, objectName) =>{
	const savedObjects = []
	for(const object of objects){
		const objectFromDatabase = await getObjectFromDatabase(objectName, object)
		let parseObject = {}
		if(objectFromDatabase === undefined) {
			const ParseObject = Parse.Object.extend(objectName)
			parseObject = new ParseObject()
		}
		else{
			parseObject = objectFromDatabase
		}

		for(const key in object){
			let value = object[key]
			if(!(value instanceof Date) && value !== undefined && value !== null) value = value.toString().trim()
			parseObject.set(key, value)
		}
		await parseObject.save()
		savedObjects.push(parseObject)
	}
	return savedObjects
}

class ParseWriter extends ExcelSheet{
	constructor(workbook) {
		super(workbook);
		this.socialWorkers = []
		this.clients = []
		this.users = []
		this.statutoryRepresentatives = []
		this.districts = []
		this.groups = []
		this.schoolsKindergartens = []
		this.statutoryBasis = []
		this.clientsData = super.getClientsData()
		this.userMails = super.getUsersData().map(user => user.email)
	}

	getAdministrativeDistrictByName(name){
		if(this.districts.length === 0) return undefined
		const district = this.districts.find(district => district.attributes.name === name)
		if(!district && name && name.length > 0){
			store.dispatch("addFailureData", {task:`Landkreis ${name} nicht gefunden!`, status:"Fehler"})
			return negativeFeedBack(`Landkreis ${name} nicht gefunden!`)
		}
		return district
	}

	getStatutoryBasisByName(name){
		if(this.statutoryBasis.length === 0) return undefined
		const statutoryBasis = this.statutoryBasis.find(statutoryBasis => statutoryBasis.attributes.name === name)
		if(!statutoryBasis && name && name.length > 0) {
			store.dispatch("addFailureData", {task:`Gesetzliche Grundlage ${name} nicht gefunden!`, status:"Fehler"})
			return negativeFeedBack(`Gesetzliche Grundlage ${name} nicht gefunden!`)
		}
		return statutoryBasis
	}

	getSocialWorkerByFirstNameAndLastName(firstName, lastName){
		if(this.socialWorkers.length === 0) return undefined
		const socialWorker =this.socialWorkers.find(obj => obj.attributes.first_name === firstName &&  obj.attributes.last_name === lastName)
		if(!socialWorker && firstName && lastName && lastName.length> 0 && firstName.length > 0) {
			store.dispatch("addFailureData", {task:`Sozialarbeiter ${lastName}, ${firstName} nicht gefunden!`, status:"Fehler"})
			return negativeFeedBack(`Sozialarbeiter ${lastName}, ${firstName} nicht gefunden!`)
		}
		return socialWorker
	}

	getStatutoryRepresentativeByFirstNameLastNameAndType({firstName, lastName, type}){
		if(this.statutoryRepresentatives.length === 0) return undefined
		const person = this.statutoryRepresentatives.find(obj => obj.attributes.first_name === firstName
			&&  obj.attributes.last_name === lastName
			&& (obj.attributes.type === type || obj.attributes.type === undefined))
		if(!person && firstName && lastName &&type&&type.length>0&& lastName.length> 0 && firstName.length > 0) {
			store.dispatch("addFailureData", {task:`Bezugsperson ${lastName}, ${firstName}- ${type} nicht gefunden!`, status:"Fehler"})
			return negativeFeedBack(`Bezugsperson ${lastName}, ${firstName}- ${type} nicht gefunden!`)
		}
		return person
	}

	getSchoolByName(name){
		if(this.schoolsKindergartens.length === 0) return undefined
		const school = this.schoolsKindergartens.find(obj => obj.attributes.name === name)
		if(!school && name && name.length > 0) return negativeFeedBack(`Schule ${name} nicht gefunden!`)
		return school
	}

	async writeSocialWorkers(){
		const socialData = super.getSocialWorkerData();
		this.socialWorkers = await writeParseObjects(socialData, "Person")
		await positiveFeedBack("Sozialarbeiter erstellt")
	}

	async writeStatutoryBasis(){
		const statutoryBasicsData = super.getStatutoryBasisData();
		this.statutoryBasis = await writeParseObjects(statutoryBasicsData, "StatutoryBasis")
		await positiveFeedBack("Gesetzliche Grundlagen erstellt")
	}

	async writeSchoolKindergartens(){
		const schoolKindergartenData = super.getSchoolKindergartenData();
		this.schoolsKindergartens = await writeParseObjects(schoolKindergartenData, "Location")
		await positiveFeedBack("Schule/Kitas erstellt")
	}

	async writeDistricts(){
		const districtsData = super.getDistrictData();
		this.districts = await writeParseObjects(districtsData, "Location")
		await positiveFeedBack("Landkreise erstellt")
	}

	async writeGroups(){
		const groupsData = super.getGroupsData();
		this.groups = await writeParseObjects(groupsData, "Group")
		await positiveFeedBack("Gruppen erstellt")
	}

	async writeStatutoryRepresentatives(){
		const statutoryRepresentativesData = super.getStatutoryRepresentativesData();
		this.statutoryRepresentatives = await writeParseObjects(statutoryRepresentativesData, "Person")
		await positiveFeedBack("Gesetzliche Vertreter erstellt")
	}

	async writeUsers(){
		const usersData = super.getUsersData()
		for(const userObject of usersData){
			const isAdmin = userObject.isAdmin === "Ja" || userObject.isAdmin === "x"
			const {last_name,first_name,birth_date,	gender, email, group, street, plz, location, phone1, info1, phone2, info2} = userObject

			const contactClients = this.clientsData.filter(client => client.contactEducatorEmail === email)
			const clients = []
			for(const contactClient of contactClients){
				const client = await queryClientsByNameAndBirthday(contactClient.first_name, contactClient.last_name, contactClient.birth_date)
				clients.push(...client)
			}
			const clientIds = clients.map(client => client.id)
			const groupIds = clients.map(client => client.attributes.group.id)
			const groupParseObject = await queryGroupByName(group)
			if(groupParseObject && !groupIds.includes(groupParseObject.id)) groupIds.push(groupParseObject.id)
			const newUserParams = {
				email: email,first_name: first_name,
				family_name:  last_name,birth_date: birth_date,
				gender: gender,phone1: phone1,
				phone2: phone2,location: location,
				street: street,plz: plz,
				groupIds: groupIds,
				password: undefined,
				clientIds: clientIds,
				dropFile: undefined, type: "staff",uuid: uuid(),
				isAdmin: isAdmin,func: "Mitarbeiter",dropFileType: undefined,
				info1: info1, info2: info2
			}
			const sessionToken = Parse.User.current().getSessionToken()
			const user = await getUser(email.toLowerCase())
			const userMetaClass = user ? await queryUserMetaClassIncludingDeletedByUser(user) : undefined
			if(userMetaClass) {
				userMetaClass.set("deleted", false)
				await userMetaClass.save()
			}
			if(user) {
				await editUser(newUserParams, userMetaClass, user)
				continue
			}
			const savedUser = await Parse.Cloud.run("createUser", newUserParams)
			if(isAdmin) await addUserToAdmin(savedUser.user)
			await destroyCreatedSession(sessionToken, savedUser.user)
		}
		await positiveFeedBack("Mitarbeiter erstellt")
		await positiveFeedBack("Import abgeschlossen!")
	}

	createNewClient =async(clientParams) =>{
		const savedClient = await Parse.Cloud.run("createClient", clientParams)
		this.clients.push(savedClient)
	}

	editClient = async (clientParams) =>{
		const clients = await queryClientsByNameAndBirthday(clientParams.firstName, clientParams.lastName, clientParams.birthday)
		for(const client of clients){
			clientParams["clientId"] = client.id
			const savedClient = await Parse.Cloud.run("editClient", clientParams)
			this.clients.push(savedClient)
		}
	}

	async writeClients(){
		const clientsData = this.clientsData;
		let index = 1
		for(const clientObject of clientsData){
			index++
			const goal_day = GOAL_DAY_DICT[clientObject.goalDay]
			const group = await queryGroupByName(clientObject.group)
			if(!group) {
					await store.dispatch("addFailureData", {task:`Gruppenname ${name} aus Kliententabelle (Zeile ${index.toString()}) stimmt 
		mit keinem Gruppennamen aus der Gruppe überein- Abbruch Klientenupload!`, status:"Fehler"})
					await negativeFeedBack(`Gruppenname ${name} aus Kliententabelle stimmt 
		mit keinem Gruppennamen aus der Gruppe überein`, 5000)
				return
			}
			const {last_name, first_name, gender, birth_date, birth_location, administrative_district,
				statutory_basis, entry, license, exit, street, plz, location, phone1, info1, phone2, info2,statutoryRepresentative3,
				statutoryRepresentative1, custody1, statutoryRepresentative2, custody2, socialWorker, school, custody3,
				contactEducatorEmail} = clientObject

			if(!this.userMails.includes(contactEducatorEmail) && contactEducatorEmail && contactEducatorEmail.length>0) {
				await store.dispatch("addFailureData", {task:`Kontakterzieher mit der E-Mail ${contactEducatorEmail} nicht gefunden!`, status:"Fehler"})
				await negativeFeedBack(`Kontakterzieher mit der E-Mail ${contactEducatorEmail} nicht gefunden!`, 5000)
			}
			const administrativeDistrictId = this.getAdministrativeDistrictByName(administrative_district) ?
				this.getAdministrativeDistrictByName(administrative_district).id : undefined
			const statutoryBasisId = this.getStatutoryBasisByName(statutory_basis) ?
				this.getStatutoryBasisByName(statutory_basis).id : undefined
			const schoolId = this.getSchoolByName(school) ?
				this.getSchoolByName(school).id : undefined

			const social_worker_first_name =socialWorker && socialWorker.includes(",")? socialWorker.split(", ")[0] :""
			const social_worker_last_name =	socialWorker&& socialWorker.includes(",")? socialWorker.split(", ")[1] :""
			const socialWorkerId = this.getSocialWorkerByFirstNameAndLastName(social_worker_last_name, social_worker_first_name) ?
				this.getSocialWorkerByFirstNameAndLastName(social_worker_last_name, social_worker_first_name).id : undefined
			const personObject1 = getFirstNameLastNameType(statutoryRepresentative1)
			const person1 = statutoryRepresentative1 ?this.getStatutoryRepresentativeByFirstNameLastNameAndType(personObject1) : undefined
			const personObject2 = getFirstNameLastNameType(statutoryRepresentative2)
			const person2 = statutoryRepresentative2 ?this.getStatutoryRepresentativeByFirstNameLastNameAndType(personObject2) : undefined
			const personObject3 = getFirstNameLastNameType(statutoryRepresentative3)
			const person3 = statutoryRepresentative3 ?this.getStatutoryRepresentativeByFirstNameLastNameAndType(personObject3) : undefined
			const personId1 = person1 ? person1.id : undefined
			const personId2 = person2 ? person2.id : undefined
			const personId3 = person3 ? person3.id : undefined

			const clientParams =
				{clientId: undefined, firstName: first_name, lastName:last_name, birthday: birth_date,
					birth_location: birth_location,	groupId: group.id, gender: gender,
					administrativeDistrictId: administrativeDistrictId,
					statutoryBasisId: statutoryBasisId, entry: entry, license: license,
					exit: exit, phone1: phone1, phone2: phone2, plz: plz,
					location: location, street:street, uuid: uuid(),
					info1: info1, info2: info2,
					pictureId: uuid(),	dropFile: undefined, dropFileType: undefined,inactive: false, caseDataUuid: uuid(),
					goalDay: goal_day,schoolId: schoolId,socialWorkerId: socialWorkerId,personIds: [personId1, personId2, personId3], custodyArray: [custody1 === "Ja", custody2 === "Ja", custody3 === "Ja"]} // uuid

			const isClientExistent = await clientExists(clientObject)
			const isClientWithGroupExistent = await clientWithGroupExists(clientObject, group.id)

			if(!isClientExistent) await this.createNewClient(clientParams)
			if(isClientWithGroupExistent) await this.editClient(clientParams)
			if(isClientExistent && !isClientWithGroupExistent) { //only group is not matching
				const clients = await queryClientsByNameAndBirthday(clientObject.first_name, clientObject.last_name, clientObject.birth_date)
				if (clients[0]) {
					clientParams["uuid"] = clients[0].attributes.uuid
					await this.createNewClient(clientParams)
				}
			}
		}
		await positiveFeedBack("Klienten erstellt")
	}


}

export default ParseWriter
