<template>
  <div>
    <SearchableTable
      :panel-header="'Pflichtattribute'"
      :panel-route="'AddAttribute'"
      :panel-params="{ isOptional: false }"
      :show-search-bar="false"
      :table-data="attributes"
      :table-meta-data="tableMetaData"
      :query-string="'setUserSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerView(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="eye"
            style="cursor: pointer"
          />
        </b-button>
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>

    <SearchableTable
      :panel-header="'Optionale Attribute'"
      :panel-route="'AddAttribute'"
      :panel-params="{ isOptional: true }"
      :show-search-bar="false"
      :table-data="optionalAttributes"
      :table-meta-data="tableMetaData"
      :query-string="'setUserSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerView(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="eye"
            style="cursor: pointer"
          />
        </b-button>
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>

    <b-modal v-model="isErrorModal">
      <div
        v-if="isErrorModal"
        class="panel m-5"
        style="background-color: white"
      >
        <p class="panel-heading">
          Das Attribut "{{ chosenAttribute.attributes.name }}" enthält Ausprägungen!
          Möchtest du es mit allen Ausprägungen löschen?
        </p>
        <div
          class="buttons mt-2"
          style="display: flex; justify-content: center"
        >
          <b-button
            class="is-danger"
            :loading="isLoading"
            @click="submitDeleteCompleteAttribute()"
          >
            Ja
          </b-button>
          <b-button
            class="is-primary"
            @click="isErrorModal = false"
          >
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="isDeleteAttributeCheck">
      <div
        v-if="isDeleteAttributeCheck"
        class="panel m-5"
        style="background-color: white"
      >
        <p class="panel-heading">
          Möchtest du "{{ chosenAttribute.attributes.name }}" wirklich
          löschen?
        </p>
        <div
          class="buttons mt-2"
          style="display: flex; justify-content: center"
        >
          <b-button
            class="is-danger"
            :loading="isLoading"
            @click="submitDeleteAttribute()"
          >
            Ja
          </b-button>
          <b-button
            class="is-primary"
            @click="isDeleteAttributeCheck = false"
          >
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { queryAttributeCharacteristicsByConfigPointer } from "@/lib/Query";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import SearchableTable from "../../helperComponents/SearchableTable.vue";

export default {
	name: "EntryAttributes",
	components: { SearchableTable },
	data() {
		return {
			chosenAttribute: undefined,
			isDeleteAttributeCheck: false,
			isErrorModal: false,
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Attributname", "field": "name", "sortField": "attributes.name" },
				{ "id": 1, "type": "color", "label": "Farbe", "field": "color" }
			],
		}
	},
	computed: {
		...mapGetters({
			attributes: "getDiaryAttributes",
			optionalAttributes: "getDiaryOptionalAttributes",
		}),
	},
	methods: {
		triggerEdit(attribute) {
			this.$router.push({ name: "EditAttribute", params: { data: attribute } })
		},
		async areAttributesCharacteristicsDeleted() {
			const existentCharacteristics = await queryAttributeCharacteristicsByConfigPointer(this.chosenAttribute)
			return existentCharacteristics.length === 0
		},
		async triggerDelete(attribute) {
			this.chosenAttribute = attribute
			const attributeInUsage = await this.areAttributesCharacteristicsDeleted()
			if (attributeInUsage) {
				this.isDeleteAttributeCheck = true
			} else {
				this.isErrorModal = true
			}

		},
		async submitDeleteAttribute() {
			this.isLoading = true
			try {
				this.chosenAttribute.set("deleted", true)
				await this.chosenAttribute.save()
				positiveFeedBack("Attribut gelöscht!")
			} catch (e) {
				console.error("couldnt delete attribute " + e)
				negativeFeedBack("Attribut nicht gelöscht!")
			}
			this.isLoading = false
			this.isDeleteAttributeCheck = false
		},
		triggerView(attribute) {
			this.$router.push({ name: "ViewAttribute", params: { data: attribute } })
		},
		async submitDeleteCompleteAttribute() {
			this.isLoading = true
			const attributeCharacteristics = await queryAttributeCharacteristicsByConfigPointer(this.chosenAttribute.toPointer())
			for (const attributeCharacteristic of attributeCharacteristics) {
				attributeCharacteristic.set("deleted", true)
				try {
					await attributeCharacteristic.save()
				} catch (e) {
					console.error("couldnt delete characteristics in attribute " + e)
				}
			}
			this.chosenAttribute.set("deleted", true)
			try {
				await this.chosenAttribute.save()
				positiveFeedBack("Attribut gelöscht!")
			} catch (e) {
				negativeFeedBack("Attribut nicht gelöscht!")
				console.error("couldnt delete attribute " + e)
			}
			this.isLoading = false
			this.isErrorModal = false
		},
		async triggerOptionalState() {
			await this.$store.dispatch("setIsOptionalState", true)
			await this.$router.push("add_attribute")
		},
		async triggerNonOptionalState() {
			await this.$store.dispatch("setIsOptionalState", false)
			await this.$router.push("add_attribute")
		}
	}
}
</script>

<style scoped></style>