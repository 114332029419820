<template>
	<div>
		<DiagnoseTable />
	</div>
</template>
  
<script>
/**
 * This file shows all currently stored diagnoses and allows adding, editing, viewing and soft deleting diagnoses.
 */
import DiagnoseTable from "@/components/medication/diagnose/DiagnoseTable";
export default {
	name: "Diagnose",
	components: { DiagnoseTable },
	data() {
		return {};
	},
	computed: {},
	mounted() { },
	methods: {},
};
</script>
  
<style lang="scss" scoped></style>
  