import Parse from 'parse/dist/parse.min.js';

let localSubscription = undefined;

function createSubscription(store) {
	let dataQuery = new Parse.Query('MedicationLog');
	dataQuery.include("client", "instantMedication", "instantMedication.medication", "instantMedication.medication.medicationUnit", "medication", "medication.contactUser", "medication.contactUser.dependency", "medication.medication", "medication.medication.medicationUnit", "givingUser");
	dataQuery.descending("date");

	dataQuery.find()
		.then((results) => {
			store.dispatch('medicationLogEntries/set', results)
		})
	
	dataQuery.subscribe().then((subscription) => {
		
		localSubscription = subscription;

		subscription.on('create', object => {
			store.dispatch('medicationLogEntries/insert', object)
		});
		subscription.on('update', object => {
			store.dispatch('medicationLogEntries/replace', object)
		});
		subscription.on('enter', object => {
			store.dispatch('medicationLogEntries/insert', object)
		});
		subscription.on('leave', object => {
		
			store.dispatch('medicationLogEntries/delete', object)
		});
		subscription.on('delete', object => {
			
			store.dispatch('medicationLogEntries/delete', object)
		});
		subscription.on('close', () => {});
		subscription.on('error', error => {})
	})
}

export default function createMedicationLogEntriesSubscription() {
	return store => {
		if (store.state.currentUser) {
			createSubscription(store)
		}
		store.subscribe(mutation => {
			if (mutation.type === 'SET_CURRENT_USER') {
				if (localSubscription) {
					localSubscription.unsubscribe()
				}
				if (store.state.currentUser) {
					createSubscription(store)
				}
			}
		})
	}
}
