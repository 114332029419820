<template>
  <div>
    <div class="panel m-5">
      <p class="panel-heading">
        Eintragstyp
      </p>
      <div class="container pt-3 pb-3">
        <div class="columns">
          <div class="column">
            <b-field label="Eintragstyp">
              <p class="content">
                {{ entryTypeName }}
              </p>
            </b-field>
          </div>

          <div class="column">
            <b-field label="gewählte Farbe">
              <div
                class="container pt-3 pb-3"
                style="display: flex; justify-content: center"
              >
                <div
                  style="width:30px; height:30px; cursor:pointer; border-radius: 5px"
                  :style="{'background-color':colors.hex}"
                />
              </div>
            </b-field>
          </div>

          <div class="column">
            <b-field label="Standard">
              <p class="content">
                {{ isStandardEntryType ? "Standard" : "nicht Standard" }}
              </p>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <section class="m-5">
      <div
        class="buttons"
        style="display: flex; justify-content: center"
      >
        <b-button
          class="is-danger"
          tag="router-link"
          to="/diary"
        >
          Zurück
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
/**
 * This file is for viewing a focused entry type
 */
export default {
	name: "ViewEntryType",
	data() {
		return {
			entryTypeName: "",
			colors: {
				"hex": "#000000",
				"source": "hex"
			},
			isStandardEntryType: false,
		}
	},
	created() {
		const entryType = this.$route.params.data
		this.entryTypeName = entryType.attributes.name
		this.isStandardEntryType = entryType.attributes.isStandardEntryType
		this.colors["hex"] = entryType.attributes.color
	}
}
</script>

<style lang="scss" scoped>

</style>
