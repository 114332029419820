<template>
  <div v-if="idx===0">
    {{ column.label }}
  </div>
  <div v-else>
    <div
      v-if="columnObj.value"
      class="is-flex is-justify-content-center is-align-items-center"
    >
      <div
        style="cursor: pointer"
        @click="columnObj.value = undefined"
      >
        <b-icon
          icon="delete"
          color="black"
        />
      </div>
      <div>{{ columnObj.value.attributes.name }}</div>
    </div>
    <div v-else>
      <div>{{ column.label }}</div>
      <b-field
        v-if="selectedBalance"
        type="is-danger"
        message="Wähle ein Budget!"
      >
        <b-autocomplete
          placeholder="Budgets"
          :data="selectedBalance.attributes.budgets"
          icon="magnify"
          field="name"
          :clearable="true"
          :open-on-focus="true"
          @select="(option) => option? columnObj.value = option : columnObj.value = undefined"
        >
          <template #default="props">
            <div>{{ props.option.attributes.name }}</div>
          </template>
        </b-autocomplete>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
	name: "HeaderSelection",
	props: ["idx", "columnObj", "column", "selectedBalance"]
}
</script>

<style scoped>

</style>