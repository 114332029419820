<template>
	<div>
		<div class="panel m-5">
			<PanelHeader :panel-header="panelHeader" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<InputWithValidation class="column" message="Bitte geben Sie einen Bezeichner ein!" :name="diagnoseName"
						:set-name="(name) => (diagnoseName = name)" label="Bezeichner der Diagnose" />
				</div>
			</div>
		</div>
		<div class="m-5 buttons" style="display: flex; justify-content: center">
			<SaveButton :is-loading="isLoading" :save-function="saveDiagnose" :disabled="$v.diagnoseName.$invalid" />
			<CancelButton text="Abbrechen" back-route="/diagnose" :is-loading="false" />
		</div>
	</div>
</template>
  
<script>
/**
 * This file is for creating and editing diagnoses.
 */
import Parse from "parse/dist/parse.min.js";
import { minLength, required } from "vuelidate/lib/validators";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";

export default {
	name: "NewDiagnose",
	components: {
		SaveButton,
		CancelButton,
		InputWithValidation,
		PanelHeader,
	},
	data() {
		return {
			diagnose: undefined,
			isLoading: false,
			diagnoseName: "",
			panelHeader: "Neue Diagnose anlegen"
		};
	},
	computed: {},
	watch: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.diagnose = this.$route.params.data
			this.diagnoseName = this.diagnose.attributes.name
			this.panelHeader = "Diagnose bearbeiten"
		}
	},
	validations: {
		diagnoseName: {
			required,
			minLength: minLength(1),
		}
	},
	methods: {
		async saveDiagnose() {
			this.isLoading = true;
			const Diagnose = Parse.Object.extend("MedicationDiagnosis")
			const diagnose = this.diagnose ? this.diagnose : new Diagnose()
			diagnose.set("name", this.diagnoseName)
			diagnose.set("deleted", false)
			await diagnose.save().catch((e) => {
				negativeFeedBack("Diagnose nicht gespeichert!")
			});
			this.isLoading = false;
			await this.$router.push({ path: "/diagnose" })
			await positiveFeedBack("Diagnose gespeichert!")
		},
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		},
	}
};
</script>
  
<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
  