import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../lib/Errors';
import { router } from '../../lib/router';

let localSubscription = undefined;

// Entry subscription
function createSubscription(store) {
	let widgetQuery = new Parse.Query('Widget');
	widgetQuery.equalTo("isGlobal", true)
	widgetQuery.find()
		.then((results) => {
			store.dispatch('widgets/set', results)
		})
		.catch((error) => {
			handleParseError(error);
			
		});
		widgetQuery.subscribe().then((subscription) => {
		localSubscription = subscription;

		subscription.on('create', object => {
			store.dispatch('widgets/insert', object)
		});
		subscription.on('update', object => {
			store.dispatch('widgets/replace', object)
		});
		subscription.on('enter', object => {
			store.dispatch('widgets/insert', object)
		});
		subscription.on('leave', object => {
			store.dispatch('widgets/delete', object)
		});
		subscription.on('delete', object => {
			store.dispatch('widgets/delete', object)
		});
		subscription.on('Widget', () => {
		});
		subscription.on('error', error => {
			console.log('Widget subscription error', error);
		})
	})
}

export default function createWidgetSubscription() {
	return store => {
		store.subscribe(mutation => {
			//1: Check - Wird die richtige Mutation ausgelöst? Diese wird im Router bei jedem Routing-Vorgang ausgelöst.
			if (mutation.type === 'ADD_LAST_ROUTE') {
				//2: Check - Bin ich aktuell bei den Widgets?
				if (router.currentRoute.name == "WidgetOverview" || router.currentRoute.name == "NewWidget" || 
					router.currentRoute.name == "EditWidget") {
					//3: Check - Prüfe, ob bereits eine lokale Subscription vorliegt.
					if (!localSubscription) {
						createSubscription(store)
					}
				}
			}
		})
	}
}