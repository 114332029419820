<template>
	<b-field :message="!$v.name.$invalid
			? ''
			: message
		" :type="!$v.name.$invalid
			? 'is-success'
			: 'is-danger'
		">
		<template #label>
			<div class="align-content-center" style="display: inline-flex">
				<!--				<b-icon icon="format-text" class="pr-2"></b-icon>-->
				<p>{{ label }}</p>
			</div>
		</template>
		<b-input v-model="nameInput" class="m-2 has-text-left" :placeholder="label" :type="type ? type : 'text'"
			autocomplete="one-time-code" :password-reveal="type === 'password'" @input="set()" />
	</b-field>
</template>

<script>
import { minLength, required, sameAs, minValue } from "vuelidate/lib/validators";
/**
 *  This file provides an input for setting a String with additional validation (indicator message and red outline if user input is inappropriate)
 *  @param: name:String for setted string, setName: function for setting the name, label: String description for the input,
 *  message: String indicator message showing inappropriate input,optional type: String indicating whether its a password, or just text,
 *  optional: isNewPassword: String for a new password (confirm password with two inputs -> see NewUser.vue as example),
 *  optional passwordValidation: String of repeated password (confirm password with two inputs -> see NewUser.vue as example),
 *
 */


export default {
	name: "InputWithValidation",
	props: [
		"message", "label", "setName", "name", "type", "passwordValidation", "isNewPassword"
	],
	validations() {
		if (this.isNewPassword) {
			return { name: { sameAsPassword: required, minLength: minLength(6) } }
		}
		if (this.passwordValidation) {
			return { name: { sameAsPassword: sameAs("passwordValidation") }, passwordValidation: required, minLength: minLength(6) }
		}
		else if (this.type === "password") {
			return { name: { minLength: minLength(6) } }
		}
		else if (this.type === "number") {
			return { name: { required, minValue: minValue(0) } }
		}
		else if (this.type === "currency") {
			return {
				name: {
					required,
					validator: function (value) {
						let regexReturn = value.match(/((^(0,\d{2})$)|(^([1-9][0-9]*(,\d{2})?)$))/g) ? true : false
						return (value == "0") || regexReturn
					}
				}
			}
		}
		else {
			return { name: { required } }
		}
	},
	data() {
		return {
			nameInput: this.name
		}
	},
	updated() {
		this.nameInput = this.name
	},
	methods: {
		set() {
			this.setName(this.nameInput)
			return this.$v.name.$touch
		}
	}
}
</script>

<style scoped></style>
