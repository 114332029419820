<template>
  <b-modal v-model="isDeleteCheck">
    <div
      v-if="isDeleteCheck"
      class="panel m-5"
      style="background-color: white"
    >
      <p
        class="panel-heading"
        style="text-align: center"
      >
        Möchtest du
        {{ item }} wirklich
        löschen?
      </p>
      <div
        class="buttons mt-2"
        style="display: flex; justify-content: center"
      >
        <b-button
          class="is-danger"
          :loading="isLoading"
          @click="submit()"
        >
          Ja
        </b-button>
        <b-button
          class="is-primary"
          @click="cancel"
        >
          Nein
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
/**
 * This file is a Modal warning before deleting an object
 * @param: isDeleteCheck: boolean for showing modal, item: String what would be deleted, submit: Function for submitting,
 * cancel: Function for cancelling, isLoading: boolean indicating loading process after submitting
 */
export default {
	name: "ModalComponent",
	props:["isDeleteCheck","item", "submit", "cancel", "isLoading"]
}
</script>

<style scoped>

</style>
