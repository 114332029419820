<template>
	<div>
		<div v-if="dataObject" class="panel m-5">
			<PanelHeader panel-header="Medikament" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<div class="column">
						<b-field label="Bezeichner">
							<p class="content">
								{{ medicationName }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Wirkstoff">
							<p class="content">
								{{ medicationDrug }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Stärke">
							<p class="content">
								{{ medicationPotency }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Klasse">
							<p class="content">
								{{ medicationClass.attributes.name }}
							</p>
						</b-field>
					</div>
				</div>
				<hr>
				<div class="columns">
					<div class="column">
						<b-field label="Anzahl/(Füll-)Menge">
							<p class="content">
								{{ medicationMax }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Darreichungsform (Einheit)">
							<p class="content">
								{{ medicationUnit.attributes.name + " (" + medicationUnit.attributes.symbol + ")" }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Darreichungsform (Art)">
							<p class="content">
								{{ medicationForm.attributes.name }}
							</p>
						</b-field>
					</div>
				</div>
				<hr>
				<div class="columns">
					<div class="column">
						<b-field label="Hinweise">
							<p class="content">
								{{ medicationHint }}
							</p>
						</b-field>
					</div>
				</div>
				<hr>
				<div class="columns">
					<div class="column">
						<b-field label="Anzeigename">
							<p class="content">
								{{ medicationDisplayName }}
							</p>
						</b-field>
					</div>
				</div>
			</div>
		</div>
		<div class="m-5 buttons" style="display: flex; justify-content: center">
			<CancelButton text="Zurück" back-route="/medication" :is-loading="false" />
		</div>
	</div>
</template>
  
<script>
/**
 * This file is for viewing a focused medication.
 */

import PanelHeader from "@/components/helperComponents/PanelHeader";
import CancelButton from "@/components/helperComponents/CancelButton";

export default {
	name: "ViewMedication",
	components: {
		PanelHeader,
		CancelButton,
	},
	data() {
		return {
			dataObject: undefined,
			medicationName: "",
			medicationDrug: "",
			medicationPotency: "",
			medicationMax: "",
			medicationForm: undefined,
			medicationUnit: undefined,
			medicationHint: "",
			medicationClass: undefined,
			medicationDisplayName: ""
		};
	},
	computed: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.dataObject = this.$route.params.data
			this.medicationName = this.dataObject.attributes.name
			this.medicationDrug = this.dataObject.attributes.drug
			this.medicationPotency = this.dataObject.attributes.potency
			this.medicationMax = this.dataObject.attributes.max
			this.medicationForm = this.dataObject.attributes.medicationForm
			this.medicationUnit = this.dataObject.attributes.medicationUnit
			this.medicationHint = this.dataObject.attributes.hint
			this.medicationClass = this.dataObject.attributes.medicationClass
			this.medicationDisplayName = this.dataObject.attributes.displayName
		}
	},
	methods: {
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		},
	},
}
</script>
  
<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
  