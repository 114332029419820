<template>
  <div class="panel m-5">
    <PanelHeader :panel-header="person ? getName(person) : 'Person'" />
    <div class="container pt-3 pb-3">
      <div class="columns">
        <b-field
          v-if="person"
          class="column"
          label="Vorname"
        >
          <p>{{ person.attributes.first_name }}</p>
        </b-field>
        <b-field
          v-if="person"
          class="column"
          label="Nachname"
        >
          <p>{{ person.attributes.last_name }}</p>
        </b-field>
        <b-field
          v-if="person"
          class="column"
          label="Email"
        >
          <p>{{ person.attributes.email }}</p>
        </b-field>
        <b-field
          v-if="person"
          class="column"
          label="Typ"
        >
          <p>{{ person.attributes.type }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
        <b-field
          v-if="person"
          class="column"
          label="Telefon 1"
        >
          <p>{{ person.attributes.phone1 }}</p>
        </b-field>
        <b-field
          v-if="person"
          class="column"
          label="Info 1"
        >
          <p>{{ person.attributes.info1 }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
        <b-field
          v-if="person"
          class="column"
          label="Telefon 2"
        >
          <p>{{ person.attributes.phone2 }}</p>
        </b-field>
        <b-field
          v-if="person"
          class="column"
          label="Info 2"
        >
          <p>{{ person.attributes.info2 }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
		<b-field
          v-if="person"
          class="column"
          label="Straße"
        >
          <p>{{ person.attributes.street }}</p>
        </b-field>
        <b-field
          v-if="person"
          class="column"
          label="PLZ"
        >
          <p>{{ person.attributes.plz }}</p>
        </b-field>
        <b-field
          v-if="person"
          class="column"
          label="Ort"
        >
          <p>{{ person.attributes.location }}</p>
        </b-field>
      </div>
      <hr>
      <CancelButton
        text="Zurück"
        back-route="/persons"
      />
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/helperComponents/PanelHeader";
import CancelButton from "@/components/helperComponents/CancelButton";
export default {
	name: "ViewPerson",
	components: {CancelButton, PanelHeader},
	data(){
		return{
			person: undefined
		}
	},
	async mounted() {
		if (!this.$route.params.data) return await this.$router.push({path: "/person"})
		this.person = this.$route.params.data;
	},
	methods:{
		getName(person){
			return `${person.attributes.first_name} ${person.attributes.last_name}`
		}
	}
}
</script>

<style scoped>
p{
	text-align: center;
}
</style>
