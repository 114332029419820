<template>
	<b-field class="borderContainer">
		<div class="is-flex is-flex-direction-column">
			<div v-for="inputObject in inputList" :key="inputObject.id" class="is-flex pb-2 is-justify-content-center">
				<div class="is-flex is-align-items-center is-justify-content-center">
					<div style="background-color: rgba(0,0,0,0); padding: .25rem; cursor: pointer"
						class="is-flex is-align-items-center is-align-self-stretch mt-3" @click="() => filterInput(inputObject.id)">
						<b-icon icon="delete" type="" />
					</div>

					<InputWithValidation message="Bitte geben Sie einen Kontonamen ein!"
						:name="inputObject.name" :set-name="(val) => (inputObject.name = val)" label="Konto" class="is-align-self-stretch"
						placeholder="Konto" />

					<InputWithValidation style="margin-bottom: 0.75rem;" message="Bitte geben Sie ein Kürzel ein!" class="is-align-self-stretch" :name="inputObject.token"
						:set-name="(val) => (inputObject.token = val)" label="Kürzel" placeholder="Kürzel" />
				</div>
			</div>

			<div style="background-color: rgba(0,0,0,0); padding: .25rem; cursor: pointer" @click="(e) => addInput(e)">
				<b-icon icon="plus" />
			</div>
		</div>
	</b-field>
</template>

<script>
import { v4 as uuid } from "uuid"
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
export default {
	name: "AccountList",
	components: {
		InputWithValidation
	},
	props: ["label", "inputList"],
	methods: {
		addInput(e) {
			e.preventDefault();
			this.inputList.push({
				id: uuid(),
				name: "",
				token: "",
			});
		},
		filterInput(id) {
			this.$emit("filter", id);
		},
	}
}
</script>

<style scoped>
.borderContainer {
	border-radius: .5rem;
	padding: .5rem;
	margin: .5rem;
}

::v-deep .label {
	font-size: large;
}
</style>
