<template>
  <div>
    <div class="panel m-5">
      <PanelHeader panel-header="Neuer Bildungsbereich" />
      <div class="container pt-3 pb-3">
        <div class="columns">
          <InputWithValidation
            class="column"
            message="Bitte gib einen Namen ein!"
            :name="name"
            :set-name="(val) => (name = val)"
            label="Bildungsbereich"
          />
          <ColorPicker
            class="column"
            :colors="colors"
            :set-colors="(val ) => colors = val"
          />
        </div>
      </div>
      <hr>
      <div
        class="m-5 buttons"
        style="display: flex; justify-content: center"
      >
        <SaveButton
          :is-loading="isLoading"
          :save-function="save"
          :disabled="false"
        />
        <CancelButton
          text="Abbrechen"
          back-route="/education_fields"
          :is-loading="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/helperComponents/PanelHeader";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import SaveButton from "@/components/helperComponents/SaveButton";
import CancelButton from "@/components/helperComponents/CancelButton";
import Parse from "parse/dist/parse.min.js"
import ColorPicker from "@/components/helperComponents/ColorPicker";

export default {
	name: "NewEducationField",
	components: {
		ColorPicker,
		CancelButton,
		SaveButton,
		InputWithValidation,
		PanelHeader
	},
	data() {
		return {
			name: "",
			isLoading: false,
			educationField: undefined,
			colors: {
				"hex": "#000000",
				"source": "hex"
			},
		}
	},
	created() {
		if(!this.$route.params.educationField) return
		this.educationField = this.$route.params.educationField
		this.name = this.educationField.attributes.name
		this.colors = {
			"hex": this.educationField.attributes.color,
			"source": "hex"
		}
	},
	methods: {
		async save() {
			const EducationField = new Parse.Object.extend("EducationField")
			const educationField = this.educationField ? this.educationField : new EducationField()
			educationField.set("name", this.name)
			educationField.set("color", this.colors.hex)
			await educationField.save();
			await this.$router.push({name:"EducationFields"})
		},
	},
}
</script>

<style scoped>
</style>
