import Parse from "parse/dist/parse.min.js"

/**
 * This function clones a parse object
 * @param object
 * @returns {*}
 */
export const cloneObject = object =>{
    const ObjectClass = Parse.Object.extend(object.className)
    const clonedObject = new ObjectClass()
    for(const key in object.attributes){
        clonedObject.set(key, object.attributes[key])
    }
    return clonedObject
}
