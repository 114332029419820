import VueRouter from 'vue-router'
import 'buefy/dist/buefy.css'
import Parse from 'parse/dist/parse.min.js';
import InfoComponent from "../../components/home/InfoComponent.vue";
import UsersComponent from "../../components/user/UsersComponent.vue";
import Clients from "../../components/clients/Clients.vue";
import GroupsComponent from "../../components/group/GroupsComponent.vue";
import LoginComponent from "../../components/authentication/LoginComponent";
import ExternalComponent from "../../components/externals/ExternalComponent";
import NewClient from "../../components/clients/NewClient";
import NewUser from "../../components/user/NewUser";
import NewExternal from "../../components/externals/NewExternal";
import ViewClient from "../../components/clients/ViewClient";
import ViewUser from "../../components/user/ViewUser";
import EditExternal from "../../components/externals/EditExternal";
import ViewExternal from "../../components/externals/ViewExternal";
import NewGroup from "../../components/group/NewGroup";
import ViewGroup from "../../components/group/ViewGroup";
import NewAttribute from "../../components/diary/entryAttributes/NewAttribute";
import EditAttribute from "../../components/diary/entryAttributes/EditAttribute";
import NewEntryType from "../../components/diary/entryTypes/NewEntryType";
import EditEntryType from "../../components/diary/entryTypes/EditEntryType";
import ViewEntryType from "../../components/diary/entryTypes/ViewEntryType";
import ViewAttribute from "../../components/diary/entryAttributes/ViewAttribute";
import LogoutComponent from "../../components/authentication/LogoutComponent";
import ClientRecords from "@/components/clientRecords/home/ClientRecords";
import NewClientRecordCategory from "@/components/clientRecords/add/NewClientRecordCategory";
import EditClientRecordCategory from "@/components/clientRecords/EditClientRecordCategory";
import ViewClientRecordCategory from "@/components/clientRecords/ViewClientRecordCategory";
import FocusedClientCategory from "@/components/clientRecords/FocusedClientCategory";
import DataImport from "@/components/dataimport/DataImport";
import DataExport from "@/components/dataexport/DataExport";
import DistrictsComponent from "@/components/districts/DistrictsComponent";
import NewDistrict from "@/components/districts/NewDistrict";
import ViewDistrict from "@/components/districts/ViewDistrict";
import ViewSchool from "@/components/school/ViewSchool";
import NewSchool from "@/components/school/NewSchool";
import SchoolsComponent from "@/components/school/SchoolsComponent";
import StatutoryBasis from "@/components/statutoryBasis/StatutoryBasis";
import NewStatutoryBasis from "@/components/statutoryBasis/NewStatutoryBasis";
import SocialWorkers from "@/components/socialWorker/SocialWorkers";
import NewSocialWorker from "@/components/socialWorker/NewSocialWorker";
import ViewSocialWorker from "@/components/socialWorker/ViewSocialWorker";
import Persons from "@/components/persons/Persons";
import NewPerson from "@/components/persons/NewPerson";
import ViewPerson from "@/components/persons/ViewPerson";
import ImprintComponent from "@/components/about/Imprint";
import DataProtection from "@/components/about/DataProtection";
import ReportTemplates from "@/components/reportTemplates/ReportTemplates.vue";
import AddReportTemplate from "@/components/reportTemplates/AddReportTemplate.vue";
import DiaryComponent from "@/components/diary/DiaryComponent";
import ClientRecordsUpload from "@/components/clientRecords/upload/ClientRecordsUpload";
import NewBalanceGroup from "@/components/balance/balanceGroup/NewBalanceGroup";
import ViewBalanceGroup from "@/components/balance/balanceGroup/ViewBalanceGroup";
import EducationFields from "@/components/weeklySchedule/EducationFields";
import NewEducationField from "@/components/weeklySchedule/NewEducationField";
import BalanceOverview from "@/components/balance/BalanceOverview";
import WidgetOverview from "@/components/widget/WidgetOverview";
import AddWidget from "@/components/widget/AddWidget";
import EditWidget from "@/components/widget/EditWidget";
import AnAbwesenheit from "@/components/anwesenheit/AnAbwesenheit";
import NewAnwesenheit from "@/components/anwesenheit/anwesenheitsgrund/NewAnwesenheit";
import ViewAnwesenheit from "@/components/anwesenheit/anwesenheitsgrund/ViewAnwesenheit";
import NewAbwesenheit from "@/components/anwesenheit/abwesenheitsgrund/NewAbwesenheit";
import ViewAbwesenheit from "@/components/anwesenheit/abwesenheitsgrund/ViewAbwesenheit";
import LicenseKind from "@/components/licenseKind/LicenseKind";
import NewLicenseKind from "@/components/licenseKind/NewLicenseKind";
import ViewLicenseKind from "@/components/licenseKind/ViewLicenseKind";

import store from '../../store/store';
import Diagnose from "@/components/medication/diagnose/Diagnose"
import NewDiagnose from "@/components/medication/diagnose/NewDiagnose"
import ViewDiagnose from "@/components/medication/diagnose/ViewDiagnose"
import MedicationForm from "@/components/medication/medication_form/MedicationForm"
import NewMedicationForm from "@/components/medication/medication_form/NewMedicationForm"
import ViewMedicationForm from "@/components/medication/medication_form/ViewMedicationForm"
import MedicationUnit from "@/components/medication/medication_unit/MedicationUnit"
import NewMedicationUnit from "@/components/medication/medication_unit/NewMedicationUnit"
import ViewMedicationUnit from "@/components/medication/medication_unit/ViewMedicationUnit"
import ICD10 from "@/components/medication/icd_10/ICD10"
import NewICD10 from "@/components/medication/icd_10/NewICD10"
import ViewICD10 from "@/components/medication/icd_10/ViewICD10"
import Doctor from "@/components/medication/doctor/Doctor"
import NewDoctor from "@/components/medication/doctor/NewDoctor"
import ViewDoctor from "@/components/medication/doctor/ViewDoctor"
import Medication from "@/components/medication/medication/Medication"
import NewMedication from "@/components/medication/medication/NewMedication"
import ViewMedication from "@/components/medication/medication/ViewMedication"
import InstantMedication from "@/components/medication/instant_medication/InstantMedication"
import NewInstantMedication from "@/components/medication/instant_medication/NewInstantMedication"
import ViewInstantMedication from "@/components/medication/instant_medication/ViewInstantMedication"
/**
 * router components, all possible route directions for the app
 * @type {VueRouter}
 */

export const router = new VueRouter({
	mode: 'history',
	base: __dirname,
	routes: [
		{
			path: '/users', component: UsersComponent, name: "Users", meta: {
				requiresAuth: true
			}
		}, // No props, no nothing
		{
			path: '/', component: InfoComponent, name: "Info", meta: {
				requiresAuth: true
			}
		}, // Pass route.params to props
		{
			path: '/clients', name: "Clients", component: Clients, meta: {
				requiresAuth: true
			}
		},
		{
			path: '/groups', name: 'Groups', component: GroupsComponent, meta: {
				requiresAuth: true
			}
		},
		{
			path: '/externals', name: 'Externals', component: ExternalComponent, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/diary", name: "diary", component: DiaryComponent, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_client", name: "AddClient", component: NewClient, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_user", name: "AddUser", component: NewUser, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_external", name: 'AddExternal', component: NewExternal, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_client", name: "ViewClient", component: ViewClient, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_user", name: "ViewUser", component: ViewUser, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/edit_external", name: "EditExternal", component: EditExternal, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_external", name: "ViewExternal", component: ViewExternal, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_group", name: "AddGroup", component: NewGroup, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_group", name: "ViewGroup", component: ViewGroup, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_attribute:isOptional", name: "AddAttribute", component: NewAttribute, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_entry_type", name: "AddEntryType", component: NewEntryType, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/edit_attribute", name: "EditAttribute", component: EditAttribute, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/edit_entry_type", name: "EditEntryType", component: EditEntryType, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_entry_type", name: "ViewEntryType", component: ViewEntryType, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_attribute", name: "ViewAttribute", component: ViewAttribute, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/log_out", name: "Logout", component: LogoutComponent, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/client_records", name: "ClientRecords", component: ClientRecords, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_client_category", name: "AddClientRecords", component: NewClientRecordCategory, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/edit_client_category", name: "EditClientRecords", component: EditClientRecordCategory, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_client_category", name: "ViewClientRecords", component: ViewClientRecordCategory, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/edit_focused_client_category", name: "EditFocusedClientRecords", component: FocusedClientCategory, meta: {
				requiresAuth: true, requiresFocus: true
			}
		},
		{
			path: "/data_import", name: "DataImport", component: DataImport, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/data_export", name: "DataExport", component: DataExport, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/districts", name: "Districts", component: DistrictsComponent, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_district", name: "AddDistrict", component: NewDistrict, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_district", name: "ViewDistrict", component: ViewDistrict, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_school", name: "AddSchool", component: NewSchool, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/schools", name: "Schools", component: SchoolsComponent, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_school", name: "ViewSchool", component: ViewSchool, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/statutoryBasis", name: "StatutoryBasis", component: StatutoryBasis, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_statutory_basis", name: "AddStatutoryBasis", component: NewStatutoryBasis, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/socialWorker", name: "SocialWorkers", component: SocialWorkers, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_social_worker", name: "AddSocialWorker", component: NewSocialWorker, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_social_worker", name: "ViewSocialWorker", component: ViewSocialWorker, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/persons", name: "Persons", component: Persons, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/add_person", name: "AddPerson", component: NewPerson, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_person", name: "ViewPerson", component: ViewPerson, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/dataprotection", name: "DataProtection", component: DataProtection
		},
		{
			path: "/imprint", name: "Imprint", component: ImprintComponent
		},
		{
			path: "/widgets", name: "WidgetOverview", component: WidgetOverview
		},
		{
			path: "/widgets_add", name: "NewWidget", component: AddWidget
		},
		{
			path: "/widgets_edit", name: "EditWidget", component: EditWidget
		}, {
			path: "/report_templates", name: "ReportTemplates", component: ReportTemplates
		}, {
			path: "/add_report_template", name: "AddReportTemplate", component: AddReportTemplate
		},
		{
			path: "/edit_pdf/:id", name: "EditPdf", component: ClientRecordsUpload
		},
		{
			path: "/balance_overview", name: "BalanceOverview", component: BalanceOverview
		},
		{
			path: "/add_balance_group", name: "AddBalanceGroup", component: NewBalanceGroup
		},
		{
			path: "/view_balance_group", name: "ViewBalanceGroup", component: ViewBalanceGroup
		},
		{
			path: "/education_fields", name: "EducationFields", component: EducationFields
		},
		{
			path: "/add_education_field", name: "AddEducationField", component: NewEducationField
		},
		{
			path: "/diagnose", name: "Diagnose", component: Diagnose
		},
		{
			path: "/add_diagnose", name: "AddDiagnose", component: NewDiagnose, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_diagnose", name: "ViewDiagnose", component: ViewDiagnose, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/medication_form", name: "MedicationForm", component: MedicationForm
		},
		/*{
			 path: "/add_medication_form", name: "AddNewMedicationForm", component: NewMedicationForm, meta: {
				requiresAuth: true
			} 
		},*/
		{
			path: "/view_medication_form", name: "ViewMedicationForm", component: ViewMedicationForm, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/medication_unit", name: "MedicationUnit", component: MedicationUnit
		},
		/*{
			 path: "/add_medication_unit", name: "AddNewMedicationUnit", component: NewMedicationUnit, meta: {
				requiresAuth: true
			} 
		},*/
		{
			path: "/view_medication_unit", name: "ViewMedicationUnit", component: ViewMedicationUnit, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/icd_10", name: "ICD10", component: ICD10
		},
		/*{
			path: "/add_icd_10", name: "AddICD10", component: NewICD10, meta: {
				requiresAuth: true
			} 
		}, */
		{
			path: "/view_icd_10", name: "ViewICD10", component: ViewICD10, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/doctor", name: "Doctor", component: Doctor
		},
		{
			path: "/add_doctor", name: "AddDoctor", component: NewDoctor, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_doctor", name: "ViewDoctor", component: ViewDoctor, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/medication", name: "Medication", component: Medication
		},
		{
			path: "/add_medication", name: "AddMedication", component: NewMedication, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_medication", name: "ViewMedication", component: ViewMedication, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/instant_medication", name: "InstantMedication", component: InstantMedication
		},
		{
			path: "/add_instant_medication", name: "AddInstantMedication", component: NewInstantMedication, meta: {
				requiresAuth: true
			}
		},
		{
			path: "/view_instant_medication", name: "ViewInstantMedication", component: ViewInstantMedication, meta: {
				requiresAuth: true
			}
		},
		/*
		{
			path: "/anwesenheit", name: "AnAbwesenheit", component: AnAbwesenheit
		},
		{
			path: "/add_anwesen", name: "AddAnwesenheit", component: NewAnwesenheit
		},
		{
			path: "/view_anwesen", name: "ViewAnwesenheit", component: ViewAnwesenheit
		},
		{
			path: "/add_abwesen", name: "AddAbwesenheit", component: NewAbwesenheit
		},
		{
			path: "/view_abwesen", name: "ViewAbwesenheit", component: ViewAbwesenheit
		},
		*/
		{
			path: "/license_kind", name: "LicenseKind", component: LicenseKind
		},
		{
			path: "/add_license_kind", name: "AddLicenseKind", component: NewLicenseKind
		},
		{
			path: "/view_license_kind", name: "ViewLicenseKind", component: ViewLicenseKind
		},
		{ name: 'Login', path: '/login', component: LoginComponent, meta: { requiresAuth: false } },
	],

	scrollBehavior() {
		document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
	}
})

router.beforeEach((to, from, next) => {
	if (to.name === 'Login' && Parse.User.current()) {
		return next({ path: '/home' });
	}
	if (to.matched.some(record => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (!Parse.User.current()) {
			next({ name: 'Login' })
		} else {
			next() // go to wherever I'm going
		}
	} else {
		next() // does not require auth, make sure to always call next()!
	}
})

router.afterEach((to, from, next) => {
	store.commit("ADD_LAST_ROUTE", from)
})