<template>
	<div>
		<div>
			<SearchableTable :panel-header="'Darreichungsform (Einheit)'" :panel-route="''" :show-search-bar="true"
				:table-data="dataObject" :table-meta-data="tableMetaData" :query-string="'setMedicationUnitSearchQuery'"
				:event-value="parentEventValue">
			</SearchableTable>
		</div>
	</div>
</template>
  
<script>
/**
 * This file shows all currently stored medication units and allows adding, editing, viewing and soft deleting these.
 */
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import { mapGetters } from "vuex";
import SearchableTable from "@/components/helperComponents/SearchableTable.vue";

export default {
	name: "MedicationUnitTable",
	components: { SearchableTable },
	data() {
		return {
			parentEventValue: undefined,
			isDeleteCheck: false,
			chosenElement: {},
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Bezeichner", "field": "name", "sortField": "attributes.name" },
				{ "id": 1, "type": "string", "label": "Kürzel", "field": "symbol", "sortField": "attributes.symbol" }
			]
		};
	},
	computed: {
		...mapGetters({
			dataObject: "getMedicalUnit"
		})
	},
	mounted() { },
	methods: {
		triggerView(element) {
			//this.$router.push({ name: "ViewMedicationUnit", params: { data: element } });
		}, /*
		triggerEdit(element) {
			this.$router.push({ name: "AddNewMedicationUnit", params: { data: element } });
		},
		triggerDelete(element) {
			this.chosenElement = element;
			this.isDeleteCheck = true;
		},
		closeModal() {
			this.isDeleteCheck = false;
		},
		async submitDelete() {
			this.isLoading = true
			this.chosenElement.set("deleted", true);
			this.chosenElement.save().catch((e) => {
				negativeFeedBack(this.chosenElement.attributes.name + " nicht gelöscht!")
			});
			await positiveFeedBack(this.chosenElement.attributes.name + " gelöscht!")
			this.isLoading = false
			this.isDeleteCheck = false
		} */
	},
};

</script>
  
<style scoped></style>
  