<template>
	<b-field :message="hasValidation && !$v.selectedItem.$model ? message : ''"
		:type="hasValidation ? $v.selectedItem.$model ? 'is-success' : 'is-danger' : ''">
		<template #label>
			<div class="align-content-center" style="display: inline-flex">
				<p>{{ label }}</p>
			</div>
		</template>
		<b-select v-model="selectedItem" class="m-2" :placeholder="label" expanded @input="set()">
			<option v-for="option in options" :key="option.id" :value="option.toPointer()">
				<div v-if="nestedAttribute">
					{{ option.attributes[attributeName].attributes[secondAttributeName] + " " +
						option.attributes[attributeName].attributes[thirdAttributeName] }}
				</div>
				<div v-else>{{ option.attributes[attributeName] }}</div>
				<div v-if="secondAttributeName">
					<div v-if="useBracket">
						{{ "(" + option.attributes[secondAttributeName] + ")" }}
					</div>
					<div v-else>
						{{ option.attributes[secondAttributeName] }}
					</div>
				</div>
			</option>
		</b-select>
	</b-field>
</template>

<script>
/**
 * This file provides a selection field with additional optional validation for different items as Parse objects with description
 * label: String for description, options: Array of Parse objects as options, selectedOption: Parse object as pointer for selected option,
 * select: Function for selecting the option, hasValidation: boolean if validation desired, message: String for message indicating an inappropriate input,
 * attributeName: String name of attribute for the parse object
 */
import { required } from "vuelidate/lib/validators";
export default {
	name: "ValidatedSelection",
	props: ["label", "options", "selectedOption", "select", "message", "hasValidation", "attributeName", "useBracket", "secondAttributeName", "nestedAttribute", "thirdAttributeName"],
	data() {
		return {
			selectedItem: this.selectedOption
		}
	},
	methods: {
		set() {
			if (this.selectedItem) {
				this.select(this.selectedItem)
			}
		}
	},
	watch: {
		selectedOption(currentValue) {
			if (currentValue) {
				this.selectedItem = currentValue
			}
		}
	},
	validations() {
		if (this.hasValidation) {
			return {
				selectedItem: { required, }
			}
		}
	}
}
</script>

<style scoped></style>
