/**
 * This is a global store used for better performance when switching tabs and providing additional live function
 */
import Vue from 'vue';
import Vuex from "vuex";
import Parse from 'parse/dist/parse.min.js';

import clientSubscription from './plugins/clients';
import userSubscription from './plugins/users';
import userMetaSubscription from './plugins/usermetas';
import groupSubscription from './plugins/groups';
import entryTypesSubscription from './plugins/entrytypes';
import attributeConfigsSubscription from './plugins/attributeconfigs';
import createRoleSubscription from "./plugins/roles";
import createSocialworkerSubscription from "./plugins/socialWorker";
import createPersonSubscription from "./plugins/person";
import createWidgetSubscription from './plugins/widgets';
import createDiagnoseSubscription from './plugins/diagnoses';
import createMedicationFormSubscription from './plugins/medicationForm';
import createMedicationSubscription from './plugins/medication';
import createMedicationClassSubscription from './plugins/medicationClass';
import createMedicationUnitSubscription from './plugins/medicationUnit';
import createICD10CodeSubscription from './plugins/icd_10_codes';
import createDoctorSubscription from './plugins/doctors';
import createInstantMedicationSubscription from './plugins/instant_medication';
import createDoctorSpecializationSubscription from './plugins/doctorSpecialization'
import createMedicationLogEntriesSubscription from './plugins/medicationLogEntries';
import createPresenceSubscription from './plugins/presence';
import createAbsenceSubscription from './plugins/absence';
import createLicenseKindSubscription from './plugins/licenseKind';

import clients from './modules/clients'
import socialWorkers from './modules/socialWorkers'
import users from './modules/users'
import usermetas from './modules/usermetas'
import groups from './modules/groups'
import entrytypes from './modules/entrytypes'
import attributeconfigs from './modules/attributeconfigs'
import roles from "./modules/roles"
import staffs from "./modules/staffs"
import admins from "./modules/admins"
import externals from "./modules/externals"
import persons from "./modules/persons"
import presence from './modules/presence';
import absence from './modules/absence';
import licenseKind from './modules/licenseKind';
import optional_attributeconfigs from './modules/optional_attributeconfigs'
import widgets from './modules/widgets';
import diagnoses from './modules/diagnoses';
import { APP_ID, APP_KEY, SERVER_URL } from "@/lib/config/AppConfigurations";
import createOptionalAttributeConfigSubscription from "@/store/plugins/optional_attributeconfigs";
import versions from './modules/versions';
import medicationForm from "./modules/medicationForm"
import medicationUnit from "./modules/medicationUnit"
import medicationClass from "./modules/medicationClass"
import medication from "./modules/medication"
import icd_10_codes from "./modules/icd_10_codes"
import doctors from "./modules/doctors"
import instant_medication from "./modules/instant_medication"
import doctorSpecialization from "./modules/doctorSpecialization"
import medicationLogEntries from './modules/medicationLogEntries';

// Parse configuration & initilization
Parse.initialize(APP_ID, APP_KEY);
Parse.serverURL = SERVER_URL

// Vuex include
Vue.use(Vuex);

let store = new Vuex.Store({
	state: {
		currentUser: Parse.User.current(),
		instantMedicationSearchQuery: "",
		searchQuery: "",
		icd10CodeSearchQuery: "",
		medicationUnitSearchQuery: "",
		medicationFormSearchQuery: "",
		medicationSearchQuery: "",
		userSearchQuery: "",
		clientSearchQuery: "",
		groupSearchQuery: "",
		externalSearchQuery: "",
		socialWorkerSearchQuery: "",
		personSearchQuery: "",
		focusedClientId: undefined,
		groupId: undefined,
		savedUser: undefined,
		clientInactiveSearchQuery: "",
		is_optional: false,
		isResetPasswordModalOpen: false,
		failureData: [],
		lastRoutes: [],
	},
	getters: {
		getFailureData(state) {
			return state.failureData
		},
		getIsResetPasswordModalOpen(state) {
			return state.isResetPasswordModalOpen
		},
		getIsOptionalAttribute(state) {
			return state.is_optional
		},
		getClientId(state) {
			return state.focusedClientId
		},
		getFocusedClient(state, getters) {
			if (!getters.getClients) return undefined
			return getters.getClients.find(client => state.focusedClientId === client.id)
		},
		getCurrentUser() {
			return Parse.User.current()
		},
		getCurrentUserMeta(state) {
			if (state.currentUser) {
				return state.usermetas.data.filter((meta) => meta.id === state.currentUser.attributes.dependency.id
				)[0]
			}
			return undefined
		},
		getAppointmentNotifications(state) {
			if (state.notifications.data.length === 0) return undefined
			return state.notifications.data.filter(notification => notification.attributes.type === "appointment")
		},
		getGroups(state) {
			if (state.groups.data.length === 0) return []
			return state.groups.data
		},
		getAdminRole(state) {
			if (state.roles.data.length === 0) return undefined
			return state.roles.data.find(role => role.attributes.name === "Admin")
		},
		getStaffRole(state) {
			if (state.roles.data.length === 0) return undefined
			return state.roles.data.find(role => role.attributes.name === "Staff")
		},
		getStaffUsers(state) {
			if (state.staffs.data.length === 0) return undefined
			return state.staffs.data
		},
		getStaffUserMetas(state) {
			if (state.usermetas.data.length === 0) return undefined
			return state.usermetas.data.filter(usermeta => usermeta.attributes.type === "staff")
		},
		getStaffUserMetasFiltered(state, getters) {
			if (!getters.getStaffUserMetas) return undefined
			const userMetas = getters.getStaffUserMetas
			return userMetas.filter(userMeta => {
				const name = `${userMeta.attributes.first_name.toLowerCase()} ${userMeta.attributes.last_name.toLowerCase()}`
				return name.includes(state.userSearchQuery.toLowerCase())
			})
		},
		getUserMetaClasses(state) {
			if (state.usermetas.data.length === 0) return undefined
			return state.usermetas.data
		},
		getUsers(state) {
			if (state.users.data.length === 0) return undefined
			return state.users.data
		},
		getClients(state) {
			if (state.clients.data.length === 0) return []
			return state.clients.data
		},
		// UNUSED
		// getClientsFiltered(state) {
		// 	if (state.clients.data.length === 0) return undefined
		// 	const clients = state.clients.data.filter(client => {
		// 		const name = `${client.attributes.first_name.toLowerCase()} ${client.attributes.last_name.toLowerCase()}`
		// 		return name.includes(state.clientSearchQuery.toLowerCase())
		// 	})
		// 	return clients.sort((c1, c2) => c1.attributes.inactive - c2.attributes.inactive)
		// },
		getClientsFilteredWithContactEducators(state){
			if(state.clients.data.length === 0) return undefined

			// Search-Query anwenden
			let clients = state.clients.data.filter(client => {
				const name = `${client.attributes.first_name.toLowerCase()} ${client.attributes.last_name.toLowerCase()}`
				return (name.includes(state.clientSearchQuery.toLowerCase()))
			})

			// Clients mit all_inactive und gemeinsamer uuid auf einen Eintrag reduzieren
			const uuids = []
			clients = clients.filter(c => {
				let client = undefined
				if(c.attributes.all_inactive == true && !uuids.includes(c.attributes.uuid)) {
					client = c
				}
				else if (c.attributes.all_inactive == false) {
					client = c
				}
				uuids.push(c.attributes.uuid)
				return client
			})

			// Den Kontakterzieher für den Klienten heraussuchen, da diese Information am User steht, nicht am Klienten
			clients.forEach(clientElement => {
				const filteredUserMetaClasses =state.usermetas.data.filter(userMetaClass => {
					if (!userMetaClass.attributes.clients) return false
					const contactChildrenIds = userMetaClass.attributes.clients.map(contactChild => contactChild.id)
					return contactChildrenIds.includes(clientElement.id)
				})
				let names = ""
				filteredUserMetaClasses.forEach((userMetaClass, index) => {
					names += `${userMetaClass.attributes.first_name} ${userMetaClass.attributes.last_name}`
					if (index < filteredUserMetaClasses.length - 1) {
						names += ", "
					}
				})
				clientElement["educator"] = names
			})
			return clients
		},
		getInactiveClientsFiltered(state) {
			if (state.clients.data.length === 0) return undefined
			return state.clients.data.filter(client => {
				const name = `${client.attributes.first_name.toLowerCase()} ${client.attributes.last_name.toLowerCase()}`
				return name.includes(state.clientInactiveSearchQuery.toLowerCase()) && client.attributes.inactive
			})
		},
		getGroupsFiltered(state) {
			if (state.groups.data.length === 0) return undefined
			return state.groups.data.filter(group => {
				const name = group.attributes.group_name.toLowerCase()
				return name.includes(state.groupSearchQuery.toLowerCase())
			})
		},
		getExternalFiltered(state) {
			if (state.usermetas.data.length === 0) return undefined
			const externalMetas = state.usermetas.data.filter(usermeta => usermeta.attributes.type === "external")
			return externalMetas.filter(external => {
				const name = `${external.attributes.first_name.toLowerCase()} ${external.attributes.last_name.toLowerCase()}`
				return name.includes(state.externalSearchQuery.toLowerCase())
			})
		},
		getSocialWorkerFiltered(state) {
			if (state.socialWorkers.data.length === 0) return undefined
			const externalMetas = state.socialWorkers.data.filter(person => person.attributes.type === "socialWorker")
			return externalMetas.filter(external => {
				const name = `${external.attributes.first_name.toLowerCase()} ${external.attributes.last_name.toLowerCase()}`
				return name.includes(state.socialWorkerSearchQuery.toLowerCase())
			})
		},
		getPersonFiltered(state) {
			if (state.persons.data.length === 0) return undefined
			const externalMetas = state.persons.data.filter(person => person.attributes.type != "socialWorker")
			return externalMetas.filter(external => {
				const name = `${external.attributes.first_name.toLowerCase()} ${external.attributes.last_name.toLowerCase()}`
				return name.includes(state.personSearchQuery.toLowerCase())
			})
		},
		getUserMetas(state) {
			if (state.usermetas.data.length === 0) return undefined
			return state.usermetas.data
		},
		getAttributes(state) {
			if (state.attributes.data.length === 0) return undefined
			return state.attributes.data
		},
		getEntryTypes(state) {
			if (state.entrytypes.data.length === 0) return undefined
			return state.entrytypes.data
		},
		getWidgets(state) {
			if (state.widgets.data.length === 0) return undefined
			return state.widgets.data
		},
		getMedicalClass(state) {
			if (state.medicationClass.data.length === 0) return undefined
			return state.medicationClass.data
		},
		getDoctorSpecialization(state) {
			if (state.doctorSpecialization.data.length === 0) return undefined
			return state.doctorSpecialization.data
		},
		getDiagnoses(state) {
			if (state.diagnoses.data.length === 0) return undefined
			return state.diagnoses.data.filter(diagnose => {
				const name = `${diagnose.attributes.name.toLowerCase()}`
				return name.includes(state.searchQuery.toLowerCase())
			})
		},
		getMedicalForm(state) {
			if (state.medicationForm.data.length === 0) return undefined
			return state.medicationForm.data.filter(medicalForm => {
				const name = `${medicalForm.attributes.name.toLowerCase()}`
				const symbol = `${medicalForm.attributes.symbol.toLowerCase()}`
				return (name.includes(state.medicationFormSearchQuery.toLowerCase()) || symbol.includes(state.medicationFormSearchQuery.toLowerCase()))
			})
		},
		getMedicalUnit(state) {
			if (state.medicationUnit.data.length === 0) return undefined
			return state.medicationUnit.data.filter(medicalUnit => {
				const name = `${medicalUnit.attributes.name.toLowerCase()}`
				const symbol = `${medicalUnit.attributes.symbol.toLowerCase()}`
				return (name.includes(state.medicationUnitSearchQuery.toLowerCase()) || symbol.includes(state.medicationUnitSearchQuery.toLowerCase()))
			})
		},
		get_icd_10_code(state) {
			if (state.icd_10_codes.data.length === 0) return undefined
			return state.icd_10_codes.data.filter(icd_10_code => {
				const name = `${icd_10_code.attributes.name.toLowerCase()}`
				const icd10 = `${icd_10_code.attributes.icd_10_code.toLowerCase()}`
				return (name.includes(state.icd10CodeSearchQuery.toLowerCase()) || icd10.includes(state.icd10CodeSearchQuery.toLowerCase()))
			})
		},
		getDoctor(state) {
			if (state.doctors.data.length === 0) return undefined
			return state.doctors.data.filter(doctor => {
				const name = `${doctor.attributes.name.toLowerCase()}`
				const specialization = `${doctor.attributes.specialization?.attributes.specialization?.toLowerCase()}`
				const street = `${doctor.attributes.street.toLowerCase()}`
				const plz = `${doctor.attributes.plz.toLowerCase()}`
				const location = `${doctor.attributes.location.toLowerCase()}`
				return (name.includes(state.searchQuery.toLowerCase()) || street.includes(state.searchQuery.toLowerCase()) ||
					plz.includes(state.searchQuery.toLowerCase()) || location.includes(state.searchQuery.toLowerCase()) || specialization.includes(state.searchQuery.toLowerCase()))
			})
		},
		getMedication(state) {
			if (state.medication.data.length === 0) return undefined
			return state.medication.data.filter(medication => {
				const name = `${medication.attributes.name.toLowerCase()}`
				const max = `${medication.attributes.max}`
				const potency = `${medication.attributes.potency.toLowerCase()}`
				const medicationForm = `${medication.attributes.medicationForm.attributes.name.toLowerCase()}`
				return (name.includes(state.medicationSearchQuery.toLowerCase()) || max.includes(state.medicationSearchQuery.toLowerCase()) ||
					potency.includes(state.medicationSearchQuery.toLowerCase()) || medicationForm.includes(state.medicationSearchQuery.toLowerCase()))
			})
		},
		getInstantMedication(state) {
			if (state.instant_medication.data.length === 0) return undefined
			return state.instant_medication.data.filter(instant_medication => {
				const name = `${instant_medication.attributes.medication.attributes?.name.toLowerCase()}`
				const medication_art = `${instant_medication.attributes.medication.attributes?.medicationForm.attributes.name.toLowerCase()}`
				const medication_art_symbol = `${instant_medication.attributes.medication.attributes?.medicationForm.attributes.symbol.toLowerCase()}`
				const medication_potency = `${instant_medication.attributes.medication.attributes?.potency.toLowerCase()}`
				const medication_max = `${instant_medication.attributes.medication.attributes?.max}`
				const medication_unit = `${instant_medication.attributes.medication.attributes.medicationUnit?.attributes.name.toLowerCase()}`
				const medication_unit_symbol = `${instant_medication.attributes.medication.attributes?.medicationUnit.attributes.symbol.toLowerCase()}`
				const min = `${instant_medication.attributes.min}`
				const first_name = `${instant_medication.attributes.user.attributes.dependency.attributes.first_name.toLowerCase()}`
				const last_name = `${instant_medication.attributes.user.attributes.dependency.attributes.last_name.toLowerCase()}`
				return (name.includes(state.instantMedicationSearchQuery.toLowerCase()) || min.includes(state.instantMedicationSearchQuery.toLowerCase()) ||
					first_name.includes(state.instantMedicationSearchQuery.toLowerCase()) || last_name.includes(state.instantMedicationSearchQuery.toLowerCase())
					|| medication_art.includes(state.instantMedicationSearchQuery.toLowerCase()) || medication_art_symbol.includes(state.instantMedicationSearchQuery.toLowerCase())
					|| medication_potency.includes(state.instantMedicationSearchQuery.toLowerCase()) || medication_max.includes(state.instantMedicationSearchQuery.toLowerCase())
					|| medication_unit.includes(state.instantMedicationSearchQuery.toLowerCase()) || medication_unit_symbol.includes(state.instantMedicationSearchQuery.toLowerCase()))
			})
		},
		getDiaryAttributes(state) {
			if (state.attributeconfigs.data.length === 0) return undefined
			return state.attributeconfigs.data
		},
		getDiaryOptionalAttributes(state) {
			if (state.optional_attributeconfigs.data.length === 0) return undefined
			return state.optional_attributeconfigs.data
		},
		getFocusedGroup(state, getters) {
			if (!getters.getGroups) return undefined
			return getters.getGroups.find(group => group.id === state.groupId)
		},
		addLastRoute({ commit }, query) {
			commit("ADD_LAST_ROUTE", query)
		},
	},
	mutations: {
		SET_INSTANT_MEDICATION_SEARCH_QUERY(state, data) {
			state.instantMedicationSearchQuery = data;
		},
		SET_MEDICATION_SEARCH_QUERY(state, data) {
			state.medicationSearchQuery = data;
		},
		SET_SEARCH_QUERY(state, data) {
			state.searchQuery = data;
		},
		SET_MEDICATION_UNIT_SEARCH_QUERY(state, data) {
			state.medicationUnitSearchQuery = data;
		},
		SET_MEDICATION_FORM_SEARCH_QUERY(state, data) {
			state.medicationFormSearchQuery = data;
		},
		SET_ICD_10_CODE_SEARCH_QUERY(state, data) {
			state.icd10CodeSearchQuery = data;
		},
		SET_USER_SEARCH_QUERY(state, data) {
			state.userSearchQuery = data;
		},
		SET_CLIENT_SEARCH_QUERY(state, data) {
			state.clientSearchQuery = data;
		},
		SET_CLIENT_INACTIVE_SEARCH_QUERY(state, data) {
			state.clientInactiveSearchQuery = data;
		},
		SET_GROUP_SEARCH_QUERY(state, data) {
			state.groupSearchQuery = data;
		},
		SET_EXTERNAL_SEARCH_QUERY(state, data) {
			state.externalSearchQuery = data;
		},
		SET_Person_SEARCH_QUERY(state, data) {
			state.personSearchQuery = data;
		},
		SET_SOCIALWORKER_SEARCH_QUERY(state, data) {
			state.socialWorkerSearchQuery = data;
		},
		SET_FOCUSED_CLIENT_ID(state, data) {
			state.focusedClientId = data;
		},
		SET_GROUP_ID(state, data) {
			state.groupId = data;
		},
		SET_SAVED_USER(state, data) {
			state.savedUser = data;
		},
		SET_IS_OPTIONAL_STATE(state, data) {
			state.is_optional = data;
		},
		SET_IS_PASSWORD_RESET_MODAL_OPEN(state, data) {
			state.isResetPasswordModalOpen = data;
		},
		SET_FAILURE_DATA(state, data) {
			state.failureData = data;
		},
		ADD_FAILURE_DATA(state, data) {
			state.failureData.push(data);
		},
		ADD_LAST_ROUTE(state, data) {
			state.lastRoutes.push(data)
		},
	},
	actions: {
		logoutUser({ commit }) {
			return new Promise((resolve, reject) => {
				Parse.User.logOut()
					.then((succ) => {
						commit('SET_CURRENT_USER', undefined)
						resolve(succ)
					})
					.catch((err) => {
						reject(err)
					});
			})
		},
		loginUser({ commit }, { email, password }) {
			return new Promise((resolve, reject) => {
				Parse.User.logIn(email, password)
					.then((user) => {
						commit('SET_CURRENT_USER', Parse.User.current())
						resolve(user)
					})
					.catch((err) => {
						reject(err)
					});
			})
		},
		setSearchQuery({ commit }, query) {
			commit('SET_SEARCH_QUERY', query)
		},
		setInstantMedicationSearchQuery({ commit }, query) {
			commit('SET_INSTANT_MEDICATION_SEARCH_QUERY', query)
		},
		setMedicationSearchQuery({ commit }, query) {
			commit('SET_MEDICATION_SEARCH_QUERY', query)
		},
		setMedicationUnitSearchQuery({ commit }, query) {
			commit('SET_MEDICATION_UNIT_SEARCH_QUERY', query)
		},
		setICD10CodeUnitSearchQuery({ commit }, query) {
			commit('SET_ICD_10_CODE_SEARCH_QUERY', query)
		},
		setMedicationFormSearchQuery({ commit }, query) {
			commit('SET_MEDICATION_FORM_SEARCH_QUERY', query)
		},
		setUserSearchQuery({ commit }, query) {
			commit('SET_USER_SEARCH_QUERY', query)
		},
		setClientSearchQuery({ commit }, query) {
			commit('SET_CLIENT_SEARCH_QUERY', query)
		},
		setClientInactiveSearchQuery({ commit }, query) {
			commit('SET_CLIENT_INACTIVE_SEARCH_QUERY', query)
		},
		setGroupSearchQuery({ commit }, query) {
			commit('SET_GROUP_SEARCH_QUERY', query)
		},
		setsocialWorkerSearchQuery({ commit }, query) {
			commit('SET_SOCIALWORKER_SEARCH_QUERY', query)
		},
		setExternalSearchQuery({ commit }, query) {
			commit('SET_EXTERNAL_SEARCH_QUERY', query)
		},
		setPersonSearchQuery({ commit }, query) {
			commit('SET_Person_SEARCH_QUERY', query)
		},
		setFocusedClientId({ commit }, query) {
			commit("SET_FOCUSED_CLIENT_ID", query)
		},
		setGroupId({ commit }, query) {
			commit("SET_GROUP_ID", query)
		},
		setSavedUser({ commit }, query) {
			commit("SET_SAVED_USER", query)
		},
		setIsOptionalState({ commit }, query) {
			commit("SET_IS_OPTIONAL_STATE", query)
		},
		setIsPasswordResetModalOpen({ commit }, query) {
			commit("SET_IS_PASSWORD_RESET_MODAL_OPEN", query)
		},
		setFailureData({ commit }, query) {
			commit("SET_FAILURE_DATA", query)
		},
		addFailureData({ commit }, query) {
			commit("ADD_FAILURE_DATA", query)
		},
	},
	modules: {
		versions,
		persons,
		socialWorkers,
		clients,
		users,
		usermetas,
		groups,
		entrytypes,
		attributeconfigs,
		roles,
		admins,
		staffs,
		externals,
		optional_attributeconfigs,
		widgets,
		diagnoses,
		medicationForm,
		medicationUnit,
		icd_10_codes,
		doctors,
		medicationClass,
		medication,
		instant_medication,
		doctorSpecialization,
		medicationLogEntries,
		presence,
		absence,
		licenseKind
	},
	plugins: [
		createDiagnoseSubscription(),
		createWidgetSubscription(),
		createPersonSubscription(),
		createSocialworkerSubscription(),
		clientSubscription(),
		userSubscription(),
		userMetaSubscription(),
		groupSubscription(),
		entryTypesSubscription(),
		attributeConfigsSubscription(),
		createRoleSubscription(),
		createOptionalAttributeConfigSubscription(),
		createPresenceSubscription(),
		createAbsenceSubscription(),
		createLicenseKindSubscription(),
		createMedicationFormSubscription(),
		createMedicationUnitSubscription(),
		createICD10CodeSubscription(),
		createDoctorSubscription(),
		createMedicationClassSubscription(),
		createMedicationSubscription(),
		createInstantMedicationSubscription(),
		createDoctorSpecializationSubscription(),
		createMedicationLogEntriesSubscription(),
	]
});
/* UNUSED
const unsubscribe = store.subscribe((mutation, state) => {
	if (mutation.type === "clients/DELETE") {
		if (mutation.payload.id === state.focusedClientId) {
			state.focusedClientId = undefined;
		}
	}
}) */

export default store;
