<template>
  <div>
    <div class="panel m-5">
      <PanelHeader :panel-header="statutoryBasis ? statutoryBasis.attributes.name : 'Neue gesetzliche Grundlage'" />
      <div class="container pt-3 pb-3">
        <InputWithValidation
          :key="isMounted ? 'nameU' : 'name'"
          :name="name"
          :set-name="(val) => (name = val)"
          label="Name"
          message="Bitte gib einen Namen ein!"
        />
      </div>
    </div>

    <div
      class="m-5 buttons"
      style="display: flex; justify-content: center"
    >
      <SaveButton
        :is-loading="isLoading"
        :save-function="save"
        :disabled=" $v.name.$invalid
        "
      />
      <CancelButton
        text="Abbrechen"
        back-route="/statutoryBasis"
        :is-loading="false"
      />
    </div>
  </div>
</template>

<script>
import Parse from "parse/dist/parse.min"
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import {minLength, required} from "vuelidate/lib/validators";
import {positiveFeedBack} from "@/lib/Toast";
import PanelHeader from "@/components/helperComponents/PanelHeader";
export default {
	name: "NewStatutoryBasis",
	components:{PanelHeader, InputWithValidation, SaveButton, CancelButton},
	data(){
		return{
			isMounted: false,
			isLoading: false,
			name: undefined,
			statutoryBasis: undefined
		}
	},
	validations: {
		name: {
			required,
			minLength: minLength(1),
		},
	},
	mounted() {
		if (!this.$route.params.data) return (this.isMounted = true);
		this.statutoryBasis = this.$route.params.data;
		const {name} = this.statutoryBasis.attributes
		this.name = name
		this.isMounted = true
	},
	methods:{
		async save(){
			const StatutoryBasis = Parse.Object.extend("StatutoryBasis")
			const statutoryBasis = this.statutoryBasis ? this.statutoryBasis : new StatutoryBasis()
			statutoryBasis.set("name", this.name)
			await statutoryBasis.save()
			await this.$router.push({path: "/statutoryBasis"})
			await positiveFeedBack("Gesetzliche Grundlage erstellt")
		}
	}
}
</script>

<style scoped>

</style>