<template>
	<div>
		<div class="panel m-5">
			<PanelHeader :panel-header="panelHeader" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<InputWithValidation class="column" message="Bitte geben Sie einen Bezeichner ein!"
						:name="abwesenheitsgrundName" :set-name="(val) => (abwesenheitsgrundName = val)"
						label="Bezeichner" />
					<ColorPicker class="column" :colors="abwesenheitsgrundColor" :set-colors="setColors" />
					<InputComponent class="column" :name="abwesenheitsgrundHint" :key="isMounted ? 'hinte' : 'hint'"
						:set-name="(val) => (abwesenheitsgrundHint = val)" label="Kürzel" />
				</div>
			</div>
		</div>

		<div class="m-5 buttons" style="display: flex; justify-content: center">
			<SaveButton :is-loading="isLoading"
				:disabled="$v.abwesenheitsgrundName.$invalid || $v.abwesenheitsgrundColor.$invalid"
				:save-function="saveData" />
			<CancelButton text="Abbrechen" back-route="/anwesenheit" :is-loading="false" />
		</div>
	</div>
</template>

<script>

import Parse from "parse/dist/parse.min.js";
import { minLength, required } from "vuelidate/lib/validators";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import InputComponent from "@/components/helperComponents/InputComponent";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import ColorPicker from "@/components/helperComponents/ColorPicker";
import { positiveFeedBack, negativeFeedBack } from "@/lib/Toast";

export default {
	name: "NewAnwesenheit",
	components: {
		PanelHeader,
		InputWithValidation,
		InputComponent,
		ColorPicker,
		SaveButton,
		CancelButton,
	},
	data() {
		return {
			isLoading: false,
			isMounted: false,
			abwesenheitsgrund: undefined,
			abwesenheitsgrundName: "",
			abwesenheitsgrundColor: {
				"hex": "#000000",
				"source": "hex"
			},
			abwesenheitsgrundHint: "",
			panelHeader: "Neuen Abwesenheitsgrund anlegen"
		};
	},
	watch: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.abwesenheitsgrund = this.$route.params.data
			this.abwesenheitsgrundName = this.abwesenheitsgrund.attributes.name
			this.abwesenheitsgrundColor.hex = this.abwesenheitsgrund.attributes.color
			this.abwesenheitsgrundHint = this.abwesenheitsgrund.attributes.hint
			this.panelHeader = "Abwesenheitsgrund bearbeiten"
			this.isMounted = true
		}
	},
	validations: {
		abwesenheitsgrundName: {
			required,
			minLength: minLength(1),
		},
		abwesenheitsgrundColor: {
			required,
		},
	},
	methods: {
		setColors(colors) {
			this.abwesenheitsgrundColor = colors
		},
		async saveData() {
			this.isLoading = true;
			const Abwesenheitsgrund = Parse.Object.extend("Absence")
			const abwesenheitsgrund = this.abwesenheitsgrund ? this.abwesenheitsgrund : new Abwesenheitsgrund()
			abwesenheitsgrund.set("name", this.abwesenheitsgrundName)
			abwesenheitsgrund.set("color", this.abwesenheitsgrundColor.hex)
			abwesenheitsgrund.set("hint", this.abwesenheitsgrundHint)
			abwesenheitsgrund.set("deleted", false)
			await abwesenheitsgrund.save().catch((e) => {
				negativeFeedBack("Abwesenheitsgrund nicht gespeichert!")
			});
			this.isLoading = false;
			await this.$router.push({ path: "/anwesenheit" })
			await positiveFeedBack("Abwesenheitsgrund gespeichert!")
		},
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		},
	}
};


</script>

<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}

.anwesenheitstage {
	flex-direction: column;
}
</style>
