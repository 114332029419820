<template>
	<div>
		<div>
			<SearchableTable :panel-header="'Diagnosen'" :panel-route="'AddDiagnose'" :show-search-bar="true"
				:table-data="diagnoses" :table-meta-data="tableMetaData" :query-string="'setSearchQuery'"
				:event-value="parentEventValue">
				<b-table-column v-slot="props" label="Aktionen" cell-class="has-text-right truncate-column"
					header-class="th-right truncate-column">
					<b-button class="is-small mr-2" @click="triggerView(props.row)">
						<b-icon class="mt-1" icon="eye" style="cursor: pointer" />
					</b-button>
					<b-button class="is-small mr-2" @click="triggerEdit(props.row)">
						<b-icon class="mt-1" icon="pencil" style="cursor: pointer;" />
					</b-button>
					<b-button class="is-small" @click="triggerDelete(props.row)">
						<b-icon class="mt-1" icon="delete" style="cursor: pointer" />
					</b-button>
				</b-table-column>
			</SearchableTable>

			<b-modal v-model="isDeleteCheck">
				<div v-if="isDeleteCheck" class="panel m-5" style="background-color: white">
					<p class="panel-heading">
						Möchtest du "{{ chosenElement.attributes.name }}" wirklich löschen?
					</p>
					<div class="buttons mt-2" style="display: flex; justify-content: center">
						<b-button class="is-danger" :loading="isLoading" @click="submitDelete()">
							Ja
						</b-button>
						<b-button class="is-primary" @click="closeModal">
							Nein
						</b-button>
					</div>
				</div>
			</b-modal>
		</div>
	</div>
</template>
  
<script>
/**
 * This file shows all currently stored diagnoses and allows adding, editing, viewing and soft deleting diagnoses.
 */
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import { mapGetters } from "vuex";
import SearchableTable from "@/components/helperComponents/SearchableTable.vue";

export default {
	name: "DiagnoseTable",
	components: { SearchableTable },
	data() {
		return {
			parentEventValue: undefined,
			isDeleteCheck: false,
			chosenElement: {},
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Bezeichner", "field": "name", "sortField": "attributes.name" }
			]
		};
	},
	computed: {
		...mapGetters({
			diagnoses: "getDiagnoses"
		})
	},
	mounted() { },
	methods: {
		triggerEdit(element) {
			this.$router.push({ name: "AddDiagnose", params: { data: element } });
		},
		triggerView(element) {
			this.$router.push({ name: "ViewDiagnose", params: { data: element } });
		},
		triggerDelete(element) {
			this.chosenElement = element;
			this.isDeleteCheck = true;
		},
		closeModal() {
			this.isDeleteCheck = false;
		},
		async submitDelete() {
			this.isLoading = true
			this.chosenElement.set("deleted", true);
			this.chosenElement.save().catch((e) => {
				negativeFeedBack(this.chosenElement.attributes.name + " nicht gelöscht!")
			});
			await positiveFeedBack(this.chosenElement.attributes.name + " gelöscht!")
			this.isLoading = false
			this.isDeleteCheck = false
		}
	},
};

</script>
  
<style scoped></style>
  