<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
	name: "MainBar"
}
</script>

<style scoped>

</style>
