<template>
	<div>
		<div v-if="licenseKind" class="panel m-5">
			<p class="panel-heading">
				Bewilligungstyp
			</p>
			<div class="columns">
				<div class="column">
					<b-field label="Bezeichner">
						<p class="content">
							{{ licenseKindName }}
						</p>
					</b-field>
				</div>
				<div class="column">
					<b-field label="Tagessatz (in €)">
						<p class="content">
							{{ licenseKindRate }}
						</p>
					</b-field>
				</div>
				<div class="column">
					<b-field label="Anmerkung">
						<p class="content">
							{{ licenseKindHint }}
						</p>
					</b-field>
				</div>
			</div>
		</div>
		<section class="m-2">
			<b-button class="is-danger" tag="router-link" to="/license_kind">
				Zurück
			</b-button>
		</section>
	</div>
</template>

<script>
export default {
	name: "ViewAnwesenheitsgrund",
	data() {
		return {
			licenseKind: undefined,
			licenseKindName: "",
			licenseKindHint: "",
			licenseKindRate: "0",
		};
	},
	computed: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.licenseKind = this.$route.params.data
			this.licenseKindName = this.licenseKind.attributes.name
			this.licenseKindRate = String(this.licenseKind.attributes.rate).replace('.', ',')
			this.licenseKindHint = this.licenseKind.attributes.hint
		}
	},
	methods: {
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
