<template>
  <div>
    <div
      v-if="attributeCharacteristics"
      class="panel m-5"
    >
      <div
        class="panel-heading"
        style="display: flex"
      >
        <p class="mr-2">
          {{ attributeName }}
        </p>
        <div
          class=""
          style="width:30px; height:30px; cursor:pointer; border-radius: 5px"
          :style="{'background-color':colors.hex, 'color':getComplementaryHexColor(colors.hex), 'border':'1px solid black'}"
          @click="toggleColorPicker"
        />
      </div>
      <b-modal v-model="colorPickerOpen">
        <div style="display: flex; justify-content: center">
          <chrome-picker
            v-if="colorPickerOpen"
            v-model="colors"
          />
        </div>
      </b-modal>
      <div class="container pt-3 pb-3">
        <b-table
          narrowed
          :data="attributeCharacteristics"
          striped
        >
          <b-table-column
            v-slot="props"
            :label="attribute.attributes.name"
            field="attributes.name"
            sortable
            cell-class="has-text-left"
          >
            {{ props.row ? props.row.attributes.name : "" }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            label="Löschen"
            cell-class="has-text-right"
            header-class="th-right"
          >
            <!--						<b-button class="is-small mr-2" @click="triggerEditAttributeCharacteristic(props.row, attribute)">-->
            <!--							<b-icon icon="pencil" style="cursor: pointer"/>-->
            <!--						</b-button>-->
            <b-button
              class="is-small"
              @click="triggerDeleteAttributeCharacteristic(props.row)"
            >
              <b-icon
                icon="delete"
                style="cursor: pointer"
              />
            </b-button>
          </b-table-column>
        </b-table>
        <div style="display: flex">
          <b-input
            v-model="attributeCharacteristic"
            class="ml-2 mr-2"
            :placeholder="attribute.attributes.name"
            type="text"
            @input="() => changed = true"
          />
          <b-button @click="addAttributeCharacteristicLocally">
            <b-icon
              icon="plus"
              style="line-height:1; color: #0d0d0d !important;"
            />
          </b-button>
        </div>


        <b-modal v-model="isDeleteAttributeCharacteristicCheck">
          <div
            v-if="isDeleteAttributeCharacteristicCheck"
            class="panel m-5"
            style="background-color: white"
          >
            <p class="panel-heading">
              Möchtest du "{{ chosenAttributeCharac.attributes.name }}"
              wirklich löschen?
            </p>
            <div
              class="buttons mt-2"
              style="display: flex; justify-content: center"
            >
              <b-button
                class="is-danger"
                @click="submitDeleteAttributeCharac()"
              >
                Ja
              </b-button>
              <b-button
                class="is-primary"
                @click="isDeleteAttributeCharacteristicCheck = false"
              >
                Nein
              </b-button>
            </div>
          </div>
        </b-modal>
        <section>
          <div
            class="buttons"
            style="display: flex; justify-content: center"
          >
            <b-button
              id="submitClient"
              type="is-primary"
              :loading="isLoading"
              @click="editAttribute()"
            >
              Speichern
            </b-button>
            <b-button
              class="is-danger"
              @click="cancel()"
            >
              Abbrechen
            </b-button>
          </div>
        </section>
      </div>
    </div>

    <b-modal v-model="cancelWarning">
      <div
        v-if="cancelWarning"
        class="panel m-5"
        style="background-color: white"
      >
        <p class="panel-heading">
          Möchtest du wirklich abbrechen (Änderungen gehen verloren)?
        </p>
        <div
          class="buttons mt-2"
          style="display: flex; justify-content: center"
        >
          <b-button
            class="is-danger"
            tag="router-link"
            to="/diary"
          >
            Ja
          </b-button>
          <b-button
            class="is-primary"
            @click="cancelWarning = false"
          >
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>

    <!-- soft delete -->
    <b-modal v-model="inUsageWarning">
      <div
        v-if="inUsageWarning"
        class="panel m-5"
        style="background-color: white"
      >
        <p class="panel-heading">
          Das Attribut "{{ chosenAttributeCharac.attributes.name }}" ist in Verwendung. Möchtest du es wirklich löschen?
        </p>
        <div
          class="buttons mt-2"
          style="display: flex; justify-content: center"
        >
          <b-button
            class="is-danger"
            @click="softDeleteAttribute"
          >
            Ja
          </b-button>
          <b-button
            class="is-primary"
            @click="inUsageWarning = false"
          >
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/**
 * This file is for editing an attribute and additional sub attributes (used in diary)
 */
import {Chrome} from 'vue-color'
import {
	queryAttributeCharacteristicsByConfigPointer,
	queryEntries
} from "../../../lib/Query";
import {getComplementaryHexColor} from "../../../lib/Color";
import Parse from "parse/dist/parse.min.js"
import {negativeFeedBack, positiveFeedBack} from "@/lib/Toast";

export default {
	name: "EditAttribute",
	components: {
		'chrome-picker': Chrome
	},
	data() {
		return {
			attributeName: "",
			colors: {
				"hex": "#000000",
				"source": "hex"
			},
			colorPickerOpen: false,
			isSingleSelection: true,
			attribute: undefined,

			isEditAttributeCharacteristic: false,
			chosenAttributeCharac: undefined,
			isDeleteAttributeCharacteristicCheck: false,
			attributeCharacteristics: undefined,
			entryAttributes: [],

			attributeCharacteristic: "",
			attributeCharacteristicsLocal: [],
			changed: false,
			cancelWarning: false,

			entries: [],
			inUsageWarning: false,
			isLoading: false
		}
	},
	async created() {
		const attribute = this.$route.params.data
		if(!attribute) return await this.$router.push("/diary")
		this.attributeName = attribute.attributes.name
		this.colors["hex"] = attribute.attributes.color
		this.isSingleSelection = attribute.attributes.isSingleSelection
		this.attribute = attribute
		this.attributeCharacteristics = await queryAttributeCharacteristicsByConfigPointer(this.attribute.toPointer())
		this.entries = await queryEntries()


	},
	methods: {
		toggleColorPicker() {
			this.changed = true;
			this.colorPickerOpen = !this.colorPickerOpen;
		},
		async editAttribute() {
			this.isLoading = true
			this.attribute.set("color", this.colors.hex)
			for(const attributeCharacteristicLocal of this.attributeCharacteristicsLocal){
				try {
					await attributeCharacteristicLocal.save()
					const entryAttributes = this.attribute.attributes.entryAttributes ? this.attribute.attributes.entryAttributes : []
					entryAttributes.push(attributeCharacteristicLocal.toPointer())
					this.attribute.set("entryAttributes", entryAttributes)
				} catch (e) {
					console.error("couldnt create entry attribute " + e)
				}
			}
			try {
				await this.attribute.save()
				positiveFeedBack("Attribut bearbeitet!")
				await this.$router.push({path: "/diary"})
			} catch (e) {
				console.error("attribute couldnt be editted" + e)
				negativeFeedBack("Attribut wurde nicht bearbeitet!")
			}
			this.isLoading = false
		},
		getComplementaryHexColor(colors) {
			return getComplementaryHexColor(colors)
		},
		triggerEditAttributeCharacteristic(chosenAttributeCharacteristic, attribute) {
			this.$router.push({
				name: "EditAttributeCharacteristic",
				params: {attribute: attribute, defValue: chosenAttributeCharacteristic}
			})
			this.chosenAttributeCharac = chosenAttributeCharacteristic
			this.isEditAttributeCharacteristic = true
		},
		triggerDeleteAttributeCharacteristic(chosenAttributeCharacteristic) {
			this.chosenAttributeCharac = chosenAttributeCharacteristic
			if(this.inUsage()){
				this.inUsageWarning = true
			}else{
				this.isDeleteAttributeCharacteristicCheck = true
			}
		},
		triggerAddAttributeCharacteristic(attribute) {
			this.$router.push({name: "NewAttributeCharacteristic", params: {data: attribute}})
		},
		async submitDeleteAttributeCharac() {
			if(this.attributeCharacteristicsLocal.includes(this.chosenAttributeCharac)){
				this.attributeCharacteristicsLocal = this.attributeCharacteristicsLocal.filter(attribute => attribute !== this.chosenAttributeCharac)
				positiveFeedBack("Attribut gelöscht!")
			}else{
				try {
					const newEntryAttributes = this.attribute.attributes.entryAttributes.filter(entryAttribute => entryAttribute.id !== this.chosenAttributeCharac.id)
					this.attribute.set("entryAttributes", newEntryAttributes)
					this.chosenAttributeCharac.set("deleted", true)
					await this.attribute.save()
					await this.chosenAttributeCharac.save()
					positiveFeedBack("Attribut gelöscht!")
				} catch (e) {
					negativeFeedBack("Attribut nicht gelöscht!")
					console.error("couldnt delete attribute characteristic " + e)
				}
			}
			this.attributeCharacteristics = await queryAttributeCharacteristicsByConfigPointer(this.attribute.toPointer())
			this.attributeCharacteristicsLocal.forEach(attribute => this.attributeCharacteristics.push(attribute))
			this.isDeleteAttributeCharacteristicCheck = false
		},
		addAttributeCharacteristicLocally() {
			this.changed = true
			const attributeConfig = this.attribute.toPointer()
			const EntryAttribute = Parse.Object.extend("EntryAttributes")
			const entryAttribute = new EntryAttribute()
			entryAttribute.set("attribute_config", attributeConfig)
			entryAttribute.set("type", this.attribute.attributes.name.trim())
			entryAttribute.set("name", this.attributeCharacteristic.trim())
			this.attributeCharacteristicsLocal.push(entryAttribute)
			this.attributeCharacteristics.push(entryAttribute)
			this.attributeCharacteristic = ""
		},
		async cancel(){
			if(this.changed){
				this.cancelWarning = true
			}else{
				await this.$router.push({path: "/diary"})
			}
		},
		inUsage(){
			for(const entry of this.entries){
				if(entry.attributes.attributes){
					for(const entryAttribute of entry.attributes.attributes){
						if(entryAttribute && entryAttribute.id === this.chosenAttributeCharac.id){
							return true
						}
					}
				}

			}
			return false;
		},
		async softDeleteAttribute(){
				this.chosenAttributeCharac.set("deleted", true)
				try {
					await this.chosenAttributeCharac.save()
					positiveFeedBack("Attribut gelöscht!")
				} catch (e) {
					negativeFeedBack("Attribut nicht gelöscht!")
					console.error("couldnt delete attribute characteristic " + e)
				}

			this.attributeCharacteristics = await queryAttributeCharacteristicsByConfigPointer(this.attribute.toPointer())
			this.attributeCharacteristicsLocal.forEach(attribute => this.attributeCharacteristics.push(attribute))
			this.inUsageWarning = false
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
