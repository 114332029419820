import Parse from "parse/dist/parse.min.js";

/**
 * handles errors in query
 * @param err
 */
export const handleParseError = (err) => {
	switch (err.code) {
		case Parse.Error.INVALID_SESSION_TOKEN:
			Parse.User.logOut()
			this.$router.push({path: "/login"}).catch(e=> console.error("couldnt redirect to login page " + e))
			break;
	}
}
