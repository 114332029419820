<template>
  <div class="is-flex column">
    <div class="column">
      <InputComponent
        class="column mb-0 pb-0 is-fullwidth mt-2"
        :label="title"
        :set-name="val => modelValue= val"
        :name="modelValue"
        type="number"
      />
      <span
        v-if="modelValue && message"
        style="color: #f01f49; font-size: small"
        class="ml-4"
      >{{ message }}</span>
    </div>
    <b-field
      label=""
      class="is-flex is-align-items-center mt-5 pt-3"
    >
      <p style="font-weight: bold">
        Tage
      </p>
    </b-field>
  </div>
</template>

<script>
import InputComponent from "@/components/helperComponents/InputComponent";
export default {
	name: "ReportDateComponent",
	components:{
		InputComponent
	},
	props:[
		"title",
		"proppedModelValue",
		"message",
	],
	emits:["input"],
	data(){
		return{
		}
	},
	computed: {
		modelValue:{
			get(){
				return this.proppedModelValue
			},
			set(val){
				this.$emit("input", val)
			}
		}
	},
	watch:{
		modelValue(newVal){
			if(newVal && newVal.length === 0) this.modelValue = 0
		}
	},

}
</script>

<style scoped>

</style>
