<template>
  <div class="p-2">
    <b-button
      v-if="data.length > 0"
      class="is-primary"
      :disabled="!selectedAccount ||
        !selectedBalance ||
        data.some(row => row.entity === undefined) ||
        columns.some((c, idx) => {
          if(idx === 0) return false
          return c.value === undefined
        })"
      @click="$emit('save')"
    >
      Speichern
    </b-button>
  </div>
</template>

<script>
export default {
	name: "StockUpSave",
	props: ["selectedAccount", "selectedBalance", "data", "columns"]
}
</script>

<style scoped>

</style>