<template>
	<div>
		<div class="panel m-5">
			<PanelHeader :panel-header="panelHeader" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<InputWithValidation class="column" message="Bitte geben Sie einen Bezeichner ein!" :name="medicationFormName"
						:set-name="(name) => (medicationFormName = name)" label="Bezeichner der Darreichungseinheit" />
					<InputWithValidation class="column" message="Bitte geben Sie ein Kürzel ein!" :name="medicationFormSymbol"
						:set-name="(name) => (medicationFormSymbol = name)" label="Kürzel der Darreichungseinheit" />
				</div>
			</div>
		</div>
		<div class="m-5 buttons" style="display: flex; justify-content: center">
			<SaveButton :is-loading="isLoading" :save-function="saveDataObject"
				:disabled="$v.medicationFormName.$invalid || $v.medicationFormSymbol.$invalid" />
			<CancelButton text="Abbrechen" back-route="/medication_unit" :is-loading="false" />
		</div>
	</div>
</template>
  
<script>
/**
 * This file is for creating and editing medication units.
 */
import Parse from "parse/dist/parse.min.js";
import { minLength, required } from "vuelidate/lib/validators";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";

export default {
	name: "NewMedicationUnit",
	components: {
		SaveButton,
		CancelButton,
		InputWithValidation,
		PanelHeader,
	},
	data() {
		return {
			dataObject: undefined,
			isLoading: false,
			medicationFormName: "",
			medicationFormSymbol: "",
			panelHeader: "Neue Darreichungsform (Einheit) anlegen"
		};
	},
	computed: {},
	watch: {},
	mounted() {
		if (this.$route.params.data) {
			this.dataObject = this.$route.params.data
			this.medicationFormName = this.dataObject.attributes.name
			this.medicationFormSymbol = this.dataObject.attributes.symbol
			this.panelHeader = "Darreichungsform (Einheit) bearbeiten"
		}
	},
	validations: {
		medicationFormName: {
			required,
			minLength: minLength(1),
		},
		medicationFormSymbol: {
			required,
			minLength: minLength(1),
		},
	},
	methods: {
		async saveDataObject() {
			this.isLoading = true;
			const parseObject = Parse.Object.extend("MedicationUnit")
			const dataObject = this.dataObject ? this.dataObject : new parseObject()
			dataObject.set("name", this.medicationFormName)
			dataObject.set("symbol", this.medicationFormSymbol)
			dataObject.set("deleted", false)
			await dataObject.save().catch((e) => {
				negativeFeedBack("Darreichungsform (Einheit) nicht gespeichert!")
			});
			this.isLoading = false;
			await this.$router.push({ path: "/medication_unit" })
			await positiveFeedBack("Darreichungsform (Einheit) gespeichert!")
		},
	},
};
</script>
  
<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
  