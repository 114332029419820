<template>
  <div>
    <SearchableTable
      :panel-header="'Externe'"
      :panel-route="'AddExternal'"
      :show-search-bar="true"
      :table-data="externals"
      :table-meta-data="tableMetaData"
      :query-string="'setExternalSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerView(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="eye"
            style="cursor: pointer"
          />
        </b-button>
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>

    <b-modal v-model="isDeleteCheck">
      <div
        v-if="isDeleteCheck"
        class="panel m-5"
        style="background-color: white"
      >
        <p class="panel-heading">
          Möchtest du
          "{{ chosenExternal.attributes.first_name }}
          {{
            chosenExternal.attributes.last_name
          }}"
          wirklich löschen?
        </p>
        <div
          class="buttons mt-2"
          style="display: flex; justify-content: center"
        >
          <b-button
            class="is-danger"
            :loading="isLoading"
            @click="submitDelete()"
          >
            Ja
          </b-button>
          <b-button
            class="is-primary"
            @click="closeModal()"
          >
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/**
 * This file shows all currently stored external persons and allows adding, editing, viewing and soft deleting external persons
 */
import { queryExternals, queryUserDetails } from "../../lib/Query";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import { mapGetters } from "vuex";
import SearchableTable from "../helperComponents/SearchableTable.vue";

export default {
	name: "ExternalComponent",
	components: { SearchableTable },
	data() {
		return {
			externalData: [],
			chosenExternal: undefined,
			isDeleteCheck: false,
			externalFilter: "",
			externalDataFiltered: [],
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Email", "field": "email", "sortField": "attributes.email" },
				{ "id": 1, "type": "string", "label": "Vorname", "field": "first_name", "sortField": "attributes.first_name" },
				{ "id": 2, "type": "string", "label": "Nachname", "field": "last_name", "sortField": "attributes.last_name" }
			]

		};
	},
	computed: {
		...mapGetters({
			externals: "getExternalFiltered"
		})
	},
	async mounted() {
		//await this.refreshExternal();
		//await this.$store.dispatch("setExternalSearchQuery", "")
	},
	methods: {
		triggerEdit(external) {
			this.$router.push({
				name: "EditExternal",
				params: { data: external },
			});
		},
		triggerView(external) {
			this.$router.push({
				name: "ViewExternal",
				params: { data: external },
			});
		},
		triggerDelete(external) {
			this.chosenExternal = external;
			this.isDeleteCheck = true;
		},
		async submitDelete() {
			this.isLoading = true
			this.chosenExternal.set("deleted", true);
			await this.chosenExternal
				.save()
				.then(async () => {
					positiveFeedBack("Externe Person gelöscht!")
					await this.refreshExternal();
					await this.filterExternal()
					this.isLoading = false
					this.isDeleteCheck = false;
				})
				.catch((e) => {
					negativeFeedBack("Externe Person nicht gelöscht!")
					console.error(e);
				});
		},
		closeModal() {
			this.isDeleteCheck = false;
		},
		async refreshExternal() {
			this.externalData = [];
			this.externalDataFiltered = []
			const externalUsers = await queryExternals();
			for (const external of externalUsers) {
				const externalDetails = await queryUserDetails(
					external.attributes.dependency.id
				);
				if (externalDetails) { // otherwise deleted
					const deleted = externalDetails.attributes.deleted
						? externalDetails.attributes.deleted === true
						: false;
					if (!deleted) {
						this.externalData.push(externalDetails);
						this.externalDataFiltered.push(externalDetails)
					}
				}

			}
		},
		filterExternal() {
			this.$store.dispatch("setExternalSearchQuery", this.externalFilter)
		}
	},
};
</script>

<style lang="scss" scoped></style>
