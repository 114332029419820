<template>
  <b-field
    :message="selectedAccount ? '' : 'Bitte wähle ein Konto!' "
    :type="selectedAccount ? 'is-success' : 'is-danger'"
  >
    <template #label>
      <div
        class="align-content-center"
        style="display: inline-flex"
      >
        <p>Accounts</p>
      </div>
    </template>
    <b-select
      v-model="selectedAccount"
      class="m-2"
      placeholder="Konten"
      expanded
      @input="set"
    >
      <option
        v-for="(account) in accounts"
        :key="account.id"
        :value="account"
      >
        {{ account.name }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
	name: "AccountSelection",
	props:["accounts", "selectedAccountValue"],
	computed:{
		selectedAccount:{
			get(){
				return this.selectedAccountValue
			},
			set(val){
				this.$emit("input", val)
			}
		}
	},
	methods:{
		set(){

		},
	}
}
</script>

<style scoped>

</style>
