<template>
  <div>
    <SearchableTable
      :panel-header="'Bildungsbereiche'"
      :panel-route="'AddEducationField'"
      :show-search-bar="false"
      :table-data="educationFields"
      :table-meta-data="tableMetaData"
      :query-string="'setPersonSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
    <b-modal v-model="isDeleteCheck">
      <div
        v-if="isDeleteCheck"
        class="panel m-5"
        style="background-color: white"
      >
        <p class="panel-heading">
          Möchtest du
          "{{ chosenEducationField.attributes.name }}" wirklich
          löschen?
        </p>
        <div
          class="buttons mt-2"
          style="display: flex; justify-content: center"
        >
          <b-button
            class="is-danger"
            :loading="isLoading"
            @click="submitDelete()"
          >
            Ja
          </b-button>
          <b-button
            class="is-primary"
            @click="() => isDeleteCheck = false"
          >
            Nein
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Parse from "parse/dist/parse.min"
import SearchableTable from "../helperComponents/SearchableTable.vue";
export default {
	name: "EducationFields",
	components: { SearchableTable },
	data() {
		return {
			educationFields: [],
			isDeleteCheck: false,
			chosenEducationField: undefined,
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Name", "field": "name", "sortField": "attributes.name" },
				{ "id": 1, "type": "color", "label": "Farbe", "field": "color" }
			]
		}
	},
	async mounted() {
		await this.query()
	},
	updated() {
		this.query()
	},
	methods: {
		async query() {
			const query = new Parse.Query("EducationField").notEqualTo("deleted", true)
			this.educationFields = await query.find()
		},
		triggerEdit(educationField) {
			this.$router.push({ name: "AddEducationField", params: { educationField: educationField } });
		},
		triggerDelete(educationField) {
			this.chosenEducationField = educationField
			this.isDeleteCheck = true
		},
		submitDelete() {
			this.chosenEducationField.set("deleted", true)
			this.chosenEducationField.save().then(() => {
				this.isDeleteCheck = false
				this.query()
			})
		}
	}
}
</script>

<style scoped></style>
