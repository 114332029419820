<template>
  <div class="panel m-5">
    <p class="panel-heading">
      {{ groupName }}
    </p>
    <div class="container pt-3 pb-3">
      <div class="bodyContainer">
        <div class="columns">
          <b-field
            v-if="group"
            class="column"
            label="Telefon 1"
          >
            <p>{{ group.attributes.phone1 }}</p>
          </b-field>
          <b-field
            v-if="group"
            class="column"
            label="Info 1"
          >
            <p>{{ group.attributes.info1 }}</p>
          </b-field>
        </div>
        <hr>
        <div class="columns">
          <b-field
            v-if="group"
            class="column"
            label="Telefon 2"
          >
            <p>{{ group.attributes.phone2 }}</p>
          </b-field>
          <b-field
            v-if="group"
            class="column"
            label="Info 2"
          >
            <p>{{ group.attributes.info2 }}</p>
          </b-field>
        </div>
        <hr>
        <div class="columns">
			<b-field
            v-if="group"
            class="column"
            label="Straße"
          >
            <p>{{ group.attributes.street }}</p>
          </b-field>
          <b-field
            v-if="group"
            class="column"
            label="PLZ"
          >
            <p>{{ group.attributes.plz }}</p>
          </b-field>
          <b-field
            v-if="group"
            class="column"
            label="Ort"
          >
            <p>{{ group.attributes.location }}</p>
          </b-field>
        </div>
        <hr>
        <div class="columns">
          <div class="column">
            <b-table
              narrowed
              :data="chosenClients"
              striped
            >
              <b-table-column
                v-slot="props"
                label="Klienten"
                cell-class="has-text-left"
              >
                <p class="content">
                  {{ props.row.attributes.first_name }}
                  {{ props.row.attributes.last_name }}
                </p>
              </b-table-column>
            </b-table>
          </div>
          <div class="column">
            <b-table
              narrowed
              :data="chosenUsers"
              striped
            >
              <b-table-column
                v-slot="props"
                label="Mitarbeiter"
                cell-class="has-text-left"
              >
                <p class="content">
                  {{ props.row.attributes.first_name }}
                  {{ props.row.attributes.last_name }}
                </p>
              </b-table-column>
            </b-table>
          </div>
        </div>

        <section class="m-5">
          <div
            class="buttons"
            style="display: flex; justify-content: center"
          >
            <b-button
              class="is-danger"
              tag="router-link"
              to="/groups"
            >
              Zurück
            </b-button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * This file is for viewing a focused group
 */
import {queryClients, queryGroups, queryUserMetaClasses} from "../../lib/Query";

export default {
	name: "ViewGroup",
	data() {
		return {
			groupName: "",
			chosenClients: [],
			chosenUsers: [],
			group: undefined
		}
	},
	async created() {
		await queryGroups()
		const clients = await queryClients()
		const users = await queryUserMetaClasses()
		const group = this.$route.params.data
		this.group = group
		if (group) {
			this.groupName = group.attributes.group_name
			clients.forEach(client => {
				if(client.attributes.group.id === group.id){
					this.chosenClients.push(client)
				}
			})
			users.forEach(user => {
				const groupIds = user.attributes.groups ? user.attributes.groups.map(group => group.id): []
				if(groupIds.includes(group.id)){
					this.chosenUsers.push(user)
				}
			})
		}else{
			await this.$router.push("/groups")
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
