<template>
	<div>
		<div class="panel m-5">
			<PanelHeader panel-header="Kasseninformationen" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<b-field label="Kassenname" class="column">
						{{ name }}
					</b-field>
					<b-field label="Kassennummer" class="column">
						{{ num }}
					</b-field>
					<b-field label="Darf das Konto ins Minus gehen?" class="column">
						{{ allowNegativeValue? "Ja" : "Nein" }}
					</b-field>
				</div>
				<b-field v-if="false" label="Hinweis" class="column">
					{{ description }}
				</b-field>
			</div>
		</div>
		<div class="panel m-5">
			<PanelHeader panel-header="Konten" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<div class="borderContainer column">
						<b-field class="column">
							<ul>
								<li v-for="acc in accountList" :key="acc.id">
									{{ acc.name + " (" + acc.token + ")" }}
								</li>
							</ul>
						</b-field>
					</div>
				</div>
			</div>
		</div>
		<div class="panel m-5">
			<PanelHeader panel-header="Budgets" />
			<div class="container pt-3 pb-3">
				
					<ul>
						<li v-for="budget in budgetList" :key="budget.id">
							<div class="columns">
								<div class="column">
									{{ budget.attributes.name }}
								</div>
								<div class="column">
									{{ budget.attributes.budgetNumber }}
								</div>
								<div class="column">
									{{ budget.attributes.childSpecific ? 'Kind-spezifisch' :
										'Kind-unspezifisch'
									}}
								</div>
							</div>
							<div>{{ budget.attributes.description }}</div>
							<hr>
						</li>
						
					</ul>
				
			</div>
		</div>
		<div class="panel m-5">
			<PanelHeader panel-header="Verantwortlicher" />
			<div class="container pt-3 pb-3">
				<b-field >
					<ul>
						<li class="columns">
							<div class="column">
								{{ manager.attributes.dependency.attributes.first_name }} {{
									manager.attributes.dependency.attributes.last_name
								}}
							</div>
						</li>
					</ul>
				</b-field>
			</div>
		</div>
		<div class="panel m-5">
			<PanelHeader panel-header="Gruppe/Kinder/Mitarbeiter" />
			<div class="container pt-3 pb-3 text-center">
				<b-field label="Gruppen">
					<div class="columns">
						<div class="column">
							{{ chosenGroup.attributes.group_name }}
						</div>
					</div>
				</b-field>
				<b-field label="Kinder" class="mt-3">
					<div class="columns">
						<div class="column">
							<div v-for="client in chosenClients" :key="client.id">
								{{ client.attributes.first_name }} {{ client.attributes.last_name }}
							</div>
						</div>
					</div>
				</b-field>

				<b-field label="Mitarbeiter" class="mt-3">
					<div class="columns">
						<div class="column">
							<div v-for="user in chosenUsers" :key="user.id" >
								{{ user.attributes.dependency.attributes.first_name }} {{
									user.attributes.dependency.attributes.last_name }}
							</div>
						</div>
					</div>
				</b-field>
				<hr>
				<div class="m-5 buttons" style="display: flex; justify-content: center">
					<CancelButton text="Zurück" back-route="/balance_overview" :is-loading="false" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PanelHeader from "@/components/helperComponents/PanelHeader";
import CancelButton from "@/components/helperComponents/CancelButton";

export default {
	name: "ViewBalanceGroup",
	components: {
		CancelButton,
		PanelHeader
	},
	data() {
		return {
			name: "",
			manager: undefined,
			num: undefined,
			description: "",
			accountList: [],
			budgetList: [],
			isLoading: false,
			chosenClients: [],
			chosenGroup: undefined,
			value: 0,
			balanceGroup: undefined,
			chosenUsers: [],
			allowNegativeValue : false,
		}
	},
	created() {
		if (!this.$route.params.balanceGroup) return
		this.balanceGroup = this.$route.params.balanceGroup
		this.name = this.balanceGroup.attributes.name
		this.description = this.balanceGroup.attributes.description
		this.manager = this.balanceGroup.attributes.manager
		this.num = +this.balanceGroup.attributes.balanceNumber
		this.accountList = this.balanceGroup.attributes.accounts.filter(acc => !acc.isCash)
		this.budgetList = this.balanceGroup.attributes.budgets
		const entities = this.balanceGroup.attributes.entities.slice();
		this.chosenGroup = entities[entities.length - 1]
		entities.pop()
		this.chosenClients = entities
		this.chosenUsers = this.balanceGroup.attributes.users;
		this.allowNegativeValue = this.balanceGroup.attributes.allowNegativeAccountBalance
	},
	methods: {
		budgetSum(entities) {
			let sum = 0
			for (const entity in entities) {
				sum += entities[entity]
			}
			return sum;
		}
	},
}
</script>

<style scoped>
.tableMatrix {
	width: 100%;
}

.table-header {
	padding-bottom: .75rem;
	border-bottom: 2px solid rgba(0, 0, 0, .1);
}

.table-footer {
	padding-bottom: .75rem;
	border-top: 2px solid rgba(0, 0, 0, .1);
}

.table-header>th {
	height: 40px;
	text-align: center;
	vertical-align: middle;
}

td {
	text-align: center;
	vertical-align: middle;
	height: 40px;
}

td,
th {
	min-width: 150px;
}


.has-shadow {
	border-radius: .5rem;
	border: 2px solid rgba(0, 0, 0, .8);
}

.overflow {
	width: 100%;
	max-height: 30rem;
	overflow: auto;
}

.borderContainer {
	border-radius: .5rem;
	padding: .5rem;
	margin: .5rem;
}

.w50 {
	width: 50%;
}

::v-deep .label {
	font-size: large;
}
</style>
