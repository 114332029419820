<template>
  <div>
    <b-field>
      <b-input
        v-model="searchTerm"
        placeholder="Suchfeld"
        type="search"
        icon="magnify"
        icon-clickable
        icon-right="close-circle"
        icon-right-clickable
        @keyup.native.enter="startSearch"
        @icon-click="startSearch"
        @icon-right-click="clear"
      />
    </b-field>
    <b-menu class="is-primary">
      <b-menu-list
        v-if="isUserLoggedIn()"
        class="is-primary"
      >
        <b-menu-item
          v-if="searchResult"
          icon="cog"
          :active="isActive"
          expanded
        >
          <template #label="props">
            Suchergebnisse
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'menu-down' : 'menu-up'"
            />
          </template>
          <div
            v-for="(item, index) in filteredData"
            :key="index"
          >
            <div @click="setSelectedTab(item.name)">
              <b-menu-item
                :icon="item.icon"
                :tag="item.tag"
                :to="item.path"
                :label="item.label"
                :class="selectedTab === item.name ? 'is-active' : ''"
              />
            </div>
          </div>
        </b-menu-item>
        <b-menu-item
          v-else
          icon="cog"
          label="Verwaltung"
          :active="isActive"
          expanded
        >
          <b-menu-item
            v-for="(cat, catIndex) in catArray"
            :key="catIndex"
            :icon="cat.icon"
            disabled
            expanded
            :label="cat.label"
          >
            <div
              v-for="(item, index) in arrayData"
              :key="index"
            >
              <div
                v-if="item.cat == cat.name"
                @click="setSelectedTab(item.name)"
              >
                <b-menu-item
                  :icon="item.icon"
                  :tag="item.tag"
                  :to="item.path"
                  :label="item.label"
                  :class="selectedTab === 'Info' ? '' : selectedTab === item.name ? 'is-active' : ''"
                />
              </div>
            </div>
          </b-menu-item>
        </b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Parse from "parse/dist/parse.min";
// 2. vor schleife um die bestehnde drum herum mit extra kategorien in 2. array + kategorie tag um 1. array einfügen

const catArray = [
	{ 'id': 0, 'name': 'User', 'label': 'Personen', 'icon': 'account' },
	{ 'id': 1, 'name': 'Facilities', 'label': 'Einrichtungen', 'icon': 'account' },
	{ 'id': 2, 'name': 'Finance', 'label': 'Finanzen', 'icon': 'account' },
	{ 'id': 3, 'name': 'Documents', 'label': 'Dokumente', 'icon': 'account' },
	{ 'id': 4, 'name': 'Medication', 'label': 'Medikation', 'icon': 'medication' },
	//{ 'id': 5, 'name': 'Data', 'label': 'Daten', 'icon': 'account' },
	{ 'id': 6, 'name': 'Etc', 'label': 'Sonstiges', 'icon': 'view-dashboard' }
];

const arrayData = [
	{ 'id': 0, 'name': 'Users', 'label': 'Nutzer', 'icon': 'account-plus', 'tag': 'router-link', 'path': '/users', 'cat': 'User' },
	{ 'id': 1, 'name': 'Groups', 'label': 'Gruppen', 'icon': 'account-group', 'tag': 'router-link', 'path': '/groups', 'cat': 'User' },
	{ 'id': 2, 'name': 'Clients', 'label': 'Klienten', 'icon': 'account-child', 'tag': 'router-link', 'path': '/clients', 'cat': 'User' },
	{ 'id': 3, 'name': 'External', 'label': 'Externe', 'icon': 'account-plus', 'tag': 'router-link', 'path': '/externals', 'cat': 'User' },
	{ 'id': 4, 'name': 'Diary', 'label': 'Tagebuch', 'icon': 'book-open-variant', 'tag': 'router-link', 'path': '/diary', 'cat': 'Documents' },
	{ 'id': 5, 'name': 'ClientRecords', 'label': 'Kinderakte', 'icon': 'text-box', 'tag': 'router-link', 'path': '/client_records', 'cat': 'Documents' },
	{ 'id': 6, 'name': 'Districts', 'label': 'Landkreise', 'icon': 'map-marker-radius', 'tag': 'router-link', 'path': '/districts', 'cat': 'Facilities' },
	{ 'id': 7, 'name': 'persons', 'label': 'Personen', 'icon': 'account-child', 'tag': 'router-link', 'path': '/persons', 'cat': 'User' },
	{ 'id': 8, 'name': 'socialWorker', 'label': 'Sozialarbeiter', 'icon': 'account-tie', 'tag': 'router-link', 'path': '/socialWorker', 'cat': 'User' },
	{ 'id': 9, 'name': 'school', 'label': 'Schule/Kindergarten', 'icon': 'school', 'tag': 'router-link', 'path': '/schools', 'cat': 'Facilities' },
	{ 'id': 10, 'name': 'statutoryBasis', 'label': 'Gesetzliche Grundlage', 'icon': 'scale-balance', 'tag': 'router-link', 'path': '/statutoryBasis', 'cat': 'Documents' },
	{ 'id': 11, 'name': 'reportTemplates', 'label': 'Berichtsvorlagen', 'icon': 'book', 'tag': 'router-link', 'path': '/report_templates', 'cat': 'Documents' },
	{ 'id': 12, 'name': 'balance', 'label': 'Kassen', 'icon': 'account-cash', 'tag': 'router-link', 'path': '/balance_overview', 'cat': 'Finance' },
	{ 'id': 13, 'name': 'education_fields', 'label': 'Wochenplan', 'icon': 'calendar-week', 'tag': 'router-link', 'path': '/education_fields', 'cat': 'Documents' },

	//{ 'id': 14, 'name': 'abwesenheit', 'label': 'An-/Abwesenheit', 'icon': 'calendar-blank', 'tag': 'router-link', 'path': '/anwesenheit', 'cat': 'Documents' },
	{ 'id': 14, 'name': 'licenseKind', 'label': 'Bewilligungstyp', 'icon': 'book-information-variant', 'tag': 'router-link', 'path': '/license_kind', 'cat': 'Documents' },

	{ 'id': 16, 'name': 'medication', 'label': 'Medikamente', 'icon': 'medication', 'tag': 'router-link', 'path': '/medication', 'cat': 'Medication' },
	{ 'id': 17, 'name': 'medication_form', 'label': 'Darreichungsform (Art)', 'icon': 'medical-bag', 'tag': 'router-link', 'path': '/medication_form', 'cat': 'Medication' },
	{ 'id': 18, 'name': 'medication_unit', 'label': 'Darreichungsform (Einheit)', 'icon': 'medical-bag', 'tag': 'router-link', 'path': '/medication_unit', 'cat': 'Medication' },
	{ 'id': 19, 'name': 'doctor', 'label': 'Ärzte', 'icon': 'doctor', 'tag': 'router-link', 'path': '/doctor', 'cat': 'Medication' },
	{ 'id': 20, 'name': 'diagnose', 'label': 'Diagnosen', 'icon': 'doctor', 'tag': 'router-link', 'path': '/diagnose', 'cat': 'Medication' },
	{ 'id': 21, 'name': 'icd_10_code', 'label': 'ICD-10-Codes', 'icon': 'medical-bag', 'tag': 'router-link', 'path': '/icd_10', 'cat': 'Medication' },
	{ 'id': 22, 'name': 'instant_medication', 'label': 'Bedarfsmedikamente', 'icon': 'medication', 'tag': 'router-link', 'path': '/instant_medication', 'cat': 'Medication' },
	{ 'id': 23, 'name': 'DataImport', 'label': 'Daten Import', 'icon': 'database-import', 'tag': 'router-link', 'path': '/data_import', 'cat': 'Data' },
	{ 'id': 24, 'name': 'DataExport', 'label': 'Daten Export', 'icon': 'database-export', 'tag': 'router-link', 'path': '/data_export', 'cat': 'Data' },
	{ 'id': 25, 'name': 'DataProtection', 'label': 'Datenschutz', 'icon': 'shield', 'tag': 'router-link', 'path': '/dataprotection', 'cat': 'Etc' },
	{ 'id': 26, 'name': 'Imprint', 'label': 'Impressum', 'icon': 'help-circle', 'tag': 'router-link', 'path': '/imprint', 'cat': 'Etc' },
	{ 'id': 27, 'name': 'Widget', 'label': 'Widgets', 'icon': 'shield', 'tag': 'router-link', 'path': '/widgets', 'cat': 'Etc' }
];

let filteredData = [];

export default {
	name: "SideBar",
	data() {
		return {
			catArray,
			arrayData,
			filteredData,
			searchTerm: "",
			searchResult: false,
			selectedTab: "",
			isActive: true,
			isAttributesActive: false,
		};
	},
	computed: {
		...mapGetters({
			user: "getCurrentUser",
		}),
	},
	async mounted() {
		if(this.$route.name != null && this.routeExists(this.$route.name))
			await this.setSelectedTab(this.$route.name);
		else
		{
			this.$router.push('/');
		}
	},
	methods: {
		setSelectedTab: async function (selectedTab) {
			this.selectedTab = selectedTab;
			if (this.searchResult) {
				this.clear();
			}
		},
		isUserLoggedIn() {
			return Parse.User.current() !== null;
		},
		startSearch() {
			this.filteredData = [];
			this.arrayData.forEach(element => {
				if (element.label.toLowerCase().match(this.searchTerm.toLowerCase())) {
					this.filteredData.push(element);
				}
			})
			if (this.filteredData.length > 0) {
				this.searchResult = true;
			} else {
				this.searchResult = false;
			}
			this.searchTerm = "";
		},
		clear() {
			this.searchTerm = "";
			this.searchResult = false;
			this.filteredData = [];
		},
		routeExists: async function (route) {
			let found = false;
			found = this.arrayData.forEach(ele => {
				if (ele.name == route)
				{
					return true;
				}
			})
			return found;
		},
	},
};
</script>

<style scoped>
::v-deep .is-active {
	background-color: rgba(0, 0, 0, 0.8) !important;
	color: white !important;
}

::v-deep .is-active>a {
	color: white !important;
}

::v-deep .is-active>a:hover {
	color: black !important;
	background-color: whitesmoke;
	border-radius: 0 0 0 0;
}

::v-deep a.is-active {
	font-weight: bold;
	font-size: 1.25rem;
	padding: 0.9em 1em;
	border-radius: 0 6px 0 0;
}

::v-deep .menu-list>li {
	box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
	border-radius: 0 0 6px 0;
	padding-bottom: 0.75em;
}

::v-deep .menu-list>li>ul {
	border:none;
	margin-left: 0;
}
</style>