<template>
  <div class="panel m-5">
    <PanelHeader :panel-header="school ? school.attributes.name : 'Schule/Kindergarten'" />
    <div class="container pt-3 pb-3">
      <div class="columns">
        <b-field
          v-if="school"
          class="column"
          label="Name"
        >
          <p>{{ school.attributes.name }}</p>
        </b-field>
        <b-field
          v-if="school"
          class="column"
          label="Email"
        >
          <p>{{ school.attributes.email }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
        <b-field
          v-if="school"
          class="column"
          label="Telefon 1"
        >
          <p>{{ school.attributes.phone1 }}</p>
        </b-field>
        <b-field
          v-if="school"
          class="column"
          label="Info 1"
        >
          <p>{{ school.attributes.info1 }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
        <b-field
          v-if="school"
          class="column"
          label="Telefon 2"
        >
          <p>{{ school.attributes.phone2 }}</p>
        </b-field>
        <b-field
          v-if="school"
          class="column"
          label="Info 2"
        >
          <p>{{ school.attributes.info2 }}</p>
        </b-field>
      </div>
      <hr>
      <div class="columns">
		<b-field
          v-if="school"
          class="column"
          label="Straße"
        >
          <p>{{ school.attributes.street }}</p>
        </b-field>
        <b-field
          v-if="school"
          class="column"
          label="PLZ"
        >
          <p>{{ school.attributes.plz }}</p>
        </b-field>
        <b-field
          v-if="school"
          class="column"
          label="Ort"
        >
          <p>{{ school.attributes.location }}</p>
        </b-field>
      </div>
      <hr>
      <CancelButton
        text="Zurück"
        back-route="/schools"
      />
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/helperComponents/PanelHeader";
import CancelButton from "@/components/helperComponents/CancelButton";
export default {
	name: "ViewSchool",
	components: {CancelButton, PanelHeader},
	data(){
		return{
			school: undefined
		}
	},
	async mounted() {
		if (!this.$route.params.data) await this.$router.push({path: "/schools"})
		this.school = this.$route.params.data;
	}
}
</script>

<style scoped>
p{
	text-align: center;
}
</style>
