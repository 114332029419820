<template>
  <div class="containerWindow">
    <p>{{ text }}</p>
    <div
      class="p-4"
      style="display: flex; align-items: center"
      @click="deleteFile"
    >
      <b-icon icon="delete" />
    </div>
  </div>
</template>

<script>
/**
 * This file is a container for showing some text and triggering a function
 * @param: text: String as description, triggerDelete: function for submititng
 */
export default {
	name: "FileDelete",
	props:["text","triggerDelete"],
	methods:{
		deleteFile(){
			this.triggerDelete()
		}
	}
}
</script>

<style scoped>
.containerWindow{
	width: 50%;
	border: 2px solid rgba(0,0,0,0.6);
	border-radius: .5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
