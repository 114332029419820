<template>
	<div>
		<div class="panel m-5">
			<PanelHeader :panel-header="panelHeader" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<InputWithValidation class="column" message="Bitte geben Sie einen Bezeichner ein!"
						:name="medicationName" :set-name="(val) => (medicationName = val)" label="Bezeichner" />
					<InputComponent class="column" :name="medicationDrug" :set-name="(val) => (medicationDrug = val)"
						label="Wirkstoff" />
					<InputWithValidation class="column" message="Bitte geben Sie die Stärke ein!" :name="medicationPotency"
						:set-name="(val) => (medicationPotency = val)" label="Stärke" />
					<ValidatedSelection class="column" label="Klasse" message="Bitte wählen Sie eine Klasse aus!"
						attributeName="name" :has-validation="true" :options="medicationClassArray"
						:selected-option="medicationClass" :select="(item) => (medicationClass = item)" />

				</div>
				<hr>
				<div class="columns">
					<InputWithValidation type="number" class="column" message="Bitte geben Sie die maximale Menge ein!"
						:name="medicationMax" :set-name="(val) => (medicationMax = val)" label="Anzahl/(Füll-)Menge" />
					<ValidatedSelection class="column" label="Darreichungsform (Einheit)"
						message="Bitte wählen Sie eine Darreichungseinheit aus!" attributeName="name"
						secondAttributeName="symbol" :useBracket="true" :has-validation="true"
						:options="medicationUnitArray" :selected-option="medicationUnit"
						:select="(item) => (medicationUnit = item)" />
					<ValidatedSelection class="column" label="Darreichungsform (Art)"
						message="Bitte wählen Sie eine Darreichungsart aus!" attributeName="name" :has-validation="true"
						:options="medicationFormArray" :selected-option="medicationForm"
						:select="(item) => (medicationForm = item)" />
				</div>
				<hr>
				<div class="columns">
					<InputComponent type="textarea" class="column" :name="medicationHint"
						:set-name="(val) => (medicationHint = val)" label="Hinweise"
						:placeholder="'Beispiel: Zur Anwendung bei Kindern ab 20kg, Jugendlichen und Erwachsenen'" />
				</div>
				<hr>
				<div class="columns">
					<div class="column">
						<b-field label="Anzeigename">
							<p class="content">
								{{ displayName }}
							</p>
						</b-field>
						<p class="content">
							<small> (Bezeichner, Art, Stärke, Menge Einheit) </small>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="m-5 buttons" style="display: flex; justify-content: center">
			<SaveButton :is-loading="isLoading" :save-function="saveMedication" :disabled="$v.medicationName.$invalid || $v.medicationPotency.$invalid || $v.medicationForm.$invalid || $v.medicationUnit.$invalid
				|| $v.medicationClass.$invalid || $v.medicationMax.$invalid" />
			<CancelButton text="Abbrechen" back-route="/medication" :is-loading="false" />
		</div>
	</div>
</template>  

<script>
/**
 * This file is for creating and editing medications.
 */
import Parse from "parse/dist/parse.min.js";
import { minLength, required } from "vuelidate/lib/validators";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import InputComponent from "@/components/helperComponents/InputComponent"
import { mapGetters } from "vuex";
import ValidatedSelection from "@/components/helperComponents/ValidatedSelection";

export default {
	name: "NewMedication",
	components: {
		SaveButton,
		CancelButton,
		InputWithValidation,
		PanelHeader,
		InputComponent,
		ValidatedSelection,
	},
	data() {
		return {
			dataObject: undefined,
			isLoading: false,
			medicationName: "",
			medicationDrug: "",
			medicationPotency: "",
			medicationMax: "",
			medicationForm: undefined,
			medicationUnit: undefined,
			medicationHint: "",
			medicationClass: undefined,
			panelHeader: "Neues Medikament anlegen"
		};
	},
	computed: {
		...mapGetters({
			medicationFormArray: "getMedicalForm",
			medicationUnitArray: "getMedicalUnit",
			medicationClassArray: "getMedicalClass",
		}),
		displayName: function () {
			let medicationName = this.medicationName ? this.medicationName + ", " : "-, "
			let medicationPotency = this.medicationPotency ? this.medicationPotency + ", " : "-, "
			let medicationMax = this.medicationMax ? this.medicationMax + " " : "- "
			let medicationForm = "-, "
			if (this.medicationForm) {
				medicationForm = this.medicationFormArray.find((element) =>
					element.id == this.medicationForm.objectId
				);
			}
			let medicationUnit = "-"
			if (this.medicationUnit) {
				medicationUnit = this.medicationUnitArray.find((element) =>
					element.id == this.medicationUnit.objectId
				);
			}
			let displayName = medicationName + (medicationForm.attributes ? medicationForm.attributes.name + ", " : medicationForm) +
				medicationPotency + medicationMax + (medicationUnit.attributes ? medicationUnit.attributes.symbol + " " : medicationUnit)
			return displayName
		},
	},
	watch: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.dataObject = this.$route.params.data
			this.medicationName = this.dataObject.attributes.name
			this.medicationDrug = this.dataObject.attributes.drug
			this.medicationPotency = this.dataObject.attributes.potency
			this.medicationMax = this.dataObject.attributes.max
			this.medicationForm = (this.dataObject.attributes.medicationForm).toPointer()
			this.medicationUnit = (this.dataObject.attributes.medicationUnit).toPointer()
			this.medicationHint = this.dataObject.attributes.hint
			this.medicationClass = (this.dataObject.attributes.medicationClass).toPointer()
			this.panelHeader = "Medikament bearbeiten"
		}
	},
	validations: {
		medicationName: {
			required,
			minLength: minLength(1),
		},
		medicationPotency: {
			required,
			minLength: minLength(1),
		},
		medicationMax: {
			required
		},
		medicationForm: {
			required
		},
		medicationUnit: {
			required
		},
		medicationClass: {
			required
		}
	},
	methods: {
		async saveMedication() {
			this.isLoading = true;
			const parseObject = Parse.Object.extend("MedicationMedication")
			const dataObject = this.dataObject ? this.dataObject : new parseObject()
			dataObject.set("name", this.medicationName)
			dataObject.set("drug", this.medicationDrug)
			dataObject.set("potency", this.medicationPotency)
			dataObject.set("max", Number(this.medicationMax))
			dataObject.set("medicationForm", this.medicationForm)
			dataObject.set("medicationUnit", this.medicationUnit)
			dataObject.set("hint", this.medicationHint)
			dataObject.set("medicationClass", this.medicationClass)
			dataObject.set("displayName", this.displayName)
			dataObject.set("deleted", false)

			await dataObject.save().catch((e) => {
				negativeFeedBack("Medikament nicht gespeichert!")
			});
			this.isLoading = false;
			await this.$router.push({ path: "/medication" })
			await positiveFeedBack("Medikament gespeichert!")
		},
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		},
	},
};
</script>
  
<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
  