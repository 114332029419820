<template>
	<div>
		<div class="panel m-5">
			<PanelHeader v-if="enableHeader" :panel-header="panelHeader" :route-name="panelRoute"
				:route-params="panelParams">
				<b-input v-if="showSearchBar" v-model="filterString" :placeholder="'Suche ' + panelHeader" type="search"
					size="is-small" style="width:30%" icon="magnify" @input="filterElements" />
			</PanelHeader>
			<div class="container pt-3 pb-3">
				<div class="bodyContainer">
					<b-table :data="tableData" narrowed striped>
						<template v-for="column in tableMetaData">
							<b-table-column v-if="column.type === 'string'" :key="column.id" v-slot="props"
								:field="column.sortField" :label="column.label" cell-class="has-text-left" sortable>
								{{ getData(props, column.field) }}
							</b-table-column>
							<b-table-column v-if="column.type === 'math'" :key="column.id" v-slot="props"
								:label="column.label" cell-class="has-text-left">
								{{ createData(props) }}
							</b-table-column>
							<b-table-column v-if="column.type === 'ref'" :key="column.id" v-slot="props"
								:field="column.sortField" :label="column.label" cell-class="has-text-left" sortable>
								{{ getDataRef(props, column.refName, column.field) }}
							</b-table-column>
							<b-table-column v-if="column.type === 'test'" :key="column.id" v-slot="props"
								:field="column.sortField" :label="column.label" cell-class="has-text-left" sortable>
								{{ getMultipleDataRefDeep(props, column.outerRef, column.innerRef, column.field,
									column.sortField) }}
							</b-table-column>
							<b-table-column v-if="column.type === 'multiple'" :key="column.id" v-slot="props"
								:label="column.label" cell-class="has-text-left">
								{{ getMultipleDataRef(props, column.refName, column.field) }}
							</b-table-column>
							<b-table-column v-if="column.type === 'boolean'" :key="column.id" v-slot="props"
								:field="column.sortField" :label="column.label" cell-class="has-text-left" sortable>
								{{ getData(props, column.field) ? column.true : column.false }}
							</b-table-column>
							<b-table-column v-if="column.type === 'shallowData'" :key="column.id" v-slot="props"
								:field="column.sortField" :label="column.label" cell-class="has-text-left" sortable>
								{{ getShallowData(props, column.field) }}
							</b-table-column>
							<b-table-column v-if="column.type === 'combination'" :key="column.id" v-slot="props"
								:label="column.label" cell-class="has-text-left" :field="column.sortField" sortable>
								{{ getCombination(props, column.field, column.symbol, column.alternativeSymbol) }}
							</b-table-column>
							<b-table-column v-if="column.type === 'currency'" :key="column.id" v-slot="props"
								:label="column.label" cell-class="has-text-left" :field="column.sortField" sortable>
								{{ getCurrency(props, column.field) + column.symbol}}
							</b-table-column>
							<b-table-column v-if="column.type === 'color'" :key="column.id" v-slot="props"
								:label="column.label" cell-class="has-text-left">
								<div class="" style="width:30px; height:30px; border-radius: 5px"
									:style="{ 'background-color': getData(props, column.field) }" />
							</b-table-column>
							<b-table-column v-if="column.type === 'icon'" :key="column.id" v-slot="props"
								:label="column.label" cell-class="has-text-left" sortable>
								<b-icon :icon="props.row.attributes.icon" />
							</b-table-column>
							<b-table-column v-if="column.type === 'date'" :key="column.id" v-slot="props" :label="column.label"
								cell-class="has-text-left">
								{{ props.row.attributes[column.field] instanceof Date ? getData(props,
									column.field).toLocaleDateString("de-DE", {
										year: "numeric",
										month: "2-digit",
										day: "2-digit"
									}) : "-" }}
							</b-table-column>
						</template>
						<slot />
					</b-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import PanelHeader from "@/components/helperComponents/PanelHeader";
import { mapGetters } from "vuex";

export default {
	name: "SearchableTable",
	components: { PanelHeader },
	computed: {
		...mapGetters({
			instantMedicationLogs: "medicationLogEntries/getInstantMedicationLogs"
		}),
	},
	props: {
		panelHeader: {
			type: String,
			default: "SearchableTable Header"
		},
		panelRoute: {
			type: String,
			default: ""
		},
		panelParams: {
			type: Object,
			default: () => { return {} }
		},
		showSearchBar: {
			type: Boolean,
			default: true
		},
		tableData: {
			type: Array,
			default: undefined
		},
		tableMetaData: {
			type: Array,
			default: undefined
		},
		queryString: {
			type: String,
			default: ""
		},
		enableHeader: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			filterString: "",
		};
	},
	mounted() {
		this.$store.dispatch(this.queryString, "")
	},
	methods: {
		getShallowData(dataField, fieldName) {
			return dataField.row[fieldName]
		},
		getData(dataField, fieldName) {
			return dataField.row.attributes[fieldName]
		},
		getCurrency(dataField, fieldName) {
			return String(dataField.row.attributes[fieldName].toFixed(2)).replace('.', ',')
		},
		//TODO Das ist nur ein Konstrukt für den bestand in der Bedarfsmedikation
		createData(dataField) {
			let bestand = dataField.row.attributes?.medication?.attributes?.max
			this.instantMedicationLogs.forEach(element => {
				if(dataField.row.id == element.attributes?.instantMedication.id){
					bestand = bestand - element.attributes?.amount
				}
			});
			let symbol = dataField.row.attributes?.medication?.attributes?.medicationUnit?.attributes?.symbol
			bestand = String(bestand).replace(".", ",");
			return bestand + " " + symbol
		},
		getMultipleDataRef(dataField, refName, fieldName) {
			var erg = ""
			fieldName.forEach(element => {
				erg += this.getDataRef(dataField, refName, element) + " "
			});
			return erg
		},
		getDataRef(dataField, refName, fieldName) {
			if (dataField.row.attributes[refName] && dataField.row.attributes[refName].attributes) return dataField.row.attributes[refName].attributes[fieldName]
		},
		filterElements() {
			this.$store.dispatch(this.queryString, this.filterString)
		},
		getMultipleDataRefDeep(dataField, outerRef, innerRef, fieldName, test) {
			var erg = ""
			fieldName.forEach(element => {
				erg += this.getDataRefDeep(dataField, outerRef, innerRef, element) + " "
			});
			return erg
		},
		getDataRefDeep(dataField, outerRef, innerRef, fieldName) {
			if (dataField.row.attributes[outerRef].attributes) return dataField.row.attributes[outerRef].attributes[innerRef].attributes[fieldName]
		},
		getCombination(dataField, fieldName, symbol, alternativeSymbol) {
			let returnString = ""
			fieldName?.forEach(function (element, idx, array) {
				//Letzte iteration
				//TODO das Oder ist nur für die Bedarfsmedikation sinnvoll, d.h. refactoren und bessere alternative finden
				//TODO Die If-Abfragen nach "dataField.row.attributes[element[0]].attributes" untersuchen. Woher kommt der Fehler konkret?
				if (idx === array.length - 1) {
					if (element.length == 1) {
						returnString += dataField?.row?.attributes[element[0]]
					} else if (element.length == 2) {
						//Notwendig durch das Bearbeiten
						if (dataField.row.attributes[element[0]].attributes) {
							returnString += dataField?.row?.attributes[element[0]]?.attributes[element[1]]
						}
					} else {
						//Notwendig durch das Bearbeiten
						if (dataField.row.attributes[element[0]].attributes) {
							returnString += dataField?.row?.attributes[element[0]]?.attributes[element[1]]?.attributes[element[2]]
						}
					}
				}
				//Vorletzte Iteration
				else if (idx === array.length - 2) {
					if (element.length == 1) {
						returnString += dataField?.row?.attributes[element[0]] + alternativeSymbol
					} else if (element.length == 2) {
						//Notwendig durch das Bearbeiten
						if (dataField.row.attributes[element[0]].attributes) {
							returnString += dataField?.row?.attributes[element[0]]?.attributes[element[1]] + alternativeSymbol
						}
					} else {
						//Notwendig durch das Bearbeiten
						if (dataField.row.attributes[element[0]].attributes) {
							returnString += dataField?.row?.attributes[element[0]]?.attributes[element[1]]?.attributes[element[2]] + alternativeSymbol
						}
					}
					//alle anderen Iterationen
				} else {
					if (element.length == 1) {
						returnString += dataField?.row?.attributes[element[0]] + symbol
					} else if (element.length == 2) {
						//Notwendig durch das Bearbeiten
						if (dataField.row.attributes[element[0]].attributes) {
							returnString += dataField?.row?.attributes[element[0]]?.attributes[element[1]] + symbol
						}
					} else {
						//TODO Beispiel für das Refactoring der Komponente
						/*
						let testVariable = "";
						for (let index = 0; index < element.length; index++) {
							if(index != 0)
							{
								testVariable += ".attributes." + element[index];
							}else
							{
								testVariable += "attributes." + element[index];
							}
							
	
						}
	
						
						var splittet = testVariable.split('.');
						var dataField_temp = dataField.row;
						for(var x = 0; x < splittet.length; x++)
						{
						
							dataField_temp = dataField_temp[splittet[x]];
						}
						
						// let myObj = [];
						// testVariable.split('.').forEach(function(item) {
						// 	myObj = myObj[item];
						// })
	
						
						 */
						//Notwendig durch das Bearbeiten
						if (dataField.row.attributes[element[0]].attributes) {
							returnString += dataField?.row?.attributes[element[0]]?.attributes[element[1]]?.attributes[element[2]] + symbol
						}
					}
				}
			});
			return returnString
		},
	},
};
</script>
  
<style lang="scss">
.bodyContainer {
	background-color: white;
}


.th-right {
	justify-content: right;
	text-align: right;
	display: flex;
}

.attributes.last_name {
	margin-left: 0 !important;
}

.b-table .table th .th-wrap .icon {
	margin-left: 0 !important;
}

.attributes.first_name {
	margin-left: 0 !important;
}

.truncate-column {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
  