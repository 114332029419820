<template>
  <b-field label="Farbe mit Color-Picker wählen">
    <div
      class="container pt-3 pb-3"
      style="display: flex; justify-content: center"
    >
      <slider-picker
        v-if="isSlidePicker"
        v-model="colorInput"
        @input="set()"
      />
      <template v-else>
        <chrome-picker
          v-if="colorPickerOpen"
          v-model="colorInput"
          @input="set()"
        />
        <div
          class=""
          style="width:30px; height:30px; cursor:pointer; border-radius: 5px"
          :style="{'background-color':colors.hex, 'color':getComplementaryHexColor(colors.hex), 'border':'1px solid black'}"
          @click="toggleColorPicker"
        >
          {{ colorPickerOpen ? "X" : "" }}
        </div>
      </template>
    </div>
  </b-field>
</template>

<script>
import {Chrome} from 'vue-color'
import {getComplementaryHexColor} from "@/lib/Color";
import { Slider } from 'vue-color'
/**
 *  This file provides a color picker
 *  @input: colors: object containing hex value and source-key with value "hex", and setter for colors as function,
 *  optional isSlidePicker: boolean for indicating a preferred slider picker
 */
export default {
	name: "ColorPicker",
	components: {
		'chrome-picker': Chrome,
		'slider-picker': Slider,
	},
	props:[
		"colors",
		"setColors",
		"isSlidePicker"
	],
	data(){
		return{
			colorPickerOpen: false,
			colorInput: {
				"hex": "#000000",
				"source": "hex"}
		}

	},
	methods:{
		set(){
			this.setColors(this.colorInput)
		},
		getComplementaryHexColor(colors) {
			return getComplementaryHexColor(colors)
		},
		toggleColorPicker() {
			this.colorPickerOpen = !this.colorPickerOpen;
		},
	}
}
</script>

<style scoped>

</style>
