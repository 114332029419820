<template>
  <div>
    <div class="panel m-5">
      <div
        class="panel-heading"
        style="display: flex"
      >
        <p class="mr-2">
          {{ entryTypeName }}
        </p>
        <div
          class=""
          style="width:30px; height:30px; cursor:pointer; border-radius: 5px"
          :style="{'background-color':colors.hex, 'color':getComplementaryHexColor(colors.hex), 'border':'1px solid black'}"
          @click="toggleColorPicker"
        />
      </div>
      <b-modal v-model="colorPickerOpen">
        <div style="display: flex; justify-content: center">
          <chrome-picker
            v-if="colorPickerOpen"
            v-model="colors"
          />
        </div>
      </b-modal>
      <div class="container pt-3 pb-3">
        <div class="columns">
          <div class="column">
            <b-field label="Standard">
              <b-select
                id="isSigleSelection"
                v-model="isStandardEntryType"
                class="m-2"
                expanded
              >
                <option
                  v-for="option in ['Ja','Nein']"
                  :key="option"
                  :value="option === 'Ja'"
                >
                  {{ option }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <section class="m-5">
      <div
        class="buttons"
        style="display: flex; justify-content: center"
      >
        <b-button
          id="submitClient"
          type="is-primary"
          :loading="isLoading"
          @click="editAttribute()"
        >
          Speichern
        </b-button>
        <b-button
          class="is-danger"
          tag="router-link"
          to="/diary"
        >
          Abbrechen
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
/**
 * This file is for editing an entry type
 */
import {Chrome} from 'vue-color'
import {queryStandardEntryTypes} from "../../../lib/Query";
import {getComplementaryHexColor} from "../../../lib/Color";
import {negativeFeedBack, positiveFeedBack} from "@/lib/Toast";

export default {
	name: "EditEntryType",
	components: {
		'chrome-picker': Chrome
	},
	data() {
		return {
			entryTypeName: "",
			colors: {
				"hex": "#000000",
				"source": "hex"
			},
			colorPickerOpen: false,
			isStandardEntryType: false,
			entryType: "",
			isLoading: false
		}
	},
	async created() {
		const entryType = this.$route.params.data
		if(entryType){
			this.entryType = entryType
			this.entryTypeName = entryType.attributes.name
			this.isStandardEntryType = entryType.attributes.isStandardEntryType
			this.colors["hex"] = entryType.attributes.color
		}else{
			await this.$router.push("/diary")
		}

	},
	methods: {
		toggleColorPicker() {
			this.colorPickerOpen = !this.colorPickerOpen;
		},
		async editAttribute() {
			this.isLoading = true
			if (this.isStandardEntryType) {
				const standardEntryTypes = await queryStandardEntryTypes()
				for (const standardEntryType of standardEntryTypes) {
					standardEntryType.set("isStandardEntryType", false)
					try {
						await standardEntryType.save()
					} catch (e) {
						console.error("couldnt change other standard entry types" + e)
					}
				}
			}

			const entryAttribute = this.entryType
			entryAttribute.set("name", this.entryTypeName.trim())
			entryAttribute.set("isStandardEntryType", this.isStandardEntryType)
			entryAttribute.set("color", this.colors.hex)
			try {
				await entryAttribute.save()
				positiveFeedBack("Eintragstyp bearbeitet!")
				await this.$router.push({path: "/diary"})
			} catch (e) {
				console.error("entry type couldnt be editted" + e)
				negativeFeedBack("Eintragstyp wurde nicht bearbeitet!")
			}
			this.isLoading = false
		},
		getComplementaryHexColor(colors) {
			return getComplementaryHexColor(colors)
		},
	}
}
</script>

<style lang="scss" scoped>

</style>
