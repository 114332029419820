<template>
  <div class="panel m-5">
    <PanelHeader panel-header="Bearbeite Kinderakte-Kategorie" />

    <div class="p-2">
      <div class="columns">
        <div class="column">
          <InputWithValidation
            message="Bitte gib einen Kategorienamen ein!"
            :name="categoryName"
            :set-name="setCategoryName"
            label="Kategoriename"
          />
        </div>
        <div class="column">
          <ColorPicker
            :colors="colors"
            :set-colors="setColors"
          />
        </div>
      </div>

      <!--			<div>-->
      <!--				<div style="display: flex; justify-content: center">-->
      <!--					<Input label="PDF(s) für Kind(er) hinzufügen" :name="filter" :set-name="val => this.filter=val" style="width: 80%"/>-->
      <!--				</div>-->
      <!--				<div style="display: flex; justify-content: center" >-->
      <!--					<div class="scrollable" style="display: grid;grid-template-columns: repeat(4, 1fr); gap: .5rem">-->
      <!--						<div v-for="client of clientsFiltered" :key="client.id" style="border-radius: .5rem; border: 2px solid rgba(0,0,0,0.8)">-->
      <!--							<EntryContainer :client="client" :function="clientClickFunction"/>-->
      <!--						</div>-->
      <!--					</div>-->
      <!--				</div>-->
      <!--			</div>-->

      <section
        class="m-5 buttons"
        style="display: flex; justify-content: center"
      >
        <SaveButton
          :disabled="$v.categoryName.$invalid"
          :is-loading="isLoading"
          :save-function="editCategory"
        />
        <CancelButton
          back-route="/client_records"
          text="Abbrechen"
          :is-loading="isCancelLoading"
        />
      </section>
    </div>
  </div>
</template>

<script>
import Parse from "parse/dist/parse.min.js";
import {v4 as uuid} from "uuid";
import {required} from "vuelidate/lib/validators";
import InputWithValidation from "../helperComponents/InputWithValidation"
import ColorPicker from "@/components/helperComponents/ColorPicker";
import SaveButton from "@/components/helperComponents/SaveButton";
import CancelButton from "@/components/helperComponents/CancelButton";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import {queryClientFilesByCategory, queryClients} from "@/lib/Query";
import {positiveFeedBack} from "@/lib/Toast";

/**
 * This file handles editing an existing category for client records and choosing a client for wadding pdfs in this category and client
 */
export default {
	name: "EditClientRecordCategory",
	components: {
		PanelHeader,
		CancelButton,
		SaveButton,
		ColorPicker,
		InputWithValidation
	},
	data(){
		return{
			categoryName: "",
			colorPickerOpen: false,
			isLoading: false,
			isCancelLoading: false,
			colors: {
				"hex": "#000000",
				"source": "hex"
			},
			pdfs: [],
			category: undefined,
			clientFile: undefined,
			clientFiles: [],
			oldClientFiles:[],
			clients: [],
			filter:""
		}
	},
	computed:{
		clientsFiltered(){
			if(this.filter.length === 0) return this.clients
			return this.clients.filter(client => {
				const name = `${client.attributes.first_name} ${client.attributes.last_name}`.toLowerCase()
				return this.filter && name.includes(this.filter.toLowerCase())
			})
		}
	},
	validations: {
		categoryName: {
			required,
		}
	},
	async created(){
		if(!this.$route.params.data) return await this.$router.push({ path: "/client_records" });
		this.clients = await queryClients()
		this.isCancelLoading = true
		this.category = this.$route.params.data
		this.categoryName = this.category.attributes.name
		this.colors = {
			"hex": this.category.attributes.color,
			"source": "hex"
		}
		this.oldClientFiles = await queryClientFilesByCategory(this.category)
		for(const clientFile of this.oldClientFiles){
			clientFile.set("isNew", false)
			this.clientFiles.push(clientFile)
		}
		this.isCancelLoading = false
	},
	methods:{
		clientClickFunction(){
			this.$router.push({name: "EditFocusedClientRecords", params: {data: this.category}});
		},
		async editCategory(){
			this.isLoading = true
			const clientRecordCategory = this.category
			clientRecordCategory.set("name", this.categoryName)
			clientRecordCategory.set("color", this.colors.hex)
			try{
				await clientRecordCategory.save()
			}catch (e){
				console.error("couldnt save the client record category " + e)
			}

			const newClientFiles = this.clientFiles.filter(file => file.attributes.isNew)
			const deletedClientFiles = this.oldClientFiles.filter(file => {
				const chosenFileIds = this.clientFiles.map(f => f.id)
				return !chosenFileIds.includes(file.id)
			})

			for(const clientFile of deletedClientFiles){
				clientFile.unset("isNew")
				clientFile.set("deleted", true)
				await clientFile.save()
			}
			for(const clientFile of newClientFiles){
				const pdfFile = clientFile.attributes.pdf
				clientFile.unset("isNew")
				clientFile.set("category", clientRecordCategory)
				await pdfFile.save()
				await clientFile.save()
			}
			this.isLoading = false
			positiveFeedBack("Kategorie bearbeitet!")
			return await this.$router.push({ path: "/client_records" })
		},
		deletePdf(index){
			this.clientFiles = this.clientFiles.filter((pdf, i) => i !== index)
		},
		setCategoryName(name){
			this.categoryName = name
		},
		setColors(colors){
			this.colors = colors
		},
		setLoading(isLoading){
			this.isLoading =isLoading
		},
		addPdf(pdf){
			const ClientFile = Parse.Object.extend("ClientFile")
			const clientFile = new ClientFile()
			const pdfFile = new Parse.File(uuid(), pdf);
			clientFile.set("title", pdf.name);
			clientFile.set("pdf", pdfFile);
			clientFile.set("lastModifiedDate", pdf.lastModifiedDate);
			clientFile.set("size", pdf.size);
			clientFile.set("isNew", true);
			this.clientFiles.push(clientFile)
		}
	}
}
</script>

<style scoped>
.scrollable{
	max-height: 40rem;
	overflow-y: auto;
}
</style>
