import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../lib/Errors';

let localSubscription = undefined;

function createSubscription(store) {
	let dataQuery = new Parse.Query('MedicationMedication');
	dataQuery.notEqualTo("deleted", true)
	dataQuery.include(["medicationForm", "medicationUnit"])
	dataQuery.find()
		.then((results) => {
			store.dispatch('medication/set', results)
		})
		.catch((error) => {
			handleParseError(error);
			
		});
		dataQuery.subscribe().then((subscription) => {
		
		localSubscription = subscription;

		subscription.on('create', object => {
			
			store.dispatch('medication/insert', object)
		});
		subscription.on('update', object => {
			
			store.dispatch('medication/replace', object)
		});
		subscription.on('enter', object => {
			
			store.dispatch('medication/insert', object)
		});
		subscription.on('leave', object => {
			
			store.dispatch('medication/delete', object)
		});
		subscription.on('delete', object => {
			
			store.dispatch('medication/delete', object)
		});
		subscription.on('Diagnose', () => {
			
		});
		subscription.on('Diagnose', error => {
			
		})
	})
}

export default function createMedicationSubscription() {
	return store => {
		if (store.state.currentUser) {
			createSubscription(store)
		}
		store.subscribe(mutation => {
			if (mutation.type === 'SET_CURRENT_USER') {
				if (localSubscription) {
					localSubscription.unsubscribe()
				}
				if (store.state.currentUser) {
					createSubscription(store)
				}
			}
		})
	}
}
