import Parse from "parse/dist/parse.min.js";
import { handleParseError } from "@/lib/Errors";
/**
 * Query different parse object from database
 * @returns {Promise<*|*[]>}
 */
export const queryUsers = async () => {
	const userQuery = new Parse.Query("User")
	userQuery.include("dependency")
	try {
		return await userQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find users " + e)
		return []
	}
}


export const queryRoles = async () => {
	const roleQuery = new Parse.Query(Parse.Role)
	try {
		return await roleQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find any roles " + e)
		return []
	}
}

export const queryAdminRole = async () => {
	const roleQuery = new Parse.Query(Parse.Role)
	roleQuery.equalTo("name", "Admin")
	try {
		return await roleQuery.first()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find admin role " + e)
		return undefined
	}
}

export const queryAdmins = async () => {
	const roleQuery = new Parse.Query(Parse.Role)
	roleQuery.equalTo("name", "Admin")
	try {
		const adminRole = await roleQuery.first()
		return adminRole.getUsers().query().find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find admin role " + e)
		return []
	}
}

export const queryStaffs = async () => {
	const roleQuery = new Parse.Query(Parse.Role)
	roleQuery.equalTo("name", "Staff")
	try {
		const staffRole = await roleQuery.first()
		return await staffRole.getUsers().query().find()
	} catch (e) {
		handleParseError(e)
		console.error(e)
		return []
	}
}

export const queryStaffRole = async () => {
	const roleQuery = new Parse.Query(new Parse.Role)
	roleQuery.equalTo("name", "Staff")
	try {
		const staffRole = await roleQuery.first()
		return staffRole
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find staff role " + e)
		return undefined
	}
}

export const queryExternalRole = async () => {
	const roleQuery = new Parse.Query(new Parse.Role)
	roleQuery.equalTo("name", "External")
	try {
		const staffRole = await roleQuery.first()
		return staffRole
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find external role " + e)
		return undefined
	}
}

export const queryUserDetails = async (id) => {
	const staffMetaQuery = new Parse.Query(Parse.Object.extend("UserMetaClass"))
	staffMetaQuery.equalTo("objectId", id)
	staffMetaQuery.notEqualTo("deleted", true)
	try {
		return await staffMetaQuery.first()
	} catch (e) {
		handleParseError(e)
		console.error("no user details found" + e)
		return []
	}
}

export const queryExternals = async () => {
	const roleQuery = new Parse.Query(Parse.Role)
	roleQuery.equalTo("name", "External")
	try {
		const externalRole = await roleQuery.first()
		return await externalRole.getUsers().query().find()
	} catch (e) {
		handleParseError(e)
		console.error(e)
		return []
	}
}

export const queryUser = async (id) => {
	const query = new Parse.Query(Parse.Object.extend("UserMetaClass"))
	query.equalTo("objectId", id)
	try {
		return await query.first()
	} catch (e) {
		handleParseError(e)
		console.error(e)
		return undefined
	}
}

export const queryUserMetaClassByEmail = async (email) => {
	const query = new Parse.Query("UserMetaClass")
	query.equalTo("email", email)
	try {
		return await query.first()
	} catch (e) {
		handleParseError(e)
		console.error(e)
		return undefined
	}
}

export const queryClients = async () => {
	const Client = Parse.Object.extend("Client");
	const query = new Parse.Query(Client);
	query.notEqualTo("deleted", true)
	query.include("group")
	query.notEqualTo("inactive", true)
	query.limit(2000000)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		console.error(e)
		return []
	}
}

export const queryActiveAndInactiveClients = async () => {
	const Client = Parse.Object.extend("Client");
	const query = new Parse.Query(Client);
	query.notEqualTo("deleted", true)
	query.include("group")
	query.include("licenseFile")
	query.limit(2000000)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		console.error(e)
		return []
	}
}

export const queryClientsByNameAndBirthday = async (firstName, lastName, birthday) => {
	const Client = Parse.Object.extend("Client");
	const query = new Parse.Query(Client);
	query.notEqualTo("deleted", true)
	query.equalTo("birth_date", birthday)
	query.equalTo("first_name", firstName)
	query.equalTo("last_name", lastName)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		console.error(e)
		return []
	}
}

export const queryClient = async (id) => {
	const Client = Parse.Object.extend("Client");
	const query = new Parse.Query(Client);
	try {
		return await query.get(id)
	} catch (e) {
		handleParseError(e)
		console.error(e)
		return undefined
	}
}

export const queryEntryAttributeById = async (id) => {
	const attributesQuery = new Parse.Query(Parse.Object.extend("EntryAttributes"))
	return await attributesQuery.get(id)
}

export const queryGroup = async (id) => {
	try {
		const Group = Parse.Object.extend("Group")
		const groupQuery = new Parse.Query(Group)
		groupQuery.notEqualTo("deleted", true)
		const groups = await groupQuery.find()
		return await groups.find(group => group.id === id)
	} catch (e) {
		handleParseError(e)
		console.error(e)
		return undefined
	}
}

export const queryGroupByName = async (name) => {
	try {
		const Group = Parse.Object.extend("Group")
		const groupQuery = new Parse.Query(Group)
		groupQuery.notEqualTo("deleted", true)
		groupQuery.equalTo("group_name", name)
		const group = await groupQuery.first()
		return group
	} catch (e) {
		handleParseError(e)
		console.error(e)
		return undefined
	}
}

export const queryGroupById = async (id) => {
	try {
		const Group = Parse.Object.extend("Group")
		const groupQuery = new Parse.Query(Group)
		return await groupQuery.get(id)
	} catch (e) {
		handleParseError(e)
		console.error(e)
		return undefined
	}
}

export const queryGroups = async () => {
	try {
		const Group = Parse.Object.extend("Group")
		const groupQuery = new Parse.Query(Group)
		groupQuery.notEqualTo("deleted", true)
		return await groupQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find groups " + e)
		return []
	}
}

export const queryGroupNames = async () => {
	try {
		const groupNames = []
		const Group = Parse.Object.extend("Group")
		const groupQuery = new Parse.Query(Group)
		groupQuery.notEqualTo("deleted", true)
		for (const group of await groupQuery.find()) {
			groupNames.push(group)
		}
		return groupNames
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find groups " + e)
		return []
	}
}

export const queryEntryAttributes = async () => {
	const attributesQuery = new Parse.Query(Parse.Object.extend("EntryAttributes"))
	attributesQuery.notEqualTo("type", "diary")
	attributesQuery.notEqualTo("deleted", true)
	try {
		return await attributesQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find any entry attributes " + e)
		return []
	}
}

export const queryLivingAreas = async () => {
	const attributesQuery = new Parse.Query(Parse.Object.extend("EntryAttributes"))
	attributesQuery.equalTo("type", "living_area")
	attributesQuery.notEqualTo("deleted", true)
	return await attributesQuery.find()
}


export const queryUserMetaClass = async (id) => {
	const query = new Parse.Query(Parse.Object.extend("UserMetaClass"))
	try {
		return await query.get(id)
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find user meta class " + e)
	}
}

export const queryUserMetaClasses = async () => {
	const query = new Parse.Query(Parse.Object.extend("UserMetaClass"))
	query.notEqualTo("deleted", true)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find user meta classes " + e)
		return []
	}
}

export const queryAttributes = async () => {
	const query = new Parse.Query(Parse.Object.extend("EntryAttributes"))
	query.notEqualTo("deleted", true)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt query attributes " + e)
		return []
	}
}


export const queryEntryTypes = async () => {
	const query = new Parse.Query(Parse.Object.extend("EntryTypes"))
	query.notEqualTo("deleted", true)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt query entry types " + e)
		return undefined
	}
}


export const queryStandardEntryTypes = async () => {
	const query = new Parse.Query(Parse.Object.extend("EntryTypes"))
	query.notEqualTo("deleted", true)
	query.equalTo("isStandardEntryType", true)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt query standard entry types " + e)
		return undefined
	}
}

export const queryAttributeConfigs = async () => {
	const query = new Parse.Query(Parse.Object.extend("AttributeConfig"))
	query.notEqualTo("deleted", true)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt query attribute configs " + e)
		return undefined
	}
}

export const queryAttributeCharacteristicsByConfigPointer = async (pointer) => {
	const query = new Parse.Query(Parse.Object.extend("EntryAttributes"))
	query.notEqualTo("deleted", true)
	query.equalTo("attribute_config", pointer)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt query attribute characteristics " + e)
		return undefined
	}
}

export const queryEntries = async () => {
	const query = new Parse.Query(Parse.Object.extend("Entry"))
	query.notEqualTo("deleted", true)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt query entries " + e)
		return undefined
	}
}

// UNUSED Wird nicht verwendet, aber auch nur, weil ich es benutzen wollten; sicherlich sind noch mehr Stellen betroffen
export const queryUsersByUserMetaClasses = async (userMetaClasses) => {
	const userQuery = new Parse.Query("User")
	userQuery.containedIn("dependency", userMetaClasses)
	try {
		return await userQuery.find()
	} catch (e) {
		handleParseError(e)
		//console.error("couldnt find users for setting reminders in events" + e)
		return []
	}
}

export const queryUserByUserMetaClass = async (userMetaClass) => {
	const userQuery = new Parse.Query(Parse.Object.extend("User"))
	userQuery.equalTo("dependency", userMetaClass)
	try {
		return await userQuery.first()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find user for give userMetaClass" + e)
		return []
	}
}

export const queryGlobalUserAppointments = async (user) => {
	const appointmentQuery = new Parse.Query("Notification")
	appointmentQuery.equalTo("message", "Termin anlegen!")
	appointmentQuery.equalTo("user", user)
	try {
		return await appointmentQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find appointments " + e)
		return []
	}
}

export const queryGlobalClientAppointments = async (client) => {
	const appointmentQuery = new Parse.Query("Notification")
	appointmentQuery.equalTo("message", "Termin anlegen!")
	appointmentQuery.equalTo("client", client)
	try {
		return await appointmentQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find appointments " + e)
		return []
	}
}


export const queryUserMetaClassByUser = async user => {
	const userMetaClassQuery = new Parse.Query("UserMetaClass")
	userMetaClassQuery.notEqualTo("deleted", true)
	userMetaClassQuery.equalTo("objectId", user.attributes.dependency.id)
	try {
		return await userMetaClassQuery.first()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find usermetaclass of user " + e)
		return undefined
	}
}


export const queryUserMetaClassIncludingDeletedByUser = async user => {
	const userMetaClassQuery = new Parse.Query("UserMetaClass")
	userMetaClassQuery.equalTo("objectId", user.attributes.dependency.id)
	try {
		return await userMetaClassQuery.first()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find usermetaclass of user " + e)
		return undefined
	}
}



export const queryClientRecordCategories = async () => {
	const reminderQuery = new Parse.Query("ClientRecordCategory")
	reminderQuery.notEqualTo("deleted", true)
	try {
		return await reminderQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find clientrecord categories " + e)
		return []
	}
}

export const queryClientFilesByCategory = async category => {
	const clientFileQuery = new Parse.Query("ClientFile")
	clientFileQuery.notEqualTo("deleted", true)
	clientFileQuery.equalTo("category", category)
	try {
		return await clientFileQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find client files for this category " + e)
		return []
	}
}


export const queryDocumentCreators = async () => {
	const documentCreatorQuery = new Parse.Query("DocumentCreator")
	documentCreatorQuery.notEqualTo("deleted", true)
	try {
		return await documentCreatorQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find document creators " + e)
		return []
	}
}

export const queryDocumentCreator = async (id) => {
	const documentCreatorQuery = new Parse.Query("DocumentCreator")
	documentCreatorQuery.notEqualTo("deleted", true)
	documentCreatorQuery.equalTo("objectId", id)
	try {
		return await documentCreatorQuery.first()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find the document creator " + e)
		return undefined
	}
}

export const queryClientFileLinksByClientAndCategory = async (client, category) => {
	const clientFileLinkQuery = new Parse.Query("ClientFileLink")
	clientFileLinkQuery.notEqualTo("deleted", true)
	clientFileLinkQuery.equalTo("category", category)
	clientFileLinkQuery.equalTo("client", client)
	try {
		return await clientFileLinkQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find client file links for this category and client " + e)
		return []
	}
}

export const queryClientFilesByUUid = async (uuid) => {
	const clientFileLinkQuery = new Parse.Query("ClientFile")
	clientFileLinkQuery.notEqualTo("deleted", true)
	clientFileLinkQuery.equalTo("uuid", uuid)
	try {
		return await clientFileLinkQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find client files for this uuid " + e)
		return []
	}
}

export const queryClientFilesByClientAndCategory = async (client, category) => {
	const clientFileLinkQuery = new Parse.Query("ClientFile")
	clientFileLinkQuery.notEqualTo("deleted", true)
	clientFileLinkQuery.equalTo("category", category)
	clientFileLinkQuery.equalTo("client", client)
	try {
		return await clientFileLinkQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find client files for this category and client " + e)
		return []
	}
}

export const queryClientFilesByIds = async (ids) => {
	const clientFileQuery = new Parse.Query("ClientFile")
	clientFileQuery.include("category")
	clientFileQuery.notEqualTo("deleted", true)
	clientFileQuery.containedIn("objectId", ids)
	try {
		return await clientFileQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find client files for these ids " + e)
		return []
	}
}

export const queryClientFileLinksByClientFile = async (file) => {
	const clientFileLinkQuery = new Parse.Query("ClientFileLink")
	clientFileLinkQuery.notEqualTo("deleted", true)
	clientFileLinkQuery.equalTo("file", file)
	try {
		return await clientFileLinkQuery.find()
	} catch (e) {
		handleParseError(e)
		console.error("couldnt find client file links for this file " + e)
		return []
	}
}

export const queryObjectByName = async (objectName, name) => {
	const query = new Parse.Query(objectName)
	query.equalTo("name", name)
	query.limit(100000)
	try {
		return await query.first()
	} catch (e) {
		handleParseError(e)
		return undefined
	}
}

export const queryLocationByType = async (type) => {
	const query = new Parse.Query("Location")
	query.equalTo("type", type)
	query.notEqualTo("deleted", true)
	query.limit(100000)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		return []
	}
}

export const queryPersonByType = async (type) => {
	const query = new Parse.Query("Person")
	query.equalTo("type", type)
	query.notEqualTo("deleted", true)
	query.limit(100000)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		return []
	}
}

export const queryPersonsWithOutSocialWorkers = async () => {
	const query = new Parse.Query("Person")
	query.notEqualTo("type", "socialWorker")
	query.notEqualTo("deleted", true)
	query.limit(100000)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		return []
	}
}

export const queryStatutoryBasis = async () => {
	const query = new Parse.Query("StatutoryBasis")
	query.notEqualTo("deleted", true)
	query.limit(100000)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		return []
	}
}

export const queryNextPdfs = async (queriedPdfIds, queryParams) => {
	const query = new Parse.Query("ClientFile")
	query.include("category")
	query.include("client")
	query.notEqualTo("deleted",true)
	query.notContainedIn("objectId", queriedPdfIds)
	if (queryParams.category) query.equalTo("category", queryParams.category)
	// if(queryParams.searchTerm && queryParams.searchTerm.length >= 0) query.fullText("text", queryParams.searchTerm,{
	// 	caseSensitive: false,
	// 	diacriticSensitive: false,
	// 	language: false
	// })
	if (queryParams.creator && queryParams.creator.length >= 0) query.startsWith("creator", queryParams.creator)
	if (queryParams.startDate) query.greaterThanOrEqualTo("createdAt", queryParams.startDate)
	//TODO: Das hier ist auskommentiert, prüfen ob Fehlerquelle

	let bisDatum = new Date(queryParams.endDate.getTime())
	bisDatum.setDate(bisDatum.getDate() + 1)

	if (queryParams.endDate) query.lessThan("createdAt", bisDatum)
	if (queryParams.client) query.equalTo("client", queryParams.client)

	query.descending("updatedAt")
	query.limit(10)
	try {
		return await query.find()
	} catch (e) {
		handleParseError(e)
		return []
	}
}
