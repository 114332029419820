<template>
  <div class="panel m-5">
    <PanelHeader :panel-header="template? template.attributes.title : 'Neue Berichtsvorlage'" />
    <div class="container pt-3 pb-3">
      <div class="bodyContainer">
        <div class="is-flex is-justify-content-center mb-2">
          <InputWithValidation
            message="Bitte gib einen Titel ein!"
            label="Titel"
            :set-name="val => title= val"
            :name="title"
            type="text"
          />
        </div>
        <div class="columns">
          <ReportDateComponent
            title="Fälligkeitzeitpunkt Bearbeitung"
            :propped-model-value="processingDays"
            :message="message(processingDays, 'processing')"
            @input="val => processingDays = val"
          />
          <ReportDateComponent
            title="Fälligkeitzeitpunkt Korrektur"
            :propped-model-value="correctionDays"
            :message="message(correctionDays, 'correction')"
            @input="val => correctionDays = val"
          />
        </div>
        <div class="columns">
          <ReportDateComponent
            title="Fälligkeitzeitpunkt Genehmigung"
            :propped-model-value="approvalDays"
            :message="message(approvalDays, 'approval')"
            @input="val => approvalDays = val"
          />
          <ReportDateComponent
            title="Fälligkeitzeitpunkt Abschluss/Versand"
            :propped-model-value="shippingDays"
            :message="message(shippingDays, 'shipping')"
            @input="val => shippingDays = val"
          />
        </div>
				
        <div
          v-if="template && !deletedContent"
          class="is-flex is-align-items-center is-justify-content-center"
        >
          <div
            style="cursor: pointer"
            @click="()=> deletedContent = true"
          >
            <b-icon icon="close" />
          </div>
        </div>
				
        <div
          v-if="template && !deletedContent"
          class="p-2"
        >
          <div style="display: grid; grid-template-columns: 33% 33% 33%;">
            <div>Überschriften</div>
            <div>Hinweise</div>
            <div>Standardtext</div>
          </div>
          <hr class="m-0">
          <div style="overflow-y: auto; max-height: 30rem;">
            <div
              v-for="(structureEntity, idx) in template.attributes.structure"
              :key="idx"
            >
              <div style="display: grid; grid-template-columns: 33% 33% 33%; ">
                <div v-if="structureEntity.heading">
                  {{ structureEntity.heading }}
                </div>
                <div
                  v-if="structureEntity.advice"
                  v-html="urlify(structureEntity.advice)"
                />
                <div
                  v-if="structureEntity.content"
                  v-html="urlify(structureEntity.content)"
                />
              </div>
              <hr
                class="m-0"
                style="width: 100%"
              >
            </div>
          </div>
        </div>
				
        <UploadSection
          v-if="deletedContent || template === undefined"
          :file="file"
          @updateFile="val => file = val"
        >
          <p>Kapitelstruktur</p>
        </UploadSection>

        <template v-if="false">
          <div
            v-for="(question, idx) in questions"
            :key="question.id"
            class=" is-flex"
          >
            <IconButton
              v-if="questions.length>1"
              class="is-flex is-align-items-center mt-2"
              :size="10"
              icon="minus"
              :trigger-function="()=>removeQuestion(question.id)"
            />
            <InputComponent
              style="width: 90%"
              :label="`Kapitelüberschrift ${idx+1}`"
              :set-name="val => question.value= val"
              :name="question.value"
              type="text"
            />
            <InputComponent
              style="width: 90%"
              :label="`Hinweise ${idx+1}`"
              :set-name="val => question.value= val"
              :name="question.value"
              type="textarea"
            />
            <IconButton
              class="is-flex is-align-items-center mt-2"
              :size="10"
              icon="plus"
              :trigger-function="addQuestion"
            />
          </div>
        </template>
      </div>

      <div
        class="buttons m-5"
        style="display: flex; justify-content: center"
      >
        <SaveButton
          :disabled="hasMessage || title === undefined || (template === undefined && file === undefined)"
          :save-function="save"
          :is-loading="isLoading"
        />
        <CancelButton
          text="Abbrechen"
          back-route="/report_templates"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * This file is for creating and editing a group
 */
import PanelHeader from "@/components/helperComponents/PanelHeader";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import InputComponent from "@/components/helperComponents/InputComponent.vue";
import IconButton from "@/components/helperComponents/IconButton";
import {v4 as uuid} from "uuid"
import Parse from "parse/dist/parse.min"
import {positiveFeedBack} from "@/lib/Toast";
import ReportDateComponent from "@/components/reportTemplates/ReportDateComponent";
import XLSX from "xlsx/dist/xlsx.full.min";
import UploadSection from "@/components/helperComponents/UplaodSection";
export default {
	name: "AddReportTemplate",
	components: {UploadSection, ReportDateComponent, IconButton, InputWithValidation, SaveButton, CancelButton, PanelHeader, InputComponent},
	data() {
		return {
			isLoading: false,
			template: undefined,
			title: undefined,
			processingDays: undefined,
			correctionDays: undefined,
			approvalDays: undefined,
			shippingDays: undefined,
			questions: [{
				id: uuid,
				value: undefined
			}],
			file: undefined,
			structureList: [],
			deletedContent: false

		}
	},
	validations: {},
	computed: {
		hasMessage() {
			return +this.processingDays < +this.correctionDays || +this.processingDays < +this.approvalDays
				|| +this.processingDays < +this.shippingDays || +this.correctionDays < +this.approvalDays || +this.correctionDays < +this.shippingDays
				|| +this.approvalDays < +this.shippingDays
		}
	},
	watch: {
		correctionDays(newVal){
			if(newVal && newVal.length === 0) this.correctionDays = undefined
		},
		processingDays(newVal){
			if(newVal && newVal.length === 0) this.processingDays = undefined
		},
		approvalDays(newVal){
			if(newVal && newVal.length === 0)this.approvalDays = undefined
		},
		shippingDays(newVal){
			if(newVal && newVal.length === 0) this.shippingDays = undefined
		},},
	async created() {
		if (!this.$route.params.data) return
		this.template = this.$route.params.data
		this.title = this.template.attributes.title
		this.processingDays = this.template.attributes.processingDays
		this.correctionDays = this.template.attributes.correctionDays
		this.approvalDays = this.template.attributes.approvalDays
		this.shippingDays = this.template.attributes.shippingDays
	},
	methods: {
		addQuestion() {
			this.questions.push({
				id: uuid(),
				value: undefined
			})
		}, removeQuestion(id) {
			this.questions = this.questions.filter(q => q.id !== id)
		},
		handleFileRead(callback){
			const reader = new FileReader();
			reader.onload = async function (e) {
				const structureList = []
				const data = e.target.result;

				const workbook = XLSX.read(data, {
					type: "binary",
					cellDates: true,
				});
				this.isLoading = false
				const structureSheet = XLSX.utils.sheet_to_json(workbook.Sheets["Kapitelstruktur"])
				for(const row of structureSheet){
					structureList.push({
						heading: row["Überschriften"],
						advice: row["Hinweise"],
						content: row["Platzhalter"],
						headerType: row["Überschriftsart"],
					})
				}
				this.structureList = structureList
				await callback(structureList)
				this.file = undefined
			};
			reader.readAsBinaryString(this.file);
		},
		async saveTemplate(structureList){
			const Template = Parse.Object.extend("ReportTemplate")
			const template = this.template ? this.template : new Template()
			template.set("title", this.title)
			template.set("processingDays", +this.processingDays)
			template.set("correctionDays", +this.correctionDays)
			template.set("approvalDays", +this.approvalDays)
			template.set("shippingDays", +this.shippingDays)
			template.set("structure", structureList)

			await template.save()
			await positiveFeedBack("Vorlage angelegt")
			await this.$router.push("report_templates")
		},
		async save() {
			this.handleFileRead(this.saveTemplate)

		},
		message(days, param) {
			if(param === 'processing') return ""
			let message = "Diese Zahl muss kleiner sein als "
			if (+this.processingDays < +days) {
				message += "\"Fälligkeitszeitpunkt Bearbeitung\""
				return message
			}
			if (param === "correction") return
			if (+this.correctionDays < +days) {
				message += "\"Fälligkeitszeitpunkt Korrektur\""
				return message
			}
			if (param === "approval") return
			if (+this.approvalDays < +days) {
				message += "\"Fälligkeitszeitpunkt Genehmigung\""
				return message
			}
			if (param === "shipping") return ""
		},
		urlify(text) {
			var urlRegex = /(https?:\/\/[^\s]+)/g;
			return text.replace(urlRegex, function(url) {
				return '<a class="cursor-pointer text-blue" target="_blank" href="' + url + '">' + url + '</a>';
			})
}
	},
}
</script>

<style lang="scss" scoped>
hr{
	background-color: rgba(0,0,0,.4);
}
</style>
