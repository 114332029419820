<template>
  <b-field
    :label="label"
    :message="message"
    :type="message ? 'is-danger' :''"
  >
    <b-autocomplete
      v-model="selected"
      :data="data"
      :placeholder="label"
      icon="magnify"
      @input="input"
      @select="select"
    >
      <template #empty>
        {{ emptyText }}
      </template>
      <template slot-scope="props">
        <div
          class="content"
          style="display: flex; justify-content: center"
        >
          <div>{{ props.option.attributes[attributeName] }}</div>
          <div v-if="secondAttributeName">
            &nbsp;{{ props.option.attributes[secondAttributeName] }}
          </div>
          <div v-if="showGroupName && props.option.attributes.group">
            &nbsp;({{ props.option.attributes.group.attributes.group_name }})
          </div>
        </div>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
/**
 * this file is used as an autocomplete selection for parse objects as options
 * @param: label: String for labeling the component, select: function for selecting Parse Object, input: function for input types, data: array of Parse objects,
 * emptyText:String message for unfound data, attributeName: String for the attribute name of the Parse Object,
 * secondAttributeName: String for the attribute name of the Parse Object (for optional! second attribute),
 * showGroupName: optional Boolean for showing group name
 */
export default {
	name: "AutoSelection",
	props:["label", "select", "input", "data", "emptyText", "attributeName", "secondAttributeName", "message", "showGroupName"],
	data(){
		return{
			selected: undefined
		}
	}
}
</script>

<style scoped>

</style>
