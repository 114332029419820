<template>
  <div>
    <b-image
      :src="dropFileURL"
      ratio="64by64"
      alt="Profilbild"
      rounded
    />
    <button
      class="delete is-small mt-3"
      type="button"
      @click="deleteDropFile"
    />
  </div>
</template>

<script>
/**
 * This file shows an uploaded image and provides deleting the uploaded image (indicated as small button with cross)
 * @param: dropFileUrl: String for the image url, deleteDropFile: Function for deleting the dropFile
 */
export default {
	name: "ImageWithDelete",
	props:["dropFileURL", "deleteDropFile"]
}
</script>

<style scoped>

</style>
