<template>
  <div>
    <SearchableTable
      :panel-header="'Landkreise'"
      :panel-route="'AddDistrict'"
      :show-search-bar="false"
      :table-data="districts"
      :table-meta-data="tableMetaData"
      :query-string="'setPersonSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerView(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="eye"
            style="cursor: pointer"
          />
        </b-button>
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
    <ModalComponent
      v-if="isDeleteCheck"
      :item="district.attributes.name"
      :is-delete-check="isDeleteCheck"
      :is-loading="isLoading"
      :cancel="() => isDeleteCheck = false"
      :submit="submitDelete"
    />
  </div>
</template>

<script>
import { queryLocationByType } from "@/lib/Query";
import ModalComponent from "../helperComponents/ModalComponent"
import { positiveFeedBack } from "@/lib/Toast";
import SearchableTable from "../helperComponents/SearchableTable.vue";

export default {
	name: "DistrictsComponent",
	components: { SearchableTable, ModalComponent },
	data() {
		return {
			districts: [],
			district: undefined,
			isDeleteCheck: false,
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Email", "field": "email", "sortField": "attributes.email" },
				{ "id": 1, "type": "string", "label": "Name", "field": "name", "sortField": "attributes.name" },
				{ "id": 2, "type": "string", "label": "Ort", "field": "location", "sortField": "attributes.location" }
			]
		}
	},
	async created() {
		this.districts = await queryLocationByType("district")
	},
	methods: {
		triggerEdit(district) {
			this.$router.push({ name: "AddDistrict", params: { data: district } });
		},
		triggerView(district) {
			this.$router.push({ name: "ViewDistrict", params: { data: district } });
		},
		triggerDelete(district) {
			this.district = district
			this.isDeleteCheck = true
		},
		async submitDelete() {
			const name = this.district.attributes.name
			this.district.set("deleted", true)
			await this.district.save()
			this.isDeleteCheck = false
			this.districts = await queryLocationByType("district")
			await positiveFeedBack(name + " gelöscht!")
		}
	}
}
</script>

<style scoped></style>
