<template>
	<div>
		<div v-if="abwesenheitsgrund" class="panel m-5">
			<p class="panel-heading">
				Abwesenheitsgrund
			</p>
			<div class="columns">
				<div class="column">
					<b-field label="Bezeichner">
						<p class="content">
							{{ abwesenheitsgrundName }}
						</p>
					</b-field>
				</div>
				<div class="column">
					<b-field label="Farbe">
						<div style="text-align: center; display: inline-block;">
							<div class="content" style="width:30px; height:30px;"
								:style="{ 'background-color': abwesenheitsgrundColor.hex }">
							</div>
						</div>
					</b-field>
				</div>
				<div class="column">
					<b-field label="Kürzel">
						<p class="content">
							{{ abwesenheitsgrundHint }}
						</p>
					</b-field>
				</div>
			</div>
		</div>
		<section class="m-2">
			<b-button class="is-danger" tag="router-link" to="/anwesenheit">
				Zurück
			</b-button>
		</section>
	</div>
</template>

<script>
export default {
	name: "ViewAnwesenheitsgrund",
	data() {
		return {
			abwesenheitsgrund: undefined,
			abwesenheitsgrundName: "",
			abwesenheitsgrundColor: {
				"hex": "#000000",
				"source": "hex"
			},
			abwesenheitsgrundHint: "",
		};
	},
	computed: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.abwesenheitsgrund = this.$route.params.data
			this.abwesenheitsgrundName = this.abwesenheitsgrund.attributes.name
			this.abwesenheitsgrundColor.hex = this.abwesenheitsgrund.attributes.color
			this.abwesenheitsgrundHint = this.abwesenheitsgrund.attributes.hint
		}
	},
	methods: {
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
