<template>
	<div>
		<div class="panel m-5">
			<PanelHeader :panel-header="panelHeader" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<InputWithValidation class="column" message="Bitte geben Sie einen Namen ein!" :name="doctorName"
						:set-name="(val) => (doctorName = val)" label="Name" />
					<b-field class="column" label="Spezialisierung">
						<b-autocomplete id="doctorSpecialization_field" class="align-content-center" expanded v-model="name"
							:data="filteredDataObj" placeholder="Bspw. Hausarzt" icon="magnify" clearable
							@select="option => doctorSpecialization = option" field="attributes.specialization">
							<template #empty>Kein Ergebnis gefunden. Neue Spezialisierung wird angelegt.</template>
						</b-autocomplete>
					</b-field>
				</div>
				<hr>
				<div class="columns">
					<InputComponent class="column" :name="doctorStreet" :set-name="(val) => (doctorStreet = val)"
						label="Straße, Hausnummer" />
					<InputComponent class="column" :name="doctorPlz" :set-name="(val) => (doctorPlz = val)" label="PLZ" />
					<InputComponent class="column" :name="doctorLocation" :set-name="(val) => (doctorLocation = val)"
						label="Ort" />
				</div>
			</div>
		</div>
		<div class="m-5 buttons" style="display: flex; justify-content: center">
			<SaveButton :is-loading="isLoading" :save-function="saveDoctor" :disabled="$v.doctorName.$invalid" />
			<CancelButton text="Abbrechen" back-route="/doctor" :is-loading="false" />
		</div>
	</div>
</template>
  
<script>
/**
 * This file is for creating and editing doctors.
 */
import Parse from "parse/dist/parse.min.js";
import { minLength, required } from "vuelidate/lib/validators";
import { negativeFeedBack, positiveFeedBack } from "@/lib/Toast";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import InputComponent from "@/components/helperComponents/InputComponent"
import { mapGetters } from "vuex";

export default {
	name: "NewDoctor",
	components: {
		SaveButton,
		CancelButton,
		InputWithValidation,
		PanelHeader,
		InputComponent,
	},
	data() {
		return {
			dataObject: undefined,
			isLoading: false,
			doctorName: "",
			doctorSpecialization: undefined,
			doctorStreet: "",
			doctorPlz: "",
			doctorLocation: "",
			panelHeader: "Neuen Arzt anlegen",
			name: ""
		};
	},
	computed: {
		...mapGetters({
			doctorSpecializationArray: "getDoctorSpecialization"
		}),
		filteredDataObj() {
			if(this.doctorSpecializationArray)
			{
				return this.doctorSpecializationArray.filter(object => {
					return (
						object.attributes.specialization
							.toString()
							.toLowerCase()
							.indexOf(this.name.toLowerCase()) >= 0
					)
				})
			}else
			{
				return [];
			}
			
		},
	},
	watch: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.dataObject = this.$route.params.data
			this.doctorName = this.dataObject.attributes.name
			this.doctorSpecialization = this.dataObject.attributes.specialization ? this.dataObject.attributes.specialization : undefined
			this.name = this.doctorSpecialization ? this.doctorSpecialization.attributes.specialization : ""
			this.doctorStreet = this.dataObject.attributes.street
			this.doctorPlz = this.dataObject.attributes.plz
			this.doctorLocation = this.dataObject.attributes.location
			this.panelHeader = "Arzt bearbeiten"
		}
	},
	validations: {
		doctorName: {
			required,
			minLength: minLength(1),
		}
	},
	methods: {
		async saveDoctor() {

			this.isLoading = true;

			//Spezialisation holen, die über das Select gesetzt wurde
			let specialization = this.doctorSpecialization

			//Wenn der Inhalt vom Input nicht gleich der gewählten Select-Auswahl ist, dann resetten (Grund: Resetten der Select-Auswahl, wenn Spezialisierung bearbeitet wird)
			if((specialization && specialization.attributes.specialization != this.name) || this.name == "")
			{
				specialization = undefined;
			}

			//Wenn keine Select-Auswahl getroffen wurde, dann Input-Eingabe in den verfügbaren Select-Auswahlen suchen, um doppelt Anlagen zu vermeiden
			if(!specialization && this.doctorSpecializationArray && this.doctorSpecializationArray.length != 0)
			{
				for(var i=0; i<this.doctorSpecializationArray.length; i++)
				{
					if(this.doctorSpecializationArray[i].attributes.specialization == this.name)
					{
						specialization = this.doctorSpecializationArray[i];
					}
				}
			}

			//Wenn es eine komplett neue Eingabe ist, dann im Spezialisierungsobject neu speichern und zurückgeben.
			if(!specialization && this.name != "")
			{
				const parseObjectSpecialisierung = Parse.Object.extend("MedicationDoctorSpecialization")
				const dataObjectSpecialisierung = new parseObjectSpecialisierung()
				specialization = await dataObjectSpecialisierung
					.save({"specialization": this.name});
			}
			
			const parseObject = Parse.Object.extend("MedicationDoctor")
			const dataObject = this.dataObject ? this.dataObject : new parseObject()
			dataObject.set("name", this.doctorName)
			if(specialization)
			{
				dataObject.set("specialization", specialization)
			}else
			{
				dataObject.unset("specialization")
			}
			
			dataObject.set("street", this.doctorStreet)
			dataObject.set("plz", this.doctorPlz)
			dataObject.set("location", this.doctorLocation)
			dataObject.set("deleted", false)
			
			await dataObject.save().catch((e) => {
				negativeFeedBack("Arzt nicht gespeichert!")
			});
			this.isLoading = false;
			await this.$router.push({ path: "/doctor" })
			await positiveFeedBack("Arzt gespeichert!")
		},
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		},
	},
};
</script>
  
<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
  