<template>
  <div class="hero">
    <div
      class="
                hero-head
                is-flex is-justify-content-center is-align-content-center
            "
    >
      <b-button
        class="m-1"
        icon-left="chevron-left"
        @click="routeBack()"
      >
        Zurück
      </b-button>
    </div>
    <div
      class="hero-body container scrollable"
      v-html="imprint[app_imprint]"
    />
  </div>
</template>

<script>

import { APP_IMPRINT } from "@/lib/config/AppConfigurations";
import IMPRINT from '../../assets/texts/imprint.json'


export default {
    name: "ImprintComponent",
    data() {
        return {
			imprint : IMPRINT,
			app_imprint : APP_IMPRINT
		};
    },
    computed: {},
	methods: {
		async routeBack() {
			return await this.$router.back();
		}
	}
};
</script>

<style scoped>
.scrollable {
    overflow: auto;
    height: 93%;
}
</style>