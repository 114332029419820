<template>
  <div style="cursor: pointer;display: flex;justify-content: center; align-items: center">
    <b-button
      style="cursor: pointer"
      class="is-small mr-2"
      :class="size"
      @click="triggerFunction"
    >
      <b-icon
        :icon="icon"
        :type="type? type: ''"
      />
    </b-button>
  </div>
</template>

<script>
/**
 * This file shows button with nested icon
 * @param: triggerFunction: Function after clicking the button, icon:String for icon from materialdesigns, size:String for size of the button (is-small, is-large, '')
 */
export default {
name: "IconButton",
	props:[
		"triggerFunction", "icon", "size", "type"
	]
}
</script>

<style scoped>

</style>
