<template>
  <div>
    <SearchableTable
      :panel-header="'Personen'"
      :panel-route="'AddPerson'"
      :show-search-bar="true"
      :table-data="persons"
      :table-meta-data="tableMetaData"
      :query-string="'setPersonSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerView(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="eye"
            style="cursor: pointer"
          />
        </b-button>
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
    <ModalComponent
      v-if="isDeleteCheck && person"
      :item="getName(person)"
      :is-delete-check="isDeleteCheck"
      :is-loading="isLoading"
      :cancel="() => isDeleteCheck = false"
      :submit="submitDelete"
    />
  </div>
</template>

<script>
import { queryPersonsWithOutSocialWorkers } from "@/lib/Query";
import ModalComponent from "../helperComponents/ModalComponent"
import { positiveFeedBack } from "@/lib/Toast";
import SearchableTable from "../helperComponents/SearchableTable.vue";
import { mapGetters } from "vuex";

export default {
	name: "SocialWorkers",
	components: { SearchableTable, ModalComponent },
	data() {
		return {
			person: undefined,
			isDeleteCheck: false,
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Email", "field": "email", "sortField": "attributes.email" },
				{ "id": 1, "type": "string", "label": "Vorname", "field": "first_name", "sortField": "attributes.first_name" },
				{ "id": 2, "type": "string", "label": "Nachname", "field": "last_name", "sortField": "attributes.last_name" },
				{ "id": 3, "type": "string", "label": "Typ", "field": "type", "sortField": "attributes.type" }
			]
		}
	},
	computed: {
		...mapGetters({
			persons: "getPersonFiltered"
		})
	},
	methods: {
		triggerEdit(person) {
			this.$router.push({ name: "AddPerson", params: { data: person } });
		},
		triggerView(person) {
			this.$router.push({ name: "ViewPerson", params: { data: person } });
		},
		triggerDelete(person) {
			this.person = person
			this.isDeleteCheck = true
		},
		async submitDelete() {
			const name = this.getName(this.person)
			this.person.set("deleted", true)
			await this.person.save()
			this.isDeleteCheck = false
			this.persons = await queryPersonsWithOutSocialWorkers()
			await positiveFeedBack(name + " gelöscht!")
		},
		getName(person) {
			return `${person.attributes.first_name} ${person.attributes.last_name}`
		}
	}
}
</script>

<style scoped></style>
