<template>
  <b-upload
    v-if="typeof file !== 'string'"
    v-model="file"
    @input="setFile()"
  >
    <section class="section">
      <div class="content has-text-centered">
        <p>
          <b-icon
            class="uploadIcon"
            icon="upload"
            size="is-large"
          />
        </p>
      </div>
    </section>
  </b-upload>
</template>

<script>
/**
 * This file is for uploading an image
 * @param: dropFile: File image chosen to upload, setDropFile: Function for setting the dropfile
 */
export default {
	name: "ImageUpload",
	props:["dropFile", "setDropFile"],
	data(){
		return{
			file: this.dropFile
		}
	},
	methods:{
		setFile(){
			this.setDropFile(this.file)
		}
	}
}
</script>

<style scoped>
.uploadIcon {
	cursor: pointer;
}
</style>
