<template>
	<b-field :label="label">
		<b-datepicker v-model="date" :first-day-of-week="1" class="m-2" locale="de-DE" placeholder="Datum wählen..."
			icon="calendar-today" trap-focus editable :date-formatter="dateFormatter" @input="set()" />
	</b-field>
</template>

<script>
/**
 * This is file is d datpicker with description/label
 * @param: label: String for description, selectedDate: Date for the selected Date, select: Function for setting the selected date
 */
export default {
	name: "DatePicker",
	props: ["label", "selectedDate", "select"],
	data() {
		return {
			date: this.selectedDate
		}
	},
	watch: {
		selectedDate(currentValue) {
			if (currentValue) {
				this.date = currentValue
			}
		}
	},
	methods: {
		set() {
			this.select(this.date)
		},
		dateFormatter(dt) {
			return dt.toLocaleDateString("de-DE", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			});
		},
	}
}
</script>

<style scoped></style>
