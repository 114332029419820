<template>
  <div class="borderContainer">
    <b-field label="Klienten">
      <b-autocomplete
        v-model="client"
        class="m-2"
        :data="clientsArray"
        placeholder="Klient"
        icon="magnify"
        @input="e => clientFilter = e"
        @select="option => chosenClients.push(option)"
      >
        <template #empty>
          Keine Klienten gefunden
        </template>
        <template slot-scope="props">
          <div class="content">
            {{ props.option.attributes.first_name }}
            {{ props.option.attributes.last_name }}
            ({{ props.option.attributes.group.attributes.group_name }})
          </div>
        </template>
      </b-autocomplete>
    </b-field>
    <div class="overflowY">
      <div
        v-for="(innerClient) in chosenClients"
        :key="innerClient.id"
        class="m-2 pl-4"
        style="display: flex"
      >
        <div
          class="pr-2"
          @click="()=> filter(innerClient.id)"
        >
          <b-icon
            icon="delete"
            style="cursor: pointer"
            type=""
          />
        </div>
        <p>
          {{ innerClient.attributes.first_name }} {{ innerClient.attributes.last_name }}
          ({{ innerClient.attributes.group.attributes.group_name }})
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {queryClients} from "@/lib/Query";


export default {
	name: "ChildSelection",
	props: ["chosenClients"],
	data() {
		return {
			clientsArrayDefault: [],
			client: "",
			clientFilter: "",
		}
	},
	computed: {
		clientsArray() {
			return this.clientsArrayDefault.filter(client => {
				const name = `${client.attributes.first_name} ${client.attributes.last_name}`.toLowerCase()
				const isFilterInName = this.clientFilter && name.includes(this.clientFilter.toLowerCase())
				const clientIds = this.chosenClients.map(c => c.id)
				return isFilterInName && !clientIds.includes(client.id)
			})
		}
	},
	async mounted() {
		this.clientsArrayDefault = await queryClients()
	},
	methods:{
		filter(id){
			this.$emit('filter', id)
		}
	}
}
</script>

<style scoped>
.borderContainer {
	border-radius: .5rem;
	padding: .5rem;
	margin: .5rem;
}

.overflowY{
	max-height: 10rem;
	overflow-y: auto;
}
</style>
