<template>
  <div>
    <div class="panel m-5">
      <PanelHeader :panel-header="person ? getName(person) : 'Neue Person'" />
      <div class="container pt-3 pb-3">
        <div class="columns">
          <InputWithValidation
            :key="isMounted ? 'firstNameU' : 'fistName'"
            class="column"
            :name="firstName"
            :set-name="(val) => (firstName = val)"
            label="Vorname"
            message="Bitte gib einen Vornamen ein!"
          />
          <InputWithValidation
            :key="isMounted ? 'nameU' : 'name'"
            class="column"
            :name="lastName"
            :set-name="(val) => (lastName = val)"
            label="Nachname"
            message="Bitte gib einen Nachnamen ein!"
          />
          <InputComponent
            :key="isMounted ? 'emailu' : 'email'"
            class="column"
            :name="email"
            :set-name="(val) => (email = val)"
            label="E-Mail"
          />
          <SelectionComponent
            :key="type
              ? type
              : 'type'
            "
            class="column"
            label="Typ"
            :options="types"
            :selected-option="type"
            :select="
              (item) => (type = item)
            "
          />
        </div>
        <div class="columns">
          <InputComponent
            :key="isMounted ? 'phone1u' : 'phone1'"
            class="column"
            :name="phone1"
            :set-name="(val) => (phone1 = val)"
            label="Telefon 1"
          />
          <InputComponent
            :key="isMounted ? 'info1u' : 'info1'"
            class="column"
            :name="info1"
            :set-name="(val) => (info1 = val)"
            label="Info 1"
          />
        </div>
        <div class="columns">
          <InputComponent
            :key="isMounted ? 'phone2u' : 'phone2'"
            class="column"
            :name="phone2"
            :set-name="(val) => (phone2 = val)"
            label="Telefon 2"
          />
          <InputComponent
            :key="isMounted ? 'info2u' : 'info2'"
            class="column"
            :name="info2"
            :set-name="(val) => (info2 = val)"
            label="Info 2"
          />
        </div>
        <div class="columns">
		<InputComponent
            :key="isMounted ? 'streetu' : 'street'"
            class="column"
            :name="street"
            :set-name="(val) => (street = val)"
            label="Straße"
          />
          <InputComponent
            :key="isMounted ? 'plzu' : 'plz'"
            class="column"
            :name="plz"
            :set-name="(val) => (plz = val)"
            label="PLZ"
          />
          <InputComponent
            :key="isMounted ? 'locationu' : 'location'"
            class="column"
            :name="location"
            :set-name="(val) => (location = val)"
            label="Ort"
          />
        </div>
      </div>
    </div>

    <div
      class="m-5 buttons"
      style="display: flex; justify-content: center"
    >
      <SaveButton
        :is-loading="isLoading"
        :save-function="save"
        :disabled=" $v.firstName.$invalid || $v.lastName.$invalid
        "
      />
      <CancelButton
        text="Abbrechen"
        back-route="/persons"
        :is-loading="false"
      />
    </div>
  </div>
</template>

<script>
import Parse from "parse/dist/parse.min"
import InputComponent from "../helperComponents/InputComponent"
import CancelButton from "@/components/helperComponents/CancelButton";
import SaveButton from "@/components/helperComponents/SaveButton";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import {minLength, required} from "vuelidate/lib/validators";
import {positiveFeedBack} from "@/lib/Toast";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import SelectionComponent from "@/components/helperComponents/SelectionComponent";
import {PERSON_TYPES} from "@/lib/constants/Constants";
export default {
	name: "NewPerson",
	components:{SelectionComponent, PanelHeader, InputWithValidation, SaveButton, CancelButton, InputComponent},
	data(){
		return{
			isMounted: false,
			isLoading: false,
			firstName: undefined,
			lastName: undefined,
			location: undefined,
			phone1: undefined,
			phone2: undefined,
			info1: undefined,
			info2: undefined,
			email: undefined,
			street: undefined,
			plz: undefined,
			person: undefined,
			types: PERSON_TYPES,
			type: undefined
		}
	},
	validations: {
		firstName: {
			required,
			minLength: minLength(1),
		},
		lastName: {
			required,
			minLength: minLength(1),
		},
	},
	mounted() {
		if (!this.$route.params.data) return (this.isMounted = true);
		this.person = this.$route.params.data;
		const {first_name,last_name,type, street, plz, location, email, phone1, phone2, info1, info2} = this.person.attributes
		this.firstName = first_name
		this.lastName = last_name
		this.type = type
		this.street = street
		this.plz = plz
		this.location = location
		this.email = email
		this.phone1 = phone1
		this.phone2 = phone2
		this.info1 = info1
		this.info2 = info2
		this.isMounted = true
	},
	methods:{
		async save(){
			const Person = Parse.Object.extend("Person")
			const person = this.person ? this.person : new Person()
			person.set("first_name", this.firstName)
			person.set("last_name", this.lastName)
			person.set("type", this.type)
			person.set("street", this.street)
			person.set("plz", this.plz)
			person.set("location", this.location)
			person.set("email", this.email)
			person.set("phone1", this.phone1)
			person.set("info1", this.info1)
			person.set("phone2", this.phone2)
			person.set("info2", this.info2)
			await person.save()
			await this.$router.push({path: "/persons"})
			await positiveFeedBack("Person erstellt")
		},
		getName(person){
			return `${person.attributes.first_name} ${person.attributes.last_name}`
		}
	}
}
</script>

<style scoped>

</style>
