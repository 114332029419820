import Parse from 'parse/dist/parse.min.js';
import { handleParseError } from '../../lib/Errors';

let localSubscription = undefined;

// Socialworker subscription
function createSubscription(store) {
	let socialWorkerQuery = new Parse.Query('Person');
	socialWorkerQuery.notEqualTo("deleted", true)
	socialWorkerQuery.equalTo("type", "socialWorker")
	socialWorkerQuery.addAscending("last_name")
		.limit(10000)
		.find() //das folgende evtl nochmal lesen//überarbeiten
		.then((results) => {
			store.dispatch('socialWorkers/set', results)
		})
		.catch((error) => {
			handleParseError(error);
			
		});
	socialWorkerQuery.subscribe().then((subscription) => {
		localSubscription = subscription;

		subscription.on('create', (object) => {
			store.dispatch('socialWorkers/insert', object)
		});
		subscription.on('update', (object) => {
			store.dispatch('socialWorkers/replace', object)
		});
		subscription.on('enter', (object) => {
			store.dispatch('socialWorkers/insert', object)
		});
		subscription.on('leave', (object) => {
			store.dispatch('socialWorkers/delete', object)
		});
		subscription.on('delete', (object) => {
			store.dispatch('socialWorkers/delete', object)
		});
		subscription.on('close', () => {
		});
		subscription.on('error', error => {
            console.log('Socialworker subscription error', error);
        })
	})
}

export default function createSocialworkerSubscription() {
	return store => {
		if (store.state.currentUser) {
			createSubscription(store)
		}
		store.subscribe(mutation => {
			if (mutation.type === 'SET_CURRENT_USER') {
				if (localSubscription) {
					localSubscription.unsubscribe()
				}
				if (store.state.currentUser) {
					createSubscription(store)
				}
			}
		})
	}
}
