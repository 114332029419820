<template>
	<div>
		<div v-if="dataObject" class="panel m-5">
			<PanelHeader panel-header="Bedarfsmedikament" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<div class="column">
						<b-field label="Medikament">
							<p v-if="medicationMedication.attributes.displayName" class="content">
								{{ medicationMedication.attributes.displayName }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Mindestmenge">
							<p v-if="medicationMin >= 0" class="content">
								{{ medicationMin + " " + medicationMedication.attributes.medicationUnit.attributes.symbol}}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Verantwortlicher">
							<p v-if="medicationUser.attributes.dependency.attributes" class="content">
								{{ medicationUser.attributes.dependency.attributes.first_name + " " +
									medicationUser.attributes.dependency.attributes.last_name }}
							</p>
						</b-field>
					</div>
				</div>
				<hr>
				<div class="columns">
					<div class="column">
						<b-field label="Beginn">
							<p v-if="medicationBeginDate" class="content">
								{{ medicationBeginDate.toLocaleDateString("de-DE", {
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
								}) }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Ende">
							<p v-if="medicationEndDate" class="content">
								{{ this.medicationEndDate.toLocaleDateString("de-DE", {
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
								}) }}
							</p>
						</b-field>
					</div>
					<div class="column">
						<b-field label="Ablaufdatum">
							<p v-if="medicationExpirationDate" class="content">
								{{ this.medicationExpirationDate.toLocaleDateString("de-DE", {
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
								}) }}
							</p>
						</b-field>
					</div>
				</div>
				<hr>
				<div class="columns">
					<div class="column">
						<b-field label="Hinweise">
							<p class="content">
								{{ medicationHint }}
							</p>
						</b-field>
					</div>
				</div>
			</div>
		</div>
		<div>
			<section class="m-5">
				<b-button class="is-danger" tag="router-link" to="/instant_medication">
					Zurück
				</b-button>
			</section>
		</div>
	</div>
</template>
  
<script>
/**
 * This file is for viewing a focused instant medication.
 */

import PanelHeader from "@/components/helperComponents/PanelHeader";

export default {
	name: "ViewInstantMedication",
	components: {
		PanelHeader,
	},
	data() {
		return {
			dataObject: undefined,
			medicationMedication: undefined,
			medicationMin: "",
			medicationHint: "",
			medicationBeginDate: undefined,
			medicationEndDate: undefined,
			medicationExpirationDate: undefined,
			medicationUser: undefined,
		};
	},
	computed: {},
	mounted() {
		this.scrollToElement()
		if (this.$route.params.data) {
			this.dataObject = this.$route.params.data
			this.medicationMedication = this.dataObject.attributes.medication
			this.medicationMin = this.dataObject.attributes.min
			this.medicationHint = this.dataObject.attributes.hint
			this.medicationBeginDate = this.dataObject.attributes.beginDate
			this.medicationEndDate = this.dataObject.attributes.endDate
			this.medicationExpirationDate = this.dataObject.attributes.expirationDate
			this.medicationUser = this.dataObject.attributes.user
		}
	},
	methods: {
		scrollToElement() {
			const el = this.$parent.$parent.$refs.scrollToMe;
			if (el) {
				el.scrollIntoView({ behavior: 'smooth' });
			}
		},
	},
}
</script>
  
<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
  