<template>
	<b-field :label="label" :type="
		!$v.nameInput.$invalid
			? ''
			: 'is-danger'">
		<template #label>
			<div class="align-content-center" style="display: inline-flex">
				<!--				<b-icon icon="format-text" class="pr-2"></b-icon>-->
				<p>{{ label }}</p>
			</div>
		</template>
		<b-input v-model="nameInput" class="m-2 has-text-left" :placeholder="placeholder? placeholder : label" :type="type ? type : 'text'"
			@input="set()" />
	</b-field>
</template>

<script>
import { required } from "vuelidate/lib/validators";
/**
 *  This file provides an input for setting a String
 *  @param: name:String for setted string, setName: function for setting the name, label: String description for the input
 */
export default {
	name: "InputComponent",
	props: [
		"label", "setName", "name", "type", "placeholder",
	],
	validations() {
		return {
			name: {
				required,
			},
			nameInput: {}
		}
	},
	data() {
		return {
			nameInput: this.name
		}
	},
	watch: {
		name: function (val) {
			this.nameInput = val
		}
	},
	methods: {
		set() {
			this.setName(this.nameInput)
		}
	},
}
</script>

<style scoped></style>
