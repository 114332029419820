<template>
  <div>
    <SearchableTable
      :panel-header="'Sozialarbeiter'"
      :panel-route="'AddSocialWorker'"
      :show-search-bar="true"
      :table-data="socialWorkers"
      :table-meta-data="tableMetaData"
      :query-string="'setsocialWorkerSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerView(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="eye"
            style="cursor: pointer"
          />
        </b-button>
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
    <ModalComponent
      v-if="isDeleteCheck && socialWorker"
      :item="getName(socialWorker)"
      :is-delete-check="isDeleteCheck"
      :is-loading="isLoading"
      :cancel="() => isDeleteCheck = false"
      :submit="submitDelete"
    />
  </div>
</template>

<script>
import { queryPersonByType } from "@/lib/Query";
import ModalComponent from "../helperComponents/ModalComponent"
import { positiveFeedBack } from "@/lib/Toast";
import SearchableTable from "../helperComponents/SearchableTable.vue";
import { mapGetters } from "vuex";

export default {
	name: "SocialWorkers",
	components: { SearchableTable, ModalComponent },
	data() {
		return {
			socialWorker: undefined,
			isDeleteCheck: false,
			isLoading: false,
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Email", "field": "email", "sortField": "attributes.email" },
				{ "id": 1, "type": "string", "label": "Vorname", "field": "first_name", "sortField": "attributes.first_name" },
				{ "id": 2, "type": "string", "label": "Nachname", "field": "last_name", "sortField": "attributes.last_name" }
			]
		}
	},
	computed: {
		...mapGetters({
			socialWorkers: "getSocialWorkerFiltered"
		})
	},
	methods: {
		triggerEdit(socialWorker) {
			this.$router.push({ name: "AddSocialWorker", params: { data: socialWorker } });
		},
		triggerView(socialWorker) {
			this.$router.push({ name: "ViewSocialWorker", params: { data: socialWorker } });
		},
		triggerDelete(socialWorker) {
			this.socialWorker = socialWorker
			this.isDeleteCheck = true
		},
		async submitDelete() {
			const name = this.getName(this.socialWorker)
			this.socialWorker.set("deleted", true)
			await this.socialWorker.save()
			this.isDeleteCheck = false
			this.socialWorkers = await queryPersonByType("socialWorker")
			await positiveFeedBack(name + " gelöscht!")
		},
		getName(socialWorker) {
			return `${socialWorker.attributes.first_name} ${socialWorker.attributes.last_name}`
		}
	}
}
</script>

<style scoped></style>
