<template>
  <div>
    <div
      v-if="externalMetaClass"
      class="panel m-5"
    >
      <PanelHeader panel-header="Stammdaten" />
      <div class="container pt-3 pb-3">
        <div class="columns is-centered">
          <div class="column image_column">
            <b-field
              label="Profilbild"
              :addons="false"
            >
              <ImageWithDelete
                v-if="dropFile"
                :delete-drop-file="deleteDropFile"
                :drop-file-u-r-l="dropFileURL"
              />
              <ImageUpload
                v-else
                :drop-file="dropFile"
                :set-drop-file="(file)=>dropFile = file"
              />
            </b-field>
          </div>
        </div>
        <hr>
        <div class="columns">
          <InputWithValidation
            v-if="isMounted"
            class="column"
            message="Bitte gib einen Vornamen ein!"
            :name="firstName"
            :set-name="(name)=> firstName = name"
            label="Vorname"
          />
          <InputWithValidation
            v-if="isMounted"
            class="column"
            message="Bitte gib einen Nachnamen ein!"
            :name="familyName"
            :set-name="(name)=> familyName = name"
            label="Nachname"
          />
          <div class="column">
            <b-field label="Geschlecht">
              <b-select
                v-model="gender"
                class="m-2"
                placeholder="Geschlecht"
                expanded
              >
                <option
                  v-for="option in genderArray"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field
              label="Funktion"
              class="m-2"
            >
              <b-select
                id="function"
                v-model="externalFunction"
                placeholder="Funktion"
                expanded
              >
                <option
                  v-for="option in functions"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Suche Klienten"
              class="m-2"
            >
              <b-autocomplete
                v-model="client"
                :data="clientsArray"
                placeholder="Klient"
                icon="magnify"
                @input="e => filterClients(e)"
                @select="option => addClient(option)"
              >
                <template #empty>
                  Keine Klienten gefunden
                </template>
                <template slot-scope="props">
                  <div class="content">
                    {{ props.option.attributes.first_name }} {{ props.option.attributes.last_name }}
                  </div>
                </template>
              </b-autocomplete>
            </b-field>
            <div
              v-for="(innerClient, index) in chosenClients"
              :key="index"
              class="m-2"
              style="display: flex"
            >
              <b-button
                class="is-small mr-2"
                @click="deleteClient(index)"
              >
                <b-icon
                  icon="delete"
                  style="cursor: pointer"
                />
              </b-button>
              <p>{{ innerClient.attributes.first_name }} {{ innerClient.attributes.last_name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel m-5">
      <p class="panel-heading">
        Kontakt- und Adressdaten
      </p>
      <div class="container pt-3 pb-3">
        <div class="columns">
          <InputComponent
            v-if="isMounted"
            class="column"
            :name="phone1"
            :set-name="(name)=> phone1 = name"
            label="Telefon 1"
          />
          <InputComponent
            v-if="isMounted"
            class="column"
            :name="phone2"
            :set-name="(name)=> phone2 = name"
            label="Telefon 2"
          />
        </div>
        <div class="columns">
		<InputComponent
            v-if="isMounted"
            class="column"
            :name="street"
            :set-name="(name)=> street = name"
            label="Straße"
          />
          <InputComponent
            v-if="isMounted"
            class="column"
            :name="plz"
            :set-name="(name)=> plz = name"
            label="PLZ"
          />
          <InputComponent
            v-if="isMounted"
            class="column"
            :name="location"
            :set-name="(name)=> location = name"
            label="Ort"
          />
        </div>
      </div>
    </div>

    <section class="m-5">
      <div
        class="buttons"
        style="display: flex; justify-content: center"
      >
        <b-button
          class="is-primary"
          :loading="isLoading"
          :disabled="$v.firstName.$invalid ||$v.familyName.$invalid"
          @click="editExternal()"
        >
          Speichern
        </b-button>
        <b-button
          class="is-danger"
          tag="router-link"
          to="/externals"
        >
          Abbrechen
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
/**
 * This file is for editing external persons
 */
import Parse from "parse/dist/parse.min.js"
import {v4 as uuid} from "uuid";
import {minLength, required} from "vuelidate/lib/validators";
import {getUserPicture} from "@/lib/Picture";
import {GENDERS} from "../../lib/constants/Constants";
import {negativeFeedBack, positiveFeedBack} from "@/lib/Toast";
import {queryClients, queryUserMetaClasses} from "@/lib/Query";
import PanelHeader from "@/components/helperComponents/PanelHeader";
import ImageUpload from "@/components/helperComponents/ImageUpload";
import ImageWithDelete from "@/components/helperComponents/ImageWithDelete";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import InputComponent from "@/components/helperComponents/InputComponent";
export default {
	name: "EditExternal",
	components: {ImageWithDelete, ImageUpload, PanelHeader, InputComponent, InputWithValidation},
	data() {
		return {
			dropFile: undefined,
			firstName: "",
			familyName: "",
			phone1: "",
			phone2: "",
			street: "",
			location: "",
			plz: "",
			externalMetaClass: undefined,
			externalFunction: "",
			functions: ["Lehrer", "Therapeuten", "Elternteil"],
			client: undefined,
			clientsArray: [],
			clientsArrayDefault: [],
			chosenClients: [],
			gender: "",
			genderArray: GENDERS,
			isLoading: false,
			isMounted: false
		}
	},
	validations: {
		firstName: {
			required,
			minLength: minLength(1)
		},
		familyName: {
			required,
			minLength: minLength(1)
		}
	},
	computed: {
		dropFileURL() {
			const pictureObject = {
				newProfilePicture: typeof this.dropFile === "string" ? undefined : this.dropFile,
				userPicture: typeof this.dropFile === "string" ? this.dropFile : undefined,
				userGender: this.externalMetaClass.attributes.gender
			}
			return getUserPicture(pictureObject)
		}
	},
	async mounted() {
		await queryUserMetaClasses()
		this.clientsArray = await queryClients()
		if(!this.externalMetaClass) return
	},
	async created() {
		const externalMetaClass = this.$route.params.data
		if(!externalMetaClass) return await this.$router.push("/externals")
		this.externalMetaClass = externalMetaClass
		this.firstName = externalMetaClass.attributes.first_name ? externalMetaClass.attributes.first_name : ""
		this.familyName = externalMetaClass.attributes.last_name ? externalMetaClass.attributes.last_name : ""
		this.street = externalMetaClass.attributes.street ? externalMetaClass.attributes.street : ""
		this.plz = externalMetaClass.attributes.plz ? externalMetaClass.attributes.plz : ""
		this.location = externalMetaClass.attributes.location ? externalMetaClass.attributes.location : ""
		this.phone1 = externalMetaClass.attributes.phone1 ? externalMetaClass.attributes.phone1 : ""
		this.phone2 = externalMetaClass.attributes.phone2 ? externalMetaClass.attributes.phone2 : ""
		this.dropFile = externalMetaClass.attributes.profile_picture ? externalMetaClass.attributes.profile_picture._url : undefined
		this.chosenClients = externalMetaClass.attributes.clients ? externalMetaClass.attributes.clients : []
		this.externalFunction = externalMetaClass.attributes.function ? externalMetaClass.attributes.function : ""
		this.gender = externalMetaClass.attributes.gender ? externalMetaClass.attributes.gender : ""
		const clients = this.$store.state.clients.data
		this.clientsArrayDefault = clients.filter(client => {
			const clientIds = this.chosenClients.map(client => client.id)
			return !clientIds.includes(client.id)
		})
		this.isMounted = true
	},
	methods: {
		async editExternal() {
			this.isLoading = true
			const externalMetaClass = this.externalMetaClass
			externalMetaClass.set("first_name", this.firstName)
			externalMetaClass.set("last_name", this.familyName)
			externalMetaClass.set("phone1", this.phone1)
			externalMetaClass.set("phone2", this.phone2)
			externalMetaClass.set("plz", this.plz)
			externalMetaClass.set("location", this.location)
			externalMetaClass.set("street", this.street)
			externalMetaClass.set("is_admin", false)
			externalMetaClass.set("function", this.externalFunction)
			externalMetaClass.set("gender", this.gender)
			externalMetaClass.set("type", "external");
			const clientPointers = this.chosenClients.map(client => client.toPointer())
			externalMetaClass.set("clients", clientPointers)
			if(this.dropFile === undefined){
				externalMetaClass.unset("profile_picture")
			}
			try {
				if (this.dropFile !== undefined && typeof this.dropFile !== "string") {
					const imageFile = new Parse.File(uuid(), this.dropFile);
					externalMetaClass.set("profile_picture", imageFile);
					try{
						await imageFile.save()
					}catch(e){
						console.error("couldnt save image file " + e)
					}
				}
				await externalMetaClass.save()
				positiveFeedBack("Externe Person bearbeitet!")
				await this.$router.push({path: "/externals"})
			} catch (error) {
				negativeFeedBack("Externe Person nicht bearbeitet!")
				console.error(error)
			}

			this.isLoading = false
		},
		deleteDropFile() {
			this.dropFile = undefined;
		},
		filterClients(e) {
			if (e) {// value existent
				const value = e.toLowerCase()
				this.clientsArray = this.clientsArrayDefault.filter(client => {
					const name = `${client.attributes.first_name} ${client.attributes.last_name}`
					return name.toLowerCase().includes(value)
				})
			}
		},
		addClient(option){
			this.chosenClients.push(option)
			this.clientsArray = this.clientsArrayDefault.filter(client => client.id !== option.id)
		},
		deleteClient(index){
			this.clientsArrayDefault.push(this.chosenClients[index])
			this.chosenClients.splice(index, 1)
		}
	}
}
</script>

<style lang="scss" scoped>
.image_column {
	max-width: 200px;
}
</style>
