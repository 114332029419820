<template>
  <b-button
    v-if="backRoute"
    class="is-danger"
    tag="router-link"
    :is-loading="isLoading"
    :to="backRoute"
  >
    {{ text }}
  </b-button>
  <b-button
    v-else
    class="is-danger"
    @click="triggerFunction"
  >
    {{ text }}
  </b-button>
</template>

<script>
/**
 *  This file is a cancel button
 *  @param: backRoute for cancel-path/back-route, isLoading: boolean for indicating loading, text: String for button text
 *  triggerFunction: optional Function for delete button
 */
export default {
	name: "CancelButton",
	props:["backRoute", "text", "isLoading", "triggerFunction"]
}
</script>

<style scoped>

</style>
