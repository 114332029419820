<template>
	<div>
		<ICD10Table />
	</div>
</template>
  
<script>
/**
 * This file shows all currently stored icd 10 codes and allows adding, editing, viewing and soft deleting these.
 */
import ICD10Table from "@/components/medication/icd_10/ICD10Table";
export default {
	name: "ICD10",
	components: { ICD10Table },
	data() {
		return {};
	},
	computed: {},
	mounted() { },
	methods: {},
};
</script>
  
<style lang="scss" scoped></style>
  