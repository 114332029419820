var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2"},[(_vm.data.length > 0)?_c('b-button',{staticClass:"is-primary",attrs:{"disabled":!_vm.selectedAccount ||
      !_vm.selectedBalance ||
      _vm.data.some(row => row.entity === undefined) ||
      _vm.columns.some((c, idx) => {
        if(idx === 0) return false
        return c.value === undefined
      })},on:{"click":function($event){return _vm.$emit('save')}}},[_vm._v(" Speichern ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }