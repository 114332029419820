<template>
	<div>
		<b-navbar :class="!hasNoConnection ? 'topbar' : 'is-not-online'">
			<template #brand>
				<b-navbar-item v-if="showUsername()" @click="routeHome()">
					<div style="height: 1.75rem; width: 1.75rem;">
						<img :src="getPicture()" style="border-radius: 9999px; object-fit: cover; height: 100%"
							alt="Profilbild">
					</div>
					<p class="barelement ml-1">
						{{ userDetails ? getUserName() : "" }}
					</p>
				</b-navbar-item>
			</template>
			<template #end>
				<b-navbar-item class="logOutElement" v-if="showUsername()" tag="router-link" :to="{ path: '/log_out' }">
					<p class="barelement">
						Abmelden
					</p>
				</b-navbar-item>
			</template>
		</b-navbar>
		<version-modal v-model="isVersionModalActive" />
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { getUserPicture } from "@/lib/Picture";
import { APP_LOGO } from "@/lib/config/AppConfigurations";
import { version } from "../../../package.json"
import VersionModal from "@/components/modals/VersionModal.vue";
import Parse from "parse/dist/parse.min.js";

export default {
	name: "TopBar",
	components: { VersionModal },
	data() {
		return {
			app_logo: APP_LOGO,
			applicationVersion: version,
			isVersionModalActive: false,
			hasNoConnection: false
		};
	},
	computed: {
		...mapGetters({
			userDetails: "getCurrentUserMeta",
			user: "getCurrentUser",
			dismissUpdate: "versions/getdismissUpdate",
			apiVersion: "versions/getApiVersion"
		})
	},
	async mounted() {
		this.checkServerStatus();
		document.addEventListener('visibilitychange', this.handleVisibilityChange);
		setInterval(function () {
			this.checkServerStatus();
		}.bind(this), 10000);
	},
	beforeDestroy() {
		document.removeEventListener('visibilitychange', this.handleVisibilityChange);
	},
	methods: {
		async checkServerStatus() {
			let parseServerStatus = Parse.getServerHealth()
			let timeoutID = setTimeout(function () { this.hasNoConnection = true }.bind(this), 3000)
			parseServerStatus.then(() => { //value
				this.checkVersion()
				this.hasNoConnection = false
				clearTimeout(timeoutID);
			})
			parseServerStatus.catch(() => { //error
				this.hasNoConnection = true
				clearTimeout(timeoutID);
			})
		},
		async checkVersion() {
			var apiVersion = await Parse.Cloud.run("getDashboardVersion")
			this.$store.dispatch("versions/setApiVersion", apiVersion);
			if (!this.dismissUpdate) {
				if (apiVersion != this.applicationVersion) {
					this.isVersionModalActive = true
				} else {
					this.isVersionModalActive = false
				}
			}
		},
		handleVisibilityChange() {
			if (!document.hidden) {
				let parseServerStatus = Parse.getServerHealth()
				let timeoutID = setTimeout(function () { this.hasNoConnection = true }.bind(this), 3000)
				parseServerStatus.then(() => { //value
					this.hasNoConnection = false
					clearTimeout(timeoutID);
					
					if (!this.dismissUpdate) {
						if (this.apiVersion != this.applicationVersion) {
							this.isVersionModalActive = true
						} else {
							this.isVersionModalActive = false
						}
					}
				})
				parseServerStatus.catch(() => { //error
					
					this.hasNoConnection = true
					clearTimeout(timeoutID);
				})
			}
		},
		async routeHome() {
			if (this.$route.path !== "/") await this.$router.push({ path: "/" });

		},
		showUsername() {
			if (this.userDetails !== undefined) {
				return true
			}
			if (this.$route.path.includes("login") || this.$route.path.includes("log_out")) {
				return false
			}
			return false;
		},
		getPicture() {
			const pictureObject = {
				newProfilePicture: undefined,
				userPicture: this.userDetails.attributes.profile_picture ? this.userDetails.attributes.profile_picture._url : undefined,
				userGender: this.userDetails.attributes.gender
			}
			return getUserPicture(pictureObject)
		},
		getUserName() {
			return `${this.userDetails.attributes.first_name} ${this.userDetails.attributes.last_name}`
		},
	},
}
</script>

<style scoped>
::v-deep .img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.barelement {
	color: white;
}

.logOutElement:hover {
	background-color:rgb(74, 74, 74);
}
</style>
