<template>
  <div
    class="m-5 p-5"
    style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 100%; width: 100%"
  >
    <div class="panel-heading mt-3">
      Setup
    </div>
    <div
      class="panel-block"
      style="display: flex; flex-direction: column"
    >
      <div
        v-if="isMounted"
        style="width: 100%"
      >
        <NewUser :on-boarding="true" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * this file is for user onboarding
 * It allows creation of an initial user/admin and set up roles, the first entry type, the birthday category into an empty database
 * required password is the password for parse dashboard
 */
import NewUser from "../user/NewUser";
import Parse from "parse/dist/parse.min.js";
export default {
	name: "OnboardingComponent",
	components:{
		NewUser
	},
	data(){
		return{
			isMounted: false
		}
	},
	async mounted() {
		await Parse.Cloud.run("createRoles")
		await Parse.Cloud.run("createStandardEntryType")
		this.isMounted = true

	}
}
</script>

<style scoped>

</style>
