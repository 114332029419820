<template>
  <b-modal
    v-model="isActive"
    has-modal-card
    :can-cancel="false"
    @close="closeModal()"
  >
    <div
      class="modal-card modalCardContainer"
      style="
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
					"
    >
      <header
        class="modal-card-head"
        style="background-color: rgba(40, 40, 40, 1); color: white"
      >
        Wichtiger Hinweis
      </header>
      <section
        class="modal-card-body"
        style="padding: 0.5em; color: black"
      >
        <P>
          Sie verwenden eine veraltete Version. Ein Update wird dringend empfohlen.
          Mit "Jetzt updaten" werden Sie abgemeldet und wechseln unmittelbar auf die neue Version. Nicht
          gespeicherte Inhalte gehen dabei verloren.
        </P>
        <P>
          Um Ihre Arbeiten vorher abzuschließen, können Sie das "Später updaten". (Es erfolgt keine weitere
          Erinnerung.)
        </P>
        <P>
          Eine längere Verschiebung des Updates wird nicht empfohlen.
          Um das Update manuell anstoßen, melden Sie sich bitte aus dem System ab.
        </P>
      </section>
      <footer
        class="modal-card-foot footerContainer"
        style="background-color: rgba(40, 40, 40, 1); color: white"
      >
        <b-button
          type="is-primary"
          @click="updateLater"
        >
          Später updaten
        </b-button>
        <b-button
          type="is-primary"
          @click="updateNow"
        >
          Jetzt updaten
        </b-button>
      </footer>
    </div>
  </b-modal>
</template>

<script>

import { mapGetters } from "vuex";
import {negativeFeedBack, positiveFeedBack} from "@/lib/Toast";
import Parse from "parse/dist/parse.min.js";

export default {
	name: "VersionModal",
	props: ["value"],
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			dismissUpdate: "versions/getdismissUpdate",
		}),
		isActive: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
	mounted() { },
	methods: {
		closeModal() {
			this.isActive = false;
		},
		updateLater() {
			this.$store.dispatch("versions/setDismissUpdate", true);
			this.isActive = false;
		},
		async updateNow() {
				this.isLoading = true
				try {
					await Parse.User.logOut()
					await this.$router.push("/login")
					positiveFeedBack("Nutzer abgemeldet!")
					location.reload()
				} catch (e) {
					console.error("couldnt logout the user " + e)
					negativeFeedBack("Abmeldung fehlgeschlagen!")
				}
				this.isLoading = false
		}
	}
}

</script>

<style scoped></style>
