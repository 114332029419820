<template>
  <div class="barContainer is-flex is-flex-direction-column">
    <div
      class="
                scrollDiv
                is-flex
                is-flex-direction-row
                is-justify-content-center
                is-align-content-center
                is-flex-wrap-wrap
            "
      :style="getScrollDivStyle()"
      style="height: 100%; overflow-y: hidden"
    >
      <div
        v-for="(routeObject, index) in routeObjects"
        :key="index"
        class="routeContainer"
        :class="[
          { disabledRoute: !routeObject.isActivated },
        ]"
        @click="route(routeObject)"
      >
        <div class="route m-1">
          <template v-if="routeObject.isActivated">
            <b-button
              :icon-left="routeObject.icon"
              :size="isMobile() ? 'is-small' : ''"
              :class="[
                {
                  selectedRoute: selectedRoute(
                    routeObject.route
                  ),
                },
                {
                  disabledRouteIcon: !routeObject.isActivated,
                },
              ]"
              @click="unselectClient"
            >
              {{ routeObject.text }}
            </b-button>
          </template>
          <template v-else>
            <b-button
              :key="activeRoute"
              :icon-left="routeObject.icon"
              :size="isMobile() ? 'is-small' : ''"
              :class="[
                {
                  selectedRoute: selectedRoute(
                    routeObject.route
                  ),
                },
                { disabledRouteIcon: !routeObject.isActivated },
              ]"
            >
              {{ routeObject.text }}
            </b-button>
          </template>
        </div>
      </div>
    </div>
    <div
      class="
                is-flex
                is-flex-direction-row
                is-justify-content-center
                is-align-content-center
                m-3
            "
    >
      <figure
        class="image mt-3"
        style="width: 150px"
      >
        <img :src="require('../../assets/logo/' + app_logo_copyright)">
      </figure>
    </div>
  </div>
</template>

<script>
/**
 * BottomBar for fast and easy module navigation.
 * @displayName AboutBar
 */
import { mapGetters } from "vuex";
import { APP_LOGO_COPYRIGHT } from "@/lib/config/AppConfigurations";

export default {
    name: "AboutBar",
    data() {
        const routeObjects = [
            {
                text: "Impressum",
                icon: "help-circle",
                route: "/imprint",
                isActivated: true,
            },
            {
                text: "Datenschutz",
                icon: "shield",
                route: "/dataprotection",
                isActivated: true,
            },
        ];
        return {
            routeObjects: routeObjects,
			app_logo_copyright: APP_LOGO_COPYRIGHT
        };
    },
	computed: {
        activeRoute() {
            return this.$route.path;
        },
        ...mapGetters({}),
    },
    methods: {
        async route(object) {
            const { route, isActivated } = object;
            if (!this.$store.state.focusedClientId)
                await this.$store.dispatch("setSidePanelOpen", true);
            if (this.selectedRoute(route) || !isActivated) return;
            await this.$router.push({ path: route });
        },
        selectedRoute(route) {
            if (route === "/") return this.activeRoute === route;
            return this.activeRoute.includes(route);
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        getScrollDivStyle() {
            let style = "";
            style += `min-width: ${(this.routeObjects.length + 2) * 40}px;`;
            return style;
        },
        unselectClient() {
            this.$store.dispatch("clients/setFocusedClientId", undefined);
        },
    },
};
</script>

<style scoped>
.scrollDiv {
    height: 100%;
}
.routeText {
    text-align: center;
}

.barContainer {
    width: 100%;
    min-height: 30px;
    overflow: auto;
}

.routeContainer {
    cursor: pointer;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.route {
    min-height: 2.5rem;
    display: flex;
    align-items: center;
}

.selectedRoute {
    background-color: rgba(0, 0, 0, 0.8);
}

::v-deep .selectedRoute .mdi {
    color: whitesmoke;
}

::v-deep .notificationRoute .mdi {
    color: red;
}

.disabledRouteIcon {
    padding: 1rem;
    cursor: default;
    background-color: lightgrey;
}

.disabledRoute {
    cursor: default;
}

@media (max-width: 769px) {
    .bar {
        max-width: 95%;
    }
}
</style>
