<template>
	<b-field :message="hasValidation && !$v.selectedDate.$model ? message : ''"
		:type="hasValidation ? $v.selectedDate.$model ? 'is-success' : 'is-danger' : ''">
		<template #label>
			<div class="align-content-center" style="display: inline-flex">
				<p>{{ label }}</p>
			</div>
		</template>
		<b-datepicker v-model="date" :first-day-of-week="1" class="m-2" locale="de-DE" placeholder="Datum wählen..."
			icon="calendar-today" trap-focus editable :date-formatter="dateFormatter" @input="set()" />
	</b-field>
</template>
  
<script>

import { required } from "vuelidate/lib/validators";
export default {
	name: "ValidatedDatePicker",
	props: ["label", "selectedDate", "select", "message", "hasValidation"],
	data() {
		return {
			date: this.selectedDate
		}
	},
	methods: {
		set(){
			this.select(this.date)
		},
		dateFormatter(dt) {
			return dt.toLocaleDateString("de-DE", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			});
		},
	},
	validations() {
		if (this.hasValidation) {
			return {
				selectedDate: { required, }
			}
		}
	}
}
</script>
  
<style scoped></style>
  