<template>
  <div>
    <EntryTypes />
    <EntryAttributes />
  </div>
</template>

<script>
import EntryTypes from "@/components/diary/entryTypes/EntryTypes";
import EntryAttributes from "@/components/diary/entryAttributes/EntryAttributes";

export default {
	name: "DiaryComponent",
	components: {EntryAttributes, EntryTypes}
}
</script>

<style scoped>

</style>