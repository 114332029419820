import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import Vuelidate from 'vuelidate'
import VueLogger from 'vue-logger-plugin'

import Parse from 'parse/dist/parse.min.js';
import { APP_ID, APP_KEY, SERVER_URL } from "./lib/config/AppConfigurations";
import store from './store/store';
import {router} from "@/lib/router";
/**
 * initialization
 */
Vue.config.productionTip = false

Parse.initialize(APP_ID, APP_KEY);

Parse.serverURL = SERVER_URL


Vue.use(Buefy)
//Vuelidate include
Vue.use(Vuelidate);
Vue.use(VueRouter)
Vue.use(
	new VueLogger({
		//enabled: true,
		level: "debug",
		callerInfo: true,
		//beforeHooks: [ ... ],
		//afterHooks: [ ... ]
	}),
  );
Vue.config.productionTip = false

require('@/assets/styles.scss');

new Vue({
	store,
	router,
	mounted: function () { },
	render: h => h(App)
}).$mount('#app')


