<template>
  <div>
    <div class="container p-3">
      <div class="columns">
        <div class="column">
          <b-field label="Name">
            <b-input v-model="name" />
          </b-field>
          <div
            v-if="!$v.name.required"
            class="tag is-danger is-light"
          >
            Name benötigt!
          </div>
        </div>
        <div class="column">
          <b-field label="Link">
            <b-input v-model="link" />
          </b-field>
          <div
            v-if="!$v.link.required"
            class="tag is-danger is-light"
          >
            Link benötigt!
          </div>
          <div
            v-if="!$v.link.url"
            class="tag is-danger is-light"
          >
            Korrekten Link einfügen!
          </div>
        </div>
        <div class="column is-one-fifth">
          <b-field label="Icon">
            <b-dropdown
              v-model="icon"
              aria-role="list"
              :scrollable="true"
            >
              <template #trigger>
                <b-button
                  type="is-primary"
                  :icon-left="icon"
                  icon-right="menu-down"
                />
              </template>


              <b-dropdown-item
                v-for="option in ICONS"
                :key="option"
                :value="option"
                aria-role="listitem"
              >
                <div class="media">
                  <b-icon
                    class="media-left"
                    :icon="option"
                  />
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
          <div
            v-if="!$v.icon.required"
            class="tag is-danger is-light"
          >
            Icon benötigt!
          </div>
        </div>
      </div>
    </div>
    <div
      class="buttons m-5"
      style="display: flex; justify-content: center"
    >
      <b-button
        class="mt-3 button"
        type="is-primary"
        :loading="isLoading"
        :disabled="$v.$invalid"
        @click="saveWidget"
      >
        Speichern
      </b-button>
      <b-button
        class="mt-3 button"
        type="is-danger"
        tag="router-link"
        :to="'/widgets'"
      >
        Abbrechen
      </b-button>
    </div>
  </div>
</template>

<script>
/**
 * Module allowing the creation of a new goal.
 * @displayName GoalInput
 */

import { required, url } from "vuelidate/lib/validators";
import Parse from "parse/dist/parse.min.js";
import { icon_list } from '../../../src/assets/icon_list.js'

export default {
	name: "NewWidget",
	validations: {
		name: {
			required
		},
		link: {
			required,
			url
		},
		icon: {
			required
		}
	},
	data() {
		return {
			name: "",
			link: "",
			icon: "",
			isLoading: false,
			ICONS: icon_list
		};
	},
	computed: {	},
	mounted() { },
	methods: {
		async saveWidget() {
			this.isLoading = true;

			const widgetParams = {
				metaclassId: "",
				title: this.name,
				icon: this.icon,
				link: this.link,
				isGlobal: true
			};
			await Parse.Cloud.run("saveWidget", widgetParams);
			this.isLoading = false;
			await this.$router.push({ name: "WidgetOverview" });
		}
	},

};
</script>

<style lang="scss" scoped></style>
