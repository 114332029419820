<template>
  <div>
    <SearchableTable
      :enable-header="false"
      :panel-header="'PDFs'"
      :show-search-bar="false"
      :table-data="filteredPdfs"
      :table-meta-data="tableMetaData"
      :query-string="'setPersonSearchQuery'"
    >
      <b-table-column
        v-slot="props"
        label="Aktionen"
        cell-class="has-text-right truncate-column"
        header-class="th-right truncate-column"
      >
        <b-button
          class="is-small mr-2"
          @click="triggerView(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="eye"
            style="cursor: pointer"
          />
        </b-button>
        <b-button
          class="is-small mr-2"
          @click="triggerEdit(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="pencil"
            style="cursor: pointer;"
          />
        </b-button>
        <b-button
          class="is-small"
          @click="triggerDelete(props.row)"
        >
          <b-icon
            class="mt-1"
            icon="delete"
            style="cursor: pointer"
          />
        </b-button>
      </b-table-column>
    </SearchableTable>
  </div>
</template>

<script>
/**
 * This file shows all uploaded pdfs for a focused client and focused category
 */
import { queryDocumentCreators } from "@/lib/Query";
import SearchableTable from "../helperComponents/SearchableTable.vue";

export default {
	name: "PdfTable",
	components: { SearchableTable },
	props: ["pdfs", "deletePdf", "editPdf"],
	data() {
		return {
			tableMetaData: [
				{ "id": 0, "type": "string", "label": "Titel", "field": "title", "sortField": "attributes.title" },
				{ "id": 1, "type": "string", "label": "Typ", "field": "documentType", "sortField": "attributes.documentType" },
				{ "id": 2, "type": "date", "label": "Datum", "field": "date", "sortField": "attributes.date" },
				{ "id": 3, "type": "multiple", "label": "Klient", "field": ["first_name", "last_name"], "sortField": "attributes.date", "refName": "client" },
				{ "id": 4, "type": "string", "label": "Ersteller", "field": "creator", "sortField": "attributes.creator" }
			]
		}
	},
	computed: {
		filteredPdfs() {
			let filtered_pdfs = []
			if (this.pdfs.length === 0) return []
			this.pdfs.forEach(element => {
				if(element.attributes.deleted != true){
					filtered_pdfs.push(element)
				}
			});
			return filtered_pdfs
		},
	},
	async created() {
		await queryDocumentCreators()
	},
	methods: {
		triggerDelete(instance) {
			this.deletePdf(instance)
		},
		triggerEdit(instance) {
			this.editPdf(instance)
		},
		triggerView(pdf) {
			
			window.open(pdf.attributes.pdf._url, '_blank').focus();
		}
	}
}
</script>

<style scoped></style>
