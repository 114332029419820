/**
 * This file contains important picture functions, like showing the profile picture and default profile picture and to resize uploaded images
 */


export const getClientPicture = ({newProfilePicture, clientPicture, clientGender}) =>{
	if (newProfilePicture) {
		return URL.createObjectURL(newProfilePicture);
	}
	if (clientPicture) {
		return clientPicture;
	}
	if (clientGender === "weiblich") {
		return require("../assets/client_female.svg");
	}
	else if( clientGender === "männlich"){
		return require("../assets/client_male.svg");
	}else{
		return require("../assets/profile - d.svg");
	}
}

export const getUserPicture = ({newProfilePicture, userPicture, userGender}) =>{
	if (newProfilePicture) {
		return URL.createObjectURL(newProfilePicture);
	}
	if (userPicture) {
		return userPicture;
	}
	if (userGender === "weiblich") {
		return require("../assets/user_female.svg");
	}
	else if( userGender === "männlich"){
		return require("../assets/user_male.svg");
	}else{
		return require("../assets/profile - user - d.svg");
	}
}

export function resizeImage(value) {
	return new Promise((resolve) => {
		var img = document.createElement("img");
		img.src = window.URL.createObjectURL(value);

		img.onload = function () {
			var MAX_WIDTH = 150;
			var MAX_HEIGHT = 150;
			var width = img.width;
			var height = img.height;

			if (width > height) {
				if (width > MAX_WIDTH) {
					height *= MAX_WIDTH / width;
					width = MAX_WIDTH;
				}
			} else {
				if (height > MAX_HEIGHT) {
					width *= MAX_HEIGHT / height;
					height = MAX_HEIGHT;
				}
			}
			var canvas = document.createElement("canvas");
			canvas.width = width;
			canvas.height = height;
			var ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0, width, height);

			var dataurl = canvas.toDataURL("image/jpeg");

			var base64 = dataurl.split("base64,")[1];
			resolve(base64);
		};
	});
}
